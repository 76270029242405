import {
	FieldSet,
	SecureDownload,
	Select,
} from '../../../Components';
import React from 'react';
import {
	safeChemistryFileName
} from '../../../utility';

export class EndpointDownload extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = ({
			selectedEndpoint: null,
		});

		this.handleChange = this.handleChange.bind(this);
	}

	handleChange = (id, value) => {
		this.setState({
			selectedEndpoint: value,
		});
	};

	render() {
		const {
			endpoints,
			endpointsRetrieved,
		} = this.props;
		const {
			selectedEndpoint = '',
		} = this.state;

		const {
			aaaKey,
			dialog,
			handleShowSpinner,
			logError,
			statisticsEndpoint,
		} = this.props;

		const nowDate = new Date();
		const strDate = `${nowDate.getDate()}-${(nowDate.getMonth() + 1)}-${nowDate.getFullYear()}`;

		const renderedOptions = [];
		if (endpoints) {
			renderedOptions.push(
				{ name: "Select an endpoint...", value: '' }
			);
			endpoints.forEach((endpoint) => {
				renderedOptions.push(
					{ name: endpoint.endpointName, value: endpoint.endpointId }
				);
			});
		}

		let endpointFileName = '';
		if (selectedEndpoint !== '') {
			const selectedEndpointObject = endpoints.find((item) => item.endpointId === selectedEndpoint);
			if (selectedEndpointObject) endpointFileName = selectedEndpointObject.endpointName;
			endpointFileName = safeChemistryFileName(endpointFileName);
		}

		if (endpointsRetrieved && statisticsEndpoint) return (
			<div id={'endPointDownload'}>
				<form>
					<FieldSet>
						<Select
							handleChange={this.handleChange}
							id='endPoints'
							label=''
							options={renderedOptions}
							value={selectedEndpoint}
						/>
					</FieldSet>
				</form>
				<p id='endPointDownloadLink' className={`${selectedEndpoint ? 'show' : ''}`}>CSV: <SecureDownload
					aaaKey={aaaKey}
					contentType='text/csv'
					dialog={dialog}
					handleShowSpinner={handleShowSpinner}
					fileName={`${endpointFileName}-${strDate}.csv`}
					href={`${statisticsEndpoint}/model/validation/external/endpointResults/${selectedEndpoint}`}
					logError={logError}
				/></p>
			</div>
		);
		return (<></>);
	}
};
