import './AdminContact.scss';
import '../../styles/LoginAndPasswordReset.scss';

import {
	FieldSet,
	HelpButton,
	Page,
	TextField,
} from '../../Components';
import React from 'react';

export class AdminContact extends React.PureComponent {

	constructor(props) {

		super(props);

		const { adminEmailAddress = '' } = this.props;
		this.state = ({
			adminEmailAddress: adminEmailAddress,
			valid: true,
		});

		this.handleChange = this.handleChange.bind(this);
		this.validate = this.validate.bind(this);

	}

	componentDidMount = () => {
		const { adminEmailAddress } = this.state;
		if (adminEmailAddress !== '')this.validate();
	};

	handleChange = (what, value) => {
		this.setState({
			adminEmailAddress: value,
		}, this.validate);
	};

	validateEmail = (email) => {
		const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	};

	validate = () => {
		const {
			adminEmailAddress,
		} = this.state;
		const valid = this.validateEmail(adminEmailAddress);
		this.setState({
			valid: valid
		});
	};

	render() {

		const {
			adminEmailAddress,
			valid,
		} = this.state;

		const {
			appName,
			handleChangeAdminEmail,
			handleHomeClick,
			version,
		} = this.props;

		return (
			<Page
				appName={appName}
				handleHomeClick={handleHomeClick}
				version={version}
			>
				<div id='adminContactPage'>
					<form
						className={`form admin-contact `}
					>
						<FieldSet controlId="email">
							<p>This email address will be used when a user clicks the ‘Forgotten password’ link in the {appName} Administration interface or the StarDrop user interface. </p>
							<TextField
								error={valid ? '' : 'Please enter a valid email address'}
								id='email'
								handleChange={this.handleChange}
								placeholder='Administrator email address'
								label='Administrator email address'

								tabIndex={1}
								type={'email'}
								value={adminEmailAddress}
							/>
						</FieldSet>

						<div className='button-group ok-cancel'>
							<button
								className='home'
								id={`adminContactButtonCancel`}
								onClick={handleHomeClick}
							>Cancel</button>
							<button
								className={`home ${valid ? '' : 'disabled'}`}
								disabled={!valid}
								onClick={(e) => {
									e.preventDefault();
									handleChangeAdminEmail(adminEmailAddress);
								}}
							>OK</button>
							<HelpButton/>
						</div>
					</form>
				</div >
			</Page >
		);
	}
}