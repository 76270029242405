import './CompoundEditor.scss';
import '../../styles/LoginAndPasswordReset.scss';

import {
	copyObject,
	handleResponse,
} from '../../utility';
import {
	DateField,
	FieldSet,
	SearchBox,
	Select,
	TextField,
} from '../../Components';
import React from 'react';

export class CompoundEditor extends React.Component {

	constructor(props) {

		super(props);

		this.state = ({
			compoundFound: true,
			showForm: false,
			valid: true,
		});

		this.handleCancel = this.handleCancel.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChangeDate = this.handleChangeDate.bind(this);
		this.handleChangeSearch = this.handleChangeSearch.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleSearch = this.handleSearch.bind(this);
		this.handleUpdate = this.handleUpdate.bind(this);
		this.renderFields = this.renderFields.bind(this);
		this.validate = this.validate.bind(this);

	}

	handleCancel = () => {
		this.setState({
			showForm: false,
		});
	};

	handleChange = (field, value) => {
		// console.log(`handleChange(${field},${value})`);
		const {
			compoundData,
			dynamicColumns,
		} = this.state;

		if (field.includes('dynamicField')) {
			const id = parseInt(field.replace('dynamicField', ''));
			// console.log("dynamic field ID", id);
			const df = dynamicColumns.find((dc) => dc.fieldId === id);
			const {
				fieldName
			} = df;
			let { userMetadata } = compoundData;
			if (!userMetadata) {
				userMetadata = [];
				userMetadata.push({
					fieldId: id,
					fieldName: fieldName,
					value: value,
				});
			} else {
				const foundIndex = userMetadata.findIndex((um) => um.fieldId === id);
				if (foundIndex > -1) {
					userMetadata[foundIndex] = {
						fieldId: id,
						fieldName: fieldName,
						value: value,
					};
				} else {
					userMetadata.push({
						fieldId: id,
						fieldName: fieldName,
						value: value,
					});
				}
			}
			compoundData.userMetadata = userMetadata;
		} else {
			compoundData[field] = value;
		}
		this.setState({ compoundData: compoundData });// , this.validate);
	};

	handleChangeDate = (field, value) => {
		// console.log(`handleChangeDate(${field},${value})`);
		const {
			compoundData,
		} = this.state;
		const d = new Date(value);
		const epoch = d.getTime() / 1000;
		compoundData[field] = epoch;

		this.setState({ compoundData: compoundData });// , this.validate);
	};

	handleChangeSearch = (what, searchTerm) => {
		// console.log("handleChangeSearch");
		this.setState({
			searchTerm: searchTerm,
		}, this.validate);
	};

	handleDelete = () => {
		const{
			searchTerm,
		} = this.state;
		const {
			aaaKey,
			dialog,
			handleShowSpinner,
			ideaTrackerService,
			logError,
		} = this.props;

		handleShowSpinner(true, 'Deleting compound...');

		const IDTheaders = new Headers();
		IDTheaders.append("X-api-key", aaaKey);

		const requestOptions = {
			headers: IDTheaders,
			method: 'DELETE',
			redirect: 'follow',
		};

		fetch(`${ideaTrackerService}/compounds/id/${searchTerm}`, requestOptions)
			.then(handleResponse)
			.then(res => {
				// console.log("res", res);
				this.setState({
					searchTerm: '',
					showForm: false,
				});
				handleShowSpinner(false);
				dialog("Compound deleted", 'compound', 'clear');
			})
			.catch(error => {
				const action = `Deleting compound`;
				logError(action, error);
				handleShowSpinner(false);
			});
	};

	handleSearch = (e) => {
		e.stopPropagation();
		e.preventDefault();

		const {
			aaaKey,
			handleShowSpinner,
			ideaTrackerService,
			logError,
		} = this.props;

		this.setState({compoundData: undefined});

		handleShowSpinner(true, 'Searching...');

		// Get Schema
		// ==========
		const IDTSchemaHeaders = new Headers();
		IDTSchemaHeaders.append("X-api-key", aaaKey);

		const IDTSchemaRequestOptions = {
			headers: IDTSchemaHeaders,
			method: 'GET',
			redirect: 'follow',
		};

		const schemaPromise = fetch(`${ideaTrackerService}/compounds/schema`, IDTSchemaRequestOptions)
			.then(handleResponse)
			.then(res => {

				const {
					dynamicColumns,
					staticColumns,
				} = res;

				this.setState({
					dynamicColumns: dynamicColumns,
					staticColumns:staticColumns,
				});

				return Promise.resolve();
			})
			.catch(error => {
				logError("Searching for compound", error);
				return Promise.resolve();
			});

		// Search compound
		// ===============
		const { searchTerm } = this.state;
		this.validate();
		// console.log("handleSearch searchTerm", searchTerm);

		const IDTheaders = new Headers();
		IDTheaders.append("X-api-key", aaaKey);
		IDTheaders.append("Content-Type", "application/json");

		const payload = {"ideaTrackerIDs": [searchTerm]};

		const searchTerms = [];
		searchTerms.push(searchTerm);

		const raw = JSON.stringify(payload);

		const IDTRequestOptions = {
			body: raw,
			headers: IDTheaders,
			method: 'PUT',
			// mode: 'no-cors',
			redirect: 'follow',
		};

		const compoundPromise = fetch(`${ideaTrackerService}/compounds/compoundsByID`, IDTRequestOptions)
			.then(handleResponse)
			.then(res => {
				// console.log("res", res);
				if (res.length === 1) {
					this.setState({
						compoundData: res[0],
						compoundFound: true,
						showForm: true,
					}, () => handleShowSpinner(false));
				} else {
					this.setState({
						compoundData: undefined,
						compoundFound: false,
						showForm: false,
					}, () => handleShowSpinner(false));
				}
				return Promise.resolve();
			})
			.catch(error => {
				logError("Searching for compound", error);
				handleShowSpinner(false);
				return Promise.resolve();
			});

		return Promise.all([schemaPromise, compoundPromise]);

	};

	handleUpdate = () => {
		const {
			compoundData,
			searchTerm,
		} = this.state;
		const {
			aaaKey,
			dialog,
			handleShowSpinner,
			ideaTrackerService,
			logError,
		} = this.props;
		// console.log("handleUpdate", searchTerm);
		handleShowSpinner(true, "Updating compound...");

		const payload = copyObject(compoundData);

		// Delete "read only" fields
		delete payload.compound;
		delete payload.compoundFormat;
		delete payload.compoundHash;
		delete payload.ideaTrackerID;
		delete payload.lastUpdateTimestamp;
		delete payload.regID;
		delete payload.requestID;

		// Strip out any metadata set to null (empty string), this signals to the server to remove it.
		const { userMetadata } = payload;
		const newUserMetadata = userMetadata.filter((md) => {
			return md.value !== '';
		});
		payload.userMetadata = newUserMetadata;

		const raw = JSON.stringify(payload);

		const IDTheaders = new Headers();
		IDTheaders.append("X-api-key", aaaKey);
		IDTheaders.append("Content-Type", "application/json");

		const requestOptions = {
			body: raw,
			headers: IDTheaders,
			method: 'PUT',
			redirect: 'follow',
		};

		fetch(`${ideaTrackerService}/compounds/id/${searchTerm}`, requestOptions)
			.then(handleResponse)
			.then(res => {
				// console.log("res", res);
				this.setState({
					compoundData: res,
				}, () => {
					handleShowSpinner(false);
					dialog("Compound updated", 'compound', 'clear');
				});
			})
			.catch(error => {
				const action = `Updating compound`;
				logError(action, error);
				handleShowSpinner(false);
			});
	};

	renderDynamicField = (field, fieldsJSX, className) => {
		const {
			allowedValues,
			fieldId,
			fieldName,
		} = field;
		const {
			compoundData
		} = this.state;
		if (compoundData) {
			const { userMetadata } = compoundData;
			let value;
			if (userMetadata) {
				const foundItem = userMetadata.find((um) => um.fieldId === fieldId);
				if (foundItem) ({ value } = foundItem);
			}

			if (allowedValues && allowedValues.length > 0) {
				const options = [];
				options.push({'name': '', 'value': null });
				allowedValues.forEach((av) => {
					options.push({ 'name': av, 'value': av });
				});
				fieldsJSX.push(
					<Select
						className={className}
						handleChange={this.handleChange}
						id={`dynamicField${fieldId}`}
						key={fieldId}
						label={fieldName}
						options={options}
						value={value}
					/>
				);
			} else {
				fieldsJSX.push(
					<TextField
						className={className}
						handleChange={this.handleChange}
						id={`dynamicField${fieldId}`}
						key={fieldId}
						label={fieldName}
						value={value}
					/>
				);
			}
			return fieldsJSX;
		}
		return;
	};

	renderStaticField = (key, field, fieldsJSX) => {
		const {
			compoundData,
		} = this.state;
		if (compoundData) {
			const {
				fieldName,
				fieldType,
			} = field;
			// console.log("renderStaticField fieldType", fieldType, fieldName, key);
			let value = '';

			switch (fieldType) {
				// Idea Tracker ID, Compound Hash, Compound
				case 'aggregate':
					value = [];
					if (compoundData && compoundData) value = compoundData[key];
					// console.log("aggregate value", value);
					const pairsJSX = [];
					value.forEach((pair) => {
						pairsJSX.push(
							<tr key={`tr${pair.clientId}`}>
								<td key={`td${pair.clientId}`}>{pair.clientId}</td>
								<td key={`td${pair.dataSourceId}`}>{pair.dataSourceId}</td>
							</tr>
						);
					});
					fieldsJSX.push(
						<div
							className='row'
							key={key}
						>
							<label>RegID</label>
							<table className={`striped sub-table`}>
								<thead>
									<tr><th>clientId</th><th>dataSourceId</th></tr>
									{pairsJSX}
								</thead>
							</table>
						</div>
					);
					break;
				case 'datetime':
					if (compoundData && compoundData) value = compoundData[key];
					const d = new Date(value * 1000);

					const strD = `${d.getFullYear()}-${(`0${d.getMonth() + 1}`).slice(-2)}-${(`0${d.getDate()}`).slice(-2)}T${(`0${d.getHours()}`).slice(-2)}:${(`0${d.getMinutes()}`).slice(-2)}:${(`0${d.getSeconds()}`).slice(-2)}`;
					// console.log("strD", strD);
					fieldsJSX.push(
						<DateField
							className={`short`}
							handleChange={this.handleChangeDate}
							id={key}
							key={key}
							label={fieldName}
							step={1}
							time={true}
							value={strD}
						/>
					);
					break;
				default:
					switch (fieldName) {
						case 'Idea Tracker ID':
						case 'Compound Hash':
						case 'Compound':
							// Ultimately it would be nice to make the GUI agnostic, by having a readonly flag in the schema,
							// along with field length and a mandatory flag (i.e. no null). See IDT-717
							if (compoundData && compoundData) value = compoundData[key];
							if (value === null) value = '';
							fieldsJSX.push(
								<TextField
									elide={true}
									handleChange={this.handleChange}
									id={key}
									key={key}
									label={fieldName}
									readOnly={true}
									value={value}
								/>
							);
							break;
						default:
							if (compoundData && compoundData) value = compoundData[key];
							if (value === null) value = '';
							// console.log("string value", value, key);
							fieldsJSX.push(
								<TextField
									className={`short`}
									elide={true}
									handleChange={this.handleChange}
									id={key}
									key={key}
									label={fieldName}
									value={value}
								/>
							);
							break;
					}
					break;
			}
			return fieldsJSX;
		}
		return;
	};

	renderFields = () => {
		const {
			dynamicColumns = [],
			staticColumns = [],
		} = this.state;
		// console.log("renderFields");
		let fieldsJSX = [];

		if (dynamicColumns.length === 0 && staticColumns.length === 0) return;

		for (const [key, keyedObject] of Object.entries(staticColumns)) {
			fieldsJSX = this.renderStaticField(key, keyedObject, fieldsJSX);
		};
		let first = true;
		dynamicColumns.forEach((dc) => {
			fieldsJSX = this.renderDynamicField(dc, fieldsJSX, `short ${first ? 'first' : ''}`);
			first = false;
		});

		return fieldsJSX;
	};

	render = () => {

		const {
			compoundFound,
			searchTerm = '',
			showForm,
			valid,
		} = this.state;

		return (
			<div id='compoundEditor'>
				<p>Search for a compound to modify or delete. </p>
				<form
					className={`form compound-editor`}
					onSubmit={(e) => {
						e.preventDefault();
						e.stopPropagation();
						// console.log("submit");
						if(searchTerm !== '')this.handleSearch(e);
					}}
				>
					<label htmlFor='compoundId'>Idea Tracker ID:&nbsp;
						<SearchBox
							className={`compound-editor`}
							handleChangeSearch={this.handleChangeSearch}
							id={`compoundId`}
							searchTerm={searchTerm}
							tabIndex={0}
						/>
						<button
							className={`button compound-editor ${searchTerm === '' ? 'disabled' : ''}`}
							disabled={searchTerm === ''}
							id={`searchButton`}
							onClick={(e) => this.handleSearch(e)}
						>Search</button>
					</label>
					{showForm ?
						<>
							<FieldSet>
								{this.renderFields()}
							</FieldSet>
							{!compoundFound ? <h2 className={`none-found`}>No compound found with that ID</h2> : null}

							<div className={`button-group delete-ok-cancel`}>
								<button
									className='compound-editor delete'
									id={`compoundEditButtonDelete`}
									onClick={(e) => {
										e.preventDefault();
										this.handleDelete();
									}}
								>Delete</button>
								<button
									className='compound-editor'
									id={`compoundEditButtonCancel`}
									onClick={(e) => {
										e.preventDefault();
										this.handleCancel();
									}}
								>Cancel</button>
								<button
									className={` compound-editor ${valid ? '' : 'disabled'}`}
									disabled={!valid}
									id={`compoundEditButtonOK`}
									onClick={(e) => {
										e.preventDefault();
										this.handleUpdate();
									}}
								>Update</button>
							</div>
						</>
						:
						null
					}
				</form>
			</div >
		);
	};

	validate = () => {
		const { searchTerm } = this.state;
		if (searchTerm === '') {
			this.setState({
				error: 'No search ID given', // Should never happen! Button should be disabled
				valid: false
			});

		} else {
			this.setState({
				error: '',
				valid: true
			});
		}
	};
}
