import './Page.scss';
import {
	Footer,
	Header,
} from '..';
import React from 'react';

export class Page extends React.PureComponent {
	render() {
		const {
			appName,
			handleShowAdminClick,
			id,
			showAdminLink = false,
		} = this.props;
		return (
			<div
				className="page"
				{...id ? { id: id } : {}}
			>
				<Header {...this.props} />
				{ this.props.children}
				<Footer
					appName={appName}
					handleShowAdminClick={handleShowAdminClick}
					showAdminLink={showAdminLink}
				/>
			</div >
		);
	};
}
