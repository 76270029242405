import './Tab.scss';
import React from 'react';

export class Tab extends React.PureComponent {
	static displayName = "Tab";
	render() {
		const {
			children,
			className = '',
			handleTabClick,
			id,
			selected = false,
		} = this.props;

		return (
			<li
				className={`${className} tab-component ${selected ? 'selected' : ''}`}
				{...id ? { id: id } : {}}
				onClick={handleTabClick}
			>{children}</li>
		);
	}
}
