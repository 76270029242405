import './PageControl.scss';
import React from 'react';

export class PageControl extends React.PureComponent {
	render() {
		const {
			handleChangePage,
			recordCount = 12,
			pageSelected,
			nItems,
			what,
		} = this.props;

		const nButtons = Math.ceil(nItems / recordCount);
		if (nButtons <= 1) return (<></>);
		const pageButtons = [];
		for (let i = 0; i < nButtons; i++) {
			if (
				nButtons < 8 ||
				i === 0 ||
				i === nButtons - 1 ||
				i === pageSelected - 1 ||
				i === pageSelected ||
				i === pageSelected + 1) {
				pageButtons.push(
					<button
						key={i}
						className={`${(i === pageSelected) ? 'selected' : ''}`}
						onClick={() => {
							handleChangePage(what, i);
						}}
					>{i + 1}</button >
				);
			} else if (
				(i === 1 && pageSelected > 2) ||
				(i === nButtons - 2 && pageSelected < nButtons - 3)
			) pageButtons.push(
				<button key={i} className='ellipses'>...</button>
			);
		}

		return (
			<div
				className={`
					page-control
					${what}
				`}
			>
				<button
					className={`button-previous ${pageSelected === 0 ? 'disabled' : ''} `}
					disabled={pageSelected === 0}
					onClick={() => { handleChangePage(what, pageSelected - 1); }}
				>Previous</button>
				{pageButtons}
				<button
					className={`button-next  ${pageSelected === nButtons - 1 ? 'disabled' : ''} `}
					disabled={pageSelected === nButtons - 1}
					onClick={() => { handleChangePage(what, pageSelected + 1); }}
				> Next</button >
			</div >
		);
	};
};;
