import './TabContent.scss';
import React from 'react';

export class TabContent extends React.PureComponent {
	static displayName = "TabContent";
	render() {
		const {
			children,
			className = '',
			selected = false,
		} = this.props;
		return (
			<div
				className={`
					${className} 
					tab-content-component
					${selected ? 'selected' : ''}
				`}
			>
				<div>
					{children}
				</div>
			</div>
		);
	}
}
