// import './Log.scss';
import colourVariables from '../../../styles/_colours.module.scss';
import Plot from 'react-plotly.js';
import React from 'react';

export class Log extends React.PureComponent {

	constructor(props) {
		super(props);

		this.state = {
			log: {
				layout: {
					height: 600,
					width: 800,
				},
			},
		};
	}

	componentDidUpdate = (prevProps) => {
		const {
			AAA,
			checkPermissionsLogError,
			logError,
			usageDateRangeData,
			usageServicesAndTargets,
		} = this.props;

		const {
			endDate,
			startDate,
		} = usageDateRangeData;

		const { log } = this.state;
		const { layout } = log;
		const {
			height: plotHeight,
			width: plotWidth
		} = layout;

		if (startDate !== '' && endDate !== '' && (startDate !== prevProps.startDate || endDate !== prevProps.endDate)) {
			const {
				cerellaDataSourceColourDark = '#efeb97',
				cerellaPolicyColour = '#6b627f',
				cerellaHomeColour = '#fdbd75',
				cerellaUserColour = '#5a99b7',
				cerellaGroupColour = '#89c878',
			} = colourVariables;

			const colourWheel = [
				cerellaHomeColour,
				cerellaUserColour,
				cerellaGroupColour,
				cerellaDataSourceColourDark,
				cerellaPolicyColour,
			];

			let request;
			try {
				request = new AAA.types.UsageCountRequest({
					"end": new Date(endDate).toISOString(),
					"query": usageServicesAndTargets,
					"start": new Date(startDate).toISOString(),
				});
			} catch (err) {
				logError(`Loading Usage Log - Temporarily unavailable`, err);
			}
			if (request) {
				// Check status here
				AAA.usageCount(request)
					.then((response) => {
						// Add Cerella colours to the response
						let wheelIndex = 0;
						response.forEach((item) => {
							item.line = {
								color: colourWheel[wheelIndex],
							};
							wheelIndex++;
							if (wheelIndex > colourWheel.length) wheelIndex = 0;
						});

						this.setState({
							log: {
								config: {
									displaylogo: false,
									responsive: true,
								},
								data: response,
								layout: {
									height: plotHeight,
									title: 'Cerella Usage',
									width: plotWidth,
								},
							},

						});
					})
					.catch(error => {
						const action = `Getting usage counts`;
						checkPermissionsLogError(error, action);
					});
			}

			this.loaded = true;
		}
	};

	render() {
		const {
			className,
			usageDateRangeData,
		} = this.props;

		const {
			endDate,
			startDate,
		} = usageDateRangeData;

		const { log } = this.state;
		const { layout } = log;
		const {
			height: plotHeight,
			width: plotWidth
		} = layout;

		return (
			<>
				{startDate && endDate ?
					<Plot
						className={`log chart ${className}`}
						divId='logPlot'
						useResizeHandler
						data={log.data}
						layout={log.layout}
						config={log.config} />
					:
					<div id='emptyLog' style={{ height: plotHeight, width: plotWidth }}>
						<h2>Please select a date range</h2>
					</div>
				}
			</>
		);
	}
}
