import '../../styles/LoginAndPasswordReset.scss';

import {
	FieldSet,
	Footer,
	HelpButton,
	Logo,
	Services,
	TextField,
} from '..';
import augmentedChemistry from '../../images/AugChem_logo.svg';
import cerella from '../../images/cerella_animate.svg';
import MicrosoftLogo from '../../images/microsoft-logo.png';
import React from 'react';

export class Login extends React.PureComponent {

	constructor(props) {

		super(props);

		const { userName = '' } = this.props;
		this.state = ({
			password: '',
			username: userName,
			valid: false,
		});

		this.handleChange = this.handleChange.bind(this);
		this.validate = this.validate.bind(this);
	}

	handleChange = (what, value) => {
		switch (what) {
			case 'username': {
				this.setState({
					username: value,
				}, this.validate);
				break;
			}
			case 'password': {
				this.setState({
					password: value,
				}, this.validate);
				break;
			}
			default:
				break;
		}
	};

	validate = () => {
		const {
			password,
			username,
		} = this.state;
		if (password.trim() !== '' && username.trim() !== '') {
			this.setState({
				valid: true
			});
			return true;
		}
		return false;
	};

	render() {
		const {
			appName = 'Cerella',
			handleLogin,
			handlePasswordResetLinkClick,
			error,
			logError,
			pollingInterval,
			samlSso = false,
			samlEndpoint,
			statusEndpoint,
			version,
		} = this.props;

		const {
			password,
			username,
			valid,
		} = this.state;

		return (
			<div id='loginPage'>
				<div id='logoContainer'>
					<Logo appName={appName} />
					{appName === 'Cerella' ?
						<img src={cerella} alt="Cerella" />
						:
						null}
					<h3>Version {version}</h3>
				</div>
				<div className='form-and-status'>
					{statusEndpoint
						?
						<Services
							logError={logError}
							loggedIn={false}
							pollingInterval={pollingInterval}
							statusEndpoint={statusEndpoint}
						/>
						:
						null
					}
					<form
						className={`form login ${(this.props.loggedIn ? '' : 'show')}`}
						onSubmit={(e) => {
							handleLogin(e, {
								password: password,
								username: username,
							});
						}}
					>
						<FieldSet controlId="formBasicEmail">
							<TextField
								handleChange={this.handleChange}
								id='username'
								label='User name'
								placeholder='User name'
								tabIndex={1}
								value={username}
							/>
							<TextField
								autocomplete={'on'}
								error={error}
								handleChange={this.handleChange}
								id='password'
								label='Password'
								placeholder='Password'
								tabIndex={2}
								type='password'
								value={password}
							/>
						</FieldSet>

						<div className='button-group' >
							<p className={`anchor forgotten-password`} onClick={handlePasswordResetLinkClick}>Forgotten your password?</p>
							<button
								className={`home ${!valid ? 'disabled' : ''}`}
								disabled={!valid}
								tabIndex={3}
								type='submit'
							>
								Login
							</button>
							<HelpButton/>
						</div>
						{samlSso ?
							<div className={`login-with-microsoft`}>
								<a href={samlEndpoint}>
									<img className={`microsoft-logo`} src={MicrosoftLogo} alt="button" />
									<div className={`text`}>Log in with Microsoft</div>
								</a>
							</div>
							:
							null
						}

					</form>

				</div>
				{appName === 'Cerella' ?
					<img className={`augmented-chemistry`} src={augmentedChemistry} alt='Augmented Chemistry&reg;' />
					:
					null
				}
				<Footer
					appName={appName}
				/>
			</div >
		);
	}
}