import '../Statistics.scss';
import colourVariables from '../../../styles/_colours.module.scss';
import {
	handleResponse,
} from '../../../utility';
import Plot from 'react-plotly.js';
import React from 'react';

export class TestSetValidation extends React.PureComponent {

	constructor(props) {
		super(props);

		this.state = {
			dataRetrieved: false,
		};

		this.refresh = this.refresh.bind(this);
	}

	componentDidMount = () => {
		this.refresh();
	};

	refresh = () => {
		const {
			aaaKey,
			checkPermissionsLogError,
			statisticsEndpoint,
		} = this.props;

		const headers = new Headers();
		headers.append("X-api-key", aaaKey);

		const requestOptions = {
			headers: headers,
			method: 'GET',
			redirect: 'follow',
		};

		fetch(`${statisticsEndpoint}/model/validation/external/json`, requestOptions)

			.then(handleResponse)
			.then(res => {
				let virtual;
				let impute;
				let selected;
				for (let i = 0; i < res.length; i++) {
					if (res[i].modelName === 'Virtual') {
						virtual = res[i];
					};
					if (res[i].modelName === 'Selected') {
						selected = res[i];
					};
					if (res[i].modelName === 'Impute') {
						impute = res[i];
					}
				}
				const decimalPlaces = 3;
				const virtualMedianR2 = virtual.medianR2.value.toFixed(decimalPlaces);
				const { endpoints: virtualEndpoints } = virtual;

				// Sort on value
				let slimmedVirtual = virtualEndpoints.map((item) => { return { name: item.endpointName, y: item.r2.value }; }).sort((a, b) =>
					(a.y < b.y) ? 1 : -1
				);

				// Remove null values from list
				slimmedVirtual = slimmedVirtual.filter(item => item.y !== null);
				const imputeMedianR2 = impute.medianR2.value.toFixed(decimalPlaces);
				const { endpoints: imputeEndpoints } = impute;

				// Sort on value
				let slimmedImpute = imputeEndpoints.map((item) => { return { name: item.endpointName, y: item.r2.value }; }).sort((a, b) =>
					(a.y < b.y) ? 1 : -1
				);

				// Remove null values from list
				slimmedImpute = slimmedImpute.filter(item => item.y !== null);
				const selectedMedianR2 = selected.medianR2.value.toFixed(decimalPlaces);
				const { endpoints: selectedEndpoints } = selected;

				// Sort on value
				let slimmedSelected = selectedEndpoints.map((item) => { return { name: item.endpointName, y: item.r2.value }; }).sort((a, b) =>
					(a.y < b.y) ? 1 : -1
				);

				// Remove null values from list
				slimmedSelected = slimmedSelected.filter(item => item.y !== null);

				const {
					cerellaHomeColour = '#fdbd75',
					cerellaUserColour = '#5a99b7',
					cerellaGroupColour = '#89c878',
				} = colourVariables;

				const newData = [{
					"marker": {
						"color": cerellaHomeColour,
						"maxdisplayed": 80,
						"size": 8
					},
					"mode": "lines+markers",
					"name": `Imputation (Median&nbsp;R&#178;: ${imputeMedianR2})`,
					"text": slimmedImpute.map((item) => item.name),
					"type": "scatter",
					"y": slimmedImpute.map((item) => item.y),
				}, {
					"marker": {
						"color": cerellaUserColour,
						"maxdisplayed": 80,
						"size": 8
					},
					"mode": "lines+markers",
					"name": `Virtual (Median&nbsp;R&#178;: ${virtualMedianR2})`,
					"text": slimmedVirtual.map((item) => item.name),
					"type": "scatter",
					"y": slimmedVirtual.map((item) => item.y),
				}, {
					"marker": {
						"color": cerellaGroupColour,
						"maxdisplayed": 80,
						"size": 8
					},
					"mode": "lines+markers",
					"name": `Selected  (Median&nbsp;R&#178;: ${selectedMedianR2})`,
					"text": slimmedSelected.map((item) => item.name),
					"type": "scatter",
					"y": slimmedSelected.map((item) => item.y),
				}];

				this.setState({
					dataRetrieved: true,
					testSetValidation: {
						config: {
							displaylogo: false,
							responsive: true,
						},
						data: newData,
						layout: {
							height: 500,
							legend: {
								orientation: "h",
								// x: 1,
								// xanchor: 'left',
								// y: 1,
								// yanchor: 'top',
								// bgcolor: '#efefef',
							},
							margin: {
								b: 30,
								l: 50,
								r: 20,
								t: 50,
							},
							title: 'Model Performance',
							width: 800,
							xaxis: {
								showticklabels: false,
								title: 'Endpoints Ordered by Accuracy',
							},
							yaxis: {
								range: [-0.5, 1],
								title: 'R&#178;',
							},
						},

					},
				});
			})
			.catch(error => {
				const action = `Retrieving test set optimisation`;
				checkPermissionsLogError(error, action);
			});

	};

	componentDidUpdate = (oldProps) => {
		const { refreshStatistics: oldRefreshStatistics } = oldProps;
		const { refreshStatistics } = this.props;
		if (refreshStatistics !== oldRefreshStatistics) this.refresh();
	};

	render() {
		const {
			dataRetrieved,
			testSetValidation,
		} = this.state;

		const {
			className,
		} = this.props;

		return (
			dataRetrieved ?
				<Plot
					className={`optimisation-chart chart ${className}`}
					divId='testSetValidationPlot'
					useResizeHandler
					data={testSetValidation.data}
					layout={testSetValidation.layout}
					config={testSetValidation.config} />
				:
				<div
					className={`optimisation-chart placeholder`}
					id='testSetValidationPlaceholder'
				>Loading...</div>
		);
	}
}
