import './SimpleSpinner.scss';
import React from 'react';

export class SimpleSpinner extends React.PureComponent {
	render() {

		const {
			showSpinner,
			spinnerMessage = "Loading...",
		} = this.props;

		return (
			<div
				className={`${showSpinner ? 'show' : ''}`}
				id='simpleSpinner'
			>
				<h1>{spinnerMessage}</h1>
			</div>
		);
	}
}