import './Logout.scss';
import {
	HelpButton
} from '..';
import React from 'react';
export class Logout extends React.PureComponent {

	render() {
		const {
			handleChangePasswordLinkClick,
			handleLogout,
		} = this.props;
		if (!handleLogout) return <></>;
		return (
			<div
				onSubmit={this.props.handleLogout}
				id='logout'
				className={`form logout ${(this.props.loggedIn ? '' : 'show')}`}
			>
				<span className='login-status'>Logged in as {this.props.userName}</span>
				<span className='change-password anchor' title='Change password' onClick={handleChangePasswordLinkClick}>Change password</span>
				<button type="submit" className='home logout-button' onClick={handleLogout}>
					Logout
				</button>
				<HelpButton/>
			</div>
		);
	}
}