import './Tabs.scss';
import {
	Tab,
	TabContent
} from '../../Components';
import React from 'react';

export class Tabs extends React.PureComponent {

	constructor(props) {
		super(props);

		this.ulRef = React.createRef();
		this.containerRef = React.createRef();
		this.buttonContainerRef = React.createRef();
	}

	render() {
		const {
			children,
			handleTabClick,
			inverted,
			className = '',
			selectedTab = 0,
		} = this.props;

		// Unfortunately requires each tab has className 'tab'. I need to find a better way
		const tabs = [];
		const tabContent = [];
		children.forEach((child, i) => {
			child.type.displayName === 'Tab' ? tabs.push(child) : tabContent.push(child); // Not ideal having to have className, must be a better way.
		});

		return (
			<div className={`tabs-component ${className} ${inverted ? 'inverted' : ''}`} >
				<div className='tabs-component-container' ref={this.containerRef}>
					<ul ref={this.ulRef}>
						{
							tabs.map((tab, i) => {
								const {
									className,
									id,
								} = tab.props;
								return (
									<Tab
										key={`tab${i}`}
										selected={selectedTab === i}
										className={className}
										handleTabClick={() => { handleTabClick(i); }}
										{...id ? { id: id } : {}}
									>
										{tab.props.children}
									</Tab>
								);
							})
						}
					</ul>
				</div>
				{
					tabContent.map((tabC, i) => {
						const {
							children: tabChildren,
							className = '',
						} = tabC.props;
						return (
							<TabContent
								className={className}
								key={`tabContent${i}`}
								selected={selectedTab === i}
							>
								{tabChildren}
							</TabContent>
						);
					})
				}
			</ div >
		);
	}
}
