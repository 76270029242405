import './App.scss';

import {
	Accordion,
	AccordionArticle,
	AdminContact,
	CompoundEditor,
	DataSourceForm,
	DateRange,
	Dialog,
	EndpointDownload,
	ErrorLog,
	GroupForm,
	HeaderTag,
	HelpButton,
	IdeaTrackerFieldSettings,
	IdeaTrackerIDSettings,
	ImportanceMatrix,
	Info,
	IngestManager,
	IngestProcesses,
	IngestServerConfiguration,
	InternalValidation,
	Log,
	Login,
	MassiveMatrix,
	ModelComparison,
	Page,
	PagedContent,
	PasswordChange,
	Reports,
	RollbackPlot,
	SecureDownload,
	Services,
	SimpleSpinner,
	Spinner,
	SystemLog,
	Tab,
	TabContent,
	Tabs,
	TestSetValidation,
	UserForm,
} from './Components';
import {
	addPolicy,
	becauseGroupPolicy,
	copyObject,
	handleResponse,
	hasPolicy,
	removePolicy,
	strPadDate,
} from './utility';
import colourVariables from './styles/_colours.module.scss';
import downArrow from './images/downArrow.svg';
import download from './images/download.svg';
import React from 'react';
import upArrow from './images/upArrow.svg';
import update from 'immutability-helper';
import whiteCross from './images/whiteCross.svg';


class App extends React.Component {

	constructor(props) {
		super(props);

		// Examine parameters to see if we should display password reset instructions, password reset page etc.
		const url = window.location.href;
		const [, params] = url.split('?');
		const passwordResetKey = '';
		const passwordResetUser = '';
		let passwordChange = false;
		let requestPasswordReset = false;
		let aaaKey = '';
		let userName = '';
		if (params !== undefined) {
			if (params === 'changepassword') {
				passwordChange = true;
			} else {
				const paramsPairs = params.split('&');

				let pair;
				if (paramsPairs) {
					paramsPairs.forEach((paramsPair) => {
						if (paramsPair !== undefined) {
							pair = paramsPair.split('=');
							if (pair) {
								const [key, value] = pair;
								switch (key.toLowerCase()) {
									case 'requestpasswordreset':
										requestPasswordReset = true;
										break;
									case 'apikey':
										// Logging in via SAML
										aaaKey = value;
										break;
									case 'username':
										userName = value;
										break;
									default:
										break;
								}

							}
						}
					});
				};
			}
		}

		/* eslint-disable sort-keys */
		this.state = {
			aaaKey: aaaKey,
			loggedIn: aaaKey.length > 0,
			userName: userName,

			dialogMessage: '',
			dialogShow: false,
			dialogType: 'clear',

			groupClientPolicyId: '',

			primaryNav: '',
			secondaryNav: '',

			grantUserList: false,
			grantGroupList: false,
			grantPolicyList: false,
			grantGroupForUserList: false,
			grantDataSourcesList: false,
			endpointsRetrieved: false,
			endpoints: [],
			errors: [],

			ingest: undefined,

			showSpinner: false,

			// For usage log
			usageDateRangeData: {
				endDate: '',
				startDate: '',
			},

			requestPasswordReset: requestPasswordReset,
			passwordChange: passwordChange,
			passwordResetKey: passwordResetKey,
			passwordResetUser: passwordResetUser,

			editId: '',
			userSearchTerm: '',
			groupSearchTerm: '',
			dataSourceSearchTerm: '',
			trail: [
				{
					className: 'home',
					handleClick: this.handleHomeClick,
					name: 'Home',
				},
			],
			users: [],
			nUsersSelected: 0,
			groups: [],
			nGroupsSelected: 0,
			dataSources: [],
			nBatchesSelected: 0,
			nDataSourcesSelected: 0,
			pollingInterval: 60, // Can be overridden by config.json
			refreshErrorLog: false,
			refreshStatistics: false, // All it needs to do is toggle, to force a refresh in the child components that receive it as a prop.
		};
		/* eslint-enable sort-keys */

		this.affirmDialog = this.affirmDialog.bind(this);
		this.APIaddDataSource = this.APIaddDataSource.bind(this);
		this.APIdeleteBatch = this.APIdeleteBatch.bind(this);
		this.APIdeleteDataSource = this.APIdeleteDataSource.bind(this);
		this.APIgetDataSourceStatus = this.APIgetDataSourceStatus.bind(this);
		this.APIgetEndPoints = this.APIgetEndPoints.bind(this);
		this.APIgiveMeTheWorld = this.APIgiveMeTheWorld.bind(this);
		this.APIpurgeDataFiles = this.APIpurgeDataFiles.bind(this);
		this.APIupdateDataSource = this.APIupdateDataSource.bind(this);
		this.checkIngestServerConfigurationPermission = this.checkIngestServerConfigurationPermission.bind(this);
		this.checkPermissionsLogError = this.checkPermissionsLogError.bind(this);
		this.clearDialog = this.clearDialog.bind(this);
		this.clearError = this.clearError.bind(this);
		this.clearLog = this.clearLog.bind(this);
		this.dialog = this.dialog.bind(this);
		this.getCDDBatches = this.getCDDBatches.bind(this);
		this.getDataSourceById = this.getDataSourceById.bind(this);
		this.getEditableDataSourceById = this.getEditableDataSourceById.bind(this);
		this.getGroupById = this.getGroupById.bind(this);
		this.getIntersectionTitle = this.getIntersectionTitle.bind(this);
		this.getIntersectionValue = this.getIntersectionValue.bind(this);
		this.getUserById = this.getUserById.bind(this);
		this.handleAddRecord = this.handleAddRecord.bind(this);
		this.handleAddUpdateDataSource = this.handleAddUpdateDataSource.bind(this);
		this.handleAddUpdateGroup = this.handleAddUpdateGroup.bind(this);
		this.handleAddUpdateUser = this.handleAddUpdateUser.bind(this);
		this.handleAddBatch = this.handleAddBatch.bind(this);
		this.handleCancelClick = this.handleCancelClick.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChangeAdminEmail = this.handleChangeAdminEmail.bind(this);
		this.handleChangeDateRange = this.handleChangeDateRange.bind(this);
		this.handleChangePasswordLinkClick = this.handleChangePasswordLinkClick.bind(this);
		this.handleClearLoginError = this.handleClearLoginError.bind(this);
		this.handleClickIntersection = this.handleClickIntersection.bind(this);
		this.handleDeleteSelected = this.handleDeleteSelected.bind(this);
		this.handleDownloadUsageLog = this.handleDownloadUsageLog.bind(this);
		this.handleHomeClick = this.handleHomeClick.bind(this);
		this.handleIdeaTrackerAddMetaData = this.handleIdeaTrackerAddMetaData.bind(this);
		this.handleIdeaTrackerDeleteMetaData = this.handleIdeaTrackerDeleteMetaData.bind(this);
		this.handleIdeaTrackerUpdateMetaData = this.handleIdeaTrackerUpdateMetaData.bind(this);
		this.handleIdeaTrackerConfiguration = this.handleIdeaTrackerConfiguration.bind(this);
		this.handleInactivity = this.handleInactivity.bind(this);
		this.handleLogin = this.handleLogin.bind(this);
		this.handleLogout = this.handleLogout.bind(this);
		this.handlePasswordChange = this.handlePasswordChange.bind(this);
		this.handlePasswordResetLinkClick = this.handlePasswordResetLinkClick.bind(this);
		this.handleRowClick = this.handleRowClick.bind(this);
		this.handleSelect = this.handleSelect.bind(this);
		this.handleShowAdminClick = this.handleShowAdminClick.bind(this);
		this.handleShowSpinner = this.handleShowSpinner.bind(this);
		this.handleStatisticsRefresh = this.handleStatisticsRefresh.bind(this);
		this.handleTabClick = this.handleTabClick.bind(this);
		this.handleToggleActive = this.handleToggleActive.bind(this);
		this.handleToggleAll = this.handleToggleAll.bind(this);
		this.handleUserTabClick = this.handleUserTabClick.bind(this);
		this.name = 'Cerella Administration UI';
		this.noteActive = this.noteActive.bind(this);
		this.renderHomeTabContent = this.renderHomeTabContent.bind(this);
		this.renderModelBuildingAndPredictionOptionsHelp = this.renderModelBuildingAndPredictionOptionsHelp.bind(this);
		this.resetStateOnLogout = this.resetStateOnLogout.bind(this);

		this.AAA = null;
		this.inactivityTimer = undefined;
		this.AAAWorker = undefined;
		this.CDDPollInterval = null;
	}

	checkIngestServerConfigurationPermission = () => {
		// console.log("checkIngestServerConfigurationPermission");

		const {
			appName,
			aaaKey,
			ingestServerConfigurationEndpoint
		} = this.state;

		if (appName !== 'Cerella') return false;

		const headers = new Headers();
		headers.append("X-api-key", aaaKey);
		headers.append("Content-Type", "application/json");

		const requestOptions = {
			headers: headers,
			method: 'GET',
			redirect: 'follow',
		};

		if (ingestServerConfigurationEndpoint) {
			fetch(`${ingestServerConfigurationEndpoint}/configuration/ingest/definition`, requestOptions)
				.then(this.handleResponse)
				.then(res => {
					if (res.status !== 403) {
						this.setState({ configurationPermission: true });
						return true;
					}
				})
				.catch(error => {
					// this.setState({ configurationPermission: false }); implied
					const action = `Retrieving Configuration Server permission`;
					this.checkPermissionsLogError(error, action);
				});
		}

		return false;
	};

	componentDidMount = () => {

		// Read the config
		const headers = new Headers();
		headers.append("Content-Type", "application/json");

		const requestOptions = {
			headers: headers,
			method: 'GET',
			redirect: 'follow',
		};

		// const cerellaIncidentalsPromise =
		fetch(`${window.location.origin}/cerella.json`, requestOptions)
			.then(handleResponse)
			.then(res => {
				const { suggestedPermissions: policies } = res;
				delete res.suggestedPermissions;
				res.policies = policies;

				this.setState({ grantPolicyList: true, ...res });
			})
			.catch(error => {
				const action = `Retrieving Cerella incidentals configuration`;
				this.logError(action, error);
			});

		let adminGroupName, appName, version;
		const configPromise = fetch(`${window.location.origin}/config.json`, requestOptions)
			.then(handleResponse)
			.then(res => {
				this.setState(res);
				// We should now have the AAA and query interface endpoints and the Company code.

				({ adminGroupName = 'UserAdmin', appName, version } = res);
				this.setState({
					adminGroupName: adminGroupName,
				});
				this.name = `${appName} Administration UI`;

				document.title = `${appName === 'IdeaTracker' ? 'Idea Tracker' : appName} ${version}`;
				document.getElementsByTagName('meta')["description"].content = document.title;
				let link = document.querySelector("link[rel~='icon']");
				if (!link) {
					link = document.createElement('link');
					link.rel = 'icon';
					document.head.appendChild(link);
				}
				if (appName === 'IdeaTracker') {
					link.href = 'optibrium_favicon.png';
				} else {
					link.href = 'favicon.svg';
				}
				document.body.classList.add(`${appName === 'IdeaTracker' ? 'idea-tracker' : 'cerella'}`);

				const {
					aaaEndpoint,
					cddVaultEndpoint = '',
					ideaTrackerService,
					ingestEndpoint,
					ingestServerConfigurationEndpoint,
					queryInterfaceEndpoint,
					statisticsEndpoint,
					statusEndpoint,
				} = res;

				const message = [];
				const strPrefix = (<span>, </span>);
				let prefix = false;
				if (!version) {
					message.push(<b>version</b>);
					prefix = true;
				}
				if (!appName) {
					if (prefix) message.push(strPrefix);
					message.push(<b>appName</b>);
					prefix = true;
				}
				if (appName && appName !== 'Cerella' && appName !== 'IdeaTracker') {
					const appNameMessage = [];
					appNameMessage.push(
						<span><b>appName</b> is set to <b>'{appName}'</b>, it must be either '<b>Cerella</b>' or '<b>IdeaTracker</b>'.</span>
					);

					this.logError(`Attempting to get mandatory data from configuration`, null, null, appNameMessage);
				}

				if (!aaaEndpoint) {
					if (prefix) message.push(strPrefix);
					message.push(
						<b>aaaEndpoint</b>
					);
					prefix = true;
				}

				if (appName && appName === 'Cerella') {
					// cddVault is optional
					// if (!cddVaultEndpoint) {
					// 	if(prefix)message.push(strPrefix);
					// 	message.push(<b>cddVaultEndpoint</b>);
					// 	prefix = true;
					// }
					if (!ingestEndpoint) {
						if (prefix) message.push(strPrefix);
						message.push(<b>ingestEndpoint</b>);
						prefix = true;
					}
					if (!ingestServerConfigurationEndpoint) {
						if (prefix) message.push(strPrefix);
						message.push(<b>ingestServerConfigurationEndpoint</b>);
						prefix = true;
					}
					if (!queryInterfaceEndpoint) {
						if (prefix) message.push(strPrefix);
						message.push(<b>queryInterfaceEndpoint</b>);
						prefix = true;
					}
					if (!statisticsEndpoint) {
						if (prefix) message.push(strPrefix);
						message.push(<b>statisticsEndpoint</b>);
						prefix = true;
					}
					if (!statusEndpoint) {
						if (prefix) message.push(strPrefix);
						message.push(<b>statusEndpoint</b>);
					}
				}

				if (!appName || (appName && appName === 'Cerella' && (
					!ingestEndpoint ||
					!ingestServerConfigurationEndpoint ||
					!queryInterfaceEndpoint ||
					!statisticsEndpoint ||
					!statusEndpoint ||
					!version
				))) {
					message.push(<span> not present in config.json</span>);
					this.logError(`Attempting to get mandatory data from configuration`, null, null, message);
				}

				if (appName && appName === 'IdeaTracker') {
					// message.length = 0;
					if (!ideaTrackerService) {
						if (prefix) message.push(strPrefix);
						message.push(<b>ideaTrackerService</b>);
						prefix = true;
					}
					if (!statusEndpoint) {
						if (prefix) message.push(strPrefix);
						message.push(<b>statusEndpoint</b>);
					}
					if (
						!ideaTrackerService ||
						!statusEndpoint ||
						!version
					) {
						message.push(<span> not present in config.json</span>);
						this.logError(`Attempting to get mandatory data from configuration`, null, null, message);
					}
				}

				if (aaaEndpoint) {
					const AAAScript = document.createElement('script');
					AAAScript.src = `${aaaEndpoint}/js`;

					try {
						document.body.appendChild(AAAScript);
					} catch (err) {
						this.logError(`Loading AAA library: ${aaaEndpoint}/js`, err); // Doesn't happen! Have to detect at Login attempt!
					}
					AAAScript.onload = () => {
						// console.log("AAA library loaded");
						this.AAA = window.AAA;
						// this.AAAWorker = new this.AAA.types.WorkerRunner();

						configPromise.then(() => {
							this.AAA.contact.get()
								.then(res => {
									this.setState({
										adminEmailAddress: res.email,
									});
									return Promise.resolve();
								})
								.catch(error => {
									const action = 'Please set an administrator email address';
									this.logError(action, error);
									return Promise.reject(error);
								});
						});

						let userName = '';
						this.AAA.isLoggedIn()
							.then(handleResponse)
							.then((res) => {
								[userName] = Object.keys(res);
								const aaaKey = sessionStorage.getItem('x_api_key');
								this.setState({
									aaaKey: aaaKey,
									loggedIn: true,
									nameRules: this.AAA.name.explanation,
									userName: userName,
								}, () => {
									this.AAAWorker = new window.AAA.types.WorkerRunner();
									this.AAAWorker.onUpdate = () => {
										this.handleShowSpinner(true, 'Synchronising...');
										// console.log('AAA worker Synchronising...');
										// Then GIVE ME THE WORLD!
										this.APIgiveMeTheWorld()
											.then(() => {
												this.setState({
													showSpinner: false,
												});
											})
											.catch((error) => {
												this.setState({
													showSpinner: false,
												});
											});
									};

									this.AAAWorker.onError = (thing) => {
										this.AAA.isLoggedIn().catch(err => {
											this.handleLogout();
										});
										console.log("this.AAAWorker.onError", thing); // eslint-disable-line
									};

									configPromise.then(() => {
										this.handleShowSpinner(true, 'Synchronising...');
										// console.log('Config promise Synchronising...');

										// Then GIVE ME THE WORLD!
										this.APIgiveMeTheWorld()
											.then(() => {
												this.setState({
													showSpinner: false,
												});
											})
											.catch((error) => {
												this.setState({
													showSpinner: false,
												});
											});

										// console.log("aaaKey", aaaKey, "cddVaultEndpoint", cddVaultEndpoint);
										if (appName === 'Cerella' && cddVaultEndpoint !== '') {
											this.getCDDBatches(aaaKey, cddVaultEndpoint);
											configPromise.then(() => {
												const {
													pollingInterval,
												} = this.state;
												if (this.CDDPollInterval === null) this.CDDPollInterval = setInterval(() => { this.getCDDBatches(aaaKey, cddVaultEndpoint); }, pollingInterval * 1000);
											});
										}

										// Check on configuration server permission (by trying to get form definition and none 403 response)
										this.checkIngestServerConfigurationPermission();

									});


								});
							})
							.catch((error) => {
								this.setState({
									nameRules: this.AAA.name.explanation,
								});
							});

					};
				}

				if (ingestEndpoint) {
					const ingestScript = document.createElement('script');
					ingestScript.src = `${ingestEndpoint}/js`;

					document.body.appendChild(ingestScript);
					ingestScript.onload = () => {
						this.setState({
							ingest: window.Ingest,
						});
					};
				}
			})
			.catch(error => {
				const action = `Retrieving configuration`;
				this.logError(action, error);
			});

		const {
			cerellaHomeColour = '#fdbd75',
			ideaTrackerHomeColour = '#86dae9;',
			// cerellaUserColour = '#5a99b7',
			// cerellaGroupColour = '#89c878',
		} = colourVariables;
		// console.log("cerellaHomeColour", cerellaHomeColour); debugger;

		const commonStyles = `
		margin-top: 12px;
		padding: 12px;
		font-family: "Copperplate", "Geneva", "Calibri", "Arial Black";`;

		configPromise.then(() => {
			// console.log("appName 2", appName, this.name);

			/* eslint-disable */
			setTimeout(console.clear.bind(console));
			// setTimeout(console.log.bind(console, `%c${this.name}\n${this.version}\nby Optibrium\n%cTeam:\n%cRichard Bagnall - Senior Web Developer`,
			// 	`${commonStyles} color: ${cerellaHomeColour}; font-weight: bold;font-size: 36px;`,
			// 	`${commonStyles} color: ${cerellaUserColour}; font-weight: bold;font-size: 24px;`,
			// 	`${commonStyles} color:  ${cerellaGroupColour}; font-weight: normal;font-size: 18px;`
			// ));
			if (appName === 'IdeaTracker') {
				setTimeout(
					console.log.bind(console, `%c${appName}\n${version}\nby Optibrium`,
						`${commonStyles} color: ${ideaTrackerHomeColour}; font-weight: bold;font-size: 36px;`
					));
				setTimeout(
					console.log.bind(console, `%cMolecule renders by Ben Mills - Own work, Public Domain:\nhttps://commons.wikimedia.org/w/index.php?curid=90307788`,
						`${commonStyles} color: ${ideaTrackerHomeColour}; font-weight: bold;font-size: 12px;`
					));
			} else {
				setTimeout(console.log.bind(console, `%c${appName}\n${version}\nby Optibrium`,
					`${commonStyles} color: ${cerellaHomeColour}; font-weight: bold;font-size: 36px;`
				));
			}
			/* eslint-enable */
		});
	};

	getCDDBatches = (aaaKey, cddVaultEndpoint) => {
		const { appName } = this.state;
		// console.log("Update batches", aaaKey, this.CDDPollInterval);
		if (appName === 'Cerella' && cddVaultEndpoint) {
			const headers = new Headers();
			// headers.append("x-cdd-token", cddToken);
			headers.append("X-api-key", aaaKey);

			const requestOptions = {
				headers: headers,
				method: 'GET',
				redirect: 'follow',
			};
			return fetch(`${cddVaultEndpoint}/batch`, requestOptions)
				.then(handleResponse)
				.then(res => {
					const batches = res.map((batch) => {
						let batchStatusTranslate;
						let error = '';
						switch (batch.status) {
							case 'FINISHED':
								// console.log("batch flagged as finished", batch);
								batchStatusTranslate = 'online';
								this.handleShowSpinner(true, 'Synchronising...');
								// console.log('Get CDD Synchronising...');

								// Need to refresh datasources
								this.APIgiveMeTheWorld()
									.then(() => {
										batch.id = batch.batch_id;
										// Safe to delete batch now.
										// console.log("Deleting batch");
										this.APIdeleteBatch(batch);
									})
									.catch((error) => {
										this.logError('Refreshing datasource list', error);
										this.setState({
											showSpinner: false,
										});
									});
								break;
							case 'ERROR':
								batchStatusTranslate = 'offline';
								if (batch.error_message) {
									error = batch.error_message;
								} else if (batch.error_type) {
									error = batch.error_type;
								}
								break;
							default:
								batchStatusTranslate = 'pending';
								break;
						}
						return {
							dataSourceId: batch.datasource_id,
							error: error,
							id: batch.batch_id,
							isBatch: true,
							name: batch.name,
							progress: batch.progress_status,
							statusCode: batchStatusTranslate,
							type: 'CDD',
							vaultId: parseInt(batch.vault_id),
						};
					});

					this.setState({
						batches: batches,
					});
					return Promise.resolve();
				})
				.catch(error => {
					const action = 'Listing tasks';
					this.checkPermissionsLogError(error, action);
					clearInterval(this.CDDPollInterval);
					this.CDDPollInterval = null;
					return Promise.resolve();
				});
		}
	};

	clearLog = () => {
		this.setState({
			errors: [],
		});
	};

	clearError = (index) => {
		// console.log("clearError", index);
		const { errors } = this.state;
		errors.splice(index, 1);
		this.setState({
			errors: errors,
		});
	};

	handleChangePasswordLinkClick = () => {
		this.setState({
			passwordChange: true,
		});
	};

	handleDownloadUsageLog = () => {
		const {
			usageDateRangeData,
			usageServicesAndTargets,
		} = this.state;
		const {
			endDate,
			startDate,
		} = usageDateRangeData;
		// console.log("handleDownloadUsageLog", startDate, endDate);
		const request = new this.AAA.types.UsageCountRequest({
			"end": new Date(endDate).toISOString(),
			"query": usageServicesAndTargets,
			"start": new Date(startDate).toISOString(),
		});
		// console.log("handleDownloadUsageLog", request);

		this.AAA.usageCountCSV(request)
			.then(res => {
				const blob = new Blob([res], { type: `text/csv` });
				const fileName = `usageLog-${startDate}-${endDate}.csv`;
				if (window.navigator.msSaveOrOpenBlob) {
					window.navigator.msSaveBlob(blob, fileName);
				} else {
					const objectURL = window.URL.createObjectURL(blob);
					const temporaryAnchor = document.createElement(`a`);
					temporaryAnchor.setAttribute(`href`, objectURL);
					temporaryAnchor.setAttribute(`download`, fileName);
					temporaryAnchor.style.display = `none`;
					document.body.appendChild(temporaryAnchor);
					temporaryAnchor.click();
					document.body.removeChild(temporaryAnchor);
					URL.revokeObjectURL(objectURL);
				}
			});
	};

	handleHomeClick = (e) => {
		// console.log("handleHomeClick");
		e.preventDefault();
		e.stopPropagation();
		const newTrail = [];
		newTrail.push({
			className: 'home',
			handleClick: this.handleHomeClick,
			name: 'Home',
		});

		this.setState({
			passwordChange: false,
			primaryNav: 'home',
			requestPasswordReset: false,
			secondaryNav: '',
			trail: newTrail,
		});
	};

	handleCancelClick = () => {
		this.setState({
			editId: '',
			secondaryNav: 'list',
		});
	};

	handleInactivity = (e) => {
		// console.log("handleInactivity!");
		const {
			inactivityTimeoutMinutes,
		} = this.state;
		if (inactivityTimeoutMinutes) {
			this.handleLogout(e, true); // True for auto logout
		}
	};

	handleStatisticsRefresh = () => {
		// console.log("Stats REFRESH!");
		const { refreshStatistics } = this.state;
		this.APIgetEndPoints();
		this.setState({
			refreshStatistics: !refreshStatistics, // All it needs to do is toggle, to force a refresh in the child components that receive it as a prop.
		});
	};

	handleLogin = (e, details) => {
		e.preventDefault();

		const {
			password,
			username,
		} = details;

		const {
			appName,
			aaaEndpoint,
			cddVaultEndpoint = '',
			inactivityTimeoutMinutes,
		} = this.state;

		const action = "Logging in";
		if (this.AAA) {
			this.AAA.login(username, password)
				.then(res => {
					this.AAA.auth.get()
						.then((aaaKey) => {

							if (inactivityTimeoutMinutes) {
								if (this.inactivityTimer) clearTimeout(this.inactivityTimer);
								// console.log(30, this.inactivityTimer);
								this.inactivityTimer = setTimeout(this.handleInactivity, inactivityTimeoutMinutes * 60 * 1000);
							}

							this.setState({
								aaaKey: aaaKey, // Still needed for QI
								loggedIn: true,
								loginError: '',
								primaryNav: 'home',
								secondaryNav: '',
								showSpinner: true,
								userName: details.username,
							}, () => {
								this.AAAWorker = new window.AAA.types.WorkerRunner();

								this.AAAWorker.onUpdate = () => {
									this.handleShowSpinner(true, 'Synchronising...');
									// console.log('AAA worker handleLogin Synchronising...');
									// Then GIVE ME THE WORLD!
									this.APIgiveMeTheWorld()
										.then(() => {
											this.setState({
												showSpinner: false,
											});
										})
										.catch((error) => {
											this.setState({
												showSpinner: false,
											});
										});
									if (appName === 'Cerella' && cddVaultEndpoint !== '') {
										this.getCDDBatches(aaaKey, cddVaultEndpoint)
											.then(() => {
												const {
													pollingInterval,
												} = this.state;
												if (this.CDDPollInterval === null) this.CDDPollInterval = setInterval(() => { this.getCDDBatches(aaaKey, cddVaultEndpoint); }, pollingInterval * 1000);
											});
									}
								};

								this.AAAWorker.onError = (thing) => {
									this.AAA.isLoggedIn().catch(err => {
										this.handleLogout();
									});
									console.log("this.AAAWorker.onError", thing); // eslint-disable-line
								};
								this.checkIngestServerConfigurationPermission();

								this.handleShowSpinner(true, 'Synchronising...');
								// console.log('handleLogin Synchronising...');
								// Then GIVE ME THE WORLD!
								this.APIgiveMeTheWorld()
									.then(() => {
										this.setState({
											showSpinner: false,
										});
									})
									.catch((error) => {
										this.setState({
											showSpinner: false,
										});
									});
							});
						})
						.catch((err) => {
							this.logError(action, err);
						});
				})
				.catch(error => {
					// Sanitise log in fail
					const msg = error.message;
					if (error.status !== 403) {
						this.logError(action, error);
					}

					this.setState({
						loggedIn: false,
						loginError: msg,
						primaryNav: 'login',
						secondaryNav: '',
					});

					return Promise.resolve(); // As logerror already handled the error in a user friendly way.
				});
		} else {
			this.logError(`AAA library failed to load:`, `${aaaEndpoint}/js`);
		}
	};

	handlePasswordResetLinkClick = () => {
		this.setState({
			requestPasswordReset: true
		});
	};

	handleTabClick = (index) => {
		const newTrail = [];
		newTrail.push({
			className: 'home',
			handleClick: this.handleHomeClick,
			name: 'Home',
		});
		// console.log("handleTabClick index = ", index);
		let pn = '';
		switch (index) {
			case 1:
				pn = 'user';
				newTrail.push({
					className: 'user',
					handleClick: this.handleUserTabClick,
					name: 'Users',
				});
				break;
			case 2:
				pn = 'group';
				newTrail.push({
					className: 'group',
					handleClick: this.handleGroupTabClick,
					name: 'Groups',
				});
				break;
			case 3:
				pn = 'compound-editor';
				newTrail.push({
					className: 'compound-editor',
					handleClick: this.handleCompoundTabClick,
					name: 'Compound',
				});
				break;
			case 4:
				pn = 'data-source';
				newTrail.push({
					className: 'data-source',
					handleClick: this.handleDataSourceTabClick,
					name: 'Data Sources',
				});
				break;
			default:
				pn = 'home';
				break;
		}

		this.setState({
			editId: '',
			primaryNav: pn,
			secondaryNav: 'list',
			trail: newTrail,
		});
	};

	getIntersectionTitle = (whatRow, idRow, whatColumn, service, target, action) => {
		// ForPagedContent, drills down through group membership, then user.
		if (whatRow === 'user') {
			const user = this.getUserById(idRow);
			if (!user) return '';
			const AAAuser = this.AAA.users.get(idRow);
			switch (whatColumn) {
				case 'policy':
					if (AAAuser.groups.permissions.has(service, target, action)) return becauseGroupPolicy;
					break;
				default:
					break;
			}
		}
		return '';
	};

	getIntersectionValue = (whatRow, idRow, whatColumn, service, target, action) => {
		// For PagedContent, drills down through group membership, then user.
		switch (whatRow) {
			case 'user':
				const user = this.getUserById(idRow);
				if (!user) return false;
				switch (whatColumn) {
					case 'group':
						if (!user.groups) return false;
						return (user.groups.includes(service));
					case 'policy':
						let found = false;
						if (user.groups) for (const groupId of user.groups) {
							const group = this.getGroupById(groupId);
							if (group && hasPolicy(group, service, target, action)) {
								found = true;
								break;
							}
						}
						if (found) return 3;
						return hasPolicy(user, service, target, action);
					default:
						break;
				}
				break;
			case 'group':
				const group = this.getGroupById(idRow);
				if (!group) return false;
				switch (whatColumn) {
					case 'policy':
					case 'data-source':
						return hasPolicy(group, service, target, action);
					default:
						break;
				}
				break;
			default:
				break;
		}
	};

	handleClickIntersection = (whatRow, idRow, whatColumn, service, target, action) => {
		// For PagedContent, handles a checkbox click on a row/column intersection
		if (action) action = action.toUpperCase();
		switch (whatRow) {
			case 'user': {
				let { users } = this.state;
				const user = this.getUserById(idRow);
				const AAAuser = this.AAA.users.get(idRow);
				switch (whatColumn) {
					case 'group':
						const revertGroupList = [...user.groups];
						if (user.groups.includes(service)) {
							// Remove group from list
							const foundIndex = user.groups.indexOf(service);
							user.groups.splice(foundIndex, 1);
							AAAuser.groups.remove(service);
						} else {
							// Add group to list
							user.groups.push(service);
							AAAuser.groups.add(service);
						}
						AAAuser.save().catch((err) => {
							user.groups = [...revertGroupList];
							this.logError('Changing user groups', err);
						});

						break;
					case 'policy':
					case 'data-source':
						if (hasPolicy(user, service, target, action)) {
							removePolicy(user, service, target, action);
							AAAuser.permissions.remove(service, target, action).catch((err) => {
								addPolicy(user, service, target, action);
								this.logError(`Changing user ${whatColumn} permissions`, err);
							});
						} else {
							addPolicy(user, service, target, action);
							AAAuser.permissions.add(service, target, action).catch((err) => {
								removePolicy(user, service, target, action);
								this.logError(`Changing user ${whatColumn} permissions`, err);
							});
						}

						break;
					default:
				}
				const foundIndex = users.findIndex((user) => user.id === idRow);
				users = update(users, { $splice: [[foundIndex, 1, user]] });
				this.setState({
					users: users,
				});
				break;
			}
			case 'group': {
				let { groups } = this.state;
				const AAAgroup = this.AAA.groups.get(idRow);
				const group = this.getGroupById(idRow);
				if (hasPolicy(group, service, target, action)) {
					removePolicy(group, service, target, action);
					AAAgroup.permissions.remove(service, target, action).catch((err) => {
						addPolicy(group, service, target, action);
						this.logError(`Changing group ${whatColumn} permissions`, err);
					});
				} else {
					addPolicy(group, service, target, action);
					AAAgroup.permissions.add(service, target, action).catch((err) => {
						removePolicy(group, service, target, action);
						this.logError(`Changing group ${whatColumn} permissions`, err);
					});
				}
				const foundIndex = groups.findIndex((group) => group.id === idRow);
				groups = update(groups, { $splice: [[foundIndex, 1, group]] });
				this.setState({
					groups: groups,
				});
				break;
			}
			default:
				break;
		}
	};

	handleClearLoginError = () => {
		this.setState({
			loginError: '',
		});
	};

	handlePasswordChange = (e, credentials) => {
		const {
			oldPassword,
			password,
			userName,
		} = credentials;

		let action = "Password Change - Logging in";

		this.AAA.login(userName, oldPassword)
			.then(() => {
				action = "Password Change - Updating Password";
				try {
					this.AAA.passwordUpdate(oldPassword, password).then(() => {
						this.dialog('Password changed', 'home', 'clear', () => {
							// console.log("OK");
							this.setState({
								loggedIn: false,
								loginError: '',
							}, () => window.location.href = '/');
						});
						return;
					});
				} catch (error) {
					this.checkPermissionsLogError(error, action);
				}
			})
			.catch(error => {
				// Sanitise log in fail
				const msg = error.message;
				this.checkPermissionsLogError(error, action);

				this.setState({
					loggedIn: false,
					loginError: msg,
				});

				return Promise.reject(error);
			});
	};

	resetStateOnLogout = () => {
		this.setState({
			configurationPermission: false,
			dataSourceSearchTerm: '',
			dataSources: [],
			editId: '',
			endpoints: [],
			endpointsRetrieved: false,
			errors: [],
			grantDataSourcesList: false,
			grantGroupForUserList: false,
			grantGroupList: false,
			grantPolicyList: false,
			grantUserList: false,
			groupClientPolicyId: '',
			groupSearchTerm: '',
			groups: [],
			ideaTrackerFieldData: undefined,
			ideaTrackerIDData: undefined,
			loggedIn: false,
			nBatchesSelected: 0,
			nDataSourcesSelected: 0,
			nGroupsSelected: 0,
			nUsersSelected: 0,
			policies: [],
			primaryNav: '',
			secondaryNav: '',
			showSpinner: false,
			trail: [
				{
					className: 'home',
					handleClick: this.handleHomeClick,
					name: 'Home',
				},
			],
			// For usage log
			usageDateRangeData: {
				endDate: '',
				startDate: '',
			},
			userSearchTerm: '',
			users: [],
		});

	};

	handleLogout = (e, auto = false) => {
		const action = "Logging out";
		this.AAA.logout()
			.then(res => {
				this.resetStateOnLogout();
				return Promise.resolve();
			})
			.catch(error => {
				// AAA errors, seems to timeout and we get:
				// User is not authenticated
				// get@https://aaa.latest.cerella.ai/js:22:26
				// AAA.logout@https://aaa.latest.cerella.ai/js:67:18
				// ./src/App.js/App/this.handleLogout@http://localhost:3001/main.46e5022db80d160c960b.hot-update.js:1106:16
				// ./src/App.js/App/this.componentDidMount/configPromise</AAAScript.onload/</</this.AAAWorker.onError/<@http://localhost:3001/main.46e5022db80d160c960b.hot-update.js:377:26
				// It doesn't make sense to reflect such issues in the client and so I'm changing client code to cope quietly, like this:
				console.log(action, error); // eslint-disable-line
				// Failed to logout, make client side log out anyway!
				this.resetStateOnLogout();
				return Promise.resolve();
			});

		clearInterval(this.CDDPollInterval);
		this.CDDPollInterval = null;

		sessionStorage.removeItem('x_api_key'); // Temporary measure, may be able to remove this later.
		if (this.AAAWorker && this.AAAWorker !== null) this.AAAWorker.terminate();
		this.AAAWorker = null;
		if (auto) {
			const {
				inactivityTimeoutMinutes,
			} = this.state;
			if (this.inactivityTimer) {
				clearTimeout(this.inactivityTimer);
				this.inactivityTimer = undefined;
			}
			this.dialog(`There has been no mouse or keyboard activity for ${inactivityTimeoutMinutes} minute${inactivityTimeoutMinutes === 1 ? '' : 's'} so you have been logged out`, '', 'clear');
		}

	};

	APIgetEndPoints = () => {
		const {
			aaaKey,
			statisticsEndpoint,
		} = this.state;

		if (statisticsEndpoint) {
			const headers = new Headers();
			headers.append("X-api-key", aaaKey);

			const requestOptions = {
				headers: headers,
				method: 'GET',
				redirect: 'follow',
			};

			return fetch(`${statisticsEndpoint}/model/validation/external/json`, requestOptions)
				.then(handleResponse)
				.then(res => {
					this.setState({
						endpoints: res[0].endpoints,
						endpointsRetrieved: true,
					});
				})
				.catch(error => {
					const action = `Retrieving endpoint list`;
					this.checkPermissionsLogError(error, action);
				});
		}
	};

	APIgiveMeTheWorld = () => {
		// This gets EVERYTHING!

		// This is suboptimal and should be done as a single API request to the server OR I should move towards lazy loading.
		// Have not done the latter yet due to problems displaying 'egg timer'. If it's all in one hit, React condescends to
		// display the egg timer.
		// console.log("========== APIgiveMeTheWorld");
		const {
			aaaKey,
			appName,
			logServerEndpoint,
			queryInterfaceEndpoint,
			// statisticsEndpoint,
		} = this.state;

		const headers = new Headers();
		headers.append("X-api-key", aaaKey);

		try {
			this.setState({
				passwordRules: this.AAA.passwordPolicy.explanation,
			});
		} catch (err) {
			// console.log("Failed to retrieve AAA.passwordPolicy", err);
		}

		// Get all users
		const users = [];
		const getUsersPromise = new Promise((resolve, reject) => {
			// console.log("this.AAA.users.list()", this.AAA.users.list());
			try {
				this.AAA.users.list().forEach(userName => {
					// console.log("userName", userName);
					const AAAuser = this.AAA.users.get(userName);
					const user = {
						active: !AAAuser.inactive,
						id: userName,
						name: userName,
					};
					user.groups = AAAuser.groups.list();
					if (AAAuser.permissions) user.policies = AAAuser.permissions.json;

					users.push(user);
				});
				this.setState({
					grantGroupForUserList: true,
					grantUserList: true,
					users: users,
				}, resolve);
			} catch (err) {
				reject(err);
			}
		});

		// Get all groups
		const unsortedGroups = [];
		const getGroupsPromise = new Promise((resolve, reject) => {
			try {
				this.AAA.groups.list().forEach(groupName => {

					const AAAgroup = this.AAA.groups.get(groupName);
					// Dummy policy to prevent vanishing groups
					// AAAgroup.permissions.add('dummy_service', 'dummy_target', 'CLIENT');

					const group = {
						id: groupName,
						name: groupName,
					};
					group.policies = AAAgroup.permissions.json;

					unsortedGroups.push(group);
				});
				const groups = unsortedGroups.sort((a, b) => (a.id.toLowerCase() > b.id.toLowerCase()) ? 1 : -1);
				this.setState({
					grantGroupList: true,
					groups: groups,
				}, resolve);
			}
			catch (err) {
				reject(err);
			}
		});

		if (logServerEndpoint) {
			const cwHeaders = new Headers();
			cwHeaders.append("X-api-key", aaaKey);

			const cwRequestOptions = {
				headers: cwHeaders,
				method: 'GET',
				redirect: 'follow',
			};

			fetch(`${logServerEndpoint}/logs`, cwRequestOptions)
				.then(handleResponse)
				.then(res => {

					this.setState({
						logServerLogs: res,
					});
					return Promise.resolve();
				})
				.catch(error => {
					const action = `Listing system logs`;
					this.checkPermissionsLogError(error, action);
					return Promise.resolve();
				});
		}

		let getDataSourcesStatusPromise;
		let grantDataSourcesList = true;

		if (queryInterfaceEndpoint) {
			// Get all data sources from Query Interface
			const dsheaders = new Headers();
			dsheaders.append("X-api-key", aaaKey);

			const dsRequestOptions = {
				headers: dsheaders,
				method: 'GET',
				redirect: 'follow',
			};

			const dataSources = [];
			getDataSourcesStatusPromise = fetch(`${queryInterfaceEndpoint}/dataSources/status`, dsRequestOptions)
				.then(handleResponse)
				.then(res => {

					res.forEach((ds) => {
						const {
							ok = false,
							statusText = '',
							type = ''
						} = ds;
						let statusCode = 'unknown';
						statusCode = ok ? 'online' : 'offline';
						dataSources.push({
							id: ds.sourceId,
							name: ds.sourceName,
							statusCode: statusCode,
							statusText: statusText,
							type: type,
						});

					});

					return Promise.resolve();
				})
				.catch(error => {
					const action = `Listing datasource status`;
					this.checkPermissionsLogError(error, action);
					grantDataSourcesList = false;
					return Promise.resolve();
				});

			const dsPromises = [];
			getDataSourcesStatusPromise.then(() => {

				// Get dataSource columns & metadata
				dataSources.forEach((ds) => {
					const headers = new Headers();
					headers.append("X-api-key", aaaKey);

					const requestOptions = {
						headers: headers,
						method: 'GET',
						redirect: 'follow',
					};

					// Get all dataSource metadata
					dsPromises.push(fetch(`${queryInterfaceEndpoint}/dataSources/${ds.id}/config_metadata`, requestOptions)
						.then(handleResponse)
						.then(res => {
							const newDataSource = { ...ds, ...res };
							// Mark up CDDs as we can't get type="CDD" from QI (I know right?)
							if (newDataSource.source && newDataSource.source.additionalMetadata) {
								const {
									cdd_vault // eslint-disable-line
								} = newDataSource.source.additionalMetadata;
								if (cdd_vault) newDataSource.type = "CDD"; // eslint-disable-line
							}

							const foundIndex = dataSources.findIndex(x => x.id === newDataSource.id);
							if (foundIndex === -1) this.logError("Cannot match metadata to datasource");
							dataSources.splice(foundIndex, 1, newDataSource);

							this.setState({
								dataSources: dataSources,
							}, () => {
								return Promise.resolve();
							});
						})
						.catch(error => {
							const action = `Retrieving data sources metadata`;
							this.checkPermissionsLogError(error, action);
							grantDataSourcesList = false;
							return Promise.resolve();
						})
					);
				});

				// When we have it all, update dataSets with metadata and columns.
				Promise.allSettled(dsPromises).then((results) => {
					if (results.length === dsPromises.length) {
						this.setState({
							dataSources: dataSources,
							grantDataSourcesList: grantDataSourcesList,
							showSpinner: false,
						}, () => {
							return Promise.resolve();
						});
					}
				});
			}); // getDataSourcesStatusPromise
		} // queryInterfaceEndpoint

		if (appName === 'Cerella') {
			const getEndpointsPromise = this.APIgetEndPoints();

			Promise.allSettled([
				getDataSourcesStatusPromise,
				getEndpointsPromise,
				getGroupsPromise,
				getUsersPromise,
			]).then(() => {
				this.setState({
					showSpinner: false,
				});
			});

			return Promise.all([
				getDataSourcesStatusPromise,
				getEndpointsPromise,
				getGroupsPromise,
				getUsersPromise,
			]);
		} else {

			// Idea Tracker
			const {
				ideaTrackerFieldData = {},
				ideaTrackerIDData = {},
				ideaTrackerService,
			} = this.state;

			const IDTheaders = new Headers();
			// IDTheaders.append("Access-Control-Allow-Origin", '*');
			IDTheaders.append("X-api-key", aaaKey);

			const IDTRequestOptions = {
				headers: IDTheaders,
				method: 'GET',
				// mode: 'no-cors',
				redirect: 'follow',
			};

			const getIdeaTrackerAllowedMetadataPromise = fetch(`${ideaTrackerService}/allowedMetadata`, IDTRequestOptions)
				.then(handleResponse)
				.then(res => {
					Object.assign(ideaTrackerFieldData, { metaData: res });
					this.setState({
						ideaTrackerFieldData: ideaTrackerFieldData,
					});
					return Promise.resolve();
				})
				.catch(error => {
					this.logError("Getting Idea Tracker metadata", error);
					this.setState({
						showSpinner: false,
					});
					return Promise.reject(error);
				});

			const getIdeaTrackerConfigurationPromise = fetch(`${ideaTrackerService}/configuration`, IDTRequestOptions)
				.then(handleResponse)
				.then(res => {
					// console.log("configuration res", res);
					Object.assign(ideaTrackerIDData, { configuration: res });
					this.setState({
						ideaTrackerIDData: ideaTrackerIDData,
					});
					return Promise.resolve();
				})
				.catch(error => {
					this.logError("Getting Idea Tracker metadata", error);
					this.setState({
						showSpinner: false,
					});
					return Promise.reject(error);
				});

			Promise.allSettled([
				getDataSourcesStatusPromise,
				getGroupsPromise,
				getIdeaTrackerAllowedMetadataPromise,
				getIdeaTrackerConfigurationPromise,
				getUsersPromise,
			]).then(() => {
				this.setState({
					showSpinner: false,
				});
			});

			return Promise.all([
				getDataSourcesStatusPromise,
				getGroupsPromise,
				getIdeaTrackerAllowedMetadataPromise,
				getIdeaTrackerConfigurationPromise,
				getUsersPromise,
			]);
		}
	};

	logError = (action, ...params) => {
		// Accepts
		// (action, statusCode, statusText, message) or
		// (action, error)
		const {
			errors,
			refreshErrorLog,
		} = this.state;
		if (params.length === 1) {
			// Is error object
			const [error] = params;
			const {
				status = '',
				statusText = '',
			} = error;
			let message = '';
			if (error.message) message += error.message;
			if (error.detail) message += error.detail;

			errors.push({
				action: action,
				message: message,
				statusCode: status,
				statusText: statusText,
			});

		} else {
			const [statusCode = '', statusText = '', message = ''] = params;

			errors.push({
				action: action,
				message: message,
				statusCode: statusCode,
				statusText: statusText,
			});
		}

		this.setState({
			errors: errors,
			refreshErrorLog: !refreshErrorLog,
			showSpinner: false,
		});
	};

	APIpurgeDataFiles = () => {

		this.handleShowSpinner(true, "Purging unused data files...");
		const {
			aaaKey,
			queryInterfaceEndpoint,
		} = this.state;

		if (queryInterfaceEndpoint) {

			const headers = new Headers();
			headers.append("X-api-key", aaaKey);

			const requestOptions = {
				headers: headers,
				method: 'DELETE',
				redirect: 'follow',
			};

			fetch(`${queryInterfaceEndpoint}/dataFiles/cleanup`, requestOptions)
				.then(handleResponse)
				.then(res => {
					const {
						files_deleted: filesDeleted,
					} = res;
					this.handleShowSpinner(false);
					switch (filesDeleted) {
						case 0: {
							this.dialog(`There are no unused data files.`, 'data-source');
							break;
						}
						case 1: {
							this.dialog(`1 unused data file deleted.`, 'data-source');
							break;
						}
						default: {
							this.dialog(`${filesDeleted} unused data files deleted.`, 'data-source');
						}
					}
				})
				.catch(error => {
					const action = `Purging data files`;
					this.logError(action, error);
					this.handleShowSpinner(false);
				});
		}
	};

	restructureDSforAPI = (dataSource) => {
		let APICompatibleDataSource = copyObject(dataSource);

		const { source } = APICompatibleDataSource;
		const { availableProperties = [] } = source;
		const { additionalMetadata = {} } = source;
		if (additionalMetadata) {
			const { cerellaEndpoints = [] } = additionalMetadata;
			const newCerellaEndpoints = [];
			cerellaEndpoints.forEach((cep) => {
				const { transformation } = cep;
				if (transformation) {
					let { functionType } = transformation;
					functionType = functionType.toLowerCase();
					switch (functionType) {
						case 'none':
							cep.transformation = null;
							newCerellaEndpoints.push(cep);
							break;
						case 'log10':
							cep.transformation = {
								'constant': 0.0,
								'factor': 1.0,
								'functionType': 'LOG10',
							};
							newCerellaEndpoints.push(cep);
							break;
						case 'default':
							if ((cep.mergeRule && cep.mergeRule.toLowerCase() !== 'default') || cep.qualifierRule) {
								delete cep.transformation;
								newCerellaEndpoints.push(cep);
							}
							break;
						// if transformation, mergeRule and qualifierRule are all default, omit cerellaEndpoint
					}
				} else {
					// transformation is null
					newCerellaEndpoints.push(cep);
				}
			});
			additionalMetadata.cerellaEndpoints = newCerellaEndpoints;
		}

		delete dataSource.fileName;
		availableProperties.forEach((ap) => {
			delete ap.measurements;
			delete ap.details.dateInputOrder;
			delete ap.details.dateDisplayOrder;
			const { details } = ({ ...ap });
			if (details) {
				switch (ap.columnType) {
					case 'DATE':
						if (details.dateInputFormat === 'other') {
							details.dateInputFormat = details.dateInputFormatOther;
						}
						if (details.dateDisplayFormat === 'other') {
							details.dateDisplayFormat = details.dateDisplayFormatOther;
						}
						delete details.dateInputFormatOther;
						delete details.dateDisplayFormatOther;
						if (details.factor !== undefined) delete details.factor;
						break;
					case 'NUMBER':
						if (isNaN(details.error) || details.error === '') delete details.error;
						if (isNaN(details.precision) || details.precision === '') delete details.precision;
						if (details.valueRange) {
							if (isNaN(details.valueRange.maximum) || details.valueRange.maximum === '') delete details.valueRange.maximum;
							if (isNaN(details.valueRange.minimum) || details.valueRange.minimum === '') delete details.valueRange.minimum;
							if (!details.valueRange.maximum && !details.valueRange.minimum) delete details.valueRange;
						}
						// Factor (CRL-1327)
						if (details.factor !== undefined) {
							// console.log("20 restructureDSforAPI details.factor", details.factor);
							switch (details.factor) {
								case 'STANDARD_DEVIATION':
									details.factor = false;
									break;
								case 'FACTOR':
									details.factor = true;
									break;
								default:
									delete details.factor;
									break;
							}
							// console.log("30 restructureDSforAPI details.factor", details.factor);
						}
						break;
					default:
						if (details.factor !== undefined) delete details.factor;
						break;
				}
				ap.details = details;
			}
		});

		APICompatibleDataSource = {
			...source,
			...dataSource,
		};

		if (APICompatibleDataSource.type === 'FILE' && !APICompatibleDataSource.database) APICompatibleDataSource.database = 'None';
		delete APICompatibleDataSource.data;
		delete APICompatibleDataSource.source;
		delete APICompatibleDataSource.encodedSourceName;
		delete APICompatibleDataSource.statusCode;
		delete APICompatibleDataSource.statusText;

		if (APICompatibleDataSource && APICompatibleDataSource.sourceId) delete APICompatibleDataSource.sourceId;
		delete APICompatibleDataSource.id;
		delete APICompatibleDataSource.resource;
		delete APICompatibleDataSource.selected;
		delete APICompatibleDataSource.type;

		return APICompatibleDataSource;
	};

	APIaddDataSource = (dataSource) => {
		const {
			aaaKey,
			queryInterfaceEndpoint,
		} = this.state;
		// console.log("APIaddDataSource dataSource", dataSource);

		if (queryInterfaceEndpoint) {
			const headers = new Headers();
			headers.append("X-api-key", aaaKey);
			headers.append("Content-Type", "application/json");

			let raw = this.restructureDSforAPI(dataSource);
			raw = JSON.stringify(raw);

			const requestOptions = {
				body: raw,
				headers: headers,
				method: 'POST',
				redirect: 'follow',
			};

			// Add new dataSource
			return fetch(`${queryInterfaceEndpoint}/dataSources`, requestOptions)
				.then(handleResponse)
				.then(res => {
					dataSource.id = res.source.sourceId;
					this.setState({
						dataSource: dataSource,
					});
					return Promise.resolve(dataSource);
				})
				.catch(error => {
					const action = `Adding new dataSource`;
					this.logError(action, error);
					return Promise.reject(error);
				});
		}
	};

	APIgetDataSourceStatus = (dataSource) => {
		const {
			aaaKey,
			queryInterfaceEndpoint,
		} = this.state;

		if (queryInterfaceEndpoint) {
			const headers = new Headers();
			headers.append("X-api-key", aaaKey);
			headers.append("Content-Type", "application/json");

			const requestOptions = {
				headers: headers,
				method: 'GET',
				redirect: 'follow',
			};

			// Get dataSource status
			return fetch(`${queryInterfaceEndpoint}/dataSources/${dataSource.id}/status`, requestOptions)
				.then(handleResponse)
				.then(res => {
					const { ok, statusText } = res;
					const status = {
						statusCode: ok ? 'online' : 'offline',
						statusText: statusText,
					};
					return Promise.resolve(status);
				})
				.catch(error => {
					const action = `Getting dataSource status`;
					this.logError(action, error);
					return Promise.reject(error);
				});
		}
	};

	APIupdateDataSource = (dataSource) => {
		const {
			aaaKey,
			queryInterfaceEndpoint,
		} = this.state;

		// console.log("APIupdateDataSource dataSource", dataSource);
		if (queryInterfaceEndpoint) {
			const headers = new Headers();
			headers.append("X-api-key", aaaKey);
			headers.append("Content-Type", "application/json");

			const { id } = dataSource;

			let raw = this.restructureDSforAPI(dataSource);
			raw = JSON.stringify(raw);

			const requestOptions = {
				body: raw,
				headers: headers,
				method: 'PATCH',
				redirect: 'follow',
			};

			// Update dataSource
			return fetch(`${queryInterfaceEndpoint}/dataSources/${id}`, requestOptions)
				.then(handleResponse)
				.then(res => {
					return Promise.resolve(dataSource);
				})
				.catch(error => {
					const action = `Updating dataSource`;
					this.logError(action, error);
					return Promise.reject(error);
				});
		}
	};

	handleUserTabClick = () => {
		this.setState({
			editId: '',
			primaryNav: 'user',
			secondaryNav: 'list',
		});
	};

	handleShowSpinner = (show, message = 'Loading...') => {
		this.setState({
			showSpinner: show,
			spinnerMessage: message,
		});
	};

	handleGroupTabClick = () => {
		this.setState({
			editId: '',
			primaryNav: 'group',
			secondaryNav: 'list',
		});
	};

	handleCompoundTabClick = () => {
		this.setState({
			editId: '',
			primaryNav: 'compound-editor',
			secondaryNav: 'list',
		});
	};

	handleDataSourceTabClick = () => {
		this.setState({
			editId: '',
			primaryNav: 'data-source',
			secondaryNav: 'list',
		});
	};

	getUserById = (id) => {
		const { users } = this.state;
		const [user] = users.filter((user) => user.id === id);
		return user;
	};

	getGroupById = (id) => {
		const { groups } = this.state;
		const [group] = groups.filter((group) => group.id === id);
		return group;
	};

	getPolicyByResourceAndVerb = (resource, verb) => {
		const { policies } = this.state;
		const foundIndex = policies.findIndex((p) => p.resource === resource && p.verb === verb);
		if (foundIndex >= 0) return policies[foundIndex];
		return false;
	};

	getDataSourceById = (id) => {
		const { dataSources } = this.state;
		const [dataSource] = dataSources.filter((dataSource) => dataSource.id === id);
		return dataSource;
	};

	getEditableDataSourceById = (id) => {
		const {
			batches = [],
			dataSources = [],
		} = this.state;
		let [dataSource] = batches.filter((batch) => batch.id === id);
		if (!dataSource) [dataSource] = dataSources.filter((dataSource) => dataSource.id === id);
		return dataSource;
	};

	handleRowClick = (what, id) => {
		// For PageContent, when user clicks on a row (outside of checkboxes)
		this.setState({
			editId: id,
			primaryNav: what,
			secondaryNav: 'edit',
		});
	};

	handleSelect = (what, id, callBack) => {
		// When clicking a row in PagedContent
		switch (what) {
			case 'user':
				let { nUsersSelected = 0 } = this.state;
				let { users } = this.state;
				const user = this.getUserById(id);
				if (user.selected) {
					nUsersSelected--;
				} else {
					nUsersSelected++;
				}
				user.selected = !user.selected;
				const foundIndex = users.findIndex(x => x.id === id);
				users = update(users, { $splice: [[foundIndex, 1, user]] });
				this.setState({
					nUsersSelected: nUsersSelected,
					users: users,
				},
				callBack
				);
				break;
			case 'group':
				let { nGroupsSelected = 0 } = this.state;
				const { groups } = this.state;
				const [group] = groups.filter((group) => group.id === id);
				if (group.selected) {
					nGroupsSelected--;
				} else {
					nGroupsSelected++;
				}
				group.selected = !group.selected;
				this.setState({
					groups: groups,
					nGroupsSelected: nGroupsSelected,
				},
				callBack
				);
				break;
			case 'batch':
			case 'data-source':
				// console.log("Trying to select a batch");
				let { nBatchesSelected = 0 } = this.state;
				const { batches } = this.state;
				if (batches) {
					const [batch] = batches.filter((batch) => batch.id === id);
					if (batch) {
						if (batch.selected) {
							nBatchesSelected--;
						} else {
							nBatchesSelected++;
						}
						batch.selected = !batch.selected;
						this.setState({
							batches: batches,
							nBatchesSelected: nBatchesSelected,
						},
						callBack
						);
					}
				}
				let { nDataSourcesSelected = 0 } = this.state;
				const { dataSources } = this.state;
				if (dataSources) {
					const [dataSource] = dataSources.filter((dataSource) => dataSource.id === id);
					if (dataSource) {
						if (dataSource.selected) {
							nDataSourcesSelected--;
						} else {
							nDataSourcesSelected++;
						}
						dataSource.selected = !dataSource.selected;
						this.setState({
							dataSources: dataSources,
							nDataSourcesSelected: nDataSourcesSelected,
						},
						callBack
						);
					}
				}
				break;
			default:
		}
	};

	handleToggleActive = (userId) => {
		let { users } = this.state;
		const user = this.getUserById(userId);
		user.active = !user.active;

		const AAAuser = this.AAA.users.get(userId);
		AAAuser.inactive = !AAAuser.inactive.valueOf();
		AAAuser.save().catch((err) => {
			this.logError('Changing user group membership', err);
		});


		const foundIndex = users.findIndex(x => x.id === userId);
		users = update(users, { $splice: [[foundIndex, 1, user]] });
		this.setState({
			users: users,
		});

	};

	handleShowAdminClick = () => {
		this.setState({
			primaryNav: 'adminContact'
		});
	};

	handleChangeAdminEmail = (email) => {
		// Set admin email address
		this.AAA.contact.set(email)
			.then(() => {
				this.setState({
					adminEmailAddress: email,
					primaryNav: 'home'
				});
			})
			.catch(error => {
				const action = `Changing administrator email address`;
				this.logError(action, error);
			});
	};

	handleDeleteSelected = (what) => {
		this.handleShowSpinner(true);
		// Delete rows in PagedView that have their checkbox clicked
		switch (what) {
			case 'user': {
				const { users } = this.state;
				const usersSelected = users.filter((user) => { return user.selected; });
				const usersUnselected = users.filter((user) => { return !user.selected; });
				usersSelected.forEach((user) => {
					const AAAuser = this.AAA.users.get(user.id);
					AAAuser.delete();
				});
				this.setState({
					nUsersSelected: 0,
					users: usersUnselected,
				});
				break;
			}
			case 'group': {
				const { groups } = this.state;
				const groupsSelected = groups.filter((group) => { return group.selected; });
				const groupsUnselected = groups.filter((group) => { return !group.selected; });
				groupsSelected.forEach((group) => {
					const AAAgroup = this.AAA.groups.get(group.id);
					AAAgroup.delete();
				});
				this.setState({
					groups: groupsUnselected,
					nGroupsSelected: 0,
				});
				break;
			}
			case 'data-source': {
				const {
					batches,
				} = this.state;
				let {
					dataSources
				} = this.state;

				const dataSourcesSelected = dataSources.filter((dataSource) => { return dataSource.selected; });
				let nDataSourcesSelected = dataSourcesSelected.length;
				dataSourcesSelected.forEach((dataSource) => {
					this.APIdeleteDataSource(dataSource)
						.then(() => {
							// const foundIndexEditable = dataSources.findIndex((u) => u.id === dataSource.id);
							const foundIndex = dataSources.findIndex((u) => u.id === dataSource.id);
							if (foundIndex >= 0) {
								dataSources = update(dataSources, { $splice: [[foundIndex, 1]] });
								nDataSourcesSelected--;
								this.setState({
									dataSources: dataSources,
									nDataSourcesSelected: nDataSourcesSelected,
								});
							}
						});
				});
				if (batches) {
					const batchesSelected = batches.filter((batch) => { return batch.selected; });
					let nBatchesSelected = batchesSelected.length;
					batchesSelected.forEach((batch) => {

						// console.log("Deleting", batch);
						this.APIdeleteBatch(batch)
							.then(() => {
								nBatchesSelected--;
								this.setState({
									nBatchesSelected: nBatchesSelected,
								});
								return Promise.resolve();
							});
					});
				}
				break;
			}
			default:
				break;
		}
		this.handleShowSpinner(false);
	};

	handleToggleAll = (what, list) => {
		// When clicking the header checkbox in PagedContent
		// console.log("handleToggleAll", what, list);

		switch (what) {
			case 'user':
				const {
					users,
					userSearchTerm,
				} = this.state;
				let filteredUsers = users;
				if (userSearchTerm !== '') filteredUsers = this.filterUsers(userSearchTerm, users);
				const newUsers = copyObject(users);
				let nUsersSelected = 0;
				newUsers.forEach((user) => {
					user.selected = false; // Deselect everything
				});
				filteredUsers.forEach((user) => {
					if (list.length > 0) {
						const foundIndex = list.findIndex((l) => {
							return l === user.id;
						});
						if (foundIndex > -1) {
							const matchedUserIndex = newUsers.findIndex((u) => u.id === user.id);
							newUsers[matchedUserIndex].selected = true;
							nUsersSelected++;
						}
					}
				});
				this.setState({
					nUsersSelected: nUsersSelected,
					users: newUsers,
				});
				break;
			case 'group':
				const {
					groups,
					groupSearchTerm,
				} = this.state;
				let filteredGroups = groups;
				if (groupSearchTerm !== '') filteredGroups = this.filterGroups(groupSearchTerm, groups);
				const newGroups = copyObject(groups);
				let nGroupsSelected = 0;
				newGroups.forEach((group) => {
					group.selected = false; // Deselect everything
				});
				filteredGroups.forEach((group) => {
					if (list.length > 0) {
						const foundIndex = list.findIndex((l) => {
							return l === group.id;
						});
						if (foundIndex > -1) {
							const matchedGroupIndex = newGroups.findIndex((g) => g.id === group.id);
							newGroups[matchedGroupIndex].selected = true;
							nGroupsSelected++;
						}
					}
				});
				this.setState({
					groups: newGroups,
					nGroupsSelected: nGroupsSelected,
				});
				break;
			case 'batch':
			case 'data-source':
				const {
					batches = [],
					dataSources = [],
					dataSourceSearchTerm,
				} = this.state;

				const filteredDataSourcesAndBatches = this.filterDataSourcesAndBatches(dataSourceSearchTerm, batches, dataSources);

				const newDataSources = copyObject(dataSources);
				let nDataSourcesSelected = 0;
				newDataSources.forEach((dataSource) => {
					dataSource.selected = false; // Deselect everything
				});

				const newBatches = copyObject(batches);
				let nBatchesSelected = 0;
				newBatches.forEach((batch) => {
					batch.selected = false; // Deselect everything
				});

				filteredDataSourcesAndBatches.forEach((dataSourceOrBatch) => {
					if (list.length > 0) {
						const foundIndex = list.findIndex((l) => {
							return l === dataSourceOrBatch.id;
						});
						if (foundIndex > -1) {
							const matchedBatchIndex = newBatches.findIndex((b) => b.id === dataSourceOrBatch.id);
							if (matchedBatchIndex > -1){
								newBatches[matchedBatchIndex].selected = true;
								nBatchesSelected++;
							}
							const matchedDataSourceIndex = newDataSources.findIndex((b) => b.id === dataSourceOrBatch.id);
							if (matchedDataSourceIndex > -1) {
								newDataSources[matchedDataSourceIndex].selected = true;
								nDataSourcesSelected++;
							}
						}
					}
				});

				this.setState({
					batches: newBatches,
					dataSources: newDataSources,
					nBatchesSelected:nBatchesSelected,
					nDataSourcesSelected: nDataSourcesSelected,
				});
				break;
			default:
				break;
		}
	};

	APIdeleteBatch = (batch) => {
		const {
			aaaKey,
			cddVaultEndpoint = '',
		} = this.state;

		if (cddVaultEndpoint === '') {
			this.logError("Deleting a task but there is no CDD endpoint configured");
		} else {

			let {
				batches,
			} = this.state;

			const headers = new Headers();
			headers.append("X-api-key", aaaKey);
			headers.append("Content-Type", "application/json");

			const requestOptions = {
				headers: headers,
				method: 'DELETE',
				redirect: 'follow',
			};

			// Did we already request deletion from the API? If so, batch should also no longer be in batches in state, let's check.
			const foundIndex = batches.findIndex((b) => b.id === batch.id);
			if (foundIndex === -1) return Promise.resolve();

			return fetch(`${cddVaultEndpoint}/batch/${batch.id}`, requestOptions)
				.then(handleResponse)
				.then(res => {
					const foundIndex = batches.findIndex((u) => u.id === batch.id);
					batches = update(batches, { $splice: [[foundIndex, 1]] });

					this.setState({
						batches: batches,
					});
					return Promise.resolve();
				})
				.catch(error => {
					this.logError("Deleting a task", error);
					return Promise.reject(error);
				});
		}
	};

	APIdeleteDataSource = (dataSource) => {
		const {
			aaaKey,
			appName,
			ideaTrackerService,
			queryInterfaceEndpoint,
		} = this.state;

		let filterDeletePromise;

		if (appName === 'IdeaTracker') {
		// Need to delete filter first

			const IDTheaders = new Headers();
			IDTheaders.append("X-api-key", aaaKey);

			const IDTRequestOptions = {
				headers: IDTheaders,
				method: 'DELETE',
				redirect: 'follow',
			};

			filterDeletePromise = fetch(`${ideaTrackerService}/configuration/dataSource/${dataSource.id}`, IDTRequestOptions)
				.then(handleResponse)
				.then(res => {
					return Promise.resolve();
				})
				.catch(error => {
					if (error.status !== 404) this.logError("Deleting Idea Tracker pre-filter", error);
					return Promise.resolve();
				});
		}

		if (filterDeletePromise || appName !== 'IdeaTracker') {
			const headers = new Headers();
			headers.append("X-api-key", aaaKey);
			headers.append("Content-Type", "application/json");

			const requestOptions = {
				headers: headers,
				method: 'DELETE',
				redirect: 'follow',
			};

			// Delete dataSource
			return fetch(`${queryInterfaceEndpoint}/dataSources/${dataSource.id}`, requestOptions)
				.then(handleResponse)
				.then(res => {
					return Promise.resolve();
				})
				.catch(error => {
					const action = `Deleting dataSource ${dataSource.name}`;
					this.logError(action, error);
					return Promise.reject(error);
				});
		}

	};

	handleAddUpdateUser = (user, add) => {

		let action = '';

		try {
			this.handleShowSpinner(true);
			if (add) {
				// Add
				const AAAuser = new this.AAA.types.User({
					// groups: user.groups,
					inactive: !user.active,
					password: user.password,
					username: user.name,
				});
				AAAuser.groups.clear();
				if (user.groups) {
					user.groups.forEach((groupId) => {
						AAAuser.groups.add(groupId);
					});
				}
				for (const service in user.policies) {
					for (const target in user.policies[service]) {
						user.policies[service][target].forEach((action) => {
							AAAuser.permissions.add(service, target, action);
						});
					}
				}

				AAAuser.save().catch((err) => {
					this.logError('Adding user', err);
				});
			} else {
				// Update
				const AAAuser = this.AAA.users.get(user.name);

				AAAuser.inactive = !user.active;
				if (user.password !== '') AAAuser.password = user.password;

				AAAuser.groups.clear();
				if (user.groups) {
					user.groups.forEach((groupId) => {
						AAAuser.groups.add(groupId);
					});
				}

				for (const service in user.policies) {
					for (const target in user.policies[service]) {
						user.policies[service][target].forEach((action) => {
							if (!AAAuser.permissions.has(service, target, action)) AAAuser.permissions.add(service, target, action);
						});
					}
				}

				// Repeat for those policies no longer in the list
				const policies = AAAuser.permissions.json;
				for (const service in policies) {
					for (const target in policies[service]) {
						policies[service][target].forEach((action) => {
							if (!hasPolicy(user, service, target, action)) AAAuser.permissions.remove(service, target, action);
						});
					}
				}
				AAAuser.save().catch((err) => {
					this.logError('Updating user', err);
				});
			}
			this.setState({
				editId: '',
				secondaryNav: 'list',
				user: user,
			}, () => { this.handleShowSpinner(false); });
		} catch (error) {
			action = 'Update user';
			if (add) action = 'Add new user';
			this.logError(action, error);
			this.handleShowSpinner(false);
		}
	};

	handleAddUpdateGroup = (group, add) => {

		let action = '';

		try {
			this.handleShowSpinner(true);
			let AAAgroup;
			if (add) {
				AAAgroup = new this.AAA.types.Group(group.name);
				// Now add selected permissions to the group
				// AAAgroup.permissions.clear();  FAILS
				AAAgroup.permissions.add('dummy_service', 'dummy_target', 'CLIENT');
			} else {
				AAAgroup = this.AAA.groups.get(group.name);
			}
			for (const service in group.policies) {
				for (const target in group.policies[service]) {
					group.policies[service][target].forEach((action) => {
						if (!AAAgroup.permissions.has(service, target, action)) AAAgroup.permissions.add(service, target, action);
					});
				}
			}

			// Repeat for those policies no longer in the list
			const policies = AAAgroup.permissions.json;
			for (const service in policies) {
				for (const target in policies[service]) {
					policies[service][target].forEach((action) => {
						if (!hasPolicy(group, service, target, action)) AAAgroup.permissions.remove(service, target, action);
					});
				}
			}

			this.setState({
				editId: '',
				group: group,
				secondaryNav: 'list',
				showSpinner: false,
			});
		} catch (error) {
			action = 'Update group';
			if (add) action = 'Add new group';
			this.logError(action, error);
			this.handleShowSpinner(false);
		}
	};

	handleAddBatch = (batch) => {
		// console.log("handleAddBatch", batch);
		const {
			batches = [],
		} = this.state;

		batches.push({
			id: batch.batch_id, // eslint-disable-line
			isBatch: true,
			name: batch.name,
			progress: 0,
			statusCode: 'pending',
			type: 'CDD',
			vaultId: parseInt(batch.vaultId), // eslint-disable-line
		});

		this.setState({
			batches: batches,
			secondaryNav: 'list'
		});
	};

	handleAddUpdateDataSource = (dataSource, add) => {
		const {
			dataSources: newDataSources,
			editId,
			queryInterfaceEndpoint,
		} = this.state;
		// console.log("handleAddUpdateDataSource dataSource", dataSource);
		if (!dataSource.type) dataSource.type = 'DATA_FILE';

		// dataFileMetadata is readonly, strip it off, don't send it
		// But we still need it! So copy it and add it back later!
		let dataFileMetadataCopy;
		if (dataSource.source) {
			const { source } = dataSource;
			if (source.dataFileMetadata) {
				dataFileMetadataCopy = copyObject(source.dataFileMetadata);
				delete source.dataFileMetadata;
			}
		}

		if (queryInterfaceEndpoint) {
			if (add) {
				// Add
				this.APIaddDataSource(dataSource).then((dataSource) => {
					// As a result of the API call it should now have an ID.
					const newDataSource = copyObject(dataSource);
					newDataSource.statusCode = 'offline';
					newDataSource.statusText = '';

					// Check status here
					this.APIgetDataSourceStatus(dataSource)
						.then((status) => {

							const {
								statusCode,
								statusText,
							} = status;
							newDataSource.source.dataFileMetadata = copyObject(dataFileMetadataCopy);
							newDataSource.statusCode = statusCode;
							newDataSource.statusText = statusText;
							newDataSources.push(newDataSource);

							this.setState({
								dataSources: newDataSources,
								editId: '',
								secondaryNav: 'list',
							});
						})
						.catch(error => {
							const action = `Getting datasource status`;
							this.logError(action, error);
							return Promise.reject(error);
						});
				});
			} else {
				// Update
				this.APIupdateDataSource(dataSource).then(res => {

					// Check status here
					this.APIgetDataSourceStatus(dataSource)
						.then((status) => {

							const newDataSource = copyObject(dataSource);

							const {
								statusCode,
								statusText,
							} = status;
							if (!newDataSource.source) newDataSource.source = {};
							newDataSource.source.dataFileMetadata = copyObject(dataFileMetadataCopy);
							newDataSource.statusCode = statusCode;
							newDataSource.statusText = statusText;

							const foundIndex = newDataSources.findIndex(x => x.id === editId);
							newDataSources.splice(foundIndex, 1, newDataSource);

							this.setState({
								dataSources: newDataSources,
								editId: '',
								secondaryNav: 'list',
							});
						})
						.catch(error => {
							const action = `Getting datasource status`;
							this.logError(action, error);
							return Promise.reject(error);
						});
				});
			}
		}
	};

	handleAddRecord = (what) => {
		this.setState({
			primaryNav: what,
			secondaryNav: 'add',
		});
	};

	handleIdeaTrackerConfiguration = (prefix) => {
		// console.log("handleIdeaTrackerConfiguration prefix:", prefix);

		const {
			aaaKey,
			ideaTrackerService,
		} = this.state;

		if (ideaTrackerService) {
			const headers = new Headers();
			headers.append("X-api-key", aaaKey);
			headers.append("Content-Type", "application/json");

			const raw = JSON.stringify({
				"uniqueIdPrefix": prefix,
			});
			// console.log("raw", raw);

			const IDTRequestOptions = {
				body: raw,
				headers: headers,
				method: 'PUT',
				redirect: 'follow',
			};

			return fetch(`${ideaTrackerService}/configuration`, IDTRequestOptions)
				.then(handleResponse)
				.then(res => {
					// console.log("metadata res", res);
					return Promise.resolve(res);
				})
				.catch(error => {
					this.logError("Posting Idea Tracker configuration", error);
					return Promise.reject(error);
				});
		} else {
			this.logError("attempting to update Idea Tracker configuration", "No IdeaTrackerService");
			return Promise.reject("No IdeaTrackerService");
		}
	};

	handleIdeaTrackerAddMetaData = (field) => {
		// console.log("handleIdeaTrackerAddMetaData fields:", field);

		const {
			aaaKey,
			ideaTrackerService,
		} = this.state;

		if (ideaTrackerService) {
			const headers = new Headers();
			headers.append("X-api-key", aaaKey);
			headers.append("Content-Type", "application/json");

			const metaData = [];

			const fieldOptions = [];
			if (field.options) {
				field.options.forEach((option) => {
					fieldOptions.push(option.name);
				});
			}
			metaData.push({
				allowedValues: fieldOptions,
				defaultValue: field.defaultValue,
				fieldId: field.fieldId,
				fieldName: field.fieldName,
			});

			const raw = JSON.stringify(metaData);

			const IDTRequestOptions = {
				body: raw,
				headers: headers,
				method: 'POST',
				redirect: 'follow',
			};

			return fetch(`${ideaTrackerService}/allowedMetadata`, IDTRequestOptions)
				.then(handleResponse)
				.then(res => {
					const { results } = res;
					return Promise.resolve(results[0]);
				})
				.catch(error => {
					this.logError("Posting new idea tracker metadata", error);
				});
		} else {
			this.logError("attempting to add Idea Tracker metadata", "No IdeaTrackerService");
			return Promise.reject("No IdeaTrackerService");
		}
	};

	handleIdeaTrackerDeleteMetaData = (field) => {
		// console.log("handleIdeaTrackerDeleteMetaData field:", field);

		const {
			aaaKey,
			ideaTrackerService,
		} = this.state;

		if (ideaTrackerService) {
			const headers = new Headers();
			headers.append("X-api-key", aaaKey);

			const IDTRequestOptions = {
				// body: raw,
				headers: headers,
				method: 'DELETE',
				redirect: 'follow',
			};

			fetch(`${ideaTrackerService}/allowedMetadata/${field.fieldId}`, IDTRequestOptions)
				.then(handleResponse)
				.then(res => {
					return Promise.resolve();
				})
				.catch(error => {
					this.logError("Deleting item from Idea Tracker metadata", error);
					return Promise.reject(error);
				});
		} else {
			this.logError("attempting to delete item from Idea Tracker metadata", "No IdeaTrackerService");
			return Promise.reject("No IdeaTrackerService");
		}
	};

	handleIdeaTrackerUpdateMetaData = (field) => {
		// console.log("handleIdeaTrackerUpdateMetaData field:", field);

		const {
			aaaKey,
			ideaTrackerService,
		} = this.state;

		if (ideaTrackerService) {
			const headers = new Headers();
			headers.append("X-api-key", aaaKey);
			headers.append("Content-Type", "application/json");

			const metaData = { ...field };
			const fieldOptions = [];
			if (field.options) {
				field.options.forEach((option) => {
					fieldOptions.push(option.name);
				});
				metaData.allowedValues = fieldOptions;
			}
			delete metaData.type;
			delete metaData.selected;
			delete metaData.options;
			if (field.type === 'text') {
				delete metaData.defaultValue;
			}
			delete metaData.type;

			const raw = JSON.stringify([metaData]);

			const IDTRequestOptions = {
				body: raw,
				headers: headers,
				method: 'PUT',
				redirect: 'follow',
			};

			return fetch(`${ideaTrackerService}/allowedMetadata`, IDTRequestOptions)
				.then(handleResponse)
				.then(res => {
					const { results } = res;
					return Promise.resolve(results[0]);
				})
				.catch(error => {
					this.logError("Posting updated idea tracker metadata", error);
				});
		} else {
			this.logError("attempting to update Idea Tracker metadata", "No IdeaTrackerService");
			return Promise.reject("No IdeaTrackerService");
		}
	};

	filterUsers = (searchTerm, users) => {
		searchTerm = searchTerm.toLowerCase();
		return users.filter((user) => {
			let { name } = user;
			name = name.toLowerCase();
			return (name.indexOf(searchTerm) >= 0);
		});
	};

	filterGroups = (searchTerm, groups) => {
		searchTerm = searchTerm.toLowerCase();
		return groups.filter((group) => {
			let { name } = group;
			name = name.toLowerCase();
			return (name.indexOf(searchTerm) >= 0);
		});
	};

	filterDataSourcesAndBatches = (searchTerm, batches = [], dataSources = []) => {
		// console.log("filterDataSourcesAndBatches start batches:", batches, " dataSources:", dataSources);
		searchTerm = searchTerm.toLowerCase();

		let mergedDataSourcesAndBatches = [];
		let remainingDataSources = copyObject(dataSources);

		// First, process batches, looking to see if any have corresponding datasources
		batches.forEach((batch) => {

			// Does the batch have a corresponding dataSource?
			const foundIndex = remainingDataSources.findIndex((dataSource) => {
				return dataSource.id === batch.dataSourceId;
			});

			if (foundIndex >= 0) {
				// Yes it does!
				const matchingDataSource = remainingDataSources[foundIndex];

				// make a composite record from the matched batch and dataSource
				const composite = {
					...matchingDataSource,
					isBatch: true,
					progress: batch.progress,
					statusCode: batch.statusCode,
					statusText: batch.statusText,
					vaultId: batch.vaultId,
				};
				mergedDataSourcesAndBatches.push(composite);
				remainingDataSources = update(remainingDataSources, { $splice: [[foundIndex, 1]] });
			} else {
				// console.log("Not found!");
				// Batch does not match existing dataSource, add it as a batch in its own right
				mergedDataSourcesAndBatches.push(batch);
			}
		});

		// Add in the unmatched dataSources
		mergedDataSourcesAndBatches = [...mergedDataSourcesAndBatches, ...remainingDataSources];
		// console.log("filterDataSourcesAndBatches middle mergedDataSourcesAndBatches:", mergedDataSourcesAndBatches);

		if (searchTerm === '') return mergedDataSourcesAndBatches;
		const filteredDataSourcesAndBatches = mergedDataSourcesAndBatches.filter((dsOrBatch) => {
			let { name } = dsOrBatch;
			name = name.toLowerCase();
			return (name.indexOf(searchTerm) >= 0);
		});
		// console.log("filterDataSourcesAndBatches end filteredDataSourcesAndBatches:", filteredDataSourcesAndBatches);

		return filteredDataSourcesAndBatches;
	};

	handleChangeDateRange = (id, returnObject) => {
		this.setState({
			[`${id}Data`]: returnObject,
		});
	};

	handleChange = (field, value) => {
		switch (field) {
			default: {
				this.setState({ [field]: value });
				break;
			}
		}
	};

	handleChangeSearch = (what, searchTerm, callBack) => {
		switch (what) {
			case 'user':
				this.setState({
					userSearchTerm: searchTerm,
					usersPage: 0,
				},
				callBack
				);
				break;
			case 'group':
				this.setState({
					groupSearchTerm: searchTerm,
					groupsPage: 0,
				},
				callBack
				);
				break;
			case 'data-source':
				this.setState({
					dataSourceSearchTerm: searchTerm,
					dataSourcesPage: 0,
				},
				callBack
				);
				break;
		}
	};

	dialog = (message, theme, type = 'clear', yes, no) => {
		this.setState({
			dialogMessage: message,
			dialogNo: no,
			dialogShow: true,
			dialogTheme: theme,
			dialogType: type,
			dialogYes: yes,
		});
	};

	clearDialog = () => {
		const {
			dialogNo,
		} = this.state;
		this.setState({
			dialogMessage: '',
			dialogShow: false,
		}, () => {
			if (dialogNo) dialogNo();
		});
	};

	affirmDialog = () => {
		const { dialogYes } = this.state;
		this.setState({
			dialogMessage: '',
			dialogShow: false,
		}, () => { if (dialogYes) dialogYes(); });
	};

	checkPermissionsLogError = (error, action) => {
		if (error.status && error.status === 403) {
			console.log(action, error);  // eslint-disable-line
		} else {
			this.logError(action, error);
		}
	};

	noteActive = (e) => {
		// console.log("noteActive", e);
		const {
			inactivityTimeoutMinutes,
		} = this.state;
		if (inactivityTimeoutMinutes && this.inactivityTimer) {
			clearTimeout(this.inactivityTimer);
			this.inactivityTimer = setTimeout(this.handleInactivity, inactivityTimeoutMinutes * 60 * 1000);
		}
	};

	renderHomeTabContent = () => {
		const {
			aaaKey,
			appName,
			logServerEndpoint,
			logServerLogs = [],
			configurationPermission = false,
			endpoints,
			endpointsRetrieved,
			ideaTrackerFieldData,
			ideaTrackerIDData,
			ingest,
			ingestEndpoint,
			ingestServerConfigurationEndpoint,
			loggedIn,
			pollingInterval,
			refreshStatistics,
			statisticsEndpoint,
			statusEndpoint,
			usageDateRangeData,
			usageServicesAndTargets,
		} = this.state;

		const today = new Date();
		const strToday = strPadDate(today);

		let usageEndDate, usageStartDate;
		if (usageDateRangeData) {
			({
				endDate: usageEndDate,
				startdate: usageStartDate
			} = usageDateRangeData);
		}

		return (
			<>
				{loggedIn ?
					<Accordion>
						{appName === 'IdeaTracker' && ideaTrackerIDData && ideaTrackerFieldData ?
							<>
								<AccordionArticle
									id='fieldSettings'
									title='Field Settings'
								>
									{/* <h1>Field Settings</h1> */}
									<IdeaTrackerFieldSettings
										dialog={this.dialog}
										handleIdeaTrackerAddMetaData={this.handleIdeaTrackerAddMetaData}
										handleIdeaTrackerDeleteMetaData={this.handleIdeaTrackerDeleteMetaData}
										handleIdeaTrackerUpdateMetaData={this.handleIdeaTrackerUpdateMetaData}
										ideaTrackerFieldData={ideaTrackerFieldData}
										logError={this.logError}

									/>
								</AccordionArticle>
								<AccordionArticle
									id='idtConfiguration'
									title='Configuration'
								>
									<IdeaTrackerIDSettings
										dialog={this.dialog}
										handleIdeaTrackerConfiguration={this.handleIdeaTrackerConfiguration}
										ideaTrackerIDData={ideaTrackerIDData}
										logError={this.logError}
									/>

								</AccordionArticle>
								<AccordionArticle
									id='stats'
									title='System Status'
								>
									<div>
										<h3>Services</h3>
										<Services
											logError={this.logError}
											loggedIn={false}
											pollingInterval={pollingInterval}
											statusEndpoint={statusEndpoint}
										/>
									</div>
								</AccordionArticle>
							</>
							:
							null
						} {/* appName === 'IdeaTracker && ideaTrackerData */}

						{appName === 'Cerella' ?
							<>
								<AccordionArticle
									id='stats'
									title='System Status'
								>
									<div className='left'>
										<h3>Services</h3>
										<Services
											logError={this.logError}
											loggedIn={loggedIn}
											pollingInterval={pollingInterval}
											statusEndpoint={statusEndpoint}
										/>
										<MassiveMatrix
											aaaKey={aaaKey}
											checkPermissionsLogError={this.checkPermissionsLogError}
											statisticsEndpoint={statisticsEndpoint}
										/>
									</div>
									<div className='right'>
										<h3>Processes</h3>
										<IngestProcesses
											aaaKey={aaaKey}
											checkPermissionsLogError={this.checkPermissionsLogError}
											dialog={this.dialog}
											handleShowSpinner={this.handleShowSpinner}
											ingest={ingest}
											logError={this.logError}
											loggedIn={loggedIn}
											pollingInterval={pollingInterval}
											statisticsEndpoint={statisticsEndpoint}
											strToday={strToday}
										/>

										<Reports
											aaaKey={aaaKey}
											checkPermissionsLogError={this.checkPermissionsLogError}
											dialog={this.dialog}
											handleShowSpinner={this.handleShowSpinner}
											logError={this.logError}
											statisticsEndpoint={statisticsEndpoint}
										/>
									</div>
								</AccordionArticle>
								{endpointsRetrieved ?
									<>
										<AccordionArticle
											id='internalValidation'
											title='Internal Validation - Hyperparameter Optimisation'
										>
											<InternalValidation
												aaaKey={aaaKey}
												checkPermissionsLogError={this.checkPermissionsLogError}
												className='left'
												refreshStatistics={refreshStatistics}
												statisticsEndpoint={statisticsEndpoint}
											/>
											<div className='right'>
												<h1>Model Performance Statistics (all Endpoints)</h1>
												<p>CSV:
													<SecureDownload
														aaaKey={aaaKey}
														contentType='text/csv'
														dialog={this.dialog}
														fileName={`internalValidation-${strToday}.csv`}
														handleShowSpinner={this.handleShowSpinner}
														href={`${statisticsEndpoint}/model/validation/internal/csv`}
														logError={this.logError}
													/>
												</p>
												<h1>Training Values</h1>
												<p>CSV:
													<SecureDownload
														aaaKey={aaaKey}
														contentType='text/csv'
														dialog={this.dialog}
														fileName={`trainingValues-${strToday}.csv`}
														handleShowSpinner={this.handleShowSpinner}
														href={`${statisticsEndpoint}/model/validation/internal/trainingValues`}
														logError={this.logError}
													/>
												</p>
												<h1>Measured and Predicted Values</h1>
												<h2>Imputation Model</h2>
												<p>CSV:
													<SecureDownload
														aaaKey={aaaKey}
														contentType='text/csv'
														dialog={this.dialog}
														fileName={`trainingImputationValues-${strToday}.csv`}
														handleShowSpinner={this.handleShowSpinner}
														href={`${statisticsEndpoint}/model/prediction/internal/results/impute`}
														logError={this.logError}
													/>
												</p>
												<h2>Virtual Model</h2>
												<p>CSV:
													<SecureDownload
														aaaKey={aaaKey}
														contentType='text/csv'
														dialog={this.dialog}
														fileName={`trainingVirtualValues-${strToday}.csv`}
														handleShowSpinner={this.handleShowSpinner}
														href={`${statisticsEndpoint}/model/prediction/internal/results/virtual`}
														logError={this.logError}
													/>
												</p>
												<h2>Selected Model</h2>
												<p>CSV:
													<SecureDownload
														aaaKey={aaaKey}
														contentType='text/csv'
														dialog={this.dialog}
														fileName={`trainingSelectedValues-${strToday}.csv`}
														handleShowSpinner={this.handleShowSpinner}
														href={`${statisticsEndpoint}/model/prediction/internal/results/selected`}
														logError={this.logError}
													/>
												</p>
											</div>
										</AccordionArticle>
										<AccordionArticle
											id='testSetValidation' title='Independent Test Set Validation'
										>
											<TestSetValidation
												aaaKey={aaaKey}
												checkPermissionsLogError={this.checkPermissionsLogError}
												className='left'
												refreshStatistics={refreshStatistics}
												statisticsEndpoint={statisticsEndpoint}
											/>
											<div className='right'>
												<HeaderTag
													info={{
														message: (
															<p>The CSV file contains a list of all the endpoints and, for each endpoint,
																the coefficient of determination (R²) and root-mean-square error (RMSE) for the
																Imputation, Virtual and selected models, and the number of data points in the
																training and independent test set.</p>
														),
														title: `Model Performance Statistics (all Endpoints)`,
													}}
												>Model Performance Statistics (all Endpoints)&nbsp;
												</HeaderTag>

												<p>CSV:
													<SecureDownload
														aaaKey={aaaKey}
														contentType='text/csv'
														dialog={this.dialog}
														fileName={`testSetValidation-${strToday}.csv`}
														handleShowSpinner={this.handleShowSpinner}
														href={`${statisticsEndpoint}/model/validation/external/csv`}
														logError={this.logError}
													/>
												</p>

												<HeaderTag
													info={{
														message: (
															<>
																<p>Export a CSV file containing all measured values and the corresponding predictions
																	and uncertainties for each of the Imputation, Virtual and Selected models for the
																	independent test set. Measured and predicted values are reported in the units that
																	were used to train the Cerella models.</p>
																<p><b>Validation:</b> predictions for a compound and endpoint where there is a measured
																value (predictions where an experimental value is present).</p>
																<p><b>Predictions:</b> predictions for all compounds and endpoints (predictions for all
																measured and missing values).</p>
															</>
														),
														title: `Measured and Predicted Values`
													}}
												>Measured and Predicted Values&nbsp;</HeaderTag>
												<h2>Imputation Model</h2>
												<p>Validation CSV:
													<SecureDownload
														aaaKey={aaaKey}
														contentType='text/csv'
														dialog={this.dialog}
														fileName={`imputation-${strToday}.csv`}
														handleShowSpinner={this.handleShowSpinner}
														href={`${statisticsEndpoint}/model/validation/external/results/impute`}
														logError={this.logError}
													/>
													Prediction CSV:
													<SecureDownload
														aaaKey={aaaKey}
														contentType='text/csv'
														dialog={this.dialog}
														fileName={`testImputationValues-${strToday}.csv`}
														handleShowSpinner={this.handleShowSpinner}
														href={`${statisticsEndpoint}/model/prediction/external/results/impute`}
														logError={this.logError}
													/>
												</p>
												<h2>Virtual Model</h2>
												<p>Validation CSV:
													<SecureDownload
														aaaKey={aaaKey}
														contentType='text/csv'
														dialog={this.dialog}
														fileName={`virtual-${strToday}.csv`}
														handleShowSpinner={this.handleShowSpinner}
														href={`${statisticsEndpoint}/model/validation/external/results/virtual`}
														logError={this.logError}
													/>
													Prediction CSV:
													<SecureDownload
														aaaKey={aaaKey}
														contentType='text/csv'
														dialog={this.dialog}
														fileName={`testVirtualValues-${strToday}.csv`}
														handleShowSpinner={this.handleShowSpinner}
														href={`${statisticsEndpoint}/model/prediction/external/results/virtual`}
														logError={this.logError}
													/>
												</p>
												<h2>Selected Model</h2>
												<p>Validation CSV:
													<SecureDownload
														aaaKey={aaaKey}
														contentType='text/csv'
														dialog={this.dialog}
														fileName={`selected-${strToday}.csv`}
														handleShowSpinner={this.handleShowSpinner}
														href={`${statisticsEndpoint}/model/validation/external/results/selected`}
														logError={this.logError}
													/>
													Prediction CSV:
													<SecureDownload
														aaaKey={aaaKey}
														contentType='text/csv'
														dialog={this.dialog}
														fileName={`testSelectedValues-${strToday}.csv`}
														handleShowSpinner={this.handleShowSpinner}
														href={`${statisticsEndpoint}/model/prediction/external/results/selected`}
														logError={this.logError}
													/>
												</p>
												<HeaderTag
													info={{
														message: (
															<p>Select an individual endpoint and export a CSV file containing all measured
																values and the corresponding predictions and uncertainties for the Imputation, Virtual and Selected
																models for the independent test set.
																Measured and predicted values are reported in the units that were used to train the Cerella models.</p>
														),
														title: `Individual Endpoints (all Models)`
													}}
													level={2}>Individual Endpoints (all Models):&nbsp;</HeaderTag>

												<EndpointDownload
													aaaKey={aaaKey}
													dialog={this.dialog}
													endpoints={endpoints}
													endpointsRetrieved={endpointsRetrieved}
													handleShowSpinner={this.handleShowSpinner}
													logError={this.logError}
													statisticsEndpoint={statisticsEndpoint}
												/>

											</div>
										</AccordionArticle>
										<AccordionArticle
											id='rollback'
											title='Independent Test Set Rollback'
										>
											<RollbackPlot
												aaaKey={aaaKey}
												dialog={this.dialog}
												endpoints={endpoints}
												endpointsRetrieved={endpointsRetrieved}
												handleShowSpinner={this.handleShowSpinner}
												logError={this.logError}
												refreshStatistics={refreshStatistics}
												statisticsEndpoint={statisticsEndpoint}
											/>
										</AccordionArticle>
										<AccordionArticle
											id='importanceMatrix'
											title='Importance Matrix'
										>
											<ImportanceMatrix
												aaaKey={aaaKey}
												dialog={this.dialog}
												endpoints={endpoints}
												endpointsRetrieved={endpointsRetrieved}
												handleShowSpinner={this.handleShowSpinner}
												logError={this.logError}
												refreshStatistics={refreshStatistics}
												statisticsEndpoint={statisticsEndpoint}
												strDate={strToday}
											/>
										</AccordionArticle>
									</>
									:
									null
								} {/* endpointsRetrieved */}
								<AccordionArticle
									id='log'
									title='Usage Report'
								>
									<Log
										AAA={this.AAA}
										className='left'
										checkPermissionsLogError={this.checkPermissionsLogError}
										logError={this.logError}
										usageDateRangeData={usageDateRangeData}
										usageServicesAndTargets={usageServicesAndTargets}
									/>
									<div className='date-range form right'>
										<h3>Date Range</h3>
										<DateRange
											handleChangeDateRange={this.handleChangeDateRange}
											id={`usageDateRange`}
											value={usageDateRangeData}
										/>
										{usageEndDate && usageStartDate ?
											<>
												<h2>Usage Report</h2>
												<p>CSV:
													<img
														alt='download'
														className='download'
														onClick={(e) => {
															this.handleDownloadUsageLog(usageStartDate, usageEndDate);
															e.stopPropagation();
														}}
														src={download}
														title='download'
													/>
												</p>
											</>

											:
											null
										}
									</div>
								</AccordionArticle>
								{configurationPermission ?
									<AccordionArticle
										id='configuration'
										title='System Management'>
										<div className={`left`}>
											<h3>Model Building and Prediction Options <Info
												infoTitle={`Model Building and Prediction Options`}
												infoMessage={this.renderModelBuildingAndPredictionOptionsHelp()} /></h3>
											<IngestManager
												aaaKey={aaaKey}
												checkPermissionsLogError={this.checkPermissionsLogError}
												dialog={this.dialog}
												handleShowSpinner={this.handleShowSpinner}
												handleStatisticsRefresh={this.handleStatisticsRefresh}
												ingest={ingest}
												logError={this.logError}
												loggedIn={loggedIn}
												pollingInterval={pollingInterval}
												statisticsEndpoint={statisticsEndpoint}
												strToday={strToday}
											/>
										</div>
										<div className={`right`}>
											<h3>System Configuration</h3>
											<IngestServerConfiguration
												aaaKey={aaaKey}
												dialog={this.dialog}
												ingest={ingest}
												logError={this.logError}
												loggedIn={loggedIn}
												pollingInterval={pollingInterval}
												ingestServerConfigurationEndpoint={ingestServerConfigurationEndpoint}
											/>
										</div>

									</AccordionArticle>
									:
									null
								} {/* configurationPermissions */}
								<AccordionArticle
									id='ModelComparison'
									title='Model Comparison'>
									<ModelComparison
										aaaKey={aaaKey}
										checkPermissionsLogError={this.checkPermissionsLogError}
										dialog={this.dialog}
										handleShowSpinner={this.handleShowSpinner}
										ingestEndpoint={ingestEndpoint}
										logError={this.logError}
										loggedIn={loggedIn}
										pollingInterval={pollingInterval}
										statisticsEndpoint={statisticsEndpoint}
									/>
								</AccordionArticle>
							</>
							: null
						} {/* appName === 'Cerella' */}
						{logServerEndpoint && logServerLogs.length > 0 ?
							<AccordionArticle
								id='cloudwatch'
								title='System Logs'>
								<SystemLog
									aaaKey={aaaKey}
									logServerEndpoint={logServerEndpoint}
									logServerLogs={logServerLogs}
									dialog={this.dialog}
									handleShowSpinner={this.handleShowSpinner}
									logError={this.logError}
								/>
							</AccordionArticle>
							:
							null
						} {/* {logServerEndpoint && logServerLogs.length > 0*/}
					</Accordion>
					:
					null
				} {/* LoggedIn */}
			</>
		);
	};

	renderModelBuildingAndPredictionOptionsHelp = () => {
		const {
			ingest,
		} = this.state;

		const result = [];
		if (ingest) {
			ingest.ingestOptions.forEach(io => {
				result.push(<li key={`li-building-and-predicion-options-help-${io.name}`}><b>{io.name}</b>: {io.description}</li>);
			});

			return (
				<ul>
					{result}
				</ul>
			);
		}
	};

	render = () => {
		const {
			aaaEndpoint,
			aaaKey,
			adminGroupName,
			appName,
			adminEmailAddress = '',
			batches,
			cddVaultEndpoint,
			dialogMessage = '',
			dialogShow = false,
			dialogTheme,
			dialogType,
			editId,
			errors,
			grantDataSourcesList,
			grantGroupForUserList,
			grantGroupList,
			grantPolicyList,
			grantUserList,
			groupClientPolicyId,
			groupSearchTerm = '',
			ideaTrackerService,
			loggedIn,
			loginError = '',
			dataSourceSearchTerm = '',
			nameRules,
			nBatchesSelected,
			nDataSourcesSelected,
			nGroupsSelected,
			nUsersSelected,
			passwordChange,
			passwordRules,
			policies,
			pollingInterval,
			primaryNav,
			queryInterfaceEndpoint,
			refreshErrorLog,
			requestPasswordReset,
			samlSso = false,
			secondaryNav,
			showSpinner,
			spinnerMessage,
			statusEndpoint,
			superUI = false,
			trail,
			userName,
			userSearchTerm = '',
			version = '',
		} = this.state;

		const {
			dataSources,
			groups,
			users,
		} = this.state;

		let selectedTab = 0;
		switch (primaryNav) {
			case 'user':
				selectedTab = 1;
				break;
			case 'group':
				selectedTab = 2;
				// Cannot view groups tab unless super UI. This can only happen if superUI is set false in programmers REACT tools.
				if (!superUI) selectedTab = 0;
				break;
			case 'compound-editor':
				selectedTab = 3;
				break;
			case 'data-source':
				selectedTab = 4;
				break;
			default:
				break;
		}

		let filteredUsers = users;
		let filteredGroups = groups;

		if (userSearchTerm !== '') filteredUsers = this.filterUsers(userSearchTerm, users);
		if (groupSearchTerm !== '') filteredGroups = this.filterGroups(groupSearchTerm, groups);
		const filteredDataSourcesAndBatches = this.filterDataSourcesAndBatches(dataSourceSearchTerm, batches, dataSources);

		let dataSource, user, group, ownedPolicies = [], ownedDataSources = [];

		if (editId !== '') {
			if (primaryNav === 'user' && (secondaryNav === 'edit' || secondaryNav === 'add')) {

				user = this.getUserById(editId);
				if (user) {
					ownedPolicies = user.policies;
					ownedDataSources = user.dataSources;
				} else {
					this.logError(`User ${editId} has been deleted by another admin`);
					this.setState({
						secondaryNav: 'list'
					});
				}
			}

			if (primaryNav === 'group' && (secondaryNav === 'edit' || secondaryNav === 'add')) {

				group = this.getGroupById(editId);
				if (group) {
					ownedPolicies = group.policies;
					ownedDataSources = group.dataSources;
				} else {
					this.logError(`Group ${editId} has been deleted by another admin`);
					this.setState({
						secondaryNav: 'list'
					});
				}
			}

			if (primaryNav === 'data-source' && (secondaryNav === 'edit' || secondaryNav === 'add')) {
				dataSource = this.getEditableDataSourceById(editId);
				if (!dataSource) {
					this.logError(`Data source ${editId} has been deleted by another admin`);
					this.setState({
						secondaryNav: 'list'
					});
				}
			}
		} else {
			ownedPolicies = [];
			ownedDataSources = [];
		}

		// console.log("appName", appName);

		return (
			<div
				className={`
						app
						skin
						${appName === 'Cerella' ? 'cerella' : ''}
						${appName === 'IdeaTracker' ? 'idea-tracker' : ''}
					`}
				onMouseMove={this.noteActive}
				onKeyDown={this.noteActive}
			> {/* skin For increased specificity for skin overrides without !important */}

				<React.StrictMode>
					<img src={whiteCross} className='preload' alt='' />
					<img src={upArrow} className='preload' alt='' />
					<img src={downArrow} className='preload' alt='' />

					<ErrorLog
						clearError={this.clearError}
						clearLog={this.clearLog}
						dialog={this.dialog}
						errors={errors}
						refreshErrorLog={refreshErrorLog}
					/>
					{appName === 'Cerella' || appName === 'IdeaTracker' ?
						<>

							{appName === 'Cerella' ?
								<>
									<Spinner
										showSpinner={showSpinner}
										spinnerMessage={spinnerMessage}
									/>
								</>
								:
								<>
									<SimpleSpinner
										showSpinner={showSpinner}
										spinnerMessage={spinnerMessage}
									/>
								</>
							}

							{dialogShow ? <Dialog
								handleAffirm={this.affirmDialog}
								handleClear={this.clearDialog}
								message={dialogMessage}
								theme={dialogTheme}
								type={dialogType}
							/> : null}

							{passwordChange ? (
								<PasswordChange
									AAA={this.AAA}
									aaaEndpoint={aaaEndpoint}
									appName={appName}
									handleClearLoginError={this.handleClearLoginError}
									handleHomeClick={this.handleHomeClick}
									handlePasswordChange={this.handlePasswordChange}
									handleResponse={handleResponse}
									loginError={loginError}
									passwordRules={passwordRules}
									version={version}
								/>
							) :
								(
									requestPasswordReset ? (
										<Page
											appName={appName}
											handleHomeClick={this.handleHomeClick}
											handleChangePasswordLinkClick={this.handleChangePasswordLinkClick}
											id={'requestPasswordResetPage'}
											version={version}
										>
											<div className='request-password-reset'>
												<h1>Password Reset<HelpButton/></h1>
												<p>Please <a href={`mailto:${adminEmailAddress}?subject=Please reset my password`}>contact your administrator</a> to request that your password be reset.</p>
												<p>A link and instructions will be sent to your email address.</p>
											</div>
										</Page>
									) :
										(loggedIn ?
											(primaryNav === 'adminContact' ?
												<AdminContact
													appName={appName}
													adminEmailAddress={adminEmailAddress}
													handleChangeAdminEmail={this.handleChangeAdminEmail}
													handleHomeClick={this.handleHomeClick}
													version={version}
												/> :
												<Page
													appName={appName}
													handleChangePasswordLinkClick={this.handleChangePasswordLinkClick}
													handleHomeClick={this.handleHomeClick}
													handleLogout={this.handleLogout}
													handleShowAdminClick={this.handleShowAdminClick}
													primaryNav={primaryNav}
													secondaryNav={secondaryNav}
													showAdminLink={loggedIn && (primaryNav === 'home' || primaryNav === '')}
													trail={trail}
													userName={userName}
													version={version}
												>
													<Tabs
														handleTabClick={this.handleTabClick}
														selectedTab={selectedTab}>

														<Tab
															className={`home`}
															id={`homeTab`}
														>Home</Tab>
														<TabContent className={`home`} >
															{this.renderHomeTabContent()}
														</TabContent>

														<Tab
															className={`user ${grantUserList ? 'show' : 'hide'}`}
															id='usersTab'
														>Users</Tab>
														{grantUserList ? <TabContent className={`user`} >
															{secondaryNav === 'list' ?
																<PagedContent
																	AAA={this.AAA}
																	adminGroupName={adminGroupName}
																	appName={appName}
																	dataSources={dataSources}
																	getGroupById={this.getGroupById}
																	getIntersectionTitle={this.getIntersectionTitle}
																	getIntersectionValue={this.getIntersectionValue}
																	getUserById={this.getUserById}
																	grantDataSourcesList={grantDataSourcesList}
																	grantGroupForUserList={grantGroupForUserList}
																	grantGroupList={grantGroupList}
																	grantPolicyList={grantPolicyList}
																	groupClientPolicyId={groupClientPolicyId}
																	groups={groups}
																	handleAddRecord={this.handleAddRecord}
																	handleChangeSearch={this.handleChangeSearch}
																	handleClickIntersection={this.handleClickIntersection}
																	handleDeleteSelected={this.handleDeleteSelected}
																	handleRowClick={this.handleRowClick}
																	handleSelect={this.handleSelect}
																	handleToggleActive={this.handleToggleActive}
																	handleToggleAll={this.handleToggleAll}
																	nSelected={nUsersSelected}
																	policies={policies}
																	searchTerm={userSearchTerm}
																	superUI={superUI}
																	userName={userName}
																	users={filteredUsers}
																	what={`user`}
																/> : null}
															{primaryNav === 'user' && (secondaryNav === 'edit' || secondaryNav === 'add') ?
																<UserForm
																	AAA={this.AAA}
																	aaaEndpoint={aaaEndpoint}
																	aaaKey={aaaKey}
																	adminGroupName={adminGroupName}
																	appName={appName}
																	dataSources={dataSources}
																	dialog={this.dialog}
																	editId={editId}
																	getGroupById={this.getGroupById}
																	getUserById={this.getUserById}
																	grantDataSourcesList={grantDataSourcesList}
																	grantGroupForUserList={grantGroupForUserList}
																	grantGroupList={grantGroupList}
																	grantPolicyList={grantPolicyList}
																	groups={groups}
																	handleAddUpdateUser={this.handleAddUpdateUser}
																	handleCancelClick={this.handleCancelClick}
																	handleResponse={handleResponse}
																	handleToggleActive={this.handleToggleActive}
																	nameRules={nameRules}
																	ownedDataSources={ownedDataSources}
																	ownedPolicies={ownedPolicies}
																	passwordRules={passwordRules}
																	policies={policies}
																	primaryNav={primaryNav}
																	secondaryNav={secondaryNav}
																	superUI={superUI}
																	user={(secondaryNav === 'edit') ? user : {}}
																	userName={userName} // Logged in user name not user to be edited
																	users={users}
																/> : null}
														</TabContent> : <></>}

														<Tab
															className={`group ${grantGroupList && superUI ? 'show' : 'hide'}`}
															id={`groupsTab`}
														>Groups</Tab>
														{grantGroupList ? <TabContent className={`group`}>
															{secondaryNav === 'list' ?
																<PagedContent
																	appName={appName}
																	dataSources={dataSources}
																	getGroupById={this.getGroupById}
																	getIntersectionTitle={this.getIntersectionTitle}
																	getIntersectionValue={this.getIntersectionValue}
																	grantDataSourcesList={grantDataSourcesList}
																	grantPolicyList={grantPolicyList}
																	groups={filteredGroups}
																	handleAddRecord={this.handleAddRecord}
																	handleChangeSearch={this.handleChangeSearch}
																	handleClickIntersection={this.handleClickIntersection}
																	handleDeleteSelected={this.handleDeleteSelected}
																	handleRowClick={this.handleRowClick}
																	handleSelect={this.handleSelect}
																	handleToggleAll={this.handleToggleAll}
																	nSelected={nGroupsSelected}
																	policies={policies}
																	searchTerm={groupSearchTerm}
																	superUI={superUI}
																	what={`group`}
																/> : null}
															{primaryNav === 'group' && (secondaryNav === 'edit' || secondaryNav === 'add') ?
																<GroupForm
																	AAA={this.AAA}
																	dataSources={dataSources}
																	editId={editId}
																	getGroupById={this.getGroupById}
																	getUserById={this.getUserById}
																	grantDataSourcesList={grantDataSourcesList}
																	grantPolicyList={grantPolicyList}
																	group={(secondaryNav === 'edit') ? group : {}}
																	groups={groups}
																	handleAddUpdateGroup={this.handleAddUpdateGroup}
																	handleCancelClick={this.handleCancelClick}
																	nameRules={nameRules}
																	ownedDataSources={ownedDataSources}
																	ownedPolicies={ownedPolicies}
																	policies={policies}
																	primaryNav={primaryNav}
																	secondaryNav={secondaryNav}
																	superUI={superUI}
																/> : null}
														</TabContent> : <></>}

														<Tab
															className={`compound-editor ${appName === 'IdeaTracker' ? 'show' : 'hide'}`}
															id={`compoundTab`}
														>Compound</Tab>
														{appName === 'IdeaTracker' ? <TabContent className={`compound-editor`}>
															{primaryNav === 'compound-editor' ?
																<CompoundEditor
																	aaaKey={aaaKey}
																	dialog={this.dialog}
																	handleShowSpinner={this.handleShowSpinner}
																	ideaTrackerService={ideaTrackerService}
																	logError={this.logError}
																/>
																:
																null
															}
														</TabContent> : <></>}

														<Tab
															className={`data-source ${grantDataSourcesList ? 'show' : 'hide'}`}
															id={`datasourcesTab`}
														>Data Sources</Tab>
														{grantDataSourcesList ? <TabContent className={`data-source`}>
															{secondaryNav === 'list' ?
																<PagedContent
																	aaaKey={aaaKey}
																	appName={appName}
																	dialog={this.dialog}
																	filteredDataSourcesAndBatches={filteredDataSourcesAndBatches}
																	handleAddRecord={this.handleAddRecord}
																	handleChangeSearch={this.handleChangeSearch}
																	handleDeleteSelected={this.handleDeleteSelected}
																	handlePurgeDataFiles={this.APIpurgeDataFiles}
																	handleRowClick={this.handleRowClick}
																	handleSelect={this.handleSelect}
																	handleShowSpinner={this.handleShowSpinner}
																	handleToggleAll={this.handleToggleAll}
																	ideaTrackerService={ideaTrackerService}
																	logError={this.logError}
																	nSelected={nDataSourcesSelected + nBatchesSelected}
																	queryInterfaceEndpoint={queryInterfaceEndpoint}
																	searchTerm={dataSourceSearchTerm}
																	superUI={superUI}
																	what={`data-source`}
																/> : null}
															{primaryNav === 'data-source' && (secondaryNav === 'edit' || secondaryNav === 'add') ?
																<DataSourceForm
																	aaaKey={aaaKey}
																	appName={appName}
																	batches={batches}
																	cddVaultEndpoint={cddVaultEndpoint}
																	dialog={this.dialog}
																	getCDDBatches={this.getCDDBatches}
																	handleAddBatch={this.handleAddBatch}
																	handleAddUpdateDataSource={this.handleAddUpdateDataSource}
																	handleCancelClick={this.handleCancelClick}
																	handleDataSourceTabClick={this.handleDataSourceTabClick}
																	handleShowSpinner={this.handleShowSpinner}
																	logError={this.logError}
																	dataSource={secondaryNav === 'edit' ? dataSource : {}}
																	dataSources={dataSources}
																	primaryNav={primaryNav}
																	queryInterfaceEndpoint={queryInterfaceEndpoint}
																	secondaryNav={secondaryNav}
																/> : null}
														</TabContent> : <></>}
													</Tabs >

												</Page >)
											: <Login
												action='login'
												appName={appName}
												error={loginError}
												handleLogin={this.handleLogin}
												handlePasswordResetLinkClick={this.handlePasswordResetLinkClick}
												ideaTrackerService={ideaTrackerService}
												logError={this.logError}
												pollingInterval={pollingInterval}
												samlSso={samlSso}
												samlEndpoint={`${aaaEndpoint}/saml`}
												statusEndpoint={statusEndpoint}
												userName={userName}
												version={version}
											/>
										)
								)
							}
						</>
						:
						null
					}

				</React.StrictMode >
			</div >

		);
	};
}

export default App;