import '../Statistics.scss';
import {
	FieldSet,
	Select,
} from '../../../Components';
import {
	handleResponse,
	safeChemistryFileName
} from '../../../utility';
import blankPlot from '../../../images/blankPlot.svg';
import colourVariables from '../../../styles/_colours.module.scss';
import Plot from 'react-plotly.js';
import plotVariables from './RollbackPlot.scss';
import React from 'react';
import { SecureDownload } from '..';

export class RollbackPlot extends React.PureComponent {

	constructor(props) {
		super(props);
		const {
			plotHeight = 500,
			plotWidth = 800,
		} = plotVariables;

		this.state = {
			height: plotHeight,
			selectedEndpoint: '',
			series: '',
			width: plotWidth,
		};

		this.handleChange = this.handleChange.bind(this);
		this.refresh = this.refresh.bind(this);
	}

	refresh = () => {
		const {
			aaaKey,
			endpoints,
			handleShowSpinner,
			logError,
			statisticsEndpoint,
		} = this.props;

		const {
			selectedEndpoint,
			series,
		} = this.state;

		if (selectedEndpoint && series) {


			handleShowSpinner(true);

			const headers = new Headers();
			headers.append("X-api-key", aaaKey);

			const requestOptions = {
				headers: headers,
				method: 'GET',
				redirect: 'follow',
			};

			fetch(`${statisticsEndpoint}/model/validation/external/endpointRollback/${selectedEndpoint}`, requestOptions)

				.then(handleResponse)
				.then(res => {
					let virtual;
					let impute;
					for (let i = 0; i < res.length; i++) {
						if (res[i].modelName === 'Virtual') {
							virtual = res[i];
						};
						if (res[i].modelName === 'Impute') {
							impute = res[i];
						}
					}
					const {
						cerellaHomeColour = '#fdbd75',
						cerellaUserColour = '#5a99b7',
					} = colourVariables;

					let title = `Model Rollback`;
					if (series) {
						switch (series) {
							case 'Both':
								title = `Imputation and Virtual ${title}`;
								break;
							case 'Impute':
								title = `Imputation ${title}`;
								break;
							default:
								title = `${series} ${title}`;
								break;
						}
					}

					const newData = [];

					if (series === 'Impute' || series === "Both") {
						const { endpointData: imputeEndpoints } = impute;

						// Sort on value
						let slimmedImpute = imputeEndpoints.map((item) => {
							return {
								x: item.fraction,
								y: item.rmse,
								z: item.meanUncertainty,
							};
						}).sort((a, b) =>
							(a.fraction < b.fraction) ? 1 : -1
						);

						// Remove null values from list
						slimmedImpute = slimmedImpute.filter(item => item.y !== null);

						newData.push(
							{
								"marker": {
									"color": cerellaHomeColour,
									"maxdisplayed": 80,
									"size": 8
								},
								"mode": "lines+markers",
								"name": `Imputation`,
								"type": "scatter",
								"x": slimmedImpute.map((item) => item.x),
								"y": slimmedImpute.map((item) => item.y),
							},
							{
								"line": { "dash": "dash", },
								"marker": {
									"color": cerellaHomeColour,
									"maxdisplayed": 80,
									"size": 8
								},
								"mode": "lines",
								"name": `Mean uncertainty`,
								"type": "scatter",
								"x": slimmedImpute.map((item) => item.x),
								"y": slimmedImpute.map((item) => item.z),
							},
						);
					}
					if (series === 'Virtual' || series === "Both") {

						const { endpointData: virtualEndpoints } = virtual;

						// Sort on value
						let slimmedVirtual = virtualEndpoints.map((item) => {
							return {
								x: item.fraction,
								y: item.rmse,
								z: item.meanUncertainty,
							};
						}).sort((a, b) =>
							(a.fraction < b.fraction) ? 1 : -1
						);

						// Remove null values from list
						slimmedVirtual = slimmedVirtual.filter(item => item.y !== null);

						newData.push(
							{
								"marker": {
									"color": cerellaUserColour,
									"maxdisplayed": 80,
									"size": 8
								},
								"mode": "lines+markers",
								"name": `Virtual`,
								"type": "scatter",
								"x": slimmedVirtual.map((item) => item.x),
								"y": slimmedVirtual.map((item) => item.y),
							},
							{
								"line": { "dash": "dash", },
								"marker": {
									"color": cerellaUserColour,
									"maxdisplayed": 80,
									"size": 8
								},
								"mode": "lines",
								"name": `Mean uncertainty`,
								"type": "scatter",
								"x": slimmedVirtual.map((item) => item.x),
								"y": slimmedVirtual.map((item) => item.z),
							},
						);
					}

					let endpointFileName = '';
					const selectedEndpointObject = endpoints.find((item) => item.endpointId === selectedEndpoint);
					if (selectedEndpointObject) endpointFileName = selectedEndpointObject.endpointName;
					const safeEndpointFileName = safeChemistryFileName(endpointFileName);
					const nowDate = new Date();
					const strDate = `${nowDate.getDate()}-${(nowDate.getMonth() + 1)}-${nowDate.getFullYear()}`;

					this.setState({
						dataRetrieved: true,
						rollBack: {
							config: {
								displaylogo: false,
								responsive: true,
								toImageButtonOptions: {
									filename: `rollback-${series}-${safeEndpointFileName}-${strDate}`,
								}
							},
							data: newData,
							layout: {
								annotations: [{
									align: 'center',
									font: {
										color: 'rgb(116, 101, 130)',
										size: 11,
									},
									showarrow: false,
									text: `${endpointFileName}`,
									x: 0.5,
									xref: 'paper',
									y: 1,
									yanchor: 'bottom',
									yref: 'paper',
								}],
								legend: {
									orientation: "h",
									y: -0.2,
								},
								margin: {
									b: 30,
									l: 50,
									r: 20,
									t: 50,
								},
								title: title,
								xaxis: {
									showticklabels: true,
									title: 'Most Confidently Predicted Fraction of Test Set',
								},
								yaxis: {
									title: 'RMSE',
								},
							},

						},
					}, () => { handleShowSpinner(false); });
				})
				.catch(error => {
					const action = `Retrieving rollback`;
					logError(action, error);
					handleShowSpinner(false);
				});
		}
	};

	handleChange = (field, value) => {

		let {
			selectedEndpoint,
			series,
		} = this.state;

		switch (field) {
			case 'series':
				series = value;
				break;
			case 'selectedEndpoint':
				selectedEndpoint = value;
				break;
		}
		this.setState({ [field]: value },
			() => {
				if (selectedEndpoint && series) {
					this.refresh();
				};
			});
	};

	componentDidUpdate = (oldProps) => {
		const { refreshStatistics: oldRefreshStatistics } = oldProps;
		const { refreshStatistics } = this.props;
		if (refreshStatistics !== oldRefreshStatistics) this.refresh();
	};

	render() {
		const {
			aaaKey,
			dialog,
			endpoints,
			endpointsRetrieved,
			handleShowSpinner,
			logError,
			statisticsEndpoint,
		} = this.props;

		const {
			selectedEndpoint,
			series,
		} = this.state;

		const nowDate = new Date();
		const strDate = `${nowDate.getDate()}-${(nowDate.getMonth() + 1)}-${nowDate.getFullYear()}`;

		const renderedOptions = [];
		if (endpoints) {
			renderedOptions.push(
				{
					name: 'Select an endpoint...',
					value: '',
				}
			);
			endpoints.forEach((endpoint) => {
				renderedOptions.push(
					{
						name: endpoint.endpointName,
						value: endpoint.endpointId,
					}
				);
			});
		}
		const seriesOptions = [];
		seriesOptions.push({
			name: 'Select a model...',
			value: '',
		});
		seriesOptions.push({
			name: 'Imputation',
			value: 'Impute',
		});
		seriesOptions.push({
			name: 'Virtual',
			value: 'Virtual',
		});
		seriesOptions.push({
			name: 'Both',
			value: 'Both',
		});

		let endpointFileName = '';
		if (selectedEndpoint !== '') {
			const selectedEndpointObject = endpoints.find((item) => item.endpointId === selectedEndpoint);
			if (selectedEndpointObject) endpointFileName = selectedEndpointObject.endpointName;
			endpointFileName = safeChemistryFileName(endpointFileName);
		}

		const { rollBack } = this.state;
		const { className } = this.props;
		return (
			endpointsRetrieved ?
				<>
					<form id='rollbackForm'>
						<p>Model performance for fractions of data in the independent test set with the lowest predicted uncertainty.</p>
						<FieldSet>

							<Select
								handleChange={this.handleChange}
								id='selectedEndpoint'
								key='selectedEndpoint'
								label='Endpoint'
								options={renderedOptions}
								placeHolder={'Select an endpoint'}
								value={selectedEndpoint}
							/>

							<Select
								handleChange={this.handleChange}
								id='series'
								key='series'
								label='Model(s)'
								options={seriesOptions}
								value={series}
							/>
						</FieldSet>

						{
							selectedEndpoint && series && rollBack ?
								<p>CSV: <SecureDownload
									aaaKey={aaaKey}
									dialog={dialog}
									contentType='text/csv'
									handleShowSpinner={handleShowSpinner}
									fileName={`rollback${series}-${endpointFileName}-${strDate}.csv`}
									href={`${statisticsEndpoint}/model/validation/external/endpointRollback/csv/${selectedEndpoint}/${series}`}
									logError={logError} />
								</p> : null
						}
						<div className={`limit-width`}>
							<h1>All Endpoints</h1>
							<p>CSV: <SecureDownload
								aaaKey={aaaKey}
								dialog={dialog}
								contentType='text/csv'
								handleShowSpinner={handleShowSpinner}
								fileName={`rollbackAllEndpoints-${strDate}.csv`}
								href={`${statisticsEndpoint}/model/validation/external/endpointRollback/csv/both`}
								logError={logError} />
							</p>
						</div>
					</form>
					<div className='optimisation-chart-container'>
						{
							selectedEndpoint && series && rollBack ?
								<>
									<Plot
										className={`optimisation-chart chart ${className ? className : ''} `}
										divId='rollbackPlot'
										useResizeHandler
										data={rollBack.data}
										layout={rollBack.layout}
										config={rollBack.config} >
									</Plot>
								</>
								:
								<img src={blankPlot} alt='blank plot' />
						}
					</div>
				</>
				:
				<div className='rollback' ></div>
		);
	}
};;
