// Note that columnName is what's visible to the user, fieldName is effectively the ID.
import './DataSourceForm.scss';
import './SortableTree.scss';
import {
	CheckBox,
	FieldSet,
	Info,
	Progress,
	Radio,
	Select,
	TextField
} from '..';
/* eslint-disable sort-imports */
import {
	b64EncodeUnicode,
	copyObject,
	handleResponse,
	handleResponseCSV,
	uuidv4,
} from '../../utility';
/* eslint-enable sort-imports */
import SortableTree,
{
	addNodeUnderParent,
	changeNodeAtPath,
	getNodeAtPath,
	removeNodeAtPath,
} from 'react-sortable-tree';
import CSVReader from 'react-csv-reader';
import React from 'react';
import { readString } from 'react-papaparse';
export class DataSourceForm extends React.Component {
	constructor(props) {
		super(props);

		const {
			appName,
			secondaryNav,
		} = this.props;

		const today = new Date();
		const date = today.getDate();
		let month = `0${(today.getMonth() + 1)}`.slice(-2);
		if (month === '05') month = '01'; // May is no use as an example as it already has just 3 characters.
		const year = today.getFullYear();
		const shortYear = year.toString().substr(-2);
		const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
		let fullMonth = months[today.getMonth()];
		if (fullMonth === 'May') fullMonth = 'January'; // May is no use as an example as it already has just 3 characters.
		const shortMonth = fullMonth.slice(0, 3);
		const dates = { // See here https://docs.python.org/3/library/datetime.html#strftime-strptime-behavior for coding of date formats
			'Mixed': {
				name: 'Mixed',
				value: [
					{ disabled: true, name: 'Mixed', value: 'Mixed' }
				],
			},
			'dmy': {
				name: 'Day-Month-Year',
				value: [
					{ name: `${date} ${shortMonth}`, value: '%d %b' },
					{ name: `${date}/${month}/${year}`, value: '%d/%m/%Y' },
					{ name: `${date}/${month}/${shortYear}`, value: '%d/%m/%y' },
					{ name: `${date}-${shortMonth}-${year}`, value: '%d-%b-%Y' },
					{ name: `${date}-${fullMonth}-${year}`, value: '%d-%B-%Y' },
					{ name: `other`, value: 'other' },
				],
			},
			'mdy': {
				name: 'Month-Day-Year',
				value: [
					{ name: `${shortMonth} ${date}`, value: '%b %d' },
					{ name: `${month}/${date}/${year}`, value: '%m/%d/%Y' },
					{ name: `${month}/${date}/${shortYear}`, value: '%m/%d/%y' },
					{ name: `${shortMonth}-${date}-${year}`, value: '%b-%d-%Y' },
					{ name: `${fullMonth}-${date}-${year}`, value: '%B-%d-%Y' },
					{ name: `other`, value: 'other' },
				],
			},
			'ymd': {
				name: 'Year-Month-Day',
				value: [
					{ name: `${year}-${shortMonth}-${date}`, value: '%Y-%b-%d' },
					{ name: `${year}-${fullMonth}-${date}`, value: '%Y-%B-%d' },
					{ name: `${year}-${month}-${date}`, value: '%Y-%m-%d' },
					{ name: `${year}/${month}/${date}`, value: '%Y/%m/%d' },
					{ name: `${shortYear}/${month}/${date}`, value: '%y/%m/%d' },
					{ name: `other`, value: 'other' },
				],
			},
		};

		const dateInputOptions =
			[
				{ name: 'Day-Month-Year', value: 'dmy' },
				{ name: 'Month-Day-Year', value: 'mdy' },
				{ name: 'Year-Month-Day', value: 'ymd' },
			];

		const dateDisplayOptions = copyObject(dateInputOptions);

		const units = {
			'Mixed': {
				disabled: true,
				name: 'Mixed',
				value: [
					{ name: 'Mixed', value: 'Mixed' },
				]
			},
			'angle': {
				name: "Angle",
				value:
					[
						{ name: 'rad', value: 'RADIAN' },
						{ name: 'degrees', value: 'DEGREE' },
					]
			},
			'apparent_permeability': {
				name: "Apparent permeability",
				value:
					[
						{ name: 'cm/s', value: 'CENTIMETRES_PER_SECOND' },
						{ name: 'm/s', value: 'METRES_PER_SECOND' },
					]
			},
			'application': {
				name: "Application",
				value:
					[
						{ name: 'kg/ha', value: 'KILOGRAM_PER_HECTARE' },
						{ name: 'kg/ac', value: 'KILOGRAM_PER_ACRE' },
						{ name: 'kg/m\u00b2', value: 'KILOGRAM_PER_SQUARE_METRE' },
					]
			},
			'clearance': {
				name: "Clearance",
				value:
					[
						{ name: 'mL/min/kg', value: 'MILLILITRE_PER_MINUTE_PER_KILOGRAM' },
						{ name: '\u00b5L/min/mg', value: 'MICROLITRE_PER_MINUTE_PER_MILLIGRAM' },
					]
			},
			'concentration_mass': {
				name: "Concentration (Mass)",
				value:
					[
						{ name: 'ng/mL', value: 'NANOGRAM_PER_MILLILITRE' },
						{ name: '\u00b5g/mL', value: 'MICROGRAM_PER_MILLILITRE' },
						{ name: 'mg/mL', value: 'MILLIGRAM_PER_MILLILITRE' },
						{ name: 'log(ng/mL)', value: 'LOGNANOGRAM_PER_MILLILITRE' },
						{ name: 'log(\u00b5g/mL)', value: 'LOGMICROGRAM_PER_MILLILITRE' },
						{ name: 'log(mg/mL)', value: 'LOGMILLIGRAM_PER_MILLILITRE' },
					]
			},
			'concentration_molar': {
				name: "Concentration (Molar)",
				value:
					[
						{ name: 'pKi/pIC50', value: 'PKI' },
						{ name: 'pM', value: 'PICOMOLAR' },
						{ name: 'nM', value: 'NANOMOLAR' },
						{ name: '\u00b5M', value: 'MICROMOLAR' },
						{ name: 'mM', value: 'MILLIMOLAR' },
						{ name: 'M', value: 'MOLAR' },
						{ name: 'log(pM)', value: 'LOGPICOMOLAR' },
						{ name: 'log(nM)', value: 'LOGNANOMOLAR' },
						{ name: 'log(\u00b5M)', value: 'LOGMICROMOLAR' },
						{ name: 'log(mM)', value: 'LOGMILLIMOLAR' },
						{ name: 'log(M)', value: 'LOGMOLAR' },
					]
			},
			'dose': {
				name: "Dose",
				value:
					[
						{ name: 'mg/kg', value: 'MILLIGRAM_PER_KILOGRAM' },
					]
			},
			'dose_per_volume': {
				name: "Dose per volume",
				value:
					[
						{ name: 'mg/kg/mL', value: 'MILLIGRAM_PER_KILOGRAM_PER_MILLILITRE' },
					]
			},
			'exposure': {
				name: "Exposure",
				value:
					[
						{ name: 'ng hr/mL', value: 'NANOGRAM_HOURS_PER_MILLILITRE' },
						{ name: '\u00b5g hr/mL', value: 'MICROGRAM_HOURS_PER_MILLILITRE' },
						{ name: 'ng min/mL', value: 'NANOGRAM_MINUTES_PER_MILLILITRE' },
						{ name: '\u00b5g min/mL', value: 'MICROGRAM_MINUTES_PER_MILLILITRE' },
					]
			},
			'other': {
				name: "Other",
				value:
					[
						{ name: 'Other', value: 'OTHER' },
					]
			},
			'potential': {
				name: "Potential",
				value:
					[
						{ name: 'mV', value: 'MILLIVOLTS' },
					]
			},
			'ratio': {
				name: "Ratio",
				value:
					[
						{ name: 'Ratio', value: 'RATIO' },
						{ name: '%', value: 'PERCENT' },
						{ name: 'log(Ratio)', value: 'LOGRATIO' },
						{ name: 'ppm', value: 'PARTS_PER_MILLION' },
						{ name: 'ppb', value: 'PARTS_PER_BILLION' },
						{ name: 'ppt', value: 'PARTS_PER_TRILLION' },
					]
			},
			'temperature': {
				name: "Temperature",
				value:
					[
						{ name: '\u00b0C', value: 'CELSIUS' },
						{ name: 'K', value: 'KELVIN' },
					]
			},
			'time': {
				name: "Time",
				value:
					[
						{ name: 's', value: 'SECOND' },
						{ name: 'min', value: 'MINUTE' },
						{ name: 'h', value: 'HOUR' },
						{ name: 'd', value: 'DAY' },
					]
			},
			'turnover_per_weight': {
				name: "Turnover per weight",
				value:
					[
						{ name: 'pM/min/mg', value: 'PICOMOLAR_PER_MINUTE_PER_MILLIGRAM' },
					]
			},
			'volume_of_distribution': {
				name: "Volume of distribution",
				value:
					[
						{ name: 'mL/kg', value: 'MILLILITRE_PER_KILOGRAM' },
						{ name: 'L/kg', value: 'LITRE_PER_KILOGRAM' },
					]
			},
		};

		const measurementOptions =
			[
				{ name: 'Other', value: 'other' },
				{ name: 'Concentration (Molar)', value: 'concentration_molar' },
				{ name: 'Concentration (Mass)', value: 'concentration_mass' },
				{ name: 'Ratio', value: 'ratio' },
				{ name: 'Time', value: 'time' },
				{ name: 'Angle', value: 'angle' },
				{ name: 'Apparent permeability', value: 'apparent_permeability' },
				{ name: 'Exposure', value: 'exposure' },
				{ name: 'Turnover per weight', value: 'turnover_per_weight' },
				{ name: 'Clearance', value: 'clearance' },
				{ name: 'Dose', value: 'dose' },
				{ name: 'Dose per volume', value: 'dose_per_volume' },
				{ name: 'Potential', value: 'potential' },
				{ name: 'Volume of distribution', value: 'volume_of_distribution' },
				{ name: 'Application', value: 'application' },
				{ name: 'Temperature', value: 'temperature' },
			];

		/* eslint-disable sort-keys */
		const validShortDateFormats = {
			// dmy
			'%d %b': 'dmy',
			'%d/%m/%Y': 'dmy',
			'%d/%m/%y': 'dmy',
			'%d-%b-%Y': 'dmy',
			'%d-%B-%Y': 'dmy',
			// mdy
			'%b %d': 'mdy',
			'%m/%d/%Y': 'mdy',
			'%m/%d/%y': 'mdy',
			'%b-%d-%Y': 'mdy',
			'%B-%d-%Y': 'mdy',
			// ymd
			'%Y-%b-%d': 'ymd',
			'%Y-%B-%d': 'ymd',
			'%Y-%m-%d': 'ymd',
			'%Y/%m/%d': 'ymd',
			'%y/%m/%d': 'ymd',
		};
		/* eslint-enable sort-keys */

		const mergeRuleOptions = [
			{ name: 'Default', value: 'DEFAULT' },
			{ name: 'Arithmetic mean', value: 'ARITHMETIC_MEAN' },
			{ name: 'Geometric mean', value: 'GEOMETRIC_MEAN' },
			{ name: 'Minimum', value: 'MINIMUM' },
			{ name: 'Maximum', value: 'MAXIMUM'	},
			{ name: 'Take first', value: 'TAKE_FIRST' },
			{ name: 'Discard all', value: 'DISCARD_ALL' },
		];

		// Factor (CRL-1327)
		const factorOptions = [
			{ name: 'Default', value: 'DEFAULT' }, // Omit factor altogether
			{ name: 'Standard deviation', value: 'STANDARD_DEVIATION' }, // Send false
			{ name: 'Factor', value: 'FACTOR' }, // send true
		];

		this.state = ({
			JSONloaded: false,
			add: secondaryNav === 'add',
			cddTokenError: true,
			currentNode: {},
			dataFileLoaded: false,
			dataLoaded: 'No data loaded',
			dateDisplayOptions: dateDisplayOptions,
			dateInputOptions: dateInputOptions,
			dates: dates,
			factorOptions: factorOptions,
			idColumnError: false,
			measurementOptions: measurementOptions,
			mergeRuleOptions: mergeRuleOptions,
			nameError: '',
			previewLoaded: false,
			progress: {
				count: 0,
				done: true,
				step: 0,
			},
			searchFocusIndex: 0,
			searchString: '',
			testSetFileLoaded: false,
			units: units,
			valid: true,
			validShortDateFormats: validShortDateFormats,
		});

		this.addNodeWithoutDuplication = this.addNodeWithoutDuplication.bind(this);
		this.checkForNodesInUse = this.checkForNodesInUse.bind(this);
		this.friendlyUnitsString = this.friendlyUnitsString.bind(this);
		this.getMeasurementFromUnit = this.getMeasurementFromUnit.bind(this);
		this.getTransform = this.getTransform.bind(this);
		this.handleBlur = this.handleBlur.bind(this);
		this.handleCDDTokenRegister = this.handleCDDTokenRegister.bind(this);
		this.handleCerellaEnabled = this.handleCerellaEnabled.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChangeColumn = this.handleChangeColumn.bind(this);
		this.handleChangePublicDatasets = this.handleChangePublicDatasets.bind(this);
		this.handleConnect = this.handleConnect.bind(this);
		this.handleDataFileLoad = this.handleDataFileLoad.bind(this);
		this.handleJSONload = this.handleJSONload.bind(this);
		this.handlePreviewData = this.handlePreviewData.bind(this);
		this.handleRefreshCDD = this.handleRefreshCDD.bind(this);
		this.handleShowDisplayGroup = this.handleShowDisplayGroup.bind(this);
		this.handleShowDisplayGroupReview = this.handleShowDisplayGroupReview.bind(this);
		this.handleShowEndpointReview = this.handleShowEndpointReview.bind(this);
		this.handleShowEndPointTypeReview = this.handleShowEndPointTypeReview.bind(this);
		this.handleShowUnitsReview = this.handleShowUnitsReview.bind(this);
		this.handleTestSetFileLoad = this.handleTestSetFileLoad.bind(this);
		this.loadDataSource = this.loadDataSource.bind(this);
		this.renderCommon = this.renderCommon.bind(this);
		this.renderSpreadSheet = this.renderSpreadSheet.bind(this);
		this.selectColumn = this.selectColumn.bind(this);
		this.selectEndpoint = this.selectEndpoint.bind(this);
		this.validate = this.validate.bind(this);

		this.endpointClickHelp = "You can click on an endpoint to select it in the spreadsheet";

		this.endpointGroupDescription = (
			<>
				<p>It would not be appropriate for experiments that have multiple readouts or measurements
					represented by different columns of data to be used to impute one another. Therefore,
					these columns should be grouped into what is termed an Endpoint (or Measurement) Group.
					These readouts or measurements are always present together or absent together for compounds
					in the data set.</p><p>Please see documentation for further details.</p>
			</>
		);

		this.displayGroupDescription = (
			<>
				<p>Endpoints can be grouped into Display Groups for display purposes to make it easier to navigate
					the list of endpoints. Display Groups can be given any name you want to assign and, form a tree
					structure when viewing endpoints. Forward slashes can be added to create further subgroups.
					For example, the endpoints CYP3A4 %Inh, CYP1A2 %Inh and CYP2D6 %Inh could all be given the
					Display Group of ADME/Metabolism. When viewing the tree they would be found under ADME and
					then Metabolism.</p>
				<p>Please note that any display group nodes defined in the tree representation, will not be preserved
					between sessions unless endpoints have been assigned using the <span className={`select-node`} alt={`tick`} title={`'tick'`} >'tick'</span> button.</p>
				<p>Please see documentation for further details.</p>
			</>
		);

		this.hiddenDescription = `Endpoint will not be used in Cerella model building, and will not be searchable in the query interface.`;
		if (appName === 'IdeaTracker')this.hiddenDescription = `Endpoint will not be searchable in the query interface.`;

		this.endPointTypeDescription = (
			<>
				<ul>
					<li><b>Hidden</b>: {this.hiddenDescription}</li>

					<li><b>Input only</b>: Endpoint will be used as an input to model building, but output predictions will not be
						generated for the endpoint.</li>

					<li><b>Priority</b>: If any priority endpoints are defined, the model validation metric used in hyperparameter
						optimisation will be the median over all priority endpoints. If no endpoints are designated as priority
						endpoints, then the model validation metric will be the median over all modelled endpoints.</li>
				</ul>
				<p>Endpoints which are <b>hidden</b> cannot also be set as <b>input only</b> or <b>priority</b>.</p>
				<p>Endpoints which are <b>input only</b> cannot also be set as <b>priority</b>.</p>
			</>
		);


	}

	addNodeWithoutDuplication = (parentNode, node) => {
		// console.log("addNodeWithoutDuplication", parentNode.title, node.title);
		// console.log("    parentNode", parentNode);
		// console.log("    node", node);
		let returnedNode;
		if (!parentNode.children) {
			parentNode.children = [node];
			returnedNode = parentNode;
		} else {
			const foundIndex = parentNode.children.findIndex((c) => c.title === node.title);
			if (foundIndex !== -1) {
				returnedNode = parentNode.children.find((c) => c.title === node.title);
			} else {
				parentNode.children.push(node);
				returnedNode = node;
			}

		}
		// console.log("    returnedNode", returnedNode.title);
		return returnedNode; // child node
	};

	addWithoutDuplication = (keyedList, record) => {
		const foundIndex = keyedList.findIndex((o) => o.name === record.name && o.value === record.value);
		if (foundIndex === -1) {
			keyedList.unshift(record);
		}
	};

	addWithoutDuplicationOrRemoveMix = (keyedList, value) => {
		const mixIndex = keyedList.findIndex((o) => o.name === 'Mixed' && o.value === 'Mixed');
		if (value === 'Mixed' && mixIndex === -1) {
			keyedList.unshift({ disabled: true, name: 'Mixed', value: 'Mixed', });
		} else if (value !== 'Mixed' && mixIndex !== -1) {
			keyedList.splice(mixIndex, 1);
		}
	};

	convertToErrorType = (factor) => {
		if (factor !== undefined) {
			switch (factor) {
				case false:
				case 'STANDARD_DEVIATION':
					return 'STANDARD_DEVIATION';
				case true:
				case 'FACTOR':
					return 'FACTOR';
				default:
					return 'DEFAULT';
			}
		}
		return factor;
	};

	pathToString = (path, treeData) => {
		let pathString = '';
		let nibblePath = copyObject(path);
		// console.log("pathToString path", path, "treeData", treeData);
		for (let i = 0; i < path.length; i++) {
			const { node } = getNodeAtPath({
				getNodeKey: ({ treeIndex }) => treeIndex,
				path: nibblePath,
				treeData,
			});
			pathString = `${node.title.replace('/', '_')}${pathString !== '' ? '/' : ''}${pathString}`;
			nibblePath = path.slice(0, nibblePath.length - 1); // Nibble nodes off the end of the path one by one
		};
		// console.log("pathToString", pathString);
		return pathString;
	};

	checkForNodesInUse = (data, affirm, cancel) => {

		// DELETE data package
		// {
		// 	action: 'delete',
		// 	oldPath: path,
		// 	oldTreeData: treeData,
		// },

		// MOVE data package
		// {
		// 	action: 'move',
		// 	newPath: nextPath,
		// 	newTreeData: treeData,
		// 	oldPath: prevPath,
		// 	oldTreeData: oldTreeData,
		// },

		// RENAME data package
		// {
		// 	action: 'rename',
		// 	newTitle: node.title,
		// 	oldPath: path,
		// 	oldTreeData: oldTreeData,
		// },

		const {
			dataSource,
		} = this.state;

		const {
			source = {},
		} = dataSource;

		const {
			availableProperties = [],
		} = source;

		const {
			dialog
		} = this.props;

		let replacePath = '';
		let searchPath = '';
		const {
			action,
			newPath,
			newTitle,
			newTreeData,
			oldPath,
			oldTreeData,
		} = data;
		switch (action) {
			case 'delete':
			case 'rename':
				searchPath = this.pathToString(oldPath, oldTreeData);

				replacePath = copyObject(oldPath);
				replacePath = replacePath.slice(0, replacePath.length - 1);
				replacePath = this.pathToString(replacePath, oldTreeData);
				if (action === 'rename') {
					if (replacePath.length > 0) replacePath += `/`;
					replacePath += `${newTitle}`;
				}
				break;
			case 'move':
				searchPath = this.pathToString(oldPath, oldTreeData);
				replacePath = this.pathToString(newPath, newTreeData);
				break;
			default:
				break;
		}

		// console.log("searchPath", searchPath);
		// console.log("replacePath", replacePath);

		if (searchPath.length > 0) {
			const affectedEndpoints = [];
			const message = [<p key={`dialog-paragraph-0`}>The following endpoints with this display group <b style={{ 'color': 'red' }} >{searchPath}</b> will be affected by this change:</p>];
			const list = [];
			availableProperties.forEach((ap) => {
				if (ap && ap.group && ap.group !== '') {
					// console.log("Display group", ap.group);
					if (ap.group.search(searchPath) > -1) {
						affectedEndpoints.push(ap);
						const dgWithoutSearch = ap.group.replace(searchPath, '');
						list.push(
							<tr key={`affected-endpoint-row-${ap.columnName}`}>
								<td key={`affected-endpoint-name-${ap.columnName}`}>{ap.columnName}</td>
								<td key={`affected-endpoint-old-display-group-${ap.group}`}>
									<b style={{ 'color': 'red' }} > {searchPath}</b>{dgWithoutSearch}
								</td>
								{action !== 'delete' ?
									<td key={`affected-endpoint-new-display-group-${ap.group}`}>
										<><b style={{ 'color': 'red' }} >{replacePath}</b>{dgWithoutSearch}</>
									</td>
									:
									null
								}
								{action === 'delete' && replacePath !== '' ?
									<td key={`affected-endpoint-new-display-group-${ap.group}`}>
										<b style={{ 'color': 'red' }} >{replacePath}</b>
									</td>
									:
									null
								}
							</tr >
						);
					}
				}
			});
			if (affectedEndpoints.length > 0) {
				message.push(
					<div
						className={`for-scrolling`}
						key={`for-scrolling`}
					>
						<table className={`data-source striped`}>
							<thead>
								<tr key={`table-header-row`}>
									<th>Endpoint</th>
									<th>Display group</th>
									{action !== 'delete' || (action === 'delete' && replacePath !== '') ?
										<th>New display group</th>
										:
										null
									}
								</tr>
							</thead>
							<tbody>{list}</tbody>
						</table>
					</div>
				);
				if (action !== 'delete' || (action === 'delete' && replacePath !== '')) {
					message.push(<p key={`dialog-paragraph-1`}>The selected endpoints will have their display group changed as shown above.</p>);
				} else {
					message.push(<p key={`dialog-paragraph-1`}>The selected endpoints will have their display group cleared.</p>);
				}
				message.push(<p key={`dialog-paragraph-2`}>You can still cancel the data source edit, but then you will lose other changes you have made so far too.</p>);
				message.push(<p key={`dialog-paragraph-3`}>Do you want to continue?</p>);
				dialog(
					message,
					'data-source',
					'yesno',
					() => {
						affirm();
						// console.log("Clear endpoint display groups here?");
						// console.log("affectedEndpoints", affectedEndpoints);
						affectedEndpoints.forEach((ae) => {
							if (action === 'rename' || action === 'move') {
								ae.group = ae.group.replace(searchPath, replacePath);
							} else {
								ae.group = replacePath;
							}
						});
						this.setState({
							dataSource: dataSource,
						});
					},
					cancel
				);
			} else {
				affirm();
			}
		} else {
			affirm();
		}
	};

	componentDidMount = () => {
		// console.log("componentDidMount");

		const {
			additionalMetadata = {},
			dataSource,
			name,
			numberColumnOptions,
			structureColumnOptions,
			textColumnOptions,
		} = this.loadDataSource();

		const { source } = dataSource;
		const displayGroups = new Set();
		const persistedAssayIds = new Set();
		sessionStorage.removeItem('persistedAssayIds');
		// if (source) { // it always exists, even if just an empty object
		const { availableProperties } = source;
		if (availableProperties) {
			// console.log("availableProperties", availableProperties);
			availableProperties.forEach((ap) => {
				if (ap.group) displayGroups.add(ap.group);
				const { details } = ap;
				if (details) {
					const { assayId, factor } = details;
					if (assayId && assayId.length > 0) persistedAssayIds.add(assayId);
					// Factor (CRL-1327)
					ap.details.factor = this.convertToErrorType(factor);
				}
			});
			// console.log("componentDidMount availableProperties", availableProperties);
			if (persistedAssayIds.size > 0) {
				// Persist pre-existing assay IDs from the dataSet.
				const strPersistedAssayIDs = JSON.stringify([...persistedAssayIds]);
				sessionStorage.setItem('persistedAssayIds', strPersistedAssayIDs);
			}
		}
		// }
		const { treeData = [] } = this.state;

		// console.log("displayGroups", displayGroups);
		displayGroups.forEach((dg) => {
			const branch = dg.split('/');
			// console.log("branch", branch);
			let parentNode;

			const foundIndex = treeData.findIndex((topLevelNode) => topLevelNode.title === branch[0]);
			if (foundIndex === -1) {
				// Add root node
				parentNode = {
					children: [],
					expanded: true,
					title: branch[0],
				};
				treeData.push(parentNode);
			} else {
				parentNode = treeData.find((topLevelNode) => topLevelNode.title === branch[0]);
			}

			// console.log("parentNode", parentNode.title);

			for (let i = 1; i < branch.length; i++) {
				const newNode = {
					children: [],
					expanded: true,
					title: branch[i],
				};
				const newChildNode = this.addNodeWithoutDuplication(parentNode, newNode);
				parentNode = newChildNode;
			}
		});

		// console.log("treeData", treeData);
		// console.log("componentDidMount dataSource", dataSource);
		const {
			cerellaTestSetColumn,
			cerellaTestIdentifiers = [],
			cerellaTestIdentifiersFilename = '',
		} = additionalMetadata;
		const testSetFileError = '';
		const testSetFileLoaded = cerellaTestIdentifiers.length > 0;

		this.setState({
			dataSource: dataSource,
			name: name,
			numberColumnOptions: numberColumnOptions,
			structureColumnOptions: structureColumnOptions,
			testSetColumn: cerellaTestSetColumn,
			testSetDefinition: testSetFileLoaded ? 'file' : 'column',
			testSetFileError: testSetFileError,
			testSetFileLoaded: testSetFileLoaded,
			testSetFileName: cerellaTestIdentifiersFilename,
			textColumnOptions: textColumnOptions,
			treeData: treeData,
			treeIndex: 0,
		}, () => {
			const { type } = dataSource;

			if (type === 'CDD' || type === 'DATA_FILE') this.previewData();
			this.validate();
		});

	};

	csvToArray = (str) => {
		const result = readString(str);
		const { data } = result;

		// return the array
		return data;
	};

	friendlyUnitsString = (unfriendlyUnitString) => {
		const {
			units,
		} = this.state;
		// console.log("friendlyUnitsString unfriendlyUnitString", unfriendlyUnitString);
		let foundUnit = 'ERROR: friendlyUnitsString not found'; // Should never happen!
		let result = foundUnit;
		Object.values(units).forEach(measure => {
			// console.log("    measure", measure);
			// measure.value.forEach((v) => console.log("v", v));
			foundUnit = measure.value.find((v) => {
				return (v.value === unfriendlyUnitString);
			});
			// console.log("foundUnit", foundUnit);
			if (foundUnit) {
				// console.log("RETURNING", foundUnit.name);
				result = foundUnit.name;
			}
		});
		return result;
	};

	getMeasurementFromUnit = (unit) => {
		unit = unit.toUpperCase();
		const {
			units,
		} = this.state;
		let result = 'other';
		for (const [key, value] of Object.entries(units)) {
			const list = value.value;
			for (let i = 0; i < list.length && result === 'other'; i++) {
				if (list[i].value === unit) {
					result = key;
				}
			}
		}
		return result;
	};

	getTransform = (columnName) => {
		const { dataSource } = this.state;
		const { source } = dataSource;
		const { additionalMetadata } = source;
		if (additionalMetadata) {
			const { cerellaEndpoints } = additionalMetadata;
			if (cerellaEndpoints) {
				const foundItem = cerellaEndpoints.find((ce) => ce.endpointName === columnName);
				if (foundItem) {
					if (foundItem.transformation === null) {
						return 'none';
					} else if (foundItem.transformation) {
						if (foundItem.transformation.functionType) {
							return foundItem.transformation.functionType.toLowerCase();
						}
					}
				}
			}
		}

		return 'default';
	};

	handleBlur = (field, value) => {
		// console.log(`Select blur ${field}  ${value}`);
		switch (field) {
			case 'assayId':
				let strPersistedAssayIDs = sessionStorage.getItem('persistedAssayIds');
				if (strPersistedAssayIDs) {
					const persistedAssayIds = JSON.parse(strPersistedAssayIDs);
					const foundIndex = persistedAssayIds.findIndex((id) => id === value);
					if (foundIndex === -1 && value.length > 0) {
						persistedAssayIds.push(value);
						strPersistedAssayIDs = JSON.stringify(persistedAssayIds);
						sessionStorage.setItem('persistedAssayIds', strPersistedAssayIDs);
					}
				} else if (value.length > 0) {
					const persistedAssayIds = [];
					persistedAssayIds.push(value);
					strPersistedAssayIDs = JSON.stringify(persistedAssayIds);
					sessionStorage.setItem('persistedAssayIds', strPersistedAssayIDs);
				}
				break;
			default:
				break;
		}
	};

	handleCDDTokenRegister = () => {
		// console.log("handleCDDTokenRegister"); // field: ",field," value: ",value);

		const {
			cddToken = '',
		} = this.state;

		if (cddToken === '') return;

		const {
			handleShowSpinner,
		} = this.props;

		const {
			aaaKey,
			batches,
			cddVaultEndpoint,
			logError,
		} = this.props;

		if (cddVaultEndpoint) {
			handleShowSpinner(true, 'Registering CDD token...');

			this.setState({
				cddTokenReady: true,
			});

			// Get all CDD Vaults
			const cddVaultHeaders = new Headers();
			cddVaultHeaders.append("x-cdd-token", cddToken);
			cddVaultHeaders.append("X-api-key", aaaKey);

			const cddVaultRequestOptions = {
				headers: cddVaultHeaders,
				method: 'GET',
				redirect: 'follow',
			};

			const vaultIDOptions = [{ name: '<none>', value: '' }];
			fetch(`${cddVaultEndpoint}/vaults`, cddVaultRequestOptions)
				.then(handleResponse)
				.then(res => {

					res.forEach((cddVault) => {
						// Check to see if ID is already imported or being imported, if so, it's not an option.
						let foundIndex = false;
						if (batches) foundIndex = batches.find((batch) => {
							// console.log(batch, parseInt(batch.vaultId), cddVault.id, batch.statusCode);
							return (parseInt(batch.vaultId) === cddVault.id && batch.statusCode !== 'offline');
						});
						if (!foundIndex) {
							this.addWithoutDuplication(vaultIDOptions, {
								name: cddVault.name,
								value: cddVault.id,
							});
						}
					});

					this.setState({
						cddTokenError: false,
						cddTokenSet: true,
						cddVaultIDsLoaded: true,
						vaultIDOptions: vaultIDOptions,
					}, () => {
						this.validate();
						handleShowSpinner(false);
					});

				})
				.catch(error => {
					logError("Listing CDD Vaults", error);
					return Promise.reject(error);
				});
		}
	};

	handleCerellaEnabled = (enabled) => {
		// console.log("handleCerellaEnabled");
		const { dataSource } = this.state;
		const { source } = dataSource;
		let { additionalMetadata } = source;
		let cerellaEnabled = false;
		if (additionalMetadata === null || additionalMetadata === undefined) {
			additionalMetadata = { cerellaEnabled: cerellaEnabled };
		} else {
			({
				cerellaEnabled = false,
			} = additionalMetadata);
		}
		if (cerellaEnabled !== enabled) {
			additionalMetadata.cerellaEnabled = enabled;
			source.additionalMetadata = additionalMetadata;
			dataSource.source = source;
			this.setState({
				dataSource: dataSource,
			});
		}
	};

	handleChange = (field, value) => {
		// console.log("handleChange field: ", field, " value: ", value);
		switch (field) {
			case 'cartridge': {
				const {
					dataSource,
				} = this.state;
				dataSource.source.cartridge = value;
				dataSource.source.cartridgeColumn = '';
				this.setState({ dataSource: dataSource }, this.validate);
				break;
			}
			case 'cartridgeColumn': {
				const {
					dataSource,
				} = this.state;
				dataSource.source.cartridgeColumn = value;
				this.setState({ dataSource: dataSource }, this.validate);
				break;
			}
			case 'chemistryColumn': {
				const {
					dataSource,
				} = this.state;
				dataSource.source.chemistryColumn = value;
				this.setState({ dataSource: dataSource }, this.validate);
				break;
			}
			case 'cddToken': {
				this.setState({
					cddTokenError: (value === ''),
					[field]: value,
				}, this.validate);
				break;
			}
			case 'fileType': {
				this.setState({ [field]: value }, this.validate);
				break;
			}
			case 'idColumn': {
				const {
					dataSource,
				} = this.state;
				dataSource.source.idColumn = value;
				this.setState({ dataSource: dataSource }, this.validate);
				break;
			}
			case 'name': {
				const {
					dataSource,
				} = this.state;
				dataSource[field] = value;

				this.setState({
					dataSource: dataSource,
					name: value,
				}, this.validate);
				break;
			}
			case 'testSetColumn': {
				const {
					dataSource,
				} = this.state;
				const { source = {} } = dataSource;
				const { additionalMetadata } = source;
				if (!additionalMetadata) dataSource.source.additionalMetadata = {};
				dataSource.source.additionalMetadata.cerellaTestSetColumn = value;
				if (value !== 'none') {
					// Clear any test set file data file
					delete dataSource.source.additionalMetadata.cerellaTestIdentifiers;
					delete dataSource.source.additionalMetadata.cerellaTestIdentifiersFilename;
					this.setState({
						dataSource: dataSource,
						testSetFileLoaded: false,
						testSetFileName: undefined,
					}, this.validate);
				} else {
					delete dataSource.source.additionalMetadata.cerellaTestSetColumn;
					this.setState({ dataSource: dataSource }, this.validate);
				}
				break;
			}
			case 'testSetDefinition': {
				this.setState({
					testSetDefinition: value,
				});
				break;
			}
			case 'vaultID': {
				if (value && value !== '') {
					const {
						handleShowSpinner
					} = this.props;
					handleShowSpinner(true, `Retrieving dataset list`);
					const {
						aaaKey,
						cddVaultEndpoint,
						logError,
					} = this.props;
					if (cddVaultEndpoint) {

						const {
							cddToken,
						} = this.state;

						const publicDatasetsHeaders = new Headers();
						publicDatasetsHeaders.append("x-cdd-token", cddToken);
						publicDatasetsHeaders.append("X-api-key", aaaKey);

						const publicDatasetsRequestOptions = {
							headers: publicDatasetsHeaders,
							method: 'GET',
							redirect: 'follow',
						};

						const publicDatasets = [];
						/* const publicDatasetsPromise = */ fetch(`${cddVaultEndpoint}/public_datasets/${value}`, publicDatasetsRequestOptions)
							.then(handleResponse)
							.then(res => {

								res.forEach((publicDataset) => {
									this.addWithoutDuplication(publicDatasets, {
										name: publicDataset.name,
										value: publicDataset.id,
									});
								});

								this.setState({
									publicDatasets: publicDatasets,
									vaultID: value,
								}, () => {
									this.validate();
									handleShowSpinner(false);
								});

							})
							.catch(error => {
								logError("Listing public datasets", error);
								return Promise.reject(error);
							});
					} else {
						this.setState({
							publicDatasets: [],
							vaultID: '',
						}, this.validate);
					}
				}
				break;
			}
			default:
				const {
					dataSource,
				} = this.state;
				dataSource[field] = value;

				this.setState({ dataSource: dataSource }, this.validate);
		}
	};

	handleChangeColumn = (field, value) => {
		// console.log("handleChangeColumn(", field, value, ")");
		let {
			chemistryColumnError,
			idColumnError,
			dataSource,
		} = this.state;

		const {
			availablePropertiesSelected = [],
			dates,
			numberColumnOptions,
			structureColumnOptions,
			textColumnOptions,
			units,
		} = this.state;

		const { source } = dataSource;
		const {
			availableProperties,
		} = source;
		let {
			additionalMetadata = {},
		} = source;
		if (additionalMetadata === null) additionalMetadata = {};

		switch (field) {
			case 'columnName':
				availablePropertiesSelected.forEach((apIndex) => {
					const {
						columnType,
					} = availableProperties[apIndex];
					const oldValue = availableProperties[apIndex][field];

					availableProperties[apIndex][field] = value;

					if (value !== oldValue) {
						switch (columnType) {
							case 'NUMBER': {
								// Replace in number options
								const foundIndex = numberColumnOptions.findIndex((item) => { return (item.name === oldValue); });
								if (foundIndex !== -1) numberColumnOptions[foundIndex].name = value;
								break;
							}
							case 'STRUCTURE': {
								// Replace in structure options
								const foundIndex = structureColumnOptions.findIndex((item) => { return (item.name === oldValue); });
								if (foundIndex !== -1) structureColumnOptions[foundIndex].name = value;
								break;
							}
							case 'TEXT': {
								// Replace in text options with new friendly name
								const foundIndex = textColumnOptions.findIndex((item) => { return (item.name === oldValue); });
								if (foundIndex !== -1) textColumnOptions[foundIndex].name = value;
								break;
							}
							default:
								break;
						}
					}
				});
				break;
			case 'columnType':
				availablePropertiesSelected.forEach((apIndex) => {

					const {
						columnName,
						fieldName,
					} = availableProperties[apIndex];
					const oldValue = availableProperties[apIndex][field];

					availableProperties[apIndex][field] = value;

					if (value !== oldValue) {
						switch (oldValue) {
							case 'NUMBER': {
								// Remove from number options
								const foundIndex = numberColumnOptions.findIndex((item) => { return (item.name === columnName); });
								if (foundIndex !== -1) numberColumnOptions.splice(foundIndex, 1);
								break;
							}
							case 'STRUCTURE': {
								// Remove from structure options
								const foundIndex = structureColumnOptions.findIndex((item) => { return (item.name === columnName); });
								if (foundIndex !== -1) structureColumnOptions.splice(foundIndex, 1);
								if (structureColumnOptions.length < 2) chemistryColumnError = true;
								break;
							}
							case 'TEXT': {
								// Remove from text options
								const foundIndex = textColumnOptions.findIndex((item) => { return (item.name === columnName); });
								if (foundIndex !== -1) textColumnOptions.splice(foundIndex, 1);
								if (textColumnOptions.length < 2) idColumnError = true;
								break;
							}
							default:
								break;
						}
					}
					switch (value) {
						case 'NUMBER': {
							// Add to number options
							this.addWithoutDuplication(numberColumnOptions, { name: columnName, value: fieldName });
							break;
						}
						case 'STRUCTURE': {
							availableProperties[apIndex].details.format = 'smiles'; // Initialise as 'smiles'
							// Add to structure options
							this.addWithoutDuplication(structureColumnOptions, { name: columnName, value: fieldName });
							break;
						}
						case 'TEXT': {
							// Add to textOptions
							this.addWithoutDuplication(textColumnOptions, { name: columnName, value: fieldName });
							break;
						}
						default:
							break;
					}

				});
				break;
			case 'measurements':
				availablePropertiesSelected.forEach((apIndex) => {
					availableProperties[apIndex].measurements = value;
					availableProperties[apIndex].details.units = units[value].value[0].value; // Initialised
				});
				break;
			case 'dateDisplayOrder':
				availablePropertiesSelected.forEach((apIndex) => {
					availableProperties[apIndex].details.dateDisplayOrder = value;
					availableProperties[apIndex].details.dateDisplayFormat = dates[value].value[0].value;
				});
				break;
			case 'dateInputOrder':
				availablePropertiesSelected.forEach((apIndex) => {
					availableProperties[apIndex].details.dateInputOrder = value;
					availableProperties[apIndex].details.dateInputFormat = dates[value].value[0].value;
				});
				break;
			case 'dateDisplayFormat':
				availablePropertiesSelected.forEach((apIndex) => {
					availableProperties[apIndex].details.dateDisplayFormat = value;
				});
				break;
			case 'dateInputFormat':
				availablePropertiesSelected.forEach((apIndex) => {
					availableProperties[apIndex].details.dateInputFormat = value;
				});
				break;
			case 'dateDisplayFormatOther':
				availablePropertiesSelected.forEach((apIndex) => {
					availableProperties[apIndex].details.dateDisplayFormatOther = value;
				});
				break;
			case 'dateInputFormatOther':
				availablePropertiesSelected.forEach((apIndex) => {
					availableProperties[apIndex].details.dateInputFormatOther = value;
				});
				break;
			case 'hidden': {
				// console.log("hidden", value);
				const {
					cerellaInputOnlyEndpoints = [],
					cerellaPriorityEndpoints = [],
				} = additionalMetadata;


				availablePropertiesSelected.forEach((apIndex) => {
					const ap = availableProperties[apIndex];
					const { columnName } = ap;

					if (ap.columnType === 'NUMBER' && value) { // only remove if hidden is being set true
						// Remove from lists
						let foundIndex = cerellaPriorityEndpoints.findIndex((item) => item === columnName);
						if (foundIndex !== -1) cerellaPriorityEndpoints.splice(foundIndex, 1);
						foundIndex = cerellaInputOnlyEndpoints.findIndex((item) => item === columnName);
						if (foundIndex !== -1) cerellaInputOnlyEndpoints.splice(foundIndex, 1);
					}
					ap.hidden = value;
				});
				dataSource.source.additionalMetadata = additionalMetadata;

				break;
			}
			case 'inputOnly': {
				const {
					cerellaInputOnlyEndpoints = [],
					cerellaPriorityEndpoints = [],
				} = additionalMetadata;

				availablePropertiesSelected.forEach((apIndex) => {
					const ap = availableProperties[apIndex];
					const { columnName } = ap;

					if (ap.columnType === 'NUMBER') { // extra check
						// Remove from lists (add back again if required subsequently)
						if (value) { // Only remove Priority if input only being set true
							const foundIndex = cerellaPriorityEndpoints.findIndex((item) => item === columnName);
							if (foundIndex !== -1) cerellaPriorityEndpoints.splice(foundIndex, 1);
						}
						const foundIndex = cerellaInputOnlyEndpoints.findIndex((item) => item === columnName);
						if (foundIndex !== -1) cerellaInputOnlyEndpoints.splice(foundIndex, 1);

						// Add back?
						if (value) cerellaInputOnlyEndpoints.push(columnName);
					}
					additionalMetadata.cerellaInputOnlyEndpoints = cerellaInputOnlyEndpoints;
					additionalMetadata.cerellaPriorityEndpoints = cerellaPriorityEndpoints;
				});
				dataSource.source.additionalMetadata = additionalMetadata;

				break;
			}
			case 'priority': {
				const {
					cerellaInputOnlyEndpoints = [],
					cerellaPriorityEndpoints = [],
				} = additionalMetadata;

				availablePropertiesSelected.forEach((apIndex) => {
					const ap = availableProperties[apIndex];
					const { columnName } = ap;

					if (ap.columnType === 'NUMBER') { // extra check
						// Remove from lists (add back again if required subsequently)
						let foundIndex = cerellaPriorityEndpoints.findIndex((item) => item === columnName);
						if (foundIndex !== -1) cerellaPriorityEndpoints.splice(foundIndex, 1);
						foundIndex = cerellaInputOnlyEndpoints.findIndex((item) => item === columnName);
						if (foundIndex !== -1) cerellaInputOnlyEndpoints.splice(foundIndex, 1);

						// Add back?
						if (value) cerellaPriorityEndpoints.push(columnName);
					}
					additionalMetadata.cerellaInputOnlyEndpoints = cerellaInputOnlyEndpoints;
					additionalMetadata.cerellaPriorityEndpoints = cerellaPriorityEndpoints;
				});
				dataSource.source.additionalMetadata = additionalMetadata;

				break;
			}
			case 'mergeRule': {
				const {cerellaEndpoints = []} = additionalMetadata;

				availablePropertiesSelected.forEach((apIndex) => {
					const {
						columnName,
					} = availableProperties[apIndex];

					const foundEndpoint = cerellaEndpoints.find(element => element.endpointName === columnName);
					const foundEndpointIndex = cerellaEndpoints.findIndex(element => element.endpointName === columnName);

					if (foundEndpoint) {
						foundEndpoint.mergeRule = value;
						cerellaEndpoints.splice(foundEndpointIndex, 1, foundEndpoint);
					} else {
						cerellaEndpoints.push({
							endpointName: columnName,
							mergeRule: value
						});
					}
					additionalMetadata.cerellaEndpoints = cerellaEndpoints;
				});
				dataSource.source.additionalMetadata = additionalMetadata;
				break;
			}
			case 'qualifierRule': {
				const {cerellaEndpoints = []} = additionalMetadata;

				availablePropertiesSelected.forEach((apIndex) => {
					const {
						columnName,
					} = availableProperties[apIndex];

					const foundEndpoint = cerellaEndpoints.find(element => element.endpointName === columnName);
					const foundEndpointIndex = cerellaEndpoints.findIndex(element => element.endpointName === columnName);

					if (foundEndpoint) {
						foundEndpoint.qualifierRule = value ? 'IGNORE_QUALIFIERS' : null;
						cerellaEndpoints.splice(foundEndpointIndex, 1, foundEndpoint);
					} else {
						cerellaEndpoints.push({
							endpointName: columnName,
							qualifierRule: value ? 'IGNORE_QUALIFIERS' : null
						});
					}
					additionalMetadata.cerellaEndpoints = cerellaEndpoints;
				});
				dataSource.source.additionalMetadata = additionalMetadata;
				break;
			}
			case 'assayId':
			case 'display':
			case 'error':
			case 'errorColumn':
			case 'factor':
			case 'format':
			case 'function':
			case 'precision':
			case 'qualifierColumn':
			case 'units':
			case 'useFactor':
				availablePropertiesSelected.forEach((apIndex) => {
					availableProperties[apIndex].details[field] = value;
				});
				// console.log("availableProperties", availableProperties);
				break;
			case 'maximum':
			case 'minimum':
				availablePropertiesSelected.forEach((apIndex) => {
					if (!availableProperties[apIndex].details.valueRange) availableProperties[apIndex].details.valueRange = {};
					availableProperties[apIndex].details.valueRange[field] = value;
				});
				break;
			case 'transformation':
				availablePropertiesSelected.forEach((apIndex) => {
					const {
						columnName,
					} = availableProperties[apIndex];
					let { source } = dataSource;
					if (!source) dataSource.source = {};
					let { additionalMetadata } = dataSource.source;
					if (!additionalMetadata) dataSource.source.additionalMetadata = {};
					({ additionalMetadata } = dataSource.source);
					const { cerellaEndpoints = [] } = additionalMetadata;

					const foundEndpoint = cerellaEndpoints.find(element => element.endpointName === columnName);
					const foundEndpointIndex = cerellaEndpoints.findIndex(element => element.endpointName === columnName);

					if (foundEndpoint) {
						if (!foundEndpoint.transformation) {
							foundEndpoint.transformation = {
								// constant: 0, // App.js adds these
								// factor: 1,   // App.js adds these
								functionType: value,
							};
						}
						if (foundEndpoint.transformation.functionType) {
							foundEndpoint.transformation.functionType = value;
						}
						cerellaEndpoints.splice(foundEndpointIndex, 1, foundEndpoint);
					} else {
						cerellaEndpoints.push({
							endpointName: columnName,
							transformation: {
								// constant: 0, // App.js adds these
								// factor: 1,   // App.js adds these
								functionType: value,
							}
						});
					}
					additionalMetadata = { ...additionalMetadata, cerellaEndpoints };
					source = { ...source, additionalMetadata };
					dataSource = { ...dataSource, source };
				});
				break;
			case 'textInterpretation':
				// treat as category maps to category
				// treat as images maps to image
				availablePropertiesSelected.forEach((apIndex) => {
					switch (value) {
						case 'category':
							availableProperties[apIndex].details.image = false;
							availableProperties[apIndex].details.category = true;
							break;
						case 'image':
							availableProperties[apIndex].details.image = true;
							availableProperties[apIndex].details.category = false;
							break;
						default:
							availableProperties[apIndex].details.image = false;
							availableProperties[apIndex].details.category = false;
							break;
					}
				});
				break;
			default:
				availablePropertiesSelected.forEach((apIndex) => {
					availableProperties[apIndex][field] = value;
				});
				break;
		}

		dataSource.source.availableProperties = availableProperties;

		this.setState({
			chemistryColumnError: chemistryColumnError,
			dataSource: dataSource,
			idColumnError: idColumnError,
			numberColumnOptions: numberColumnOptions,
			structureColumnOptions: structureColumnOptions,
			textColumnOptions: textColumnOptions,
		}, this.validate);
	};

	handleChangePublicDatasets = (id, options) => {

		const publicDatasetsSelected = [];

		for (let i = 0, l = options.length; i < l; i++) {
			if (options[i].selected) publicDatasetsSelected.push(options[i].value);
		};

		this.setState({
			publicDatasetsSelected: publicDatasetsSelected,
		}, this.validate);

	};

	handleConnect = (replace) => {
		// console.log("handleConnect");
		const {
			aaaKey,
			cddVaultEndpoint,
			handleAddBatch,
			logError,
		} = this.props;

		const {
			cddToken,
			fileType,
			name,
			publicDatasetsSelected,
			vaultID,
		} = this.state;

		if (fileType === 'cdd' && cddVaultEndpoint) {
			// console.log(`Start the CDD load, vault ID: ${vaultID} public datasets ${publicDatasetsSelected}`);

			const headers = new Headers();
			headers.append("x-cdd-token", cddToken);
			headers.append("X-api-key", aaaKey);
			headers.append("Content-Type", "application/json");

			let publicDatasets = [];
			if (publicDatasetsSelected) publicDatasets = publicDatasetsSelected.map((dataset) => parseInt(dataset));

			let raw = {
				"name": name,
				"public_datasets": publicDatasets,
			};
			raw = JSON.stringify(raw);

			const requestOptions = {
				body: raw,
				headers: headers,
				method: 'POST',
				redirect: 'follow',
			};

			fetch(`${cddVaultEndpoint}/vaults/${vaultID}`, requestOptions)
				.then(handleResponse)
				.then(res => {

					const batch = {
						batch_id: res.batch_id, // eslint-disable-line
						name: name,
						type: 'cdd',
					};
					handleAddBatch(batch); // This will convert batch_id to id.
					this.setState({
						connectionMade: true,
					});
					return Promise.resolve();

				})
				.catch(error => {
					logError("Submitting task", error);
					return Promise.reject(error);
				});
		} else {
			// handleShowSpinner(true, 'Retrieving sample data');
			this.previewData(replace);
			this.setState({
				connectionMade: true,
			});
		}
	};

	handleDataFileLoad = (e, replace) => {
		// console.log("handleDataFileLoad replace", replace);

		const {
			// name,
			dataSource,
		} = this.state;

		const {
			source
		} = dataSource;

		let idColumn;
		if (source) ({ idColumn } = source);

		const {
			aaaKey,
			// dialog,
			handleShowSpinner,
			logError,
			queryInterfaceEndpoint,
		} = this.props;

		handleShowSpinner(true);

		const headers = new Headers();
		headers.append("X-api-key", aaaKey);

		const formData = new FormData();
		const fileName = e.target.files[0].name;
		const encodedFileName = b64EncodeUnicode(fileName);
		formData.append("fileName", encodedFileName);
		formData.append("dataFile", e.target.files[0]);
		if (idColumn && idColumn !== 'none') formData.append("idColumn", idColumn);

		const requestOptions = {
			body: formData,
			headers: headers,
			method: 'POST',
			redirect: 'follow',
		};

		const _this = this;
		_this.setState({
			progress: {
				count: 100,
				done: false,
				step: 0,
			},
		});

		// Post the data
		if (queryInterfaceEndpoint) {
			return fetch(`${queryInterfaceEndpoint}/dataFiles`, requestOptions)
				.then(response => {
					if (response.status === 413 /* && !response.ok implied by the 400 series code */) return Promise.reject({
						statusCode: response.status,
						statusText: 'File is too large',
					});
					if (response.status === 400) {
						return response.json()
							.then((json) => {
								if (!response.ok) {
									const error = {
										...json,
										...{
											message: json.message,
											status: response.status,
											statusText: response.statusText,
										}
									};
									return Promise.reject(error);
								}
								return json;
							});
					}
					if (/* response.status !== 202 catch all, not just 202 || */ !response.ok) return Promise.reject({
						statusCode: response.status,
						statusText: response.statusText,
					});
					return response;
				})
				.then((res) => {
					const reader = res.body.getReader();
					let jValue;

					return new ReadableStream({
						start(controller) {
							function push() {
								reader.read().then(({ done, value }) => {
									if (done) {
										// console.log("DONE");
										const {
											fileId,
											// fileName, // Ignore, it's just repeating back what we already know but b64 encoded
										} = jValue;

										// console.log("fileId", fileId, "fileName", fileName);

										// We need the encoded file name in our state
										if (!source) {
											dataSource.source = {};
											source.dataFileMetadata = {};
										}
										if (!source.dataFileMetadata) source.dataFileMetadata = {};
										dataSource.source.dataFileMetadata.fileName = encodedFileName;

										_this.setState({
											dataFileLoaded: true,
											dataSource: dataSource,
											fileId: fileId,
											fileName: fileName,
											progress: {
												count: 100,
												done: done,
												step: 100,
											},
										}, () => {
											// const _replace = replace;
											_this.handlePreviewData(replace);
										});

										controller.close();

										return;
									} // if (done)

									controller.enqueue(value);
									const strValue = new TextDecoder().decode(value);

									// split up any conflated lines of jSon?
									const lines = strValue.split(/\r?\n/);

									lines.forEach((line) => {

										if (line.length > 0) {

											try {
												jValue = JSON.parse(`${line} `);
											} catch (err) {
												logError(`Attempting to parse data file "${line}"`, err);
												return;
											}
											const {
												count,
												step,
											} = jValue;
											if (count && step) {
												_this.setState({
													progress: {
														count: count,
														done: done,
														step: step,
													}
												});
											}
										}
									});
									push();
								});
							}
							push();
						}
					});
				})
				.catch(error => {
					const action = `Posting datafile`;
					this.setState({
						progress: {
							count: 0,
							done: true,
							step: 0,
						}
					});
					logError(action, error);
				});
		}
	};

	handleJSONload = (e) => {
		// console.log("handleJSONload");
		this.setState({
			loading: true,
		});

		let {
			dataSource,
		} = this.state;

		const {
			dialog,
			handleShowSpinner,
			dataSources,
			secondaryNav,
		} = this.props;

		handleShowSpinner(true);
		const reader = new FileReader();
		const _this = this;

		const fileName = e.target.files[0].name;
		reader.onload = function (e) {

			const JSONresult = JSON.parse(reader.result);

			dataSource = { ...dataSource, ...JSONresult };

			// Data in JSON format { source: { <data source definition> }, type: <data source type> } as exported from Web UI
			// This does not contain the connection string since this is a secret that cannot be exported
			if (JSONresult.source) {
				dataSource.database = dataSource.source.database;
				dataSource.template = dataSource.source.template;
				dataSource.name = dataSource.source.name;
				delete dataSource.source.database;
				delete dataSource.source.template;
				delete dataSource.source.name;

				if (dataSource.type === 'DATA_FILE') {
					// Dummy connection string to ensure correct data type when creating data source
					dataSource.connection = `DATA_FILE@${uuidv4()}`;
				}
				// Data in JSON format { <data source definition> } as exported from StarDrop Query Interface Plugin
				// This does not include an explicit data source type, but may contain a connection string
			} else {
				dataSource.source = {
					additionalMetadata: dataSource.additionalMetadata,
					availableProperties: dataSource.availableProperties,
					cartridge: dataSource.cartridge,
					cartridgeColumn: dataSource.cartridgeColumn,
					chemistryColumn: dataSource.chemistryColumn,
					idColumn: dataSource.idColumn,
					sourceId: dataSource.sourceId,
					template: dataSource.template,
				};

				dataSource.type = 'FILE';
				if (dataSource.connection !== undefined &&
					dataSource.connection.slice(0, 10) === ('DATA_FILE@')) dataSource.type = 'DATA_FILE';
				if (
					dataSource.database === 'Oracle' ||
					dataSource.database === 'MySQL' ||
					dataSource.database === 'Postgres' ||
					dataSource.database === 'SQLServer' ||
					dataSource.database === 'MSAccess' ||
					dataSource.database === 'SQLITE') dataSource.type = 'DATABASE';

				delete dataSource.cartridge;
				delete dataSource.cartridgeColumn;
				delete dataSource.additionalMetadata;
				delete dataSource.availableProperties;
				delete dataSource.chemistryColumn;
				delete dataSource.idColumn;
			}

			const { name: nameInFile } = dataSource;

			if (nameInFile && nameInFile !== '') {
				let nameError = '';
				dataSources.forEach((dataSource) => {
					if (dataSource.name === nameInFile.trim() && secondaryNav === 'add') {
						nameError = ' Beware, that name is NOT unique';
					}
				});
				dialog(
					`File contains name definition of "${nameInFile}". Would you prefer to use that name?${nameError}`,
					'data-source',
					'yesno',
					() => {
						dataSource.name = nameInFile;
						_this.setState({
							name: nameInFile,
						}, () => {
							_this.setState({ valid: _this.validateName(nameInFile) });
						});
					},
					null, // Do nothing
				);
			}

			const structureColumnOptions = [{ name: '<None>', value: 'none' }];
			const textColumnOptions = [{ name: '<None>', value: 'none' }];
			let { idColumn = 'none' } = dataSource;
			const numberColumnOptions = [{ name: '<None>', value: 'none' }];

			if (dataSource.source.availableProperties) dataSource.source.availableProperties.forEach((ap) => {
				const {
					columnName,
					fieldName,
				} = ap;
				const value = fieldName;
				const name = fieldName;
				switch (ap.columnType) {
					case 'DATE':

						ap.details = {
							dateDisplayFormat: '%Y-%m-%d',
							dateDisplayOrder: 'ymd',
							dateInputFormat: '%Y-%m-%d',
							dateInputOrder: 'ymd',
						};
						break;
					case 'STRUCTURE':
						_this.addWithoutDuplication(structureColumnOptions, { name: columnName, value: fieldName });
						break;
					case 'TEXT':
						if (idColumn === 'none' && (value.toLowerCase() === 'id' || fieldName.toLowerCase() === 'id')) {
							idColumn = name;
						}
						_this.addWithoutDuplication(textColumnOptions, { name: columnName, value: fieldName });
						break;
					case 'NUMBER':
						_this.addWithoutDuplication(numberColumnOptions, { name: columnName, value: fieldName });
						if (ap.details.precision && ap.details.precision === -1) delete ap.details.precision;
						if (ap.details) ap.details.factor = _this.convertToErrorType(ap.details.factor);
						break;
					default:
						break;
				}
			});

			dataSource.fileName = fileName;
			// dataSource.source.idColumn = idColumn; // Done above

			_this.setState({
				JSONloaded: true,
				dataFileLoaded: false,
				dataSource: dataSource,
				fileName: fileName,
				loading: false,
				numberColumnOptions: numberColumnOptions,
				structureColumnOptions: structureColumnOptions,
				textColumnOptions: textColumnOptions,
			});
			_this.validate();
			handleShowSpinner(false);

		};
		reader.readAsText(e.target.files[0]);
	};

	handlePreviewData = (replace = false) => {
		// console.log("handlePreviewData replace = ", replace);

		const {
			fileId,
			dataSource,
		} = this.state;

		dataSource.connection = `DATA_FILE@${fileId}`;
		dataSource.database = 'None';
		dataSource.template = "";

		this.setState({
			dataSource: dataSource,
		});

		this.previewData(replace);
	};

	handleRefreshCDD = () => {
		// console.log("handleRefreshCDD");

		const {
			aaaKey,
			cddVaultEndpoint,
			getCDDBatches,
			handleDataSourceTabClick,
			logError,
		} = this.props;

		const {
			cddToken,
			dataSource,
		} = this.state;

		const {
			id,
			source,
			type,
		} = dataSource;

		const {
			additionalMetadata
		} = source;

		const {
			cdd_vault: vaultID,
		} = additionalMetadata;

		if (type === 'CDD' && cddVaultEndpoint) { // Should be as button does not exist for other dataSource types
			// console.log(`Start the CDD refresh, vault ID: ${vaultID}`);

			const headers = new Headers();
			headers.append("x-cdd-token", cddToken);
			headers.append("X-api-key", aaaKey);
			headers.append("Content-Type", "application/json");

			let raw = {
				"datasource_id": id,
			};
			raw = JSON.stringify(raw);

			const requestOptions = {
				body: raw,
				headers: headers,
				method: 'POST',
				redirect: 'follow',
			};

			fetch(`${cddVaultEndpoint}/vaults/${vaultID}`, requestOptions)
				.then(handleResponse)
				.then(res => {
					getCDDBatches(aaaKey, cddVaultEndpoint);
					handleDataSourceTabClick();
					return Promise.resolve();
				})
				.catch(error => {
					logError("Submitting task", error);
					return Promise.reject(error);
				});
		}
	};

	handleShowDisplayGroup = (show) => {
		this.setState({
			showDisplayGroup: show,
		});
	};

	handleShowDisplayGroupReview = (show) => {
		// window.scrollTo({ behavior: 'smooth', top: 0,  });
		this.setState({
			showDisplayGroupReview: show,
		});
	};

	handleShowEndpointReview = (show) => {
		// window.scrollTo({ behavior: 'smooth', top: 0,  });
		this.setState({
			showEndPointReview: show,
		});
	};

	handleShowEndPointTypeReview = (show) => {
		// window.scrollTo({ behavior: 'smooth', top: 0,  });
		this.setState({
			showEndPointTypeReview: show,
		});
	};

	handleShowUnitsReview = (show) => {
		// window.scrollTo({ behavior: 'smooth', top: 0,  });
		this.setState({
			showUnitsReview: show,
		});
	};

	handleTestSetFileLoad = (data, fileInfo) => {
		// console.log("handleTestSetFileLoad");
		const { handleShowSpinner } = this.props;
		handleShowSpinner(true);

		let {
			dataSource,
		} = this.state;

		// data may have an empty last line ?
		let nRows = data.length;

		if (data[nRows - 1].length === 1 && data[nRows - 1][0] === '') {
			data.pop();
			nRows--;
		}

		let oneDimensionalArray = []; // for preventing rows being arrays
		for (const row of data) {
			oneDimensionalArray = oneDimensionalArray.concat(row);
		}

		// Remove blank cells (shouldn't be any really!)
		oneDimensionalArray = oneDimensionalArray.filter(cell => cell.length > 0);
		nRows = oneDimensionalArray.length;

		let testSetFileLoaded = true;
		let testSetFileError = '';
		if (nRows <= 1 && !oneDimensionalArray[0]) {
			testSetFileLoaded = false;
			testSetFileError = "No test set data in file";
		}

		let { source } = dataSource;
		let { additionalMetadata = {} } = source;

		additionalMetadata = {
			...additionalMetadata,
			cerellaTestIdentifiers: oneDimensionalArray,
			cerellaTestIdentifiersFilename: fileInfo.name,
		};
		delete additionalMetadata.cerellaTestSetColumn;

		source = {
			...source,
			additionalMetadata,
		};

		dataSource = {
			...dataSource,
			source: source,
		};

		this.setState({
			dataSource: dataSource,
			testSetColumn: 'none',
			testSetDefinition: 'file',
			testSetFileError: testSetFileError,
			testSetFileLoaded: testSetFileLoaded,
			testSetFileName: fileInfo.name,
		}, () => {
			handleShowSpinner(false);
		});
		handleShowSpinner(false);
	};

	insertNewNode = (rowInfo) => {
		// console.log("insertNewNode");
		const { treeData } = this.state;
		const NEW_NODE = {
			children: [],
			expanded: true,
			title: '',
		};
		const newTree = addNodeUnderParent({
			expandParent: true,
			getNodeKey: ({ treeIndex }) => treeIndex,
			newNode: NEW_NODE,
			parentKey: rowInfo ? rowInfo.treeIndex : undefined,
			treeData: treeData,
		});
		this.updateTreeData(newTree.treeData);
	};

	isAlphaNumeric = (str) => { // Within the rules for datasets
		let code, i, len;

		for (i = 0, len = str.length; i < len; i++) {
			code = str.charCodeAt(i);
			if (
				!(code > 47 && code < 58) && // numeric (0-9)
				!(code > 64 && code < 91) && // upper alpha (A-Z)
				!(code > 96 && code < 123) &&// lower alpha (a-z)
				!(code === 95 || code === 45)
			) {
				return false;
			}
		}
		return true;
	};

	isDate = (text) => {
		if (text) {
			return text.trim().match(/^\d{2,4}[- /.:](\d{2,4}|\w{3,})[- /.:]\d{2,4}$/);
		}
		return false;
	};

	isStructure = (text) => {
		// Does not handle molfile. Only SMILES.
		if (text) {
			if (text.trim().match(/^([^J][0-9BCOHNSOPrIFla@+\-[\]()\\/%=#$,.~&!]{6,})$/)) {
				return true;
			}
		}
		return false;
	};

	loadDataSource = () => {
		// console.log("loadDataSource");
		const {
			dataSource: unchangedDataSource,
			logError,
			secondaryNav,
		} = this.props;

		const dataSource = copyObject(unchangedDataSource); // Make a copy to leave props version unchanged so modifications can be cancelled by navigation or by cancel button.

		const {
			name = '',
			id,
			source = {},
		} = dataSource;

		const {
			additionalMetadata = {},
		} = source;

		let {
			idColumn = 'none',
		} = source;

		const {
			availableProperties = [],
		} = source;

		const { validShortDateFormats } = this.state;

		if (secondaryNav === 'edit' && !id) {
			logError("Error dataSource has no sourceId");
		}

		const textColumnOptions = [{ name: '<None>', value: 'none' }];
		const numberColumnOptions = [{ name: '<None>', value: 'none' }];
		const structureColumnOptions = [{ name: '<None>', value: 'none' }];

		availableProperties.forEach((ap) => {
			const {
				columnName,
				fieldName,
			} = ap;
			// const value = fieldName;
			switch (ap.columnType) {
				case 'DATE':
					// Do something special with date input and display format.
					// 1st, is it one of the simple forms?
					// console.log("ap", ap);
					const { details } = ap;
					if (details) {
						const { // These are the defaults the server implies
							dateDisplayFormat = '%Y-%m-%d',
							dateInputFormat = '%Y-%m-%d',
						} = details;
						// console.log("dateDisplayFormat", dateDisplayFormat, "dateInputFormat", dateInputFormat);
						// console.log("this.a", this.a);
						if (validShortDateFormats && dateDisplayFormat !== 'other') {
							if (validShortDateFormats[dateDisplayFormat]) {
								details.dateDisplayOrder = validShortDateFormats[dateDisplayFormat];
							} else {
								details.dateDisplayFormatOther = dateDisplayFormat;
								details.dateDisplayFormat = 'other';
								details.dateDisplayOrder = 'ymd';
							}
						}
						if (validShortDateFormats && dateInputFormat !== 'other') {
							if (validShortDateFormats[dateInputFormat]) {
								details.dateInputOrder = validShortDateFormats[dateInputFormat];
							} else {
								details.dateInputFormatOther = dateInputFormat;
								details.dateInputFormat = 'other';
								details.dateInputOrder = 'ymd';
							}
						}
						// console.log("dateDisplayOrder", details.dateDisplayOrder, "dateInputOrder", details.dateInputOrder);
					}
					break;
				case 'STRUCTURE':
					this.addWithoutDuplication(structureColumnOptions, { name: columnName, value: fieldName });
					// structureColumnOptions.push({ name: columnName, value: fieldName });
					break;
				case 'TEXT':
					if (idColumn === 'none' && name === 'id') {
						idColumn = name;
					}
					this.addWithoutDuplication(textColumnOptions, { name: columnName, value: fieldName });
					// textColumnOptions.push({ name: columnName, value: fieldName });
					break;
				case 'NUMBER':
					this.addWithoutDuplication(numberColumnOptions, { name: columnName, value: fieldName });
					// numberColumnOptions.push({ name: columnName, value: fieldName });
					break;
				default:
					break;
			}
		});

		if (!dataSource.source) dataSource.source = {};
		dataSource.source.idColumn = idColumn;

		// Promote database, template and connection out of source as it is needed in the top level anyway for any PATCH
		if (dataSource.source) {
			if (dataSource.source.database) {
				dataSource.database = dataSource.source.database;
				delete dataSource.source.database;
			}
			if (dataSource.sourceconnection) {
				dataSource.connection = dataSource.source.connection;
				delete dataSource.source.connection;
			}
			if (dataSource.source.template) {
				dataSource.template = dataSource.source.template;
				delete dataSource.source.template;
			}
			delete dataSource.source.name;
		}

		return {
			additionalMetadata: additionalMetadata,
			dataSource: dataSource,
			name: name,
			numberColumnOptions: numberColumnOptions,
			structureColumnOptions: structureColumnOptions,
			textColumnOptions: textColumnOptions,
		};
	};

	moveNode = ({ treeData, node, nextParentNode, prevPath, prevTreeIndex, nextPath, nextTreeIndex }) => {
		const { oldTreeData = [] } = this.state;
		// console.log("moveNode", treeData, node, nextParentNode, prevPath, prevTreeIndex, nextPath, nextTreeIndex);
		// console.log("treeData", treeData);
		// console.log("oldTreeData", oldTreeData);
		// console.log("node", node);
		// console.log("nextParentNode", nextParentNode);
		// console.log("prevPath", prevPath);
		// console.log("prevTreeIndex", prevTreeIndex);
		// console.log(" nextPath", nextPath);
		// console.log(" nextTreeIndex", nextTreeIndex);
		this.checkForNodesInUse(
			{
				action: 'move',
				newPath: nextPath,
				newTreeData: treeData,
				oldPath: prevPath,
				oldTreeData: oldTreeData,
			},

			// affirm
			() => {
				// console.log("Do something");
				this.setState({
					oldTreeData: null,
				});
			},

			// cancel
			() => {
				// console.log("Do nothing/reset");
				this.setState({
					oldTreeData: null,
					treeData: oldTreeData,
				});
			}
		);
	};

	previewData = (replace = false) => {
		// console.log("previewData replace =", replace);
		const {
			aaaKey,
			dialog,
			handleShowSpinner,
			logError,
			queryInterfaceEndpoint,
		} = this.props;

		const {
			fileName,
			validShortDateFormats,
		} = this.state;

		let {
			dataSource,
		} = this.state;

		const {
			connection,
			database,
			id,
			name,
			source,
			template,
			type,
		} = dataSource;

		let {
			availableProperties = [],
		} = source;

		// console.log(10, "availableProperties", availableProperties);

		const headers = new Headers();

		headers.append("Accept", '*/*');
		headers.append("Content-Type", 'application/json');
		headers.append('responseType', 'text');
		headers.append("X-api-key", aaaKey);

		const newAvailableProperties = [];
		let data;
		let chemistryColumn;
		let idColumn;
		let previewLoaded = false;
		const numberColumnOptions = [{ name: '<None>', value: 'none' }];
		const textColumnOptions = [{ name: '<None>', value: 'none' }];
		const structureColumnOptions = [{ name: '<None>', value: 'none' }];
		let requestOptions = {};
		let endpoint = '';

		handleShowSpinner(true, 'Retrieving sample data');

		if (queryInterfaceEndpoint) {
			if ((type === 'CDD' || type === 'DATA_FILE') && !replace) {
				endpoint = `${queryInterfaceEndpoint}/dataSources/${id}/preview`;
				requestOptions = {
					headers: headers,
					method: 'GET',
					redirect: 'follow',
				};
			} else {
				const raw = `{
					"connection": "${connection}",
					"database": "${database}",
					"name": "${name}",
					"template": "${template}"
				}`;

				requestOptions = {
					body: raw,
					headers: headers,
					method: 'PUT',
					redirect: 'follow',
				};

				endpoint = `${queryInterfaceEndpoint}/dataSources/preview`;
			}

			const dataFilePromise = fetch(endpoint, requestOptions)
				.then(res => handleResponseCSV(res, dialog))
				.then(res => {
					data = this.csvToArray(res);

					const nColumns = data[0].length;

					for (let k = 0; k < nColumns; k++) {
						newAvailableProperties.push({
							columnName: data[0][k],
							details: {
							},
							fieldName: data[0][k],
							group: '',
							hidden: false,
						});
					}

					// console.log("newAvailableProperties", newAvailableProperties);

					for (let k = 0; k < nColumns; k++) {
						if (data[0][k].toLowerCase() === 'structure') {
							newAvailableProperties[k].columnType = 'STRUCTURE';
						} else {
							newAvailableProperties[k].columnType = 'NUMBER';
						}
					}

					// data may have an empty last line?
					const nRows = data.length;
					if (data[nRows - 1].length === 1 && data[nRows - 1][0] === '') {
						data.pop();
					}
					// console.log("new data", data);

					// Tries types in this order:
					// number -> date -> structure -> URL -> text
					for (let i = 1; i < data.length; i++) {
						for (let j = 0; j < nColumns; j++) {
							const value = data[i][j];
							if (value !== '') {
								switch (newAvailableProperties[j].columnType) {
									case 'NUMBER':
										if (isNaN(value)) {
											// No longer a number
											if (this.isDate(value)) {
												// Could be a date
												newAvailableProperties[j].columnType = 'DATE';
											} else if (this.isStructure(value)) {
												// Setting structure
												newAvailableProperties[j].columnType = 'STRUCTURE';
											} else {
												// Not a structure, must be text
												newAvailableProperties[j].columnType = 'TEXT';
											}
										}
										break;
									case 'DATE':
										if (!this.isDate(value)) {
											if (this.isStructure(value)) {
												// Setting structure
												newAvailableProperties[j].columnType = 'STRUCTURE';
											} else {
												// Not a structure, must be text
												newAvailableProperties[j].columnType = 'TEXT';
											}
										}

										break;
									case 'STRUCTURE':
										if (!this.isStructure(value)) {
											// Not a structure, must be text
											newAvailableProperties[j].columnType = 'TEXT';
										}
										break;
									default:
								}
							}
						}
					}

					const { source } = dataSource;
					({
						chemistryColumn = 'none',
						idColumn = 'none'
					} = source);
					// handleShowSpinner(false);

					previewLoaded = true;
					return Promise.resolve(true);

				})
				.catch(error => {
					const action = `Retrieving preview data`;
					// handleShowSpinner(false);
					logError(action, error);
					// return Promise.reject(error);
					return Promise.resolve(false);
				});


			let metadataPromise;
			dataFilePromise.then((success) => {
				if (success) {
					if (replace) {
						// Replacing data file
						// recover metadata from QI server
						const headers = new Headers();
						headers.append("X-api-key", aaaKey);
						headers.append("Content-Type", "application/json");

						const requestOptions = {
							headers: headers,
							method: 'GET',
							redirect: 'follow',
						};

						metadataPromise = fetch(`${queryInterfaceEndpoint}/dataSources/${dataSource.id}/config_metadata`, requestOptions)
							.then(handleResponse)
							.then(res => {
								// console.log("res", res);
								// Server still has old data file name. if we use res.source to overwrite
								// dataSource.source then we lose the new data file name, so preserve it.
								const newFileName = dataSource.source.dataFileMetadata ? dataSource.source.dataFileMetadata.fileName : undefined;

								// dataSource.source = {
								// 	...res.source,
								// 	dataFileMetaData: {
								// 		...dataSource.source.dataFileMetadata,
								// 		fileName: newFileName,
								// 	}
								// };
								// Mmmm, the above is more elegant but the backend is more fussy, we have to be surgical unfortunately
								dataSource.source = res.source;
								// Then put it back (there's probably a more elegant way to achieve this) P.S> there is, see above.
								if (newFileName) {
									if (!dataSource.source.dataFileMetadata) dataSource.source.dataFileMetadata = {};
									dataSource.source.dataFileMetadata.fileName = newFileName;
								}
								// console.log("dataSource.source", dataSource.source);

								// Need to merge available properties at this point without overwriting existing columns, delete no longer existing columns.
								const oldAvailableProperties = dataSource.source.availableProperties;

								// console.log("oldAvailableProperties", oldAvailableProperties, "newAvailableProperties", newAvailableProperties);

								if (oldAvailableProperties.length < newAvailableProperties.length) {
									dialog(
										"Warning: There are additional columns in the new data file",
										'data-source',
									);
								}

								if (oldAvailableProperties.length > newAvailableProperties.length) {
									dialog(
										"Warning: There are fewer columns in the new data file",
										'data-source',
									);
								}

								const mergedAvailableProperties = [];
								let foundMismatch = 0;
								newAvailableProperties.forEach((ap) => {
									const oap = oldAvailableProperties.find((oap) => oap.fieldName === ap.fieldName);
									if (oap) {
										mergedAvailableProperties.push(oap); // favour pre-existing (old one kept)
									} else {
										// Unable to match with an old column so add new one (old will be lost)
										mergedAvailableProperties.push(ap); // new
										foundMismatch++;
									}
								});
								if (foundMismatch > 0 && (oldAvailableProperties.length === newAvailableProperties.length)) {
									dialog(
										`Warning: ${foundMismatch} column${foundMismatch > 1 ? 's' : ''} in the new data file did not match any columns in the old data source definition`,
										'data-source',
									);
								}
								// console.log("1 availableProperties", availableProperties, "mergedAvailableProperties", mergedAvailableProperties);
								availableProperties = mergedAvailableProperties;
								// console.log("2 availableProperties", availableProperties, "mergedAvailableProperties", mergedAvailableProperties);

							})
							.catch(error => {
								logError("Retrieving datasource metadata", error);
								handleShowSpinner(false);
							});
					} else {
						if (!dataSource || !dataSource.source || !dataSource.source.availableProperties) availableProperties = newAvailableProperties;
						metadataPromise = new Promise((resolve, reject) => {
							resolve('immediately');
						});
					}
					// console.log("3 availableProperties", availableProperties);

					metadataPromise.then(() => {

						// console.log("availableProperties", availableProperties);
						dataSource = {
							...dataSource,
							connection: connection,
							data: data,
							database: database,
							fileName: fileName,
							name: name,
							source: {
								...dataSource.source,
								availableProperties: availableProperties,
								chemistryColumn: chemistryColumn,
								idColumn: idColumn,
							},
							type: (database === 'None' || database === undefined) ? type : 'DATABASE',
						};
						availableProperties.forEach((ap) => {
							const {
								columnName,
								columnType,
								fieldName,
							} = ap;
							switch (columnType) {
								case 'DATE':
									// console.log("ap.details", ap.details);
									// console.log("validShortDateFormats", validShortDateFormats);
									if (!ap.details || !ap.details.dateDisplayFormat) ap.details.dateDisplayFormat = '%Y-%m-%d';
									if (!ap.details || !ap.details.dateDisplayOrder) {
										if (validShortDateFormats[ap.details.dateDisplayFormat]) {
											ap.details.dateDisplayOrder = validShortDateFormats[ap.details.dateDisplayFormat];
										} else {
											ap.details.dateDisplayOrder = 'ymd';
											ap.details.dateDisplayFormatOther = ap.details.dateDisplayFormat;
											ap.details.dateDisplayFormat = 'other';
										}
									}
									if (!ap.details || !ap.details.dateInputFormat) ap.details.dateInputFormat = '%Y-%m-%d';
									if (!ap.details || !ap.details.dateInputOrder) {
										if (validShortDateFormats[ap.details.dateInputFormat]) {
											ap.details.dateInputOrder = validShortDateFormats[ap.details.dateInputFormat];
										} else {
											ap.details.dateInputOrder = 'ymd';
											ap.details.dateInputFormatOther = ap.details.dateInputFormat;
											ap.details.dateInputFormat = 'other';
										}
									}
									break;
								case 'TEXT':
									if (idColumn === 'none' && columnName.toLowerCase() === 'id') idColumn = fieldName;
									this.addWithoutDuplication(textColumnOptions, { name: columnName, value: fieldName });
									// textColumnOptions.push({ name: columnName, value: fieldName });
									break;
								case 'NUMBER':
									this.addWithoutDuplication(numberColumnOptions, { name: columnName, value: fieldName });
									if (ap.details) ap.details.factor = this.convertToErrorType(ap.details.factor);
									// numberColumnOptions.push({ name: columnName, value: fieldName });
									break;
								case 'STRUCTURE':
									if (!ap.details || !ap.details.format) ap.details.format = 'smiles';
									this.addWithoutDuplication(structureColumnOptions, { name: columnName, value: fieldName });
									// structureColumnOptions.push({ name: columnName, value: fieldName });
									break;
								default:
									break;
							}
						});

						this.setState({
							CSVloaded: previewLoaded,
							JSONloaded: false,
							data: data,
							dataSource: dataSource,
							loading: false,
							numberColumnOptions: numberColumnOptions,
							previewLoaded: previewLoaded,
							structureColumnOptions: structureColumnOptions,
							textColumnOptions: textColumnOptions,
						}, () => {
							handleShowSpinner(false);
							this.validate();
						});
					});
				}
			});
		}
	};

	recordTreeState = () => {
		const {
			treeData,
		} = this.state;
		this.setState({
			oldTreeData: treeData,
		});
	};

	removeNode = (path) => {
		// console.log("removeNode path", path);
		const {
			treeData,
		} = this.state;
		// console.log("removeNode treeData", treeData);

		this.checkForNodesInUse(
			{
				action: 'delete',
				oldPath: path,
				oldTreeData: treeData,
			},

			// affirm
			() => {
				// console.log("Do something");
				this.setState(state => ({
					treeData: removeNodeAtPath({
						getNodeKey: ({ treeIndex }) => treeIndex,
						path,
						treeData: treeData,
					}),
				}));
			},

			// cancel (no need to pass a function to do nothing in this case)
			// () => {
			// 	console.log("Do nothing/reset");
			// }
		);
	};

	render = () => {

		// console.log("DataSourceForm render");

		const {
			appName,
			cddVaultEndpoint = '',
			handleAddUpdateDataSource,
			handleCancelClick,
			logError,
			secondaryNav,
		} = this.props;

		const {
			add,
			availablePropertiesSelected = [],
			cartridgeColumnError,
			cddTokenSet = false,
			cddVaultIDsLoaded = false,
			chemistryColumnError,
			connectionError = false,
			connectionMade = false,
			dataFileError = '',
			dataFileLoaded = false,
			dateDisplayOptions,
			dateInputOptions,
			dates,
			factorOptions,
			fileName = '',
			fileType = 'json',
			idColumnError,
			JSONfileError = '',
			JSONloaded = false,
			measurementOptions,
			mergeRuleOptions,
			dataSource,
			name,
			nameError,
			nameValid,
			numberColumnOptions = [],
			previewLoaded,
			progress,
			publicDatasets = [],
			publicDatasetsSelected,
			structureColumnOptions = [],
			sqlError = false,
			testSetFileError,
			testSetFileLoaded,
			testSetFileName = '',
			testSetDefinition = 'column',
			textColumnOptions = [],
			units,
			valid,
			vaultID = '',
			vaultIDOptions,
		} = this.state;

		const {
			count,
			done,
			step,
		} = progress;

		if (!dataSource) return null;

		// Example of how it could be done, but strange that it won't pick up the highest level i.e. dataSource this way.
		// Anyway, I don't think this gives us any great advantage and hopefully we'll be flattening the data somewhat in
		// the next version.
		// const {
		// 	database,
		// 	connection = '',
		// 	source:
		// 	{
		// 		additionalMetadata = {},
		// 		availableProperties = [],
		// 		cartridge = '',
		// 		cartridgeColumn = '',
		// 		chemistryColumn = 'none',
		// 		dataFileMetadata = {},
		// 		idColumn = 'none',
		// 	} = { },
		// 	template = '',
		// 	type = 'DATA_FILE',
		// } = dataSource;

		const {
			database,
			connection = '',
			source = {},
			template = '',
			type = 'DATA_FILE',
		} = dataSource;

		const {
			additionalMetadata = {},
			availableProperties = [],
			chemistryColumn = 'none',
			dataFileMetadata = {},
			idColumn = 'none',
		} = source;

		let {
			fileName: fileNameFromMetaData,
		} = dataFileMetadata;


		if (fileNameFromMetaData) {
			try {
				fileNameFromMetaData = decodeURIComponent(window.atob(fileNameFromMetaData));
			} catch (err) {
				logError("Attempting to decode filename", err);
			}
		} else {
			fileNameFromMetaData = fileName;
		}

		let {
			cartridge = '',
			cartridgeColumn = '',
		} = source;
		if (cartridge === null) cartridge = '';
		if (cartridgeColumn === null) cartridgeColumn = '';


		// Exclude currently selected columns from numberColumnOptions
		const numberColumnOptionsExcludingSelected = numberColumnOptions.slice();

		if (availablePropertiesSelected.length > 0 && numberColumnOptionsExcludingSelected) {
			availablePropertiesSelected.forEach((apIndex) => {
				const foundIndex = numberColumnOptionsExcludingSelected.findIndex((item) => { return (item.name === availableProperties[apIndex].fieldName); });
				if (foundIndex !== -1) numberColumnOptionsExcludingSelected.splice(foundIndex, 1);
			});
		}

		let cerellaEnabled;
		if (additionalMetadata === null) {
			cerellaEnabled = false;
		} else {
			({
				cerellaEnabled = false,
			} = additionalMetadata);
		}

		let measurement = '';
		let lastMeasurement = undefined;
		let unitsValue = '';
		let lastUnitsValue = undefined;
		availablePropertiesSelected.forEach((apIndex) => {
			if (availableProperties[apIndex].details &&
				availableProperties[apIndex].details.units &&
				measurement !== 'Mixed' &&
				unitsValue !== 'Mixed') {
				unitsValue = availableProperties[apIndex].details.units;
				measurement = this.getMeasurementFromUnit(unitsValue);
			} else {
				measurement = 'other';
			}
			if (lastMeasurement !== undefined) {
				if (measurement !== lastMeasurement) measurement = 'Mixed';
			}
			lastMeasurement = measurement;
			if (lastUnitsValue !== undefined) {
				if (unitsValue !== lastUnitsValue) unitsValue = 'Mixed';
			}
			lastUnitsValue = unitsValue;
		});

		this.addWithoutDuplicationOrRemoveMix(measurementOptions, measurement);

		// Endpoint Type

		let priorityValue = false;
		let lastPriorityValue = undefined;
		let inputOnlyValue = false;
		let lastInputOnlyValue = undefined;
		let testSetColumn = 'none';

		if (additionalMetadata !== null) {
			const {
				cerellaInputOnlyEndpoints,
				cerellaPriorityEndpoints,
			} = additionalMetadata;
			({ cerellaTestSetColumn: testSetColumn = 'none' } = additionalMetadata);

			availablePropertiesSelected.forEach((apIndex) => {
				priorityValue = false;
				inputOnlyValue = false;
				const ap = availableProperties[apIndex];
				const { columnName } = ap;

				if (cerellaInputOnlyEndpoints && cerellaInputOnlyEndpoints.length) {
					const foundIndex = cerellaInputOnlyEndpoints.find((item) => item === columnName);
					if (foundIndex && foundIndex !== -1) inputOnlyValue = true;
				}
				if (lastInputOnlyValue !== undefined) {
					if (lastInputOnlyValue !== inputOnlyValue) inputOnlyValue = 'Mixed';
				}
				lastInputOnlyValue = inputOnlyValue;

				if (cerellaPriorityEndpoints && cerellaPriorityEndpoints.length) {
					const foundIndex = cerellaPriorityEndpoints.find((item) => item === columnName);
					if (foundIndex && foundIndex !== -1) priorityValue = true;
				}
				if (lastPriorityValue !== undefined) {
					if (lastPriorityValue !== priorityValue) priorityValue = 'Mixed';
				}
				lastPriorityValue = priorityValue;
			});
		}

		// Hidden
		let hiddenValue = false;
		let lastHiddenValue = undefined;
		availablePropertiesSelected.forEach((apIndex) => {
			hiddenValue = false;
			if (lastHiddenValue !== 'Mixed' && availableProperties[apIndex].hidden) {
				hiddenValue = availableProperties[apIndex].hidden;
			}
			if (lastHiddenValue !== undefined) {
				if (lastHiddenValue !== hiddenValue) hiddenValue = 'Mixed';
			}
			lastHiddenValue = hiddenValue;
		});

		let dateInputOrderValue = 'dmy';
		let dateDisplayOrderValue = 'dmy';
		let dateInputFormatValue = '';
		let dateDisplayFormatValue = '';
		let dateInputFormatOtherValue = '';
		let lastDateInputOrderValue = undefined;
		let lastDateDisplayOrderValue = undefined;
		let lastDateInputFormatValue = undefined;
		let lastDateDisplayFormatValue = undefined;
		let lastDateInputFormatOtherValue = undefined;

		availablePropertiesSelected.forEach((apIndex) => {
			if (availableProperties[apIndex].details) {
				// dateInputOrder
				if (availableProperties[apIndex].details.dateInputOrder && lastDateInputOrderValue !== 'Mixed') {
					dateInputOrderValue = availableProperties[apIndex].details.dateInputOrder;
				}

				// dateDisplayOrder
				if (availableProperties[apIndex].details.dateDisplayOrder && lastDateDisplayOrderValue !== 'Mixed') {
					dateDisplayOrderValue = availableProperties[apIndex].details.dateDisplayOrder;
				}

				// dateInputFormat
				if (availableProperties[apIndex].details.dateInputFormat && lastDateInputFormatValue !== 'Mixed') {
					dateInputFormatValue = availableProperties[apIndex].details.dateInputFormat;
				}

				// dateDisplayFormat
				if (availableProperties[apIndex].details.dateDisplayFormat && lastDateDisplayFormatValue !== 'Mixed') {
					dateDisplayFormatValue = availableProperties[apIndex].details.dateDisplayFormat;
				}

				// dateInputFormatOther
				if (availableProperties[apIndex].details.dateInputFormatOther && lastDateInputFormatOtherValue !== 'Mixed') {
					dateInputFormatOtherValue = availableProperties[apIndex].details.dateInputFormatOther;
				}

				if (lastDateInputOrderValue !== undefined) {
					if (dateInputOrderValue !== lastDateInputOrderValue) dateInputOrderValue = 'Mixed';
				}
				if (lastDateDisplayOrderValue !== undefined) {
					if (dateDisplayOrderValue !== lastDateDisplayOrderValue) dateDisplayOrderValue = 'Mixed';
				}
				if (lastDateDisplayFormatValue !== undefined) {
					if (dateDisplayFormatValue !== lastDateDisplayFormatValue) dateDisplayFormatValue = 'Mixed';
				}
				if (lastDateInputFormatValue !== undefined) {
					if (dateInputFormatValue !== lastDateInputFormatValue) dateInputFormatValue = 'Mixed';
				}
				if (lastDateInputFormatOtherValue !== undefined) {
					if (dateInputFormatOtherValue !== lastDateInputFormatOtherValue) dateInputFormatOtherValue = 'Mixed';
				}

				lastDateInputOrderValue = dateInputOrderValue;
				lastDateDisplayOrderValue = dateDisplayOrderValue;
				lastDateDisplayFormatValue = dateDisplayFormatValue;
				lastDateInputFormatValue = dateInputFormatValue;
				lastDateInputFormatOtherValue = dateInputFormatOtherValue;
			}
		});
		// console.log("dateInputOrderValue", dateInputOrderValue);
		// console.log("dateDisplayOrderValue", dateDisplayOrderValue);
		// console.log("dateInputFormatValue", dateInputFormatValue);
		// console.log("dateDisplayFormatValue", dateDisplayFormatValue);
		// console.log("dateInputFormatOtherValue", dateInputFormatOtherValue);
		// console.log("dates", dates);
		// console.log('');

		this.addWithoutDuplicationOrRemoveMix(dateInputOptions, dateInputOrderValue);
		this.addWithoutDuplicationOrRemoveMix(dateDisplayOptions, dateDisplayOrderValue);

		// Date Format Other
		let dateFormatOtherValue = '';
		let lastDateFormatOtherValue = undefined;
		availablePropertiesSelected.forEach((apIndex) => {
			if (lastDateFormatOtherValue !== 'Mixed') {
				if (availableProperties[apIndex].details.dateDisplayFormatOther) {
					dateFormatOtherValue = availableProperties[apIndex].details.dateDisplayFormatOther;
				}
				if (lastDateFormatOtherValue !== undefined) {
					if (lastDateFormatOtherValue !== dateFormatOtherValue) dateFormatOtherValue = 'Mixed';
				}
				lastDateFormatOtherValue = dateFormatOtherValue;
			}

		});

		// Text Interpretation
		let textInterpretationValue = 'TEXT';
		let lastTextInterpretationValue = undefined;
		availablePropertiesSelected.forEach((apIndex) => {
			textInterpretationValue = 'TEXT';
			if (lastTextInterpretationValue !== 'Mixed') {
				if (availableProperties[apIndex].details.category) textInterpretationValue = 'category';
				if (availableProperties[apIndex].details.image) textInterpretationValue = 'image';
				if (lastTextInterpretationValue !== undefined) {
					if (lastTextInterpretationValue !== textInterpretationValue) textInterpretationValue = 'Mixed';
				}
				lastTextInterpretationValue = textInterpretationValue;
			}
		});
		const textInterpretationRadios = [
			{ name: 'Interpret as text', value: 'TEXT' },
			{ name: 'Interpret as category data', value: 'category' },
			{ name: 'Interpret as image URL', value: 'image' },
		];
		this.addWithoutDuplicationOrRemoveMix(textInterpretationRadios, textInterpretationValue);

		// Text Comparison
		let textComparisonValue = 'CS';
		let lastTextComparisonValue = undefined;
		availablePropertiesSelected.forEach((apIndex) => {
			textComparisonValue = 'CS';
			if (lastTextComparisonValue !== 'Mixed' && availableProperties[apIndex].details.function) {
				textComparisonValue = availableProperties[apIndex].details.function;
			}
			if (lastTextComparisonValue !== undefined) {
				if (lastTextComparisonValue !== textComparisonValue) textComparisonValue = 'Mixed';
			}
			lastTextComparisonValue = textComparisonValue;
		});
		const textComparisonRadios = [
			{ name: 'Case sensitive', value: 'CS' },
			{ name: 'Upper case', value: 'upper' },
			{ name: 'lower case', value: 'lower' },
		];

		this.addWithoutDuplicationOrRemoveMix(textComparisonRadios, textComparisonValue);

		// Structure
		let structureValue = 'smiles';
		let lastStructureValue = undefined;
		availablePropertiesSelected.forEach((apIndex) => {
			if (lastStructureValue !== 'Mixed' && availableProperties[apIndex].details.format) {
				structureValue = availableProperties[apIndex].details.format;
			}
			if (lastStructureValue !== undefined) {
				if (lastStructureValue !== structureValue) structureValue = 'Mixed';
			}
			lastStructureValue = structureValue;
		});

		const structureRadios = [
			{ name: 'SMILES', value: 'smiles' },
			{ name: 'Molfile', value: 'molfile' },
		];

		this.addWithoutDuplicationOrRemoveMix(structureRadios, structureValue);

		let minimumValue = '';
		let maximumValue = '';
		let lastMinimumValue = undefined;
		let lastMaximumValue = undefined;
		availablePropertiesSelected.forEach((apIndex) => {
			minimumValue = '';
			maximumValue = '';
			if (
				availableProperties[apIndex].details !== undefined &&
				availableProperties[apIndex].details.valueRange !== undefined
			) {
				if (availableProperties[apIndex].details.valueRange.maximum !== undefined && lastMaximumValue !== 'Mixed')
					maximumValue = availableProperties[apIndex].details.valueRange.maximum;
				if (availableProperties[apIndex].details.valueRange.minimum !== undefined && lastMinimumValue !== 'Mixed')
					minimumValue = availableProperties[apIndex].details.valueRange.minimum;
			}
			if (lastMinimumValue !== undefined) {
				if (lastMinimumValue !== minimumValue) minimumValue = 'Mixed';
			}
			if (lastMaximumValue !== undefined) {
				if (lastMaximumValue !== maximumValue) maximumValue = 'Mixed';
			}
			lastMinimumValue = minimumValue;
			lastMaximumValue = maximumValue;
		});

		// If we want to pick up transformation data, first we need to be dealing with a number
		let transformationValue = 'default';
		let lastTransformationValue = undefined;
		availablePropertiesSelected.forEach((apIndex) => {
			if (availableProperties[apIndex].columnType === 'NUMBER') {
				// Then we have to see if there is an entry in additionalMetadata look up on columnName from availableProperties matching endpointName
				const { columnName } = availableProperties[apIndex];
				if (additionalMetadata && lastTransformationValue !== 'Mixed') {
					transformationValue = 'default';
					const { cerellaEndpoints } = additionalMetadata;
					if (cerellaEndpoints) {
						const foundEndpoint = cerellaEndpoints.find(element => element.endpointName === columnName);
						if (foundEndpoint) {
							if (foundEndpoint.transformation === null) {
								transformationValue = 'none';
							} else if (foundEndpoint.transformation) {
								if (foundEndpoint.transformation.functionType) {
									transformationValue = foundEndpoint.transformation.functionType.toLowerCase();
								}
							}
						}
					}
				}
				if (lastTransformationValue !== undefined) {
					if (lastTransformationValue !== transformationValue) transformationValue = 'Mixed';
				}
				lastTransformationValue = transformationValue;
			}
		});
		const transformationOptions = [
			{ name: 'Default', value: 'default' },
			{ name: 'Log10', value: 'log10' },
			{ name: 'None', value: 'none' },
		];

		this.addWithoutDuplicationOrRemoveMix(transformationOptions, transformationValue);

		// Display
		let displayValue = '';
		let lastDisplayValue = undefined;
		availablePropertiesSelected.forEach((apIndex) => {
			if (availableProperties[apIndex].details.display &&
				lastDisplayValue !== 'Mixed') displayValue = availableProperties[apIndex].details.display;
			if (lastDisplayValue !== undefined) {
				if (lastDisplayValue !== displayValue) displayValue = 'Mixed';
			}
			lastDisplayValue = displayValue;
		});
		const displayOptions = [
			{ name: 'Default', value: 'Default' },
			{ name: 'Decimal or Scientific', value: 'Decimal or Scientific' },
			{ name: 'Decimal', value: 'Decimal' },
			{ name: 'Scientific', value: 'Scientific' },
		];
		this.addWithoutDuplicationOrRemoveMix(displayOptions, displayValue);

		// Precision
		let precisionValue = '';
		let lastPrecisionValue = undefined;
		availablePropertiesSelected.forEach((apIndex) => {
			precisionValue = '';
			if (availableProperties[apIndex].details.precision &&
				lastPrecisionValue !== 'Mixed') precisionValue = availableProperties[apIndex].details.precision;
			if (lastPrecisionValue !== undefined) {
				if (lastPrecisionValue !== precisionValue) precisionValue = 'Mixed';
			}
			lastPrecisionValue = precisionValue;
		});

		// Error
		let errorValue = '';
		let lastErrorValue = undefined;
		availablePropertiesSelected.forEach((apIndex) => {
			errorValue = '';
			if (availableProperties[apIndex].details.error &&
				lastErrorValue !== 'Mixed') errorValue = availableProperties[apIndex].details.error;
			if (lastErrorValue !== undefined) {
				if (lastErrorValue !== errorValue) errorValue = 'Mixed';
			}
			lastErrorValue = errorValue;
		});

		// Error Column
		let errorColumnValue = 'none';
		let lastErrorColumnValue = undefined;
		availablePropertiesSelected.forEach((apIndex) => {
			errorColumnValue = 'none';
			if (availableProperties[apIndex].details.errorColumn &&
				lastErrorColumnValue !== 'Mixed') errorColumnValue = availableProperties[apIndex].details.errorColumn;
			if (lastErrorColumnValue !== undefined) {
				if (lastErrorColumnValue !== errorColumnValue) errorColumnValue = 'Mixed';
			}
			lastErrorColumnValue = errorColumnValue;
		});

		this.addWithoutDuplicationOrRemoveMix(numberColumnOptionsExcludingSelected, errorColumnValue);

		// Factor
		let factorValue = 'DEFAULT';
		let lastFactorValue = undefined;
		availablePropertiesSelected.forEach((apIndex) => {
			factorValue = 'DEFAULT';
			if (lastFactorValue !== 'Mixed' && availableProperties[apIndex].details.factor) {
				factorValue = availableProperties[apIndex].details.factor;
			}
			if (lastFactorValue !== undefined) {
				if (lastFactorValue !== factorValue) factorValue = 'Mixed';
			}
			lastFactorValue = factorValue;
		});

		this.addWithoutDuplicationOrRemoveMix(factorOptions, factorValue);

		// Qualifier Column
		let qualifierColumnValue = 'none';
		let lastQualifierColumnValue = undefined;
		availablePropertiesSelected.forEach((apIndex) => {
			qualifierColumnValue = 'none';
			if (availableProperties[apIndex].details.qualifierColumn &&
				lastQualifierColumnValue !== 'Mixed') qualifierColumnValue = availableProperties[apIndex].details.qualifierColumn;
			if (lastQualifierColumnValue !== undefined) {
				if (lastQualifierColumnValue !== qualifierColumnValue) qualifierColumnValue = 'Mixed';
			}
			lastQualifierColumnValue = qualifierColumnValue;
		});

		this.addWithoutDuplicationOrRemoveMix(textColumnOptions, qualifierColumnValue);

		// qualifierRule
		let qualifierRuleValue = null;
		if (appName === 'Cerella') {
			let lastQualifierRuleValue = undefined;
			availablePropertiesSelected.forEach((apIndex) => {
				// Then we have to see if there is an entry in additionalMetadata look up on columnName from availableProperties
				// matching endpointName
				const { columnName } = availableProperties[apIndex];
				if (additionalMetadata && lastQualifierRuleValue !== 'Mixed') {
					qualifierRuleValue = null;
					const { cerellaEndpoints } = additionalMetadata;
					if (cerellaEndpoints) {
						const foundEndpoint = cerellaEndpoints.find(element => element.endpointName === columnName);
						if (foundEndpoint) {
							if (foundEndpoint.qualifierRule !== undefined) {
								qualifierRuleValue = foundEndpoint.qualifierRule;
							}
						}
					}
				}
				if (lastQualifierRuleValue !== undefined) {
					if (lastQualifierRuleValue !== qualifierRuleValue) qualifierRuleValue = 'Mixed';
				}
				lastQualifierRuleValue = qualifierRuleValue;
			});
		}

		const fileTypeOptions = [
			{ name: 'Configuration file', value: 'json' },
			{ name: 'Data file', value: 'datafile' },
			{ name: 'Database', value: 'connection' },
		];

		// mergeRule
		let mergeRuleValue = 'DEFAULT';
		let lastMergeRuleValue = undefined;
		availablePropertiesSelected.forEach((apIndex) => {
			// Then we have to see if there is an entry in additionalMetadata look up on columnName from availableProperties matching endpointName
			const { columnName } = availableProperties[apIndex];
			if (additionalMetadata && lastMergeRuleValue !== 'Mixed') {
				mergeRuleValue = 'DEFAULT';
				const { cerellaEndpoints } = additionalMetadata;
				if (cerellaEndpoints) {
					const foundEndpoint = cerellaEndpoints.find(element => element.endpointName === columnName);
					if (foundEndpoint) {
						if (foundEndpoint.mergeRule !== undefined) {
							mergeRuleValue = foundEndpoint.mergeRule;
						}
					}
				}
			}
			if (lastMergeRuleValue !== undefined) {
				if (lastMergeRuleValue !== mergeRuleValue) mergeRuleValue = 'Mixed';
			}
			lastMergeRuleValue = mergeRuleValue;
		});
		this.addWithoutDuplicationOrRemoveMix(mergeRuleOptions, mergeRuleValue);

		// console.log("mergeRuleOptions", mergeRuleOptions);

		if (cddVaultEndpoint !== '') this.addWithoutDuplication(fileTypeOptions, { name: 'CDD Vault', value: 'cdd' });
		// fileTypeOptions.push({ name: 'CDD Vault', value: 'cdd' });

		let columnTypeValue = '';
		availablePropertiesSelected.forEach((apIndex) => {
			if (columnTypeValue !== '' && columnTypeValue !== availableProperties[apIndex].columnType) {
				columnTypeValue = 'Mixed';
			} else {
				columnTypeValue = availableProperties[apIndex].columnType;
			}
		});

		let selectionInfoJSX = [];
		let selectionInfoString = ''; // Just for a rough idea of string length
		availablePropertiesSelected.forEach((apIndex) => {
			const colName = availableProperties[apIndex].columnName;
			selectionInfoJSX.push(<i key={`selection-info-i-${colName}`}>{`'${colName}'`}</i>);
			selectionInfoString += `'${colName}'`;
			// if (apIndex < availablePropertiesSelected.length)
			selectionInfoJSX.push(', ');
		});
		if (availablePropertiesSelected.length === 0) {
			selectionInfoJSX = `No columns selected.`;
		} else if (selectionInfoString.length > 150) {
			selectionInfoJSX = `${availablePropertiesSelected.length} column${availablePropertiesSelected.length !== 1 ? 's' : ''}\xa0selected.`;
		} else {
			selectionInfoJSX = selectionInfoJSX.slice(0, selectionInfoJSX.length - 1);
			selectionInfoJSX.push(`\xa0selected.`);
		}

		return (
			<>
				<div className={`form data-source ${availablePropertiesSelected.length > 0 ? 'clicked' : ''} ${type} `}>
					<FieldSet className='data-source'>
						<h2>Data source</h2>
						{add ?
							<>
								{!dataFileLoaded && !JSONloaded && !connectionMade ?
									<Radio
										id='fileType'
										label='File type'
										handleChange={this.handleChange}
										value={fileType}
										sameRow={false}
										radios={fileTypeOptions}
									/> : null
								}
								{fileType !== 'json' && !dataFileLoaded ?
									< TextField
										autocomplete='off'
										disabled={secondaryNav === 'edit'}
										error={nameError}
										handleChange={this.handleChange}
										id='name'
										label='Name'
										placeholder='Name'
										value={name}
									/>
									: null}
								{(type === 'FILE' || type === 'DATA_FILE') ?
									<>
										{fileType === 'datafile' ?
											<>
												{dataFileLoaded ?
													<>
														<div className='text-field row'>
															<label>Name:</label>
															<div className='name'>{name}</div>
														</div>
														<div className='text-field row'>
															<label>File name:</label>
															<div className='file-name'>{fileName}</div>
														</div>
													</>
													:
													<>
														<TextField
															label='ID column (if known)'
															id='idColumn'
															handleChange={this.handleChange}
															value={idColumn === 'none' ? '' : idColumn}
														/>
														<div className='text-field row'>
															<label htmlFor='DataFileReader'>Data file:</label>
															<div className={`data-file-loader ${!nameValid ? 'disabled' : ''} ${JSONloaded ? 'loaded' : 'not-loaded'} `}>
																<input type='file'
																	className={`data-file-input`}
																	disabled={!nameValid}
																	error={dataFileError}
																	id='DataFileReader'
																	accept=".csv,.sdf"
																	onChange={(e) => this.handleDataFileLoad(e, false /* not a replacement */)}
																	value=''
																/>
															</div>
														</div>
													</>
												}
											</>
											: null
										}
										{fileType === 'json' ?
											<div className='text-field row'>
												{JSONloaded ?
													<>
														<label htmlFor='JSONreader'>File name:</label>
														<div className={`file-name`} title={`${fileName}`}>{fileName}</div>
													</>
													:
													<>
														<label htmlFor='JSONreader'>Choose file:</label>
														<div className={`json-loader ${JSONloaded ? 'loaded' : 'not-loaded'} `}>
															<input type='file'
																className={`json-input`}
																error={JSONfileError}
																// disabled={name === ''}
																id='JSONreader'
																accept="application/JSON"
																onChange={this.handleJSONload}
																value=''
															/>
														</div>
														<div className="error">{JSONfileError}</div>
													</>
												}
											</div> :
											null
										} {/* fileType === 'json' or 'connection */}
									</>
									: null
								} {/* type === 'file' */}
								{fileType === 'json' ?
									<TextField
										autocomplete='off'
										disabled={secondaryNav === 'edit'}
										error={nameError}
										handleChange={this.handleChange}
										id='name'
										label='Name'
										placeholder='Name'
										value={name}
									/>
									: null}
							</>
							:
							<>
								<div className='row'><label>Name:</label><div className={`name`} title={`${name}`}>{name}</div></div>
								{type === 'DATA_FILE' && fileNameFromMetaData ?
									<div className='row'><label>File name:</label><div className={`file-name`} title={`${fileNameFromMetaData}`}>{fileNameFromMetaData}</div></div>
									:
									null
								}
								{type === 'DATA_FILE' ?
									<>
										<div className='row'>
											<div className={`data-file-replacer ${!nameValid ? 'disabled' : ''} ${JSONloaded ? 'loaded' : 'not-loaded'} `}>
												<input type='file'
													className={`data-file-input`}
													disabled={!nameValid}
													error={dataFileError}
													id='DataFileReader'
													accept=".csv,.sdf"
													onChange={(e) => this.handleDataFileLoad(e, true /* a replacement */)}
													value=''
												/>
											</div>
											<div className="error">{dataFileError}</div>
										</div>
									</>
									: null}
							</>
						} {/* add */}
						{type === 'DATABASE' || fileType === 'connection' ?
							<>
								<TextField
									error={`${connectionError ? 'Please enter connection details' : ''}`}
									handleChange={this.handleChange}
									id='connection'
									label='Connection'
									placeholder='Please enter connection details'
									value={connection}
								/>
								<TextField
									error={`${sqlError ? 'Please enter a template SQL statement' : ''}`}
									handleChange={this.handleChange}
									id='template'
									label='SQL'
									placeholder='Please enter a template SQL statement'
									value={template}
								/>
								<Select
									handleChange={this.handleChange}
									id='database'
									label='Database'
									options={[
										{ name: 'SQLITE', value: 'SQLITE' },
										{ name: 'Postgres', value: 'Postgres' },
										{ name: 'MSAccess', value: 'MSAccess' },
										{ name: 'SQLServer', value: 'SQLServer' },
										{ name: 'MySQL', value: 'MySQL' },
										{ name: 'Oracle', value: 'Oracle' },
									]}
									value={database}
								/>
								<Select
									handleChange={this.handleChange}
									id='cartridge'
									label={'Cartridge'}
									value={cartridge}
									options={[
										{ name: 'None', value: 'none' },
										{ name: 'BioviaDirect', value: 'BioviaDirect' },
										{ name: 'ChemAxon', value: 'ChemAxon' },
									]}
								/>
								<TextField
									error={`${cartridgeColumnError ? 'Please enter the column for chemical structure queries' : ''}`}
									id='cartridgeColumn'
									label={'Cartridge column'}
									type='text'
									handleChange={this.handleChange}
									value={cartridgeColumn}
								/>
							</>
							: null}

						{fileType === 'cdd' && cddVaultEndpoint !== '' ?
							<>
								{this.renderCDDTokenField()}
								{cddVaultIDsLoaded && secondaryNav === 'add' ?
									<Select
										error={`${vaultID === '' ? (vaultIDOptions.length > 1 ? 'Please select a vault ID' : 'No remaining vaults to load') : ''}`}
										handleChange={this.handleChange}
										id='vaultID'
										label={'Vault ID'}
										value={vaultID}
										options={vaultIDOptions}
									/> :
									null}
								{publicDatasets.length ?
									<Select
										className='publicdatasetsselected-row'
										handleChange={this.handleChangePublicDatasets}
										id='publicDatasetsSelected'
										label={`Public datasets`}
										multiple={true}
										value={publicDatasetsSelected}
										options={publicDatasets}
									/> :
									null}
							</>
							:
							null
						}
						{appName === 'Cerella' && (fileType !== 'cdd' || secondaryNav === 'edit') ?
							<CheckBox
								className='cerella-enabled data-source'
								handleChange={() => {
									this.handleCerellaEnabled(!cerellaEnabled);
								}}
								id='cerellaEnabled'
								label='Cerella enabled'
								value={cerellaEnabled}
							/>
							:
							null
						}
						{type === 'DATABASE' || fileType === 'cdd' || fileType === 'connection' ?
							<div className='row'>
								<label >&nbsp;</label>
								<button
									className={`connect-button ${!valid ? 'disabled' : ''}`}
									disabled={!valid}
									// This should pass replace=true if existing data source, replace=false if new data source
									onClick={(e) => {
										e.preventDefault();
										this.handleConnect(secondaryNav === 'edit');
									}}
								>Connect</button>
							</div>
							:
							null
						}
						{/* <p>type: {type} fileType: {fileType} {availableProperties.length}</p> */}
						{textColumnOptions.length >= 1 && availableProperties.length ?
							<>
								<Select
									error={`${idColumnError ? 'Please select an ID column' : ''}`}
									handleChange={this.handleChange}
									id='idColumn'
									label={'ID column'}
									value={idColumn}
									options={textColumnOptions}
								/>
								{structureColumnOptions.length >= 1 ?
									<Select
										error={`${chemistryColumnError ? 'Please select a chemistry column' : ''}`}
										handleChange={this.handleChange}
										id='chemistryColumn'
										label={'Chemistry column'}
										value={chemistryColumn}
										options={structureColumnOptions}
									/> :
									<div className='row' id='chemistryColumnMessage'>
										<p>Please identify one or more chemistry structure columns.</p>
									</div>
								}
							</>
							: null}
						{/* <p>secondaryNav={secondaryNav}
							dataFileLoaded={dataFileLoaded}
							type={type}
							fileType={fileType}
							{fileType !== 'json'}
							{fileType !== 'cdd'}</p> */}
						{((fileType !== 'json' && dataFileLoaded) || secondaryNav === 'edit') && type !== 'CDD' && appName === 'Cerella' ?
							<>
								<Radio
									handleChange={this.handleChange}
									label={`Test set definition`}
									id={`testSetDefinition`}
									info={{
										message: (
											<>
												<p>If an independent test set is not defined explicitly, Cerella will automatically select an
													independent test set by stratified
													random sampling. However, if you wish to define your own test set, you can do so by
													identifying the test compounds using a
													column in your data source or by uploading a file containing compound identifiers.
													The options are as follows:</p>
												<ol>
													<li>To allow Cerella to automatically select a test set, use the default options with
														the <b>column</b> option selected and the <b>Test set column</b> set to "&lt;None&gt;".</li>
													<li>To specify an independent test set using a column in your data source, select the <b>column</b> option and select the name of column in which you have identified the test
														compounds in the <b>Test set column</b> drop-down list. In this column within the data source,
														the word "Test" against a compound will indicate that it should be used as part of the
														independent test set.</li>
													<li>To specify an independent test set using a separate file, choose the <b>file</b> option
														and	then click on the <b>Browse</b> button below. The uploaded file should be a CSV file
														containing a list of compound identifiers, one per row, without a header row.
														If an independent test set has been uploaded previously, the <b>Test set file name</b> is
														displayed and the independent test set may be updated by clicking the <b>Browse</b> button again.</li>
												</ol>
											</>
										),
										title: 'Test set definition',
									}}
									radios={
										[
											{ name: 'column', value: 'column'},
											{ name: 'file', value: 'file'}
										]
									}
									sameRow={true}
									value={testSetDefinition}
								/>
								{testSetDefinition === 'column' ?
									<Select
										handleChange={this.handleChange}
										id='testSetColumn'
										info={{
											message: (
												<>
													<p>You can optionally indicate a column in your data source to be used to define which compounds
														are part of the test set.
														In this column, the word "Test" against a compound will indicate
														that it should be used as part of the independent test set.
													</p>
												</>
											),
											title: 'Test set column',
										}}
										label={'Test set column'}
										value={testSetColumn}
										options={textColumnOptions}
									/>
									:
									<>
										<div className='text-field-row row'>

											<label htmlFor='TestSetFileReader'>Test set file name:</label>
											{testSetFileLoaded ?
												<>
													<div className={`file-name`} title={`${testSetFileName}`}>{testSetFileName}</div>
												</>
												:
												<>
													<CSVReader
														cssClass={`csv-loader test-set-file-loader  ${testSetFileLoaded ? 'loaded' : 'not-loaded'} `}
														id='TestSetFileReader'
														onFileLoaded={this.handleTestSetFileLoad}
														onError={(error) => {
												alert(error); // eslint-disable-line
														}}
													/>
												</>}
											{testSetFileError ? <div className="error">{testSetFileError}</div> : null}
										</div>
										{testSetFileLoaded ?
											<div className='text-field-row row'>
												<CSVReader
													cssClass={`csv-loader test-set-file-loader not-loaded`}
													id='TestSetFileReader'
													onFileLoaded={this.handleTestSetFileLoad}
													onError={(error) => {
												alert(error); // eslint-disable-line
													}}
												/>
											</div>
											:
											null
										}
									</>
								}
							</>
							: null}
						{secondaryNav === 'edit' && type === 'CDD' && cddVaultEndpoint !== '' ?
							<>
								<FieldSet className='refresh-cdd'>
									<h4>To refresh data:</h4>
									{this.renderCDDTokenField()}
									<div className='row'>
										<button
											className={`refresh-button ${!cddTokenSet ? 'disabled' : ''}`}
											disabled={!cddTokenSet}
											onClick={this.handleRefreshCDD}>Refresh data</button>
									</div>
								</FieldSet>
							</>
							:
							null
						}
					</FieldSet>
					<FieldSet className='details'>
						<h2>Edit details</h2>
						<div id="spreadSheet">
							{this.renderSpreadSheet()}
						</div>
						{/* <p>{`${availablePropertiesSelected.length === 0 ? 'No' : availablePropertiesSelected.length} column${availablePropertiesSelected.length !== 1 ? 's' : ''} selected`}</p> */}
						<p>{selectionInfoJSX}</p>

					</FieldSet>
				</div>
				{/* <p>availablePropertiesSelected.length: {availablePropertiesSelected.length}</p>*/}
				<div id='editDetails' className={`form ${availablePropertiesSelected.length > 0 ? 'show' : ''} `}>
					{
						secondaryNav === 'edit' || (fileType === 'json' && JSONloaded) || (fileType === 'datafile' && dataFileLoaded) ?
							<h2><div>C</div><div className='collapse'>lick&nbsp;on&nbsp;a&nbsp;c</div><div>olumn&nbsp;</div><div className='collapse'>to&nbsp;edit&nbsp;its&nbsp;</div><div>properties</div></h2>
							:
							null
					}
					{/* ======================================== Mixed ======================================== */}
					{columnTypeValue === 'Mixed' ?
						<FieldSet className={`text edit - details`}>
							<div className='grid columns3'>
								<FieldSet className='data-source'>
									<h3>&nbsp;</h3>
									{this.renderCommon()}
								</FieldSet>
							</div>
						</FieldSet>
						:
						null
					}

					{/* ======================================== TEXT ======================================== */}
					{columnTypeValue === 'TEXT' ?
						<FieldSet className={`text edit - details`}>
							<div className='grid columns3'>
								<FieldSet className='data-source'>
									<h3>&nbsp;</h3>
									{this.renderCommon()}
								</FieldSet>
								<FieldSet className='data-source'>
									<h3>Interpretation</h3>
									{/*
									treat as category maps to category
									treat as images maps to image
									*/}
									<Radio
										handleChange={this.handleChangeColumn}
										id='textInterpretation'
										// label='Text interpretation'
										radios={textInterpretationRadios}
										value={textInterpretationValue}
									/>
								</FieldSet>
								<FieldSet className='data-source'>
									<h3>Text comparison</h3>
									<Radio
										handleChange={this.handleChangeColumn}
										id='function'
										// label='Source type'
										radios={textComparisonRadios}
										value={textComparisonValue}
									/>
								</FieldSet>
							</div>
						</FieldSet> : null}

					{/* ======================================== STRUCTURE ======================================== */}
					{columnTypeValue === 'STRUCTURE' ?
						<FieldSet className={`structure edit - details`}>
							<div className='grid columns2'>
								<FieldSet className='data-source'>
									<h3>&nbsp;</h3>
									{this.renderCommon()}
								</FieldSet>
								<FieldSet className='data-source'>
									<h3>Structure format</h3>
									<Radio
										handleChange={this.handleChangeColumn}
										id='format'
										radios={structureRadios}
										value={structureValue}
									></Radio>
								</FieldSet>
							</div>
						</FieldSet> : null}

					{/* ======================================== DATE ======================================== */}
					{columnTypeValue === 'DATE' ?
						<FieldSet className={`date edit - details`}>
							<div className='grid columns3'>
								<FieldSet className='data-source'>
									<h3>&nbsp;</h3>
									{this.renderCommon()}
								</FieldSet>
								<FieldSet className='data-source'>
									<h3>Input format</h3>
									<Select
										className={`date-input-order-row ${dateInputFormatValue === 'other' ? 'hide' : ''}`}
										handleChange={this.handleChangeColumn}
										id='dateInputOrder'
										label='Date order'
										options={dateInputOptions}
										value={dateInputOrderValue}
									/>
									<Select
										handleChange={this.handleChangeColumn}
										id='dateInputFormat'
										label='Date format'
										options={dates[dateInputOrderValue].value}
										value={dateInputFormatValue}
									/>
									<div
										className={`other-date-format-input ${dateInputFormatValue === 'other' ? '' : 'hide'}`}
									>
										<TextField
											className={`date-input-format-other ${dateInputFormatValue === 'other' ? 'hide' : ''}`}
											handleChange={this.handleChangeColumn}
											id='dateInputFormatOther'
											label={'Other date format'}
											type='text'
											value={dateInputFormatOtherValue}
										/>
										<div
											className='row'
											style={{ gridTemplateColumns: '1fr' }}
										><p className='syntax-info'>Syntax must be entered as described in <a href='https://docs.python.org/3/library/datetime.html#strftime-strptime-behavior' target='_blank' rel='noreferrer'>strftime() and strptime() behaviour</a></p></div>
									</div>
								</FieldSet>
								<FieldSet className='data-source'>
									<h3>Display format</h3>
									<Select
										className={`date-display-order-row ${dateDisplayOrderValue === 'other' ? 'hide' : ''}`}
										id='dateDisplayOrder'
										label='Date order'
										handleChange={this.handleChangeColumn}
										value={dateDisplayOrderValue}
										options={dateDisplayOptions}
									/>
									<Select
										id='dateDisplayFormat'
										label='Date format'
										handleChange={this.handleChangeColumn}
										value={dateDisplayFormatValue}
										options={availablePropertiesSelected.length > 0 ? dates[dateDisplayOrderValue].value : []}
									/>
									<div
										className={`other-date-format-display ${dateDisplayFormatValue === 'other' ? '' : 'hide'}`}
									>
										<TextField
											id='dateDisplayFormatOther'
											label={'Other date format'}
											type='text'
											handleChange={this.handleChangeColumn}
											value={dateFormatOtherValue}
										/>
										<div className='row' style={{ gridTemplateColumns: '1fr' }}><p className='syntax-info'>Syntax must be entered as described in <a href='https://docs.python.org/3/library/datetime.html#strftime-strptime-behavior' target='_blank' rel='noreferrer'>strftime() and strptime() behaviour</a></p></div>
									</div>
								</FieldSet>
							</div>
						</FieldSet> : null}

					{/* ======================================== NUMBER ======================================== */}
					{columnTypeValue === 'NUMBER' ?
						<FieldSet className={`number edit - details`}>
							<div className='grid columns4'>
								<FieldSet className={`data-source`}>
									<h3>&nbsp;</h3>
									{this.renderCommon()}
								</FieldSet>
								<FieldSet className={`data-source units`}>
									<h3>Units</h3>
									<Select
										handleChange={this.handleChangeColumn}
										id='measurements'
										label='Measurements'
										options={measurementOptions}
										value={measurement}
									/>
									<Select
										handleChange={this.handleChangeColumn}
										id='units'
										label='Units'
										options={availablePropertiesSelected.length > 0 ? units[measurement].value : []}
										value={unitsValue}
									/>
									<div
										className={`row row-compact row-supplementary row-units-review`}
										key={`row-units-review`}
									>
										{/* <label htmlFor='unitsReview'>Units review:</label> */}
										<button
											className={`review review-button`}
											key={`units-review`}
											onClick={this.handleShowUnitsReview}
										>Review</button>
									</div>
									<Select
										className={`row-transformation`}
										handleChange={this.handleChangeColumn}
										id='transformation'
										label='Transformation'
										options={transformationOptions}
										value={transformationValue}
									/>

								</FieldSet>
								<FieldSet className={`data-source endpoint-type`}>
									<h3>Endpoint type
										<Info
											infoTitle={`Endpoint type`}
											infoMessage={this.endPointTypeDescription}
											key={`endpoint-type-info`}
										/>
										<button
											className={`review review-button`}
											key={`endpoint-type-review`}
											onClick={this.handleShowEndPointTypeReview}
										>Review</button>
									</h3>
									{this.renderHidden('NUMBER')}
									{false ?
										<p>!{typeof hiddenValue}<br />
											!{hiddenValue ? 'true' : 'false'}<br />
											!hiddenValue==='Mixed'{hiddenValue === 'Mixed' ? 'true' : 'false'}<br />
											!hiddenValue{hiddenValue ? 'true' : 'false'}<br />
											!inputOnlyValue{inputOnlyValue ? 'true' : 'false'}
										</p>
										:
										null
									}
									{inputOnlyValue === 'Mixed' ?
										<Radio
											// className={`${columnTypeValue === 'NUMBER' ? '' : 'flip-colour' }`}
											disabled={hiddenValue === 'Mixed' || hiddenValue}
											handleChange={this.handleChangeColumn}
											id='inputOnly'
											label='Input Only'
											value={inputOnlyValue}
											radios={[
												{ disabled: true, name: 'Mixed', value: 'Mixed' },
												{ name: 'Yes', value: true },
												{ name: 'No', value: false }
											]}
											sameRow={true}
											title={hiddenValue === 'Mixed' || hiddenValue ? 'Cannot set input only as endpoint is hidden' : ''}
										></Radio>
										:
										<>
											<CheckBox
												// className={`data-source ${columnTypeValue !== 'NUMBER' ? 'flip-colour' : ''}`}
												className={`data-source`}
												disabled={hiddenValue === 'Mixed' || hiddenValue}
												handleChange={this.handleChangeColumn}
												id='inputOnly'
												label='Input Only'
												title={hiddenValue === 'Mixed' || hiddenValue ? 'Cannot set input only as endpoint is hidden' : ''}
												value={inputOnlyValue}
											/>
										</>
									}
									{false ?
										<>
											<p>!{typeof hiddenValue}<br />
												!{hiddenValue ? 'true' : 'false'}<br />
												!hiddenValue==='Mixed'{hiddenValue === 'Mixed' ? 'true' : 'false'}<br />
												!hiddenValue{hiddenValue ? 'true' : 'false'}
											</p>
											<p>!{typeof inputOnlyValue}<br />
												!{inputOnlyValue ? 'true' : 'false'}<br />
												!inputOnlyValue==='Mixed'{inputOnlyValue === 'Mixed' ? 'true' : 'false'}<br />
												!inputOnlyValue{inputOnlyValue ? 'true' : 'false'}
											</p>
										</>
										:
										null
									}
									{priorityValue === 'Mixed' ?
										<Radio
											// className={`${columnTypeValue === 'NUMBER' ? '' : 'flip-colour' }`}
											disabled={hiddenValue === 'Mixed' || hiddenValue || inputOnlyValue === 'Mixed' || inputOnlyValue}
											handleChange={this.handleChangeColumn}
											id='priority'
											label='Priority'
											value={priorityValue}
											radios={[
												{ disabled: true, name: 'Mixed', value: 'Mixed' },
												{ name: 'Yes', value: true },
												{ name: 'No', value: false }
											]}
											sameRow={true}
											title={hiddenValue === 'Mixed' || hiddenValue ? 'Cannot set priority as endpoint is hidden' : inputOnlyValue === 'Mixed' || inputOnlyValue ? 'Cannot set priority as endpoint is input only' : ''}
										></Radio>
										:
										<>
											<CheckBox
												// className={`data-source ${columnTypeValue !== 'NUMBER' ? 'flip-colour' : ''}`}
												className={`data-source`}
												disabled={hiddenValue === 'Mixed' || hiddenValue || inputOnlyValue === 'Mixed' || inputOnlyValue}
												handleChange={this.handleChangeColumn}
												id='priority'
												label='Priority'
												title={hiddenValue === 'Mixed' || hiddenValue ? 'Cannot set priority as endpoint is hidden' : inputOnlyValue === 'Mixed' || inputOnlyValue ? 'Cannot set priority as endpoint is input only' : ''}
												value={priorityValue}
											/>
										</>
									}
								</FieldSet>
								<FieldSet className='data-source display'>
									<h3>Display</h3>
									<Select
										handleChange={this.handleChangeColumn}
										id='display'
										label='Notation'
										options={displayOptions}
										value={displayValue}
									/>
									{availablePropertiesSelected.length > 0 && precisionValue !== 'default' ?
										<TextField
											handleChange={this.handleChangeColumn}
											id='precision'
											label='Precision'
											min={0}
											step='any'
											type={precisionValue !== 'Mixed' ? 'NUMBER' : 'TEXT'}
											value={precisionValue}
										/> : null}
								</FieldSet>
								<FieldSet className='data-source errors'>
									<h3>Errors</h3>
									<TextField
										handleChange={this.handleChangeColumn}
										id='error'
										label='Error value'
										min={0}
										step='any'
										type={errorValue !== 'Mixed' ? 'NUMBER' : 'TEXT'}
										value={errorValue}
									/>
									<Select
										handleChange={this.handleChangeColumn}
										id='errorColumn'
										label='Error column'
										options={numberColumnOptionsExcludingSelected}
										value={errorColumnValue}
									/>

									<Select
										handleChange={this.handleChangeColumn}
										id='factor'
										label='Error type'
										options={factorOptions}
										value={factorValue}
									/>

								</FieldSet>
								<FieldSet className='data-source qualifiers'>
									<h3>Qualifiers</h3>

									<Select
										handleChange={this.handleChangeColumn}
										id='qualifierColumn'
										label='Qualifier column'
										options={textColumnOptions}
										value={qualifierColumnValue}
									/>
									{appName === 'Cerella' ?
										<>
											{qualifierRuleValue === 'Mixed' ?
												<Radio
													handleChange={this.handleChangeColumn}
													id='qualifierRule'
													label='Ignore qualifiers'
													value={qualifierRuleValue}
													radios={[
														{ disabled: true, name: 'Mixed', value: 'Mixed' },
														{ name: 'Ignore', value: 'IGNORE_QUALIFIERS' },
														{ name: 'Discard', value: null }
													]}
													sameRow={true}
												></Radio>
												:
												<CheckBox
													className={`data-source`}
													handleChange={this.handleChangeColumn}
													id='qualifierRule'
													label='Ignore qualifiers'
													value={qualifierRuleValue === 'IGNORE_QUALIFIERS'}
												/>
											}
										</>
										:
										null
									}
								</FieldSet>
								{availablePropertiesSelected.length > 0 ?
									<FieldSet className='data-source range'>
										<h3>Data range</h3>
										{minimumValue !== 'default' ?
											<TextField
												handleChange={this.handleChangeColumn}
												id='minimum'
												label='Minimum'
												step='any'
												type={minimumValue !== 'Mixed' ? 'NUMBER' : 'TEXT'}
												value={minimumValue}
											/> : null}
										{maximumValue !== 'default' ?
											<TextField
												handleChange={this.handleChangeColumn}
												id='maximum'
												label='Maximum'
												step='any'
												type={maximumValue !== 'Mixed' ? 'NUMBER' : 'TEXT'}
												value={maximumValue}
											/> : null}
									</FieldSet>
									:
									null
								}
								<FieldSet className='data-source duplicate-compounds'>
									<h3>Duplicate compounds</h3>
									<Select
										handleChange={this.handleChangeColumn}
										id='mergeRule'
										label='Merge rule'
										options={mergeRuleOptions}
										value={mergeRuleValue}
									/>
								</FieldSet>
							</div>
						</FieldSet> : null}
					{/* </div > */}

					{/* ======================================== OTHER ======================================== */}
					{columnTypeValue === 'OTHER' ?
						<FieldSet className={`other edit - details`}>
							<div className='grid columns4'>
								<FieldSet className='data-source'>
									<h3>&nbsp;</h3>
									{this.renderCommon()}
								</FieldSet>
							</div>
						</FieldSet> : null}
				</div >

				<div className='command-buttons data-source form'>
					<button
						className='data-source'
						id='datasourceButtonCancel'
						onClick={handleCancelClick}
					>Cancel</button>
					{false ?
						<>
							<p>valid: {valid ? 'true' : 'false'}</p>
							<p>fileType: {fileType}</p>
							<p>previewLoaded: {previewLoaded ? 'true' : 'false'}</p>
						</>
						:
						null
					}
					<button
						className={`data-source ${(!valid || (fileType === 'connection' && !previewLoaded) || (fileType === 'cdd')) ? 'disabled' : ''} `}
						disabled={!valid || (fileType === 'connection' && !previewLoaded) || (fileType === 'cdd')}
						id={`datasourceButton${add ? 'Add' : 'Update'}`}
						onClick={() => {
							if (this.validate()) {
								handleAddUpdateDataSource(
									dataSource
									, add);
							}
						}}>{add ? 'Add' : 'Update'}</button>
				</div>
				{!done ?
					<Progress
						count={count}
						step={step}
					/>
					:
					null
				}
			</>
		);
	};

	renderCommon = () => {
		// console.log("renderCommon");
		const {
			availablePropertiesSelected = [],
			dataSource,
			searchFocusIndex, // = 0,
			searchString, // = '',
			showDisplayGroup = false,
			showDisplayGroupReview = false,
			showEndPointReview = false,
			showEndPointTypeReview = false,
			showUnitsReview = false,
			treeData = [],
		} = this.state;

		const {
			source = {},
		} = dataSource;

		const {
			availableProperties = [],
		} = source;

		const {
			dialog,
		} = this.props;

		const assayIdOptions = [];
		const groupOptions = [];
		availableProperties.forEach((ap) => {
			if (ap && ap.details && ap.details.assayId && ap.details.assayId !== '') assayIdOptions.push(ap.details.assayId);
			if (ap && ap.group && ap.group !== '') groupOptions.push(ap.group);
		});
		// Do we have any unused assayIDs that have been persisted?
		const strPersistedAssayIDs = sessionStorage.getItem('persistedAssayIds');
		if (strPersistedAssayIDs) {
			const persistedAssayIds = JSON.parse(strPersistedAssayIDs);
			if (persistedAssayIds) {
				persistedAssayIds.forEach((id) => {
					assayIdOptions.push(id);
				});
			}
		}

		const assayIdOptionsUnique = [...new Set(assayIdOptions.map(x => x))].sort();

		const assayIdOptionsUniqueObjects = assayIdOptionsUnique.map(x => { return { name: x, value: x }; });
		// console.log("10 assayIdOptionsUniqueObjects", assayIdOptionsUniqueObjects);
		assayIdOptionsUniqueObjects.unshift({ name: "None", value: '' });
		// console.log("20 assayIdOptionsUniqueObjects", assayIdOptionsUniqueObjects);

		// Column Type
		let columnTypeValue = '';
		availablePropertiesSelected.forEach((ap) => {
			if (columnTypeValue !== '' && columnTypeValue !== availableProperties[ap].columnType) {
				columnTypeValue = 'Mixed';
			} else {
				columnTypeValue = availableProperties[ap].columnType;
			}
		});
		const columnTypeOptions = [
			{ name: 'Number', value: 'NUMBER' },
			{ name: 'Structure', value: 'STRUCTURE' },
			{ name: 'Text', value: 'TEXT' },
			{ name: 'Date', value: 'DATE' },
		];
		this.addWithoutDuplicationOrRemoveMix(columnTypeOptions, columnTypeValue);

		// Column Name
		let columnNameValue = '';
		const columnNameList = [];
		availablePropertiesSelected.forEach((ap) => {
			if (columnNameValue !== '' && columnNameValue !== availableProperties[ap].columnName) {
				columnNameValue = 'Mixed';
			} else {
				columnNameValue = availableProperties[ap].columnName;
				columnNameList.push(columnNameValue);
			}
		});

		// Display Group
		let displayGroupValue = '';
		let lastDisplayGroupValue = undefined;
		availablePropertiesSelected.forEach((apIndex) => {
			displayGroupValue = '';
			if (lastDisplayGroupValue !== 'Mixed' && availableProperties[apIndex].group) {
				displayGroupValue = availableProperties[apIndex].group;
			}
			if (lastDisplayGroupValue !== undefined) {
				if (lastDisplayGroupValue !== displayGroupValue) displayGroupValue = 'Mixed';
			}
			lastDisplayGroupValue = displayGroupValue;
		});
		// this.addWithoutDuplicationOrRemoveMix(groupOptionsUniqueObjects, displayGroupValue);

		// Endpoint Group
		let endpointGroupValue = '';
		let lastEndpointGroupValue = undefined;
		availablePropertiesSelected.forEach((apIndex) => {
			endpointGroupValue = '';
			if (lastEndpointGroupValue !== 'Mixed' && availableProperties[apIndex].details.assayId) {
				endpointGroupValue = availableProperties[apIndex].details.assayId;
			}
			if (lastEndpointGroupValue !== undefined) {
				if (lastEndpointGroupValue !== endpointGroupValue) endpointGroupValue = 'Mixed';
			}
			lastEndpointGroupValue = endpointGroupValue;
		});
		this.addWithoutDuplicationOrRemoveMix(assayIdOptionsUniqueObjects, endpointGroupValue);

		// Endpoint Type

		// Hidden
		let hiddenValue = false;
		let lastHiddenValue = undefined;
		availablePropertiesSelected.forEach((apIndex) => {
			hiddenValue = false;
			if (lastHiddenValue !== 'Mixed' && availableProperties[apIndex].hidden) {
				hiddenValue = availableProperties[apIndex].hidden;
			}
			if (lastHiddenValue !== undefined) {
				if (lastHiddenValue !== hiddenValue) hiddenValue = 'Mixed';
			}
			lastHiddenValue = hiddenValue;
		});

		return (
			<>
				{showEndPointReview ?
					<div id='endpointReview'>
						< button
							title="Close"
							onClick={(e) => { this.handleShowEndpointReview(false); }}
							className='window-close' >
						</button >

						<h2>Endpoint review</h2>
						{this.endpointGroupDescription}
						{this.renderEndpointGroups()}
					</div>
					:
					null
				}
				{showDisplayGroupReview ?
					<div id='displayGroupReview'>
						< button
							title="Close"
							onClick={(e) => { this.handleShowDisplayGroupReview(false); }}
							className='window-close' >
						</button >

						<h2>Display group review</h2>
						{this.displayGroupDescription}
						{this.renderDisplayGroups()}
					</div>
					:
					null
				}
				{showEndPointTypeReview ?
					<div id='endPointTypeReview'>
						< button
							title="Close"
							onClick={(e) => { this.handleShowEndPointTypeReview(false); }}
							className='window-close' >
						</button >

						<h2>Endpoint type review</h2>
						{this.endPointTypeDescription}
						<div className={`for-scrolling`}>
							{this.renderHiddenEndpoints()}
							{this.renderInputOnlyEndpoints()}
							{this.renderPriorityEndpoints()}
						</div>
					</div>
					:
					null
				}
				{showUnitsReview ?
					<div id='unitsReview'>
						< button
							title="Close"
							onClick={(e) => { this.handleShowUnitsReview(false); }}
							className='window-close' >
						</button >

						<h2>Units review</h2>
						{this.renderUnitsReview()}
					</div>
					:
					null
				}
				{showDisplayGroup ?
					<div id='displayGroup'>
						<header>
							< button
								title="Close"
								onClick={(e) => { this.handleShowDisplayGroup(false); }}
								className='window-close' >
							</button >
						</header>

						<h2>Display groups</h2>
						{this.displayGroupDescription}

						<div className={`toolbar`}>
							<input
								id={`sortableTreeSearch`}
								onChange={e => {
									e.preventDefault();
									e.stopPropagation();
									this.setState({
										searchString: e.target.value,
									});
								}}
								placeholder='Search'
								type='text'
								value={searchString}
							/>
							< button
								onClick={(e) => {
									this.insertNewNode();
								}}
							>Add root node
							</button >
						</div>
						<div className={`sortable-tree-container`}>
							<SortableTree
								onChange={this.updateTreeData}
								onDragStateChanged={({ isDragging, draggedNode }) => {
									// console.log("onDragStateChanged", isDragging, draggedNode);
									// Make a note of the treeData
									if (isDragging) this.recordTreeState();
								}}
								onMoveNode={this.moveNode}
								searchFocusOffset={searchFocusIndex}
								searchQuery={searchString}
								treeData={treeData}

								generateNodeProps={(rowInfo) => {
									const { node, path } = rowInfo;
									return {
										title: (
											<>
												<form
													onChange={(e) => {
														// console.log("Form onChange");
														e.preventDefault();
														e.stopPropagation();
														this.selectThis(node, path);
													}}
												>
													<input
														style={{ fontSize: "1rem", width: 200 }}
														onChange={(event) => {
															// console.log("input onChange", node, "path", path);
															const title = event.target.value;
															if (title.search('/') !== -1) {
																dialog(
																	`You cannot have forward slash '/' as part of a nodename as it is part of the hierarchy encoding`,
																	'data-source',
																	'clear',
																);
															} else {
																this.setState(state => ({
																	treeData: changeNodeAtPath({
																		getNodeKey: ({ treeIndex }) => treeIndex,
																		newNode: { ...node, title },
																		path,
																		treeData: treeData,
																	}),
																}));
															}
														}}
														onFocus={(e) => {
															// console.log("FOCUS User has clicked in a title field");
															// Make a note of the treeData (for search and for undo)
															this.recordTreeState();
															this.setState({
																previousNodeTitle: node.title,
															});
														}}
														onBlur={(e) => {
															// console.log("BLUR");
															const {
																oldTreeData,
																previousNodeTitle = '',
															} = this.state;
															if (previousNodeTitle === '' || node.title === '' || node.title === previousNodeTitle) {
																// Node was a new node, no checking needed and check was flawed in this scenario
																// due to the empty string, or user cleared the field
															} else {
																this.checkForNodesInUse(
																	{
																		action: 'rename',
																		newTitle: node.title,
																		oldPath: path,
																		oldTreeData: oldTreeData,
																	},

																	// affirm
																	() => {
																		// console.log("Do something");
																		this.setState({
																			oldTreeData: null,
																		});
																	},

																	// cancel
																	() => {
																		// console.log("Do nothing/reset");
																		this.setState({
																			oldTreeData: null,
																			oldTreeIndex: null,
																			treeData: oldTreeData,
																		});
																	},
																);

																this.setState({
																	previousNodeTitle: ''
																});
															}

														}}
														value={node.title}
													/>&nbsp;&nbsp;&nbsp;
													{node.title.length > 0 ?
														<button
															className={`add-node`}
															onClick={(e) => {
																e.preventDefault();
																e.stopPropagation();
																// console.log("Add node");
																this.insertNewNode(rowInfo);
															}}
															title={`Add 'child' node`}
														>
															+
														</button>
														:
														null}
													<button
														className={`delete-node`}
														onClick={(e) => {
															e.preventDefault();
															e.stopPropagation();
															// console.log("Delete node button onClick treeData", treeData, path);
															this.removeNode(path);
														}}
														title={`Delete this node`}
													>
														-
													</button>
													{node.title.length > 0 ?
														<button
															className={`select-node`}
															onClick={(e) => {
																e.preventDefault();
																e.stopPropagation();
																let pathString = '';
																let newPath = path;

																// console.log("select-node node", node, columnNameList);

																for (let i = 0; i < path.length; i++) {
																	const { node: n } = getNodeAtPath({
																		getNodeKey: ({ treeIndex }) => treeIndex,
																		path: newPath,
																		treeData,
																	});
																	pathString = `${n.title.replace('/', '_')}${pathString !== '' ? '/' : ''}${pathString}`;
																	newPath = path.slice(0, newPath.length - 1); // Nibble nodes off the end of the path one by one
																};
																this.handleChangeColumn('group', pathString);
																this.handleShowDisplayGroup(false);
															}}
															title={`Select this node`}
														>
															&tick;
														</button>
														:
														null
													}
												</form>
												{/* {(node.endPoints && node.endPoints.length > 0) ?
													<div className={`endpoint-list`}>
														<ul>
															{this.renderEndpointList(node)}
														</ul>
													</div>
													:
													null
												} */}
											</>
										)
									};
								}}
							/>
						</div>
					</div>
					:
					null
				}

				<Select
					handleChange={this.handleChangeColumn}
					id='columnType'
					label='Data type'
					value={columnTypeValue}
					options={columnTypeOptions}
				/>
				<TextField
					className={`${columnNameValue === 'Mixed' ? 'disabled' : ''}`}
					handleChange={this.handleChangeColumn}
					id='columnName'
					label='Display name'
					value={columnNameValue}
				/>
				{columnTypeValue === 'NUMBER' ?
					<>

						<Select
							editable={true}
							handleBlur={this.handleBlur}
							handleChange={this.handleChangeColumn}
							id='assayId'
							info={{
								message: this.endpointGroupDescription,
								title: 'Endpoint group',
							}}
							label='Endpoint group'
							options={assayIdOptionsUniqueObjects}
							value={endpointGroupValue}
						/>
						<div
							className={`row row-compact row-supplementary row-endpoint-review`}
							key={`row-endpoint-review`}
						>
							{/* <label htmlFor='endpointReview'>Endpoint review:</label> */}
							<button
								className={`review review-button`}
								key={`endpoint-group-review`}
								onClick={this.handleShowEndpointReview}
							>Review</button>
						</div>
					</>
					:
					null
				}
				<div
					className={`row row-display-group ${columnTypeValue === 'NUMBER' ? 'flip-colour' : ''}`}
				>
					<label
						htmlFor={`group`}
					>Display group <Info
							infoTitle={`Display group`}
							infoMessage={this.displayGroupDescription}
							key={`display-group-info`}
						/>:</label>
					<span
						className={`fake-input-text`}
						title={displayGroupValue}
					>{displayGroupValue}</span>
				</div>
				<div
					className={`row row-compact row-display-group row-supplementary ${columnTypeValue === 'NUMBER' ? '' : 'flip-colour'}`}
				>
					<button
						className={`display-group-button set`}
						key={`display-group-button-set`}
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							this.handleShowDisplayGroup(true);
						}}
					>Set</button>

					<button
						className={`display-group-button review`}
						key={`display-group-button-review`}
						onClick={this.handleShowDisplayGroupReview}
					>Review</button>

					<button
						className={`display-group-button clear`}
						key={`display-group-button-clear`}
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							this.handleChangeColumn('group', '');
						}}
					>Clear</button>

				</div>
				{columnTypeValue !== 'NUMBER' ?
					<>
						{this.renderHidden('NOTNUMBER')}
					</>
					:
					null
				}
			</>
		);
	};

	renderHidden(columnTypeValue) {
		const {
			availablePropertiesSelected = [],
			dataSource,
		} = this.state;

		const {
			source = {},
		} = dataSource;

		const {
			availableProperties = [],
		} = source;

		// Hidden
		let hiddenValue = false;
		let lastHiddenValue = undefined;
		availablePropertiesSelected.forEach((apIndex) => {
			hiddenValue = false;
			if (lastHiddenValue !== 'Mixed' && availableProperties[apIndex].hidden) {
				hiddenValue = availableProperties[apIndex].hidden;
			}
			if (lastHiddenValue !== undefined) {
				if (lastHiddenValue !== hiddenValue) hiddenValue = 'Mixed';
			}
			lastHiddenValue = hiddenValue;
		});

		// console.log("renderHidden", hiddenValue, typeof hiddenValue);
		const info = {
			message: this.hiddenDescription,
			title: `Hidden`,
		};

		return (
			<>
				{hiddenValue === 'Mixed' ?
					<Radio
						className={`${columnTypeValue === 'NUMBER' ? '' : 'flip-colour'}`}
						handleChange={this.handleChangeColumn}
						id='hidden'
						info={columnTypeValue !== 'NUMBER' ? info : null}
						label='Hidden'
						value={hiddenValue}
						radios={[
							{ disabled: true, name: 'Mixed', value: 'Mixed' },
							{ name: 'Yes', value: true },
							{ name: 'No', value: false }
						]}
						sameRow={true}
					></Radio>
					:
					<CheckBox
						className={`data-source ${columnTypeValue !== 'NUMBER' ? 'flip-colour' : ''}`}
						handleChange={this.handleChangeColumn}
						id='hidden'
						info={columnTypeValue !== 'NUMBER' ? info : null}
						label='Hidden'
						value={hiddenValue}
					/>
				}
			</>
		);
	};

	renderCDDTokenField() {
		const {
			cddToken = '',
			cddTokenError,
			cddTokenSet = false,
		} = this.state;

		return (
			<div className={`row cdd-row text-field-row ${cddTokenError ? 'error' : ''}`}>
				<label htmlFor='cddToken'>CDD token:&nbsp;</label>
				<input
					disabled={cddTokenSet}
					id='cddToken'
					onChange={(e) => { this.handleChange('cddToken', e.target.value); }}
					placeholder='CDD token'
					type='text'
					value={cddToken}
				/>
				<button
					className={`button set-button ${cddToken === '' || cddTokenSet ? 'disabled' : ''}`}
					onClick={this.handleCDDTokenRegister}
				>Set CDD token</button>
				{cddTokenError ?
					<div className={`error`} >Please enter a valid CDD token</div>
					:
					null
				}
			</div>

		);
	};

	renderDisplayGroups = () => {
		const {
			dataSource
		} = this.state;
		if (!dataSource) return null;
		const {
			source = {},
		} = dataSource;
		const {
			availableProperties = [],
		} = source;
		const displayGroups = [];
		const displayGroupsWithSingleEndpoint = [];
		const displayGroupsWithMultipleEndpoints = [];
		const propertiesWithoutDisplayGroups = [];
		let first = true;
		availableProperties.forEach((ap) => {
			// if (ap.columnType === 'NUMBER') {
			if (ap && ap.group && ap.group !== '') {
				displayGroups.push(ap.group);
			} else {
				if (!first) propertiesWithoutDisplayGroups.push(`, `);
				propertiesWithoutDisplayGroups.push(<i
					className={`clickable`}
					key={`display-groups-properties-without-i-${ap.columnName}`}
					onClick={(e) => { this.selectEndpoint(e, ap.columnName); }}
					title={this.endpointClickHelp}
				>{ap.columnName}</i>,);
				first = false;
			}
			// }
		});
		const displayGroupsUnique = [...new Set(displayGroups.map(x => x))].sort();
		let even = false;
		displayGroupsUnique.forEach((dgu) => {
			let displayGroups = [];
			availableProperties.forEach((ap) => {
				// console.log("ap", ap);
				if (ap && ap.group && ap.group !== '' && ap.group === dgu) displayGroups.push(ap.columnName);

			});
			// console.log("displayGroups", displayGroups);
			displayGroups = displayGroups.sort();
			if (displayGroups.length > 0) {
				if (displayGroups.length === 1) {
					displayGroupsWithSingleEndpoint.push(
						<tr
							className={`${even ? 'even' : 'odd'}`}
							key={`display-groups-rows-single-${dgu}`}
						>
							<td className={`align-top`} rowSpan={displayGroups.length}>{dgu}</td>
							<td
								className={`clickable ${even ? 'even' : 'odd'}`}
								onClick={(e) => { this.selectEndpoint(e, displayGroups[0]); }}
								title={this.endpointClickHelp}
							>{displayGroups[0]}</td>
						</tr>
					);
				} else {
					let endPointEven = even;
					displayGroupsWithMultipleEndpoints.push(
						<tr
							className={`${even ? 'even' : 'odd'}`}
							key={`display-groups-rows-multiple-${dgu}`}
						>
							<td className={`align-top`} rowSpan={displayGroups.length}>{dgu}</td>
							<td
								className={`clickable ${endPointEven ? 'even' : 'odd'}`}
								onClick={(e) => { this.selectEndpoint(e, displayGroups[0]); }}
								title={this.endpointClickHelp}
							>{displayGroups[0]}</td>
						</tr>
					);
					endPointEven = !endPointEven;
					for (let i = 1; i < displayGroups.length; i++) {
						displayGroupsWithMultipleEndpoints.push(<tr
							key={`display-groups-rows-multiple-${displayGroups[i]}`}>
							<td
								className={`clickable ${endPointEven ? 'even' : 'odd'}`}
								onClick={(e) => { this.selectEndpoint(e, displayGroups[i]); }}
								title={this.endpointClickHelp}
							>{displayGroups[i]}</td></tr>);
						endPointEven = !endPointEven;
					}
				}
			}
			even = !even;
		});
		return (
			<>
				<div className={`for-scrolling`}>
					{propertiesWithoutDisplayGroups.length ?
						<>
							<h3>Properties with no display group</h3>
							<p>{propertiesWithoutDisplayGroups}</p>
						</>
						:
						null
					}
					{displayGroupsWithSingleEndpoint.length ?
						<>
							<h3>Display groups with only one property</h3>
							<table className={`data-source striped`}>
								<thead>
									<tr>
										<th>Display group</th>
										<th>Endpoint</th>
									</tr>
								</thead>
								<tbody>
									{displayGroupsWithSingleEndpoint}
								</tbody>
							</table>
						</>
						:
						null
					}
					{displayGroupsWithMultipleEndpoints.length ?
						<>
							<h3>Display groups with more than one property</h3>
							<table className={`data-source striped`}>
								<thead>
									<tr>
										<th>Display group</th>
										<th>Endpoint</th>
									</tr>
								</thead>
								<tbody>
									{displayGroupsWithMultipleEndpoints}
								</tbody>
							</table>
						</>
						:
						null
					}
				</div>
			</>
		);
	};

	renderEndpointGroups = () => {
		const {
			dataSource
		} = this.state;
		if (!dataSource) return null;
		const {
			source = {},
		} = dataSource;
		const {
			availableProperties = [],
		} = source;
		const assayIds = [];
		const endpointGroupsWithSingleEndpoint = [];
		const endpointGroupsWithMultipleEndpoints = [];
		const propertiesWithoutEndpoints = [];
		availableProperties.forEach((ap, i) => {
			if (ap.columnType === 'NUMBER') {
				if (ap && ap.details && ap.details.assayId && ap.details.assayId !== '') {
					assayIds.push(ap.details.assayId);
				} else {
					propertiesWithoutEndpoints.push(
						<>
							<span
								className={`clickable`}
								onClick={(e) => { this.selectEndpoint(e, ap.columnName); }}
								title={this.endpointClickHelp}
							>{ap.columnName}
							</span>
						</>);
					if (i < availableProperties.length - 1) propertiesWithoutEndpoints.push(<>, </>);
				}
			}
		});
		const assayIdsUnique = [...new Set(assayIds.map(x => x))].sort();
		let even = false;
		assayIdsUnique.forEach((aiu) => {
			let endPoints = [];
			availableProperties.forEach((ap) => {
				// console.log("ap", ap);
				if (ap && ap.details && ap.details.assayId && ap.details.assayId !== '' && ap.details.assayId === aiu) endPoints.push(ap.columnName);
			});
			// console.log("endPoints", endPoints);
			endPoints = endPoints.sort();
			if (endPoints.length > 0) {
				if (endPoints.length === 1) {
					endpointGroupsWithSingleEndpoint.push(
						<tr
							className={`${even ? 'even' : 'odd'}`}
							key={`endpoint-groups-rows-multiple-${aiu}`}
						>
							<td className={`align-top`} rowSpan={endPoints.length}>{aiu}</td>
							<td
								className={`clickable ${even ? 'even' : 'odd'}`}
								onClick={(e) => { this.selectEndpoint(e, endPoints[0]); }}
								title={this.endpointClickHelp}
							>{endPoints[0]}</td>
						</tr>
					);
				} else {
					let endPointEven = even;
					endpointGroupsWithMultipleEndpoints.push(
						<tr
							className={`${even ? 'even' : 'odd'}`}
							key={`endpoint-groups-rows-multiple-${aiu}`}
						>
							<td className={`align-top`} rowSpan={endPoints.length}>{aiu}</td>
							<td
								className={`clickable ${endPointEven ? 'even' : 'odd'}`}
								onClick={(e) => { this.selectEndpoint(e, endPoints[0]); }}
								title={this.endpointClickHelp}
							>{endPoints[0]}</td>
						</tr>
					);
					endPointEven = !endPointEven;
					for (let i = 1; i < endPoints.length; i++) {
						endpointGroupsWithMultipleEndpoints.push(
							<tr key={`endpoint-groups-rows-multiple-${endPoints[i]}`}>
								<td
									className={`clickable ${endPointEven ? 'even' : 'odd'}`}
									onClick={(e) => { this.selectEndpoint(e, endPoints[i]); }}
									title={this.endpointClickHelp}
								>{endPoints[i]}</td>
							</tr>);
						endPointEven = !endPointEven;
					}
				}
			}
			even = !even;
		});
		return (
			<>
				<div className={`for-scrolling`}>
					{propertiesWithoutEndpoints.length ?
						<>
							<h3>Numeric properties with no endpoint group</h3>
							<p>{propertiesWithoutEndpoints}</p>
						</>
						:
						null
					}
					{endpointGroupsWithSingleEndpoint.length ?
						<>
							<h3>Endpoint groups with only one numeric property</h3>
							<table className={`data-source striped`}>
								<thead>
									<tr>
										<th>Endpoint group</th>
										<th>Endpoint</th>
									</tr>
								</thead>
								<tbody>
									{endpointGroupsWithSingleEndpoint}
								</tbody>
							</table>
						</>
						:
						null
					}
					{endpointGroupsWithMultipleEndpoints.length ?
						<>
							<h3>Endpoint groups with more than one numeric property</h3>
							<table className={`data-source striped`}>
								<thead>
									<tr>
										<th>Endpoint group</th>
										<th>Endpoint</th>
									</tr>
								</thead>
								<tbody>
									{endpointGroupsWithMultipleEndpoints}
								</tbody>
							</table>
						</>
						:
						null
					}
				</div>
			</>
		);
	};

	renderHiddenEndpoints = () => {
		const { dataSource } = this.state;
		const { source } = dataSource;

		const endpoints = [];
		const rows = [];
		const {
			availableProperties = [],
		} = source;

		availableProperties.forEach((ap) => {
			if (ap.hidden) {
				endpoints.push(
					ap.columnName
				);
			}
		});

		endpoints.forEach((ep) => rows.push(
			<tr key={ep}>
				<td
					className={`clickable`}
					onClick={(e) => { this.selectEndpoint(e, ep); }}
					title={this.endpointClickHelp}
				>{ep}</td>
			</tr>
		));

		return (
			<>
				<h3>Hidden endpoints</h3>
				{endpoints.length > 0 ?
					<table className={`data-source striped`}>
						<thead>
							<tr>
								<th>Endpoint</th>
							</tr>
						</thead>
						<tbody>
							{rows}
						</tbody>
					</table>
					:
					<p>None</p>
				}
			</>
		);
	};

	renderInputOnlyEndpoints = () => {
		const { dataSource } = this.state;
		const { source } = dataSource;
		const { additionalMetadata = {} } = source;
		const {
			cerellaInputOnlyEndpoints
		} = additionalMetadata;
		const endpoints = [];
		if (cerellaInputOnlyEndpoints && cerellaInputOnlyEndpoints.length > 0) {
			cerellaInputOnlyEndpoints.forEach((ep) => endpoints.push(
				<tr key={ep}>
					<td
						className={`clickable`}
						onClick={(e) => { this.selectEndpoint(e, ep); }}
						title={this.endpointClickHelp}
					>{ep}</td>
				</tr>
			));
		}

		return (
			<>
				<h3>Input only endpoints</h3>
				{endpoints.length > 0 ?
					<table className={`data-source striped`}>
						<thead>
							<tr>
								<th>Endpoint</th>
							</tr>
						</thead>
						<tbody>
							{endpoints}
						</tbody>
					</table>
					:
					<p>None</p>
				}
			</>
		);
	};

	renderPriorityEndpoints = () => {
		const { dataSource } = this.state;
		const { source } = dataSource;
		const { additionalMetadata = {} } = source;
		const {
			cerellaPriorityEndpoints
		} = additionalMetadata;
		const endpoints = [];
		if (cerellaPriorityEndpoints && cerellaPriorityEndpoints.length > 0) {
			cerellaPriorityEndpoints.forEach((ep) => endpoints.push(
				<tr key={ep}>
					<td
						className={`clickable`}
						onClick={(e) => { this.selectEndpoint(e, ep); }}
						title={this.endpointClickHelp}
					>{ep}</td>
				</tr>
			));
		}

		return (
			<>
				<h3>Priority endpoints</h3>
				{endpoints.length > 0 ?
					<table className={`data-source striped`}>
						<thead>
							<tr>
								<th>Endpoint</th>
							</tr>
						</thead>
						<tbody>
							{endpoints}
						</tbody>
					</table>
					:
					<p>None</p>
				}
			</>
		);
	};

	// renderEndpointList = (node) => {
	// 	// console.log("renderEndpointList", node);
	// 	const listItems = [];
	// 	if (node && node.endPoints) {
	// 		node.endPoints.forEach((ep) => {
	// 			listItems.push(<li>{ep}</li>);
	// 		});
	// 	}
	// 	return listItems;
	// };

	renderSpreadSheet = () => {
		// console.log("renderSpreadSheet");
		const {
			availablePropertiesSelected = [],
			dataSource,
			loading = false,
		} = this.state;

		const {
			data,
			source = {},
		} = dataSource;

		const {
			availableProperties = [],
		} = source;

		const tableHeader = [];

		// ID column
		if (availableProperties && availableProperties.length) {
			tableHeader.push(
				<th key={`headerRowNumber`}></th >
			);
			availableProperties.forEach((item, i) => {
				tableHeader.push(
					<th
						key={`header${i}`}
						className={`${(availablePropertiesSelected.includes(i)) ? 'selected' : ''}`}
						title='Click column to edit settings'
						onClick={(e) => {
							this.selectColumn(e, i);
						}}
					>{item.columnName}</th >
				);
			});
		}

		const tableRows = [];
		if (data) {

			const nColumns = data[0].length + 1; // For ID column added
			data.forEach((row, i) => {
				if (i > 0 && row.length > 1) {
					const rowCells = [];
					rowCells.push(<td key={`row${i} rownumber`} className='row-number'>{i}</td >);

					for (let j = 0; j < nColumns - 1; j++) {
						let cellOut = '';
						if (row[j]) {
							cellOut = row[j];
						}
						rowCells.push(<td
							key={`row${i} column${j} `}
							className={`${(availablePropertiesSelected.includes(j)) ? 'selected' : ''}`}
							title='Click column to edit settings'
							onClick={(e) => {
								this.selectColumn(e, j);
							}
							}
						> {cellOut}</td >);

					}
					tableRows.push(<tr key={`row${i} `}>{rowCells}</tr >);
				}
			});
		}
		return (
			<>
				<table className='data-source'>
					<thead>
						<tr>{tableHeader}</tr>
					</thead>
					{tableRows.length > 0 ?
						<tbody>
							{tableRows}
						</tbody>
						:
						null
					}
				</table >
				{tableRows.length === 0 ?
					<h3 className='no-data-loaded'>No data loaded</h3>
					:
					null
				}
				<div id='loadState' className={`${loading ? 'show' : ''} `}><h1>Loading...</h1></div>
			</>
		);
	};

	renderUnitsReview = () => {
		const {
			dataSource,
		} = this.state;
		if (!dataSource) return null;
		const {
			source = {},
		} = dataSource;
		const {
			availableProperties = [],
		} = source;
		const allUnits = [];
		const unitsWithSingleEndpoint = [];
		const unitsWithMultipleEndpoints = [];
		const propertiesWithoutUnits = [];

		let even = false;
		availableProperties.forEach((ap) => {
			if (ap.columnType === 'NUMBER') {
				if (ap && ap.details && ap.details.units && ap.details.units !== 'OTHER') {
					allUnits.push(ap.details.units);
				} else {
					const transform = this.getTransform(ap.columnName);
					propertiesWithoutUnits.push(<tr
						className={`${even ? 'even' : 'odd'}`}
						key={`unitless-rows-${ap.columnName}`}
					>
						<td
							className={`clickable ${even ? 'even' : 'odd'}`}
							onClick={(e) => { this.selectEndpoint(e, ap.columnName); }}
							title={this.endpointClickHelp}
						>{ap.columnName}</td>
						<td className={`transform ${even ? 'even' : 'odd'}`}>{transform}</td>
					</tr>
					);
					even = !even;
				}
			}
		});
		const unitsUnique = [...new Set(allUnits.map(x => x))].sort();
		even = false;
		unitsUnique.forEach((uu) => {
			let sortedUnits = [];
			availableProperties.forEach((ap) => {
				// console.log("ap", ap);
				if (ap && ap.details && ap.details.units && ap.details.units !== '' && ap.details.units === uu) sortedUnits.push(
					{
						name: ap.columnName,
						transform: this.getTransform(ap.columnName),
					}
				);
			});
			// console.log("sortedUnits", sortedUnits);
			sortedUnits = sortedUnits.sort();
			let keyUnique = 0;
			if (sortedUnits.length > 0) {
				const friendlyUnits = this.friendlyUnitsString(uu);
				// console.log("#friendlyUnits", friendlyUnits);
				if (sortedUnits.length === 1) {
					unitsWithSingleEndpoint.push(
						<tr
							className={`${even ? 'even' : 'odd'}`}
							key={`unit-rows-single-${friendlyUnits}-${keyUnique}`}
						>
							<td className={`align-top`} rowSpan={sortedUnits.length}>{friendlyUnits}</td>
							<td
								className={`clickable ${even ? 'even' : 'odd'}`}
								onClick={(e) => { this.selectEndpoint(e, sortedUnits[0].name); }}
								title={this.endpointClickHelp}
							>{sortedUnits[0].name}</td>
							<td className={`transform ${even ? 'even' : 'odd'}`}>{sortedUnits[0].transform}</td>
						</tr>
					);
					keyUnique++;
				} else {
					let endPointEven = even;
					unitsWithMultipleEndpoints.push(
						<tr
							className={`${even ? 'even' : 'odd'}`}
							key={`unit-rows-multiple-${friendlyUnits}-${keyUnique}`}
						>
							<td className={`align-top`} rowSpan={sortedUnits.length}>{friendlyUnits}</td>
							<td
								className={`clickable ${endPointEven ? 'even' : 'odd'}`}
								onClick={(e) => { this.selectEndpoint(e, sortedUnits[0].name); }}
								title={this.endpointClickHelp}
							>{sortedUnits[0].name}</td>
							<td className={`transform ${endPointEven ? 'even' : 'odd'}`}>{sortedUnits[0].transform}</td>
						</tr>
					);
					endPointEven = !endPointEven;
					keyUnique++;
					for (let i = 1; i < sortedUnits.length; i++) {
						unitsWithMultipleEndpoints.push(
							<tr
								className={`${even ? 'even' : 'odd'}`}
								key={`unit-rows-multiple-${friendlyUnits}-${keyUnique}`}
							>
								<td
									className={`clickable ${endPointEven ? 'even' : 'odd'}`}
									onClick={(e) => { this.selectEndpoint(e, sortedUnits[i].name); }}
									title={this.endpointClickHelp}
								>{sortedUnits[i].name}</td>
								<td className={`transform ${endPointEven ? 'even' : 'odd'}`}>{sortedUnits[i].transform}</td>
							</tr>
						);
						endPointEven = !endPointEven;
						keyUnique++;
					}
				}
			}
			even = !even;
		});
		return (
			<>
				<div className={`for-scrolling`}>
					{propertiesWithoutUnits.length ?
						<>
							<h3>Numeric properties with no units set</h3>
							<table className={`data-source striped`}>
								<thead>
									<tr>
										<th>Endpoint</th>
										<th>Transformation</th>
									</tr>
								</thead>
								<tbody>
									{propertiesWithoutUnits}
								</tbody>
							</table>
						</>
						:
						null
					}
					{unitsWithSingleEndpoint.length ?
						<>
							<h3>Units with only one numeric property</h3>
							<table className={`data-source striped`}>
								<thead>
									<tr>
										<th>Units</th>
										<th>Endpoint</th>
										<th>Transformation</th>
									</tr>
								</thead>
								<tbody>
									{unitsWithSingleEndpoint}
								</tbody>
							</table>
						</>
						:
						null
					}
					{unitsWithMultipleEndpoints.length ?
						<>
							<h3>Units with more than one numeric property</h3>
							<table className={`data-source striped`}>
								<thead>
									<tr>
										<th>Units</th>
										<th>Endpoint</th>
										<th>Transformation</th>
									</tr>
								</thead>
								<tbody>
									{unitsWithMultipleEndpoints}
								</tbody>
							</table>
						</>
						:
						null
					}
				</div>
			</>
		);
	};

	selectColumn = (e, columnIndex) => {
		const {
			ctrlKey,
			shiftKey,
		} = e;
		let {
			availablePropertiesSelected = [],
			lastAnchor,
		} = this.state;
		// console.log("selectColumn e", e, "columnIndex", columnIndex, "ctrlKey", ctrlKey, "shiftKey", shiftKey, "lastAnchor", lastAnchor);

		if (!ctrlKey) {
			// Clear selection, but if shiftKey, following block will deal with it
			// console.log("Clear selection first");
			availablePropertiesSelected = [];
		}
		if (shiftKey) {
			if (columnIndex > lastAnchor) {
				for (let i = lastAnchor; i <= columnIndex; i++) {
					// console.log("Add ", i);
					availablePropertiesSelected.push(i);
				}
			} else if (columnIndex < lastAnchor) {
				for (let i = lastAnchor; i >= columnIndex; i--) {
					// console.log("Add ", i);
					availablePropertiesSelected.push(i);
				}
			}
		} else if (ctrlKey) {
			const foundIndex = availablePropertiesSelected.findIndex((a) => a === columnIndex);
			if (foundIndex === -1) {
				// console.log("Just add ", columnIndex);
				availablePropertiesSelected.push(columnIndex);
			} else {
				// console.log("Just remove ", columnIndex);
				availablePropertiesSelected.splice(foundIndex, 1);
			}
		} else {
			// console.log("New selection ", columnIndex);
			availablePropertiesSelected = [columnIndex];
			lastAnchor = columnIndex;
		}

		this.setState({
			availablePropertiesSelected: availablePropertiesSelected,
			lastAnchor: lastAnchor,
		});
	};

	selectEndpoint = (e, endPointColumnName) => {
		// console.log("selectEndpoint endPointColumnName:", endPointColumnName);
		const {
			dataSource
		} = this.state;
		const { source = {} } = dataSource;
		const { availableProperties = [] } = source;

		const foundIndex = availableProperties.findIndex((ap) => ap.columnName === endPointColumnName);
		this.setState({
			showDisplayGroup: false,
			showDisplayGroupReview: false,
			showEndPointReview: false,
			showEndPointTypeReview: false,
			showUnitsReview: false,
		}, () => foundIndex !== -1 ? this.selectColumn(e, foundIndex) : null);
	};

	selectThis = (node, path) => {
		// console.log("selectThis node", node, "path", path);
		this.setState({ currentNode: node, path: path });
	};

	updateTreeData = (treeData) => {
		this.setState({
			treeData: treeData,
		});
	};

	validate = () => {
		// console.log("validate");
		const {
			cddTokenSet = false,
			JSONloaded,
			dataFileLoaded,
			fileType = 'json',
			dataSource,
			previewLoaded,
			structureColumnOptions,
			textColumnOptions,
			vaultID = '',
		} = this.state;

		const {
			secondaryNav,
		} = this.props;

		const {
			connection = '',
			source,
			template: sql,
			type,
		} = dataSource;

		let cartridgeColumnError = false;
		let chemistryColumnError = false;
		let connectionError = false;
		let dataFileError = '';
		let idColumnError = false;
		let JSONfileError = '';
		let sqlError = false;
		let valid = true;

		if (type === 'DATABASE' || fileType === 'connection') {
			if (connection === '') {
				connectionError = true;
				valid = false;
			}
			if (!sql || sql === '') {
				sqlError = true;
				valid = false;
			}
		}

		if (fileType === 'cdd' || type === 'CDD') {
			valid = false;
			if (secondaryNav === 'add' && cddTokenSet && vaultID !== '') valid = true;
			if (secondaryNav === 'edit') valid = true;
		}

		if (source && (JSONloaded || dataFileLoaded || previewLoaded || secondaryNav === 'edit')) {
			const {
				chemistryColumn = 'none',
				cartridge = 'none',
				cartridgeColumn,
				idColumn = 'none',
			} = source;

			if (!idColumn || idColumn === 'none' || textColumnOptions.findIndex((item) => item.value === idColumn) < 0) {
				idColumnError = true;
				valid = false;
			}
			if (!chemistryColumn || chemistryColumn === 'none' || structureColumnOptions.findIndex((item) => { return (item.value === chemistryColumn); }) < 0) {
				chemistryColumnError = true;
				valid = false;
			}
			if (!cartridgeColumn && (cartridge !== 'none' && cartridge !== '' && cartridge !== null)) cartridgeColumnError = true;
		}

		if (!this.validateName()) valid = false;

		if (secondaryNav === 'add') {
			if (!dataFileLoaded && fileType === 'datafile' && valid /* Not until name is valid first */) {
				valid = false;
				dataFileError = 'Please select a data file';
			}

			if (type !== 'DATABASE' && !JSONloaded && fileType === 'json' && valid /* Not until name is valid first */) {
				valid = false;
				JSONfileError = 'Please load a JSON file';
			}
		}

		this.setState({
			JSONfileError,
			cartridgeColumnError,
			chemistryColumnError: chemistryColumnError,
			connectionError: connectionError,
			dataFileError,
			idColumnError: idColumnError,
			sqlError: sqlError,
			valid: valid,
		});

		return valid;
	};

	validateName = (passedName) => {
		// console.log("validateName", passedName);
		let name = '';
		let nameError = '';

		const {
			batches,
			dataSources,
			secondaryNav,
		} = this.props;

		let nameValid = true;
		if (passedName === undefined) {
			({ name } = this.state);
		} else {
			name = passedName;
		}

		name = name.trim(name);

		// Belt and braces check for unique name. Server should also do this
		if (name === '') {
			nameError = 'Please enter a name';
			nameValid = false;
		} else if (!this.isAlphaNumeric(name)) {
			nameError = `Please use alphanumeric or '_' or '-'`;
			nameValid = false;
		} else {
			if (dataSources) dataSources.forEach((dataSource) => {
				if (dataSource.name === name && secondaryNav === 'add') {
					nameError = 'Name has to be unique';
					nameValid = false;
				}
			});
			if (batches) batches.forEach((batch) => {
				if (batch.name === name && secondaryNav === 'add') {
					nameError = 'Name has to be unique';
					nameValid = false;
				}
			});
		}

		if (name.indexOf(' ') > -1 || name.indexOf('\t') > -1) {
			nameError = 'Please enter a name with no spaces or tabs';
			nameValid = false;
		}

		this.setState({
			nameError: nameError,
			nameValid,
		});
		return nameValid;
	};
}
