import './Logo.scss';
import idtLogo from '../../images/idea_tracker_logo_white.png';
import React from 'react';

export class Logo extends React.PureComponent {

	render() {
		const { appName = 'Cerella' } = this.props;
		return (
			<>
				{ appName === 'Cerella' ?
					<div id='logo'>
						<div className='circle cerella-green large' >
							<div className='circle white double w51' />
							<div className='line l20' >
								<div className='circle white double' />
							</div>
						</div>
						<div className='circle cerella-mauve large'>
							<div className='circle white w46' />
							<div className='circle white w47' />
							<div className='line l9' >
								<div className='circle white double' />
								<div className='circle white double' />
							</div>
							<div className='line l10' >
								<div className='circle white double' />
							</div>
							<div className='line l22' >
								<div className='circle white double' />
								<div className='circle white double' />
							</div>
							<div className='circle white double w52' />
							<div className='circle white double w53' />

						</div>
						<div className='circle cerella-cyan small' />
						<div className='circle cerella-green small' >
							<div className='circle white w43' />
							<div className='line l14'>
								<div className='circle white double' />
								<div className='circle white double' />
							</div>
							<div className='line l21'>
								<div className='circle white double' />
							</div>
						</div>
						<div className='circle cerella-orange large' >
							<div className='circle white double w50' />
							<div className='line l15'>
								<div className='circle white double' />
								<div className='circle white double' />
							</div>
							<div className='line l16'>
								<div className='circle white double' />
							</div>
							<div className='line l17' />
							<div className='line l18' />
							<div className='line l19' />
						</div>
						<div className='circle cerella-yellow large' >
							<div className='line l12'>
								<div className='circle white double' />
								<div className='circle white double' />
							</div>
						</div>
						<div className='circle cerella-yellow small' />
						<div className='circle cerella-cyan large' >
							<div className='circle white w18 double' />
							<div className='circle white w49 double' />
							<div className='line l8' >
								<div className='circle white double' />
								<div className='circle white double' />
							</div>
							<div className='line l11' >
								<div className='circle white double' />
							</div>
							<div className='circle white w34 double' />
							<div className='line l23' >
								<div className='circle white double' />
								<div className='circle white double' />
							</div>

						</div>
						<div className='circle cerella-orange small' />



						<div className='circle white w1' />
						<div className='circle white w2 double' />
						<div className='circle white w3' />
						<div className='circle white w4' />


						<div className='circle white w5 double' />
						<div className='circle white w6 double' />
						<div className='circle white w7 double' />
						<div className='circle white w8 double' />
						<div className='circle white w9 double' />
						<div className='circle white w10 double' />
						<div className='circle white w11 double' />

						<div className='circle white w12 double' />
						<div className='circle white w13 double' />
						<div className='circle white w14 double' />
						<div className='circle white w15 double' />
						<div className='circle white w16 double' />
						<div className='circle white w17 double' />

						<div className='circle white w19 double' />
						<div className='circle white w20 double' />
						<div className='circle white w21 double' />
						<div className='circle white w22 double' />
						<div className='circle white w23 double' />
						<div className='circle white w24 double' />
						<div className='circle white w25 double' />
						<div className='circle white w26 double' />
						<div className='circle white w27 double' />

						<div className='circle white w28 double' />
						<div className='circle white w29 double' />
						<div className='circle white w30 double' />
						<div className='circle white w31 double' />
						<div className='circle white w32 double' />
						<div className='circle white w33 double' />
						<div className='circle white w35 double' />
						<div className='circle white w36 double' />
						<div className='circle white w37 double' />

						<div className='circle white w38 double' />
						<div className='circle white w39 double' />
						<div className='circle white w40 double' />
						<div className='circle white w41 double' />
						<div className='circle white w42 double' />


						<div className='circle white w44' />
						<div className='circle white w45' />
						<div className='circle white w48 double' />

						<div className='line l1' >
							<div className='circle white double' />
							<div className='circle white double' />
						</div>

						<div className='line l2' >
							<div className='circle white double' />
						</div>

						<div className='line l3' >
							<div className='circle white double' />
						</div>

						<div className='line l4' >
							<div className='circle white double' />
						</div>

						<div className='line l5' >
							<div className='circle white double' />
						</div>

						<div className='line l6' >
							<div className='circle white double' />
						</div>

						<div className='line l7' >
							<div className='circle white double' />
							<div className='circle white double' ><div className='line' /></div>
						</div>
					</div>
					:
					<img alt={`logo`} id='idtLogo' src={idtLogo} />
				}
			</>
		);
	}
}
