import './PagedContent.scss';
import {
	actionList,
	hasPolicy,
} from '../../utility';
import {
	PageControl,
	PreFilter,
	Progress,
	RecordCount,
	SearchBox,
	SecureDownload,
	SortButton,
	TristateCheckBox,
} from '../../Components';
import React from 'react';
import skull from '../../images/skull.svg';

export class PagedContent extends React.Component {

	constructor(props) {
		super(props);

		this.state = ({
			allOnPageSelected: false,
			pageNum: 0,
			sort: 'name',
			sortAscending: true,
		});

		this.getDataSourceCells = this.getDataSourceCells.bind(this);
		this.getGroupCells = this.getGroupCells.bind(this);
		this.getPolicyCells = this.getPolicyCells.bind(this);
		this.handleChangePage = this.handleChangePage.bind(this);
		this.handleChangeRecordCount = this.handleChangeRecordCount.bind(this);
		this.handleChangeSort = this.handleChangeSort.bind(this);
		this.handleToggleAll = this.handleToggleAll.bind(this);
		this.listRecordsOnPage = this.listRecordsOnPage.bind(this);
		this.renderDataSources = this.renderDataSources.bind(this);
		this.renderGroups = this.renderGroups.bind(this);
		this.renderUsers = this.renderUsers.bind(this);
	};

	componentDidMount = () => {
		const { what } = this.props;
		// console.log("componentDidMount", what);
		if (sessionStorage.getItem(`Cerella-IDT-UI-recordCount-${what}`)) {
			const recordCount = parseInt(JSON.parse(sessionStorage.getItem(`Cerella-IDT-UI-recordCount-${what}`)));
			this.setState({
				recordCount: recordCount,
			},
			() => this.listRecordsOnPage(what)
			);
		} else {
			this.setState({
				recordCount: 12,
			}, () => {
				sessionStorage.setItem(`Cerella-IDT-UI-recordCount-${what}`, 12);
				this.listRecordsOnPage(what);
			});
		}
	};

	getGroupCells = (userid) => {
		const groupCells = [];
		const {
			adminGroupName,
			getIntersectionTitle,
			getIntersectionValue,
			handleClickIntersection,
			userName,
		} = this.props;

		let {
			groups = [],
		} = this.props;

		groups = groups.sort((a, b) => a.name - b.name);
		groups.forEach((group, i) => {
			try {
				const value = getIntersectionValue('user', userid, 'group', group.id);
				const title = getIntersectionTitle('user', userid, 'group', group.id);
				if (group.name === adminGroupName && userid === userName && value) {
					groupCells.push(
						<td key={`group${group.id}`} className='checkbox group' onClick={(e) => { e.stopPropagation(); }}>
							<TristateCheckBox
								id={`user${userid}Group${group.id}`}
								title={`You can't remove yourself as an administrator`}
								value={3}
							/>
						</td>
					);

				} else {
					groupCells.push(
						<td key={`group${group.id}`} className='checkbox group' onClick={(e) => { e.stopPropagation(); }}>
							<TristateCheckBox
								id={`user${userid}Group${group.id}`}
								value={value}
								title={title}
								handleChange={() => {
									handleClickIntersection('user', userid, 'group', group.id, '');
								}}
							/>
						</td>
					);
				}
			} catch (err) {
				groupCells.push(
					<td key={`group${group.id}`}
						className='checkbox group'
					>
					</td>
				);

			}
		});
		return groupCells;
	};

	getPolicyCells = (what, id, policies) => {
		const {
			getIntersectionTitle,
			getIntersectionValue,
			handleClickIntersection,
			superUI,
		} = this.props;
		const policyCells = [];

		if (!superUI) return;

		// for (const service in sortObjectByKeys(policies)) {
		// 	for (const target in sortObjectByKeys(policies[service])) {
		for (const service in policies) {
			for (const target in policies[service]) {
				const actions = policies[service][target];
				// actions.sort().forEach((action) => {
				actions.forEach((action) => {
					policyCells.push(
						<td
							key={`policy${service}${target}${action}`}
							className='checkbox policy'
							onClick={(e) => { e.stopPropagation(); }}
						>
							<TristateCheckBox
								id={`${what}${id}Policy${service}${target}${action}`}
								value={getIntersectionValue(what, id, 'policy', service, target, action)}
								title={getIntersectionTitle(what, id, 'policy', service, target, action)}
								handleChange={() => {
									handleClickIntersection(what, id, 'policy', service, target, action);
								}}
							/>
						</td >
					);
				});
			}
		}

		return policyCells;
	};

	getDataSourceCells = (what, id, dataSources) => {
		const {
			getGroupById,
			getUserById,
			handleClickIntersection,
			superUI,
		} = this.props;
		const dataSourceCells = [];

		dataSources = dataSources.sort((a, b) => a.name - b.name);
		dataSources.forEach((dataSource, i) => {
			for (const action of actionList) {
				if (superUI || action === 'read') {
					// Inheritance is a ballache
					let value = false;
					let title = '';

					if (what === 'user') {
						const user = getUserById(id);

						// Check for explicit user access
						if (hasPolicy(user, 'query_interface', dataSource.id, action.toUpperCase())) {
							value = true;
							title = `User has datasource ${action} access permission`;
						}

						// Second check against user global inheritance
						if (hasPolicy(user, 'query_interface', 'data_sources', action.toUpperCase())) {
							value = 3;
							title = `User inherits datasource ${action} access from user global permissions`;
						}

						// console.log("user groups global inheritance and user groups inheritance");
						user.groups.forEach((id) => {
							const group = getGroupById(id);

							// Third check against group explicit inheritance
							if (hasPolicy(group, 'query_interface', dataSource.id, action.toUpperCase())) {
								value = 3;
								title = `User inherits datasource ${action} access from group '${id}' permission`;
							}

							// Fourth check against group global inheritance
							if (hasPolicy(group, 'query_interface', 'data_sources', action.toUpperCase())) {
								value = 3;
								title = `User inherits datasource ${action} access from group '${id}' global permissions`;
							}

						});
					}
					if (what === 'group') {
						// const AAAuser = AAA.users.get(id);
						const group = getGroupById(id);

						// Check for explicit group access
						if (hasPolicy(group, 'query_interface', dataSource.id, action.toUpperCase())) {
							value = true;
							title = `Group has datasource ${action} access permission`;
						}

						// Second check against group global inheritance
						if (hasPolicy(group, 'query_interface', 'data_sources', action.toUpperCase())) {
							value = 3;
							title = `Group inherits datasource ${action} access from group global permissions`;
						}
					}

					dataSourceCells.push(
						<td key={`data-source-${dataSource.id}-${action}`} className='checkbox data-source' onClick={(e) => { e.stopPropagation(); }}>
							<TristateCheckBox
								id={`${what}-${id}-DataSource-${dataSource.id}`}
								value={value}
								title={title}
								handleChange={() => {
									handleClickIntersection(what, id, 'data-source', 'query_interface', dataSource.id, action);
								}}
							/>
						</td>
					);
				}
			}
		});
		return dataSourceCells;
	};

	handleChangePage = (what, pageNum) => {
		// console.log("PagedContent handleChangePage", what, pageNum);
		this.setState({
			pageNum: pageNum,
		}, () => this.listRecordsOnPage(what)
		);
	};

	handleChangeRecordCount = (what, recordCount) => {
		this.setState({
			pageNum: 0,
			recordCount: recordCount,
		},
		() => {
			sessionStorage.setItem(`Cerella-IDT-UI-recordCount-${what}`, recordCount);
			this.listRecordsOnPage(what);
		});
	};

	handleChangeSearch = (what, searchTerm) => {
		const {
			handleChangeSearch
		} = this.props;
		this.setState({
			pageNum: 0,
			searchTerm: searchTerm,
		},
		() => {
			handleChangeSearch(what, searchTerm, () => this.listRecordsOnPage(what));
		}
		);
	};

	handleChangeSort = (what, field) => {
		let {
			sort,
			sortAscending,
		} = this.state;

		if (sort === field) {
			sortAscending = !sortAscending;
		} else {
			sort = field;
			sortAscending = true;
		}
		this.setState({
			sort: sort,
			sortAscending: sortAscending,
		},
		() => this.listRecordsOnPage(what)
		);
	};

	handleToggleAll = (what) => {
		const { handleToggleAll } = this.props;
		const {
			allOnPageSelected,
			listToSet,
		} = this.state;
		if (allOnPageSelected) {
			handleToggleAll(what, []); // Empty list so everything gets unselected
			this.setState({
				allOnPageSelected:false,
			});
		} else {
			handleToggleAll(what, listToSet); // unselect everything not in the list, select everything in the list
			this.setState({
				allOnPageSelected:true,
			});
		}
	};

	listRecordsOnPage = (what) => {
		const {
			pageNum,
			recordCount,
			sort,
			sortAscending,
		} = this.state;

		// console.log("listRecordsOnPage", pageNum);
		// Note what is on page and what is selected
		const listToSet = [];
		let allOnPageSelected = true;
		switch (what) {
			case 'user':
				const {
					users,
					userName,
				} = this.props;

				let sortedUsers;
				if (sortAscending) {
					sortedUsers = users.sort((a, b) => (a[sort].toLowerCase() > b[sort].toLowerCase()) ? 1 : -1);
				} else {
					sortedUsers = users.sort((a, b) => (a[sort].toLowerCase() <= b[sort].toLowerCase()) ? 1 : -1);
				}

				const sortedUsersLength = sortedUsers.length;
				for (let i = pageNum * recordCount; i < (pageNum + 1) * recordCount; i++) {
					const user = sortedUsers[i];
					if (i < sortedUsersLength) {
						const {
							id,
							selected = false,
						} = user;
						if (!selected && id !== userName) allOnPageSelected = false;
						if(user.id !== userName)listToSet.push(id);
					}
				}
				break;
			case 'group':
				const {
					groups,
				} = this.props;
				let sortedGroups;
				if (sortAscending) {
					sortedGroups = groups.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
				} else {
					sortedGroups = groups.sort((a, b) => (a.name.toLowerCase() <= b.name.toLowerCase()) ? 1 : -1);
				}

				for (let i = pageNum * recordCount; i < (pageNum + 1) * recordCount; i++) {
					const group = sortedGroups[i];
					if (i < sortedGroups.length) {
						const {
							id,
							selected = false,
						} = group;
						if (!selected) allOnPageSelected = false;
						listToSet.push(id);
					}
				}
				break;
			case 'batch':
			case 'data-source':
				const {
					filteredDataSourcesAndBatches,
				} = this.props;
				let sortedDataSources;
				if (sortAscending) {
					sortedDataSources = filteredDataSourcesAndBatches.sort((a, b) => {
						if (a[sort].toLowerCase() === b[sort].toLowerCase()) {
							return a.id > b.id ? 1 : -1; // Use id to keep rows stable within the chosen sort.
						} else {
							return a[sort].toLowerCase() > b[sort].toLowerCase() ? 1 : -1;
						}
					});
				} else {
					sortedDataSources = filteredDataSourcesAndBatches.sort((a, b) => {
						if (a[sort].toLowerCase() === b[sort].toLowerCase()) {
							return a.id <= b.id ? 1 : -1; // Use id to keep rows stable within the chosen sort.
						} else {
							return a[sort].toLowerCase() <= b[sort].toLowerCase() ? 1 : -1;
						}
					});
				}

				for (let i = pageNum * recordCount; i < (pageNum + 1) * recordCount; i++) {
					const dataSource = sortedDataSources[i];
					if (i < sortedDataSources.length) {
						const {
							id,
							selected = false,
						} = dataSource;
						if (!selected) allOnPageSelected = false;
						listToSet.push(id);
					}
				}
				break;
			default:
		}
		this.setState({
			allOnPageSelected:allOnPageSelected,
			listToSet: listToSet,
		});
	};

	renderUsers = () => {

		const {
			dataSources,
			grantDataSourcesList,
			grantGroupForUserList,
			grantGroupList,
			grantPolicyList,
			groups,
			handleRowClick,
			handleSelect,
			handleToggleActive,
			policies,
			superUI,
			userName,
			users,
		} = this.props;

		const {
			pageNum,
			recordCount,
			sort,
			sortAscending,
		} = this.state;

		const rows = [];

		let sortedUsers;
		if (sortAscending) {
			sortedUsers = users.sort((a, b) => (a[sort].toLowerCase() > b[sort].toLowerCase()) ? 1 : -1);
		} else {
			sortedUsers = users.sort((a, b) => (a[sort].toLowerCase() <= b[sort].toLowerCase()) ? 1 : -1);
		}

		const sortedUsersLength = sortedUsers.length;
		for (let i = pageNum * recordCount; i < (pageNum + 1) * recordCount; i++) {
			const user = sortedUsers[i];
			if (i < sortedUsersLength) {
				const {
					active,
					id,
					selected = false,
				} = user;
				const groupCells = this.getGroupCells(id, groups);
				const policyCells = this.getPolicyCells('user', id, policies);
				const dataSourceCells = this.getDataSourceCells('user', id, dataSources);
				// console.log(grantPolicyList, grantDataSourcesList);
				rows.push(
					<tr key={`users${i}`} onClick={() => { handleRowClick('user', id); }}>
						<td className='checkbox user'>
							{id !== userName ?
								<TristateCheckBox
									id={`delete${id}`}
									value={selected}
									handleChange={() => { handleSelect('user', user.id, () => this.listRecordsOnPage('user')); }} />
								:
								null
							}
						</td>
						<td>{id}</td>
						<td className='active checkbox'>
							{user.id === userName ?
								<TristateCheckBox
									id={id}
									title={`You can't make yourself inactive`}
									value={3}
								/>
								:
								<TristateCheckBox
									id={id}
									value={active}
									handleChange={(id) => { handleToggleActive(id); }}
								/>
							}
						</td>
						<td className='spacer spacer1'></td>
						{grantGroupList && grantGroupForUserList ? groupCells : null}
						{superUI && grantGroupList && grantGroupForUserList && grantPolicyList ?
							<td className='spacer spacer1'></td> : null}
						{superUI && grantPolicyList ? policyCells : null}
						{grantDataSourcesList ?
							<td className='spacer spacer2'></td> : null}
						{grantDataSourcesList ? dataSourceCells : null}
					</tr >
				);
			} else {
				break;
			}
		}

		return rows;
	};

	renderGroups = () => {

		const {
			dataSources,
			grantDataSourcesList,
			grantPolicyList,
			groups,
			handleRowClick,
			handleSelect,
			policies,
			superUI,
		} = this.props;

		const {
			pageNum,
			recordCount,
			sortAscending,
		} = this.state;

		const rows = [];
		if (superUI) {
			let sortedGroups;
			if (sortAscending) {
				sortedGroups = groups.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
			} else {
				sortedGroups = groups.sort((a, b) => (a.name.toLowerCase() <= b.name.toLowerCase()) ? 1 : -1);
			}

			for (let i = pageNum * recordCount; i < (pageNum + 1) * recordCount; i++) {
				const group = sortedGroups[i];
				if (i < sortedGroups.length) {
					const policyCells = this.getPolicyCells('group', group.id, policies);
					const dataSourceCells = this.getDataSourceCells('group', group.id, dataSources);
					rows.push(
						<tr key={`group-rows-${i}`}
							onClick={() => { if (group.name !== 'Administrator') handleRowClick('group', group.id); }}>
							<td className='checkbox group' >
								<TristateCheckBox
									handleChange={() => { handleSelect('group', group.id, () => this.listRecordsOnPage('group')); }}
									id={`delete${group.id}`}
									value={group.name === 'Administrator' ? 3 : group.selected}
								/>
							</td >
							<td>{group.name}</td>
							{grantPolicyList ? <td className='spacer spacer1'></td> : null}
							{grantPolicyList ? policyCells : null}
							{grantDataSourcesList ? <td className='spacer spacer2'></td> : null}
							{grantDataSourcesList ? dataSourceCells : null}
						</tr >
					);
				}

			}

			return rows;
		} else {
			return null;
		}
	};

	renderDataSources = () => {

		const {
			aaaKey,
			appName,
			dialog,
			filteredDataSourcesAndBatches,
			handleRowClick,
			handleSelect,
			handleShowSpinner,
			ideaTrackerService,
			logError,
			queryInterfaceEndpoint,
		} = this.props;

		const {
			pageNum,
			recordCount,
			sort,
			sortAscending,
		} = this.state;

		const rows = [];
		const today = new Date();
		const strDate = `${today.getDate()}-${(today.getMonth() + 1)}-${today.getFullYear()}`;

		let sortedDataSources;
		if (sortAscending) {
			sortedDataSources = filteredDataSourcesAndBatches.sort((a, b) => {
				if (a[sort].toLowerCase() === b[sort].toLowerCase()) {
					return a.id > b.id ? 1 : -1; // Use id to keep rows stable within the chosen sort.
				} else {
					return a[sort].toLowerCase() > b[sort].toLowerCase() ? 1 : -1;
				}
			});
		} else {
			sortedDataSources = filteredDataSourcesAndBatches.sort((a, b) => {
				if (a[sort].toLowerCase() === b[sort].toLowerCase()) {
					return a.id <= b.id ? 1 : -1; // Use id to keep rows stable within the chosen sort.
				} else {
					return a[sort].toLowerCase() <= b[sort].toLowerCase() ? 1 : -1;
				}
			});
		}
		for (let i = pageNum * recordCount; i < (pageNum + 1) * recordCount && i < sortedDataSources.length; i++) {
			const {
				id,
				name,
				source = {},
				statusCode,
				statusText,
				type,
			} = sortedDataSources[i];
			let {
				connection: fileName = '',
			} = sortedDataSources[i];
			const { additionalMetadata = {} } = source;
			const { cerellaEnabled = false } = additionalMetadata;
			fileName = fileName.replace('FILE@', '');

			let statusTitle = 'Offline';
			if (statusCode === 'online') statusTitle = 'Online';
			if (statusCode === 'pending') statusTitle = 'Loading';
			let clickable = true;
			if ((statusCode !== 'online' && type === 'CDD') || type === 'CUSTOM') clickable = false;

			rows.push(
				<tr
					className={`${!clickable ? 'not-clickable' : ''}`}
					key={`filteredDataSourcesAndBatches${i}`}
					onClick={() => { if (clickable) handleRowClick('data-source', sortedDataSources[i].id); }}
				>
					<td className='checkbox data-source'>
						{type !== 'CUSTOM' ?
							<TristateCheckBox
								handleChange={() => {
									handleSelect(sortedDataSources[i].isBatch ? 'batch' : 'data-source', sortedDataSources[i].id, () => this.listRecordsOnPage(sortedDataSources[i].isBatch ? 'batch' : 'data-source'));
								}}
								id={`delete${sortedDataSources[i].id}`}
								value={sortedDataSources[i].selected}
							/>
							:
							null
						}
					</td>
					<td className='status'>
						<div
							className={`indicator circle ${statusCode}`}
							title={statusTitle}
						>
							{(statusCode === 'offline' && statusText && statusText !== '' ?
								<div className='error'>{statusText}</div>
								:
								null
							)}
						</div>
					</td>
					{appName === 'Cerella' ?
						<td className='cerella-enabled'>
							<div
								className={`data-source`}
								title={`Cerella enabled`}
							>
								{cerellaEnabled ?
									<TristateCheckBox
										disabled={true}
										value={cerellaEnabled ? 3 : 0}
									/>
									:
									null
								}
							</div>
						</td>
						:
						null
					}
					<td>{name}</td>
					<td>{type}</td>
					{appName === 'IdeaTracker' ?
						<td className={`pre-filter`}>
							<PreFilter
								aaaKey={aaaKey}
								dialog={dialog}
								id={sortedDataSources[i].id}
								ideaTrackerService={ideaTrackerService}
								logError={logError}
							/>
						</td>
						:
						null
					}

					{sortedDataSources[i].isBatch ?
						(sortedDataSources[i].statusCode === 'offline' ?
							<td className={`offline`}>
								<img
									className={`skull`}
									src={skull}
									alt='Skull and crossbones'
									title='Batch failed to load'
								/>{sortedDataSources[i].error ? `\xa0${sortedDataSources[i].error}` : ''}
							</td>
							:
							<td className='progress'>
								<Progress
									className={`inline`}
									count={1.0}
									step={sortedDataSources[i].progress}
								// done={sortedDataSources[i].progress >= 1.0}
								/>
							</td>)
						:
						<td className='download'>
							<SecureDownload
								aaaKey={aaaKey}
								dialog={dialog}
								contentType='application/json'
								handleShowSpinner={handleShowSpinner}
								fileName={`${name}-${strDate}.json`}
								href={`${queryInterfaceEndpoint}/dataSources/${id}/config_metadata`}
								logError={logError}
							/>
						</td>
					}
					{/* <td>{fileName}</td> */}
				</tr >
			);
			// }

		}
		return rows;
	};

	render() {
		const {
			appName,
			dataSources = [],
			filteredDataSourcesAndBatches = [],
			grantDataSourcesList,
			grantGroupForUserList,
			grantGroupList,
			grantPolicyList,
			groups = [],
			handleAddRecord,
			handleDeleteSelected,
			handlePurgeDataFiles,
			nSelected,
			policies = {},
			superUI,
			users = [],
			what,
		} = this.props;

		const {
			allOnPageSelected = false,
			pageNum,
			recordCount,
			searchTerm = '',
			sort,
			sortAscending,
		} = this.state;

		const commandButtons = (
			<div className='command-buttons'>
				<button
					onClick={(e) => {
						handleDeleteSelected(what);
					}}
					className={`${what} ${(nSelected === 0) ? 'disabled' : ''}`}
					disabled={nSelected === 0}
					id={`${what.replace('-', '')}ButtonDelete`}
				>Delete {what.replace('-', ' ')}{nSelected > 1 ? 's' : ''}</button>
				<button
					onClick={(e) => { handleAddRecord(what); }}
					className={`${what}`}
					id={`${what.replace('-', '')}ButtonAdd`}
				>Add {what.replace('-', ' ')}</button>
			</div >
		);

		let commonRowSpan = 1;
		if (superUI) commonRowSpan = 3;

		const groupTHs = [];
		groups.forEach((group) => {
			groupTHs.push(
				<th key={`th-${group.name}`} className='group checkbox' rowSpan={commonRowSpan}>
					<span>{group.name}</span>
				</th>
			);
		});

		const actionTHs = [];
		const serviceTHs = [];
		const targetTHs = [];

		let actionColCount;
		let targetColCount;
		let serviceColCount = 0;

		for (const service in policies) {
			for (const target in policies[service]) {
				targetColCount = 0;
				actionColCount = 0;
				const actions = policies[service][target];
				// actions.sort().forEach((action) => {
				actions.forEach((action) => {
					// console.log("        action", action)
					actionTHs.push(
						<th
							key={`th-${service}-${target}-${action} `}
							className='policy action checkbox'
							title={`${action.toLowerCase()}`}>
							<span>{action.toLowerCase()}</span>
						</th >
					);
					// actionColCount++;
					// console.log("Do the TDs here too?");
				});
				actionColCount += actions.length;

				// console.log("    target", target)
				targetColCount += actionColCount;
				serviceColCount += targetColCount;
				targetTHs.push(
					<th
						colSpan={targetColCount}
						key={`th-${service}-${target} `}
						className='policy target checkbox'
						title={`${target}`}>
						<span>{target}</span>
					</th >
				);

			}
			// console.log("service", service);
			serviceTHs.push(
				<th
					colSpan={serviceColCount}
					key={`th-${service} `}
					className='policy service checkbox'
					title={`${service}`}>
					<span>{service}</span>
				</th >
			);
			serviceColCount = 0;
		}

		const dataSourceTHs = [];
		const dataSourceVerbTHs = [];
		let colSpan = 1;
		let rowSpan = 1;
		if (superUI) {
			colSpan = 3;
			rowSpan = 3;
		}

		dataSources.forEach((dataSource) => {
			dataSourceTHs.push(
				<th
					key={`th-data-source-${dataSource.id} `}
					className='data-source checkbox'
					colSpan={colSpan}
					rowSpan={Math.max(1, rowSpan - 1)}
					title={dataSource.resource}
				>
					<span>{dataSource.name}{superUI ? ':' : ''}</span>
				</th>
			);
			dataSourceVerbTHs.push(
				<th
					key={`th-data-source-action-${dataSource.id}-manage`}
					className='data-source action checkbox'
					title={dataSource.resource}
				>
					<span>manage</span>
				</th>
			);
			dataSourceVerbTHs.push(
				<th
					key={`th-data-source-action-${dataSource.id}-read`}
					className='data-source action checkbox'
					title={dataSource.resource}
				>
					<span>read</span>
				</th>
			);
			dataSourceVerbTHs.push(
				<th
					key={`th-data-source-action-${dataSource.id}-write`}
					className='data-source action checkbox'
					title={dataSource.resource}
				>
					<span>write</span>
				</th>
			);
		});

		return (
			<div className="paged-content" >

				{/* ========== USER ========== */}
				{(what === 'user') ?
					(
						<>
							<div className='table-head-container'>
								<RecordCount
									recordCount={recordCount}
									handleChangeRecordCount={this.handleChangeRecordCount}
									what={what}
								/>
								<PageControl
									{...this.props}
									handleChangePage={this.handleChangePage}
									nItems={users.length}
									pageSelected={pageNum}
									recordCount={recordCount}
								/>
								<SearchBox
									className='user'
									field='lastName'
									handleChangeSearch={this.handleChangeSearch}
									id={`searchBox${what}`}
									searchTerm={searchTerm}
									sortField={sort}
									what={what}
								/>
							</div>
							<table
								className={`${what} striped`}
								id={`${what}Table`}
							>
								<thead>
									<tr>
										<th className='checkbox user' rowSpan={commonRowSpan}>
											<TristateCheckBox
												className={`user`}
												handleChange={() => { this.handleToggleAll('user'); }}
												id={`toggleAll`}
												title={`${allOnPageSelected ? 'Deselect all records' : 'Select all records on this page, deselect all others'}`}
												value={allOnPageSelected}
											/>
										</th>
										<th
											className='sort'
											key='thName'
											rowSpan={commonRowSpan}
										>Name
											<SortButton ascending={sortAscending}
												handleChangeSort={this.handleChangeSort}
												description={'Name'}
												field={'name'}
												sortField={sort}
												what={'user'}
											/>
										</th>
										<th
											className='active checkbox'
											key='thActive'
											rowSpan={commonRowSpan}
										><span>Active</span></th>
										<th
											className='spacer'
											key='thSpacer1'
											rowSpan={commonRowSpan}
										></th>
										{grantGroupList && grantGroupForUserList ? groupTHs : null}
										{grantGroupList && grantGroupForUserList && grantPolicyList && superUI ? <th
											className='spacer'
											key='thSpacer2'
											rowSpan={commonRowSpan}
										></th> : null}
										{grantPolicyList && superUI ? serviceTHs : null}
										{grantDataSourcesList ? <th
											className='spacer'
											key='thSpacer3'
											rowSpan={commonRowSpan}
										></th> : null}
										{grantDataSourcesList ? dataSourceTHs : null}
									</tr>
									{superUI ?
										<>
											<tr className='targetTHs'>
												{grantPolicyList ? targetTHs : null}
											</tr>
											<tr className='actionTHs datasourceVerbTHs'>
												{grantPolicyList ? actionTHs : null}
												{grantDataSourcesList ? dataSourceVerbTHs : null}
											</tr>
										</> :
										null
									}
								</thead>
								<tbody>
									{this.renderUsers()}
								</tbody>
							</table>

							<div className='table-foot-container'>
								<PageControl
									{...this.props}
									handleChangePage={this.handleChangePage}
									nItems={users.length}
									pageSelected={pageNum}
									recordCount={recordCount}
								/>
								{commandButtons}
							</div>
						</>
					) : null
				}

				{/* ========== GROUP ========== */}
				{(what === 'group' && superUI) ?
					(
						<>
							<div className='table-head-container'>
								<RecordCount
									recordCount={recordCount}
									handleChangeRecordCount={this.handleChangeRecordCount}
									what={what}
								/>
								<PageControl
									{...this.props}
									handleChangePage={this.handleChangePage}
									nItems={groups.length}
									pageSelected={pageNum}
									recordCount={recordCount}
								/>
								<SearchBox
									className='user'
									handleChangeSearch={this.handleChangeSearch}
									id={`searchBox${what}`}
									searchTerm={searchTerm}
									what={what}
								/>
							</div>
							<table
								className={`${what} striped`}
								id={`${what}Table`}
							>
								<thead>
									<tr>
										<th
											key='groupcol1'
											className='checkbox group'
											rowSpan={commonRowSpan}
										>
											<TristateCheckBox
												className={`group`}
												handleChange={() => { this.handleToggleAll('group'); }}
												id={`toggleAll`}
												title={`${allOnPageSelected ? 'Deselect all records' : 'Select all records on this page, deselect all others'}`}
												value={allOnPageSelected}
											/>
										</th>
										<th
											key='groupcol2'
											className='sort'
											rowSpan={commonRowSpan}
										>Name
											<SortButton ascending={sortAscending}
												handleChangeSort={this.handleChangeSort}
												description={'Name'}
												field={'name'}
												sortField={sort}
												what={'group'}
											/>
										</th>
										{superUI && grantPolicyList ? <th
											key='groupcol3'
											className='spacer spacer1'
											rowSpan={commonRowSpan}
										></th> : null}
										{superUI && grantPolicyList ? serviceTHs : null}
										{superUI && grantPolicyList ? <th
											key='groupcol4'
											className='spacer spacer2'
											rowSpan={commonRowSpan}
										></th> : null}
										{grantDataSourcesList ? dataSourceTHs : null}
									</tr>
									{superUI ?
										<>
											<tr className='targetTHs'>
												{grantPolicyList ? targetTHs : null}
											</tr>
											<tr className='actionTHs datasourceVerbTHs'>
												{grantPolicyList ? actionTHs : null}
												{grantDataSourcesList ? dataSourceVerbTHs : null}
											</tr>
										</> :
										null
									}

								</thead>
								<tbody>
									{this.renderGroups()}
								</tbody>
							</table>

							<div className='table-foot-container'>
								<PageControl
									{...this.props}
									handleChangePage={this.handleChangePage}
									nItems={groups.length}
									pageSelected={pageNum}
									recordCount={recordCount}
								/>
								{commandButtons}
							</div>
						</>

					) : null
				}

				{/* ========== DATA SOURCE ========== */}
				{
					(what === 'data-source') ?
						(
							<>
								<div className='table-head-container'>
									<RecordCount
										recordCount={recordCount}
										handleChangeRecordCount={this.handleChangeRecordCount}
										what={what}
									/>
									<PageControl
										{...this.props}
										handleChangePage={this.handleChangePage}
										nItems={filteredDataSourcesAndBatches.length}
										pageSelected={pageNum}
										recordCount={recordCount}
									/>
									<SearchBox
										handleChangeSearch={this.handleChangeSearch}
										id={`searchBox${what}`}
										searchTerm={searchTerm}
										what={what}
									/>
								</div>
								<table
									className={`${what} striped`}
									id={`${what}Table`}
								>
									<thead>
										<tr>
											<th className='checkbox data-source'>
												<TristateCheckBox
													className={`data-source`}
													handleChange={() => { this.handleToggleAll('data-source'); }}
													id={`toggleAll`}
													title={`${allOnPageSelected ? 'Deselect all records' : 'Select all records on this page, deselect all others'}`}
													value={allOnPageSelected}
												/>
											</th>
											<th className='status sort'>
												<span>Status</span>
												<SortButton ascending={sortAscending}
													handleChangeSort={this.handleChangeSort}
													description={'Status'}
													field={'statusCode'}
													sortField={sort}
													what={'data-source'}
												/>
											</th>
											{appName === 'Cerella' ?
												<th className='cerella-enabled'>
													<span>Cerella enabled</span>
												</th>
												:
												null
											}
											<th className='sort'>Name
												<SortButton ascending={sortAscending}
													handleChangeSort={this.handleChangeSort}
													description={'Name'}
													field={'name'}
													sortField={sort}
													what={'data-source'}
												/>
											</th>
											<th className='sort'>Source type
												<SortButton ascending={sortAscending}
													handleChangeSort={this.handleChangeSort}
													description={'Source type'}
													field={'type'}
													sortField={sort}
													what={'data-source'}
												/>
											</th>
											{appName === 'IdeaTracker' ?
												<th>Pre-filter</th>
												:
												null
											}
											<th>
												Download configuration
											</th>
										</tr>
									</thead>
									<tbody>
										{this.renderDataSources()}
									</tbody>
								</table>

								<div className='table-foot-container'>
									<div className='command-buttons left'>
										<button
											className='data-source'
											id='datasourceButtonPurge'
											onClick={handlePurgeDataFiles}>Purge unused data files</button>
									</div>

									<PageControl
										{...this.props}
										handleChangePage={this.handleChangePage}
										nItems={filteredDataSourcesAndBatches.length}
										pageSelected={pageNum}
										recordCount={recordCount}
									/>

									{commandButtons}
								</div>
							</>

						) : null
				}
			</div >
		);
	};
};
