import './Services.scss';
import '../Statistics.scss';
import {
	handleResponse,
	titleCase,
} from '../../../utility';
import React from 'react';

export class Services extends React.PureComponent {

	_isMounted = false;

	constructor(props) {
		super(props);

		this.state = {
			dataRetrieved: false,
		};

		this.intervalTimer = null;

		this.getServices = this.getServices.bind(this);
		this.renderServices = this.renderServices.bind(this);
	}

	getServices = () => {
		const {
			logError,
			statusEndpoint,
		} = this.props;
		if (statusEndpoint) {
			const headers = new Headers();

			const requestOptions = {
				headers: headers,
				method: 'GET',
				redirect: 'follow',
			};

			fetch(`${statusEndpoint}/status`, requestOptions)

				.then(handleResponse)
				.then(res => {
					const services = [];
					for (const [key, value] of Object.entries(res)) {
						services.push([key, value]);
					}
					if (this._isMounted) this.setState({
						services: services.sort((a, b) => (a[0] > b[0]) ? 1 : (a[0] < b[0]) ? -1 : 0),
						servicesLoaded: true,
					});
					return Promise.resolve();
				})
				.catch(error => {
					const action = `Retrieving services`;
					if (error.status && error.status === 403) {
						console.log(action, error);  // eslint-disable-line
						clearInterval(this.intervalTimer);
					} else {
						logError(action, error);
					}

					if (this._isMounted) this.setState({
						servicesDenied: true,
					});
					return Promise.resolve();
				});
		}
	};

	componentDidMount = () => {
		this._isMounted = true;

		const { pollingInterval = 60 } = this.props;
		this.getServices();

		this.intervalTimer = setInterval(() => {
			this.getServices();
		}, pollingInterval * 1000);
	};

	componentWillUnmount = () => {
		this._isMounted = false;

		if (this.intervalTimer !== null) clearInterval(this.intervalTimer);
	};

	renderServices = () => {
		const {
			services = [],
		} = this.state;

		const listItems = [];
		services.forEach((service) => {
			listItems.push(<tr key={`${service[0]}`}>
				<th>{`${titleCase(service[0].replace(/_/g, ' '), 4)}:\xa0`}</th>
				<td>
					<div className={`${service[1]} indicator circle`} title={`${service[1] ? 'Online' : 'Offline'}`} />
				</td>
			</tr>);
		});
		return listItems;
	};

	render() {
		const { className = '' } = this.props;
		const {
			servicesDenied = false,
			servicesLoaded = false,
		} = this.state;

		if (servicesDenied) return;

		return (
			<div id='services' className={`${className}`} >
				{servicesLoaded ?
					<table>
						<tbody>
							{this.renderServices()}
						</tbody>
					</table>
					:
					<p>Services loading...</p>
				}
			</div >);
	}
}
