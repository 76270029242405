export const becauseAdministrator = 'Granted as a consequence of user being an administrator';
export const becauseGroupPolicy = 'Granted as a consequence of membership of a group with permission';
export const becauseGroupDatasource = 'Granted as a consequence of membership of a group with access';
export const becauseGroupAccess = 'Granted as a consequence of membership of a group with access';
export const actionList = ['manage', 'read', 'write'];

export const b64EncodeUnicode = (str) => {
	// first we use encodeURIComponent to get percent-encoded UTF-8,
	// then we convert the percent encodings into raw bytes which
	// can be fed into btoa.
	return window.btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
		function toSolidBytes(match, p1) {
			return String.fromCharCode(`0x${ p1}`);
		}));
};

export const titleCase = (str, minimumWordLength = 0) => {
	const splitStr = str.toLowerCase().split(' ');
	for (let i = 0; i < splitStr.length; i++) {
		// You do not need to check if i is larger than splitStr length, as your for does that for you
		// Assign it back to the array
		if (splitStr[i].length >= minimumWordLength) {
			splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
		} else {
			// Leave words with fewer than minimumWordLength characters as uppercase
			splitStr[i] = splitStr[i].toUpperCase();
		}
	}
	// Directly return the joined string
	return splitStr.join(' ');
};

export const hasPolicy = (policyOwner, service, target, action) => {
	try {
		return (policyOwner.policies && policyInList(policyOwner.policies, service, target, action));
	}
	catch (err) {
		return false;
	}
};

const policyInList = (policyList, service, target, action) => {
	// Note policy Owner is a local json object NOT a AAA object
	try {
		return policyList[service][target].includes(action);
	}
	catch (err){
		return false;
	};
};

export const addPolicy = (policyOwner, service, target, action) => {
	let { policies: newPolicies = {} } = policyOwner;
	newPolicies = addPolicyToList(newPolicies, service, target, action);
	policyOwner.policies = newPolicies;
	return policyOwner;
};

const addPolicyToList = (policyList, service, target, action) => {
	const newPolicies = policyList || {};
	// Didn't have it so add it
	if (!newPolicies[service]) {
		// Add service
		newPolicies[service] = {};
	}
	newPolicies[service][target] =
		Array.from(
			new Set(
				[action].concat(
					newPolicies[service][target]
				)))
			.filter(i => i);
	return newPolicies;
};

export const removePolicy = (policyOwner, service, target, action) => {
	const newPolicies = policyOwner.policies;
	if (newPolicies[service][target].length === 1) {
		delete newPolicies[service][target];
		if (emptyObject(newPolicies[service])) delete newPolicies[service];
	} else {
		// Splice actions
		const foundIndex = newPolicies[service][target].indexOf(action);
		if (foundIndex !== -1)newPolicies[service][target].splice(foundIndex, 1);
	}
	policyOwner.policies = newPolicies;
	return policyOwner;
};

export const copyObject = (originalObject) => {
	if (!originalObject) return undefined;
	return JSON.parse(JSON.stringify(originalObject)); // If you know of a better solution, please enlighten me.
};

export const emptyObject = (object) => {
	return object && Object.keys(object).length === 0 && Object.getPrototypeOf(object) === Object.prototype;
};

export const handleResponse = (response) => {
	// Used in all API calls
	if (response.status === 204 /* || response.status === 200 */) return Promise.resolve(true);
	return response.json()
		.then((json) => {
			if (!response.ok) {
				const error = {
					...json,
					...{
						message: json.message,
						status: response.status,
						statusText: response.statusText,
					}
				};
				return Promise.reject(error);
			}
			return json;
		});
};

export const handleResponseCSV = (response, dialog) => {
	// Used in all CSV API calls
	if (response.status === 204 /* || response.status === 200 */) return Promise.resolve(true);
	return response.text()
		.then((text) => {
			let error = '';
			if (response.status === 404) {
				error = {message: "Sorry, file is unavailable at this time"};
				return Promise.reject(error);
			}
			if (!response.ok) {
				if (typeof (text) === 'string') {
					try {
						error = JSON.parse(text);
					}
					catch (e) {
						error = {message: text};
					}
					return Promise.reject(error);
				} else {
					error = {
						...text,
						...{
							message: text.message,
							status: response.status,
							statusText: response.statusText,
						}
					};
					return Promise.reject(error);
				}
			}
			return text;
		});
};

export const sortObjectByKeys = (unordered) => {
	return Object.keys(unordered).sort().reduce(
		(obj, key) => {
			obj[key] = unordered[key];
			return obj;
		},
		{}
	);
};

export const uuidv4 = () => {
	return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
		(c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> c) / 4)).toString(16)
	);
};

export const padZeros = (what, nZeros) => {
	let result = `${what}`;
	while (result.length < nZeros) result = `0${result}`;
	return result;
};

export const renderHierarchy = (hierarchy, level = 1, index) => {
	const {
		content,
		data,
		title,
	} = hierarchy;
	// console.log(`renderHierarchy(`, level, index, hierarchy, ')');
	const JSX = [];
	const CustomTag = `h${level}`;
	JSX.push(
		<CustomTag key={`title-h${level}-${index}`}>{title}</CustomTag>
	);
	if (content) {
		if (content.length) {
			const contentJSX = [];
			content.forEach((c, index) => {
				contentJSX.push(
					<li key={`li-${level}-${index}`}>{c}</li>
				);
			});
			JSX.push(
				<ul key={`list-${level}-${index}`}>{contentJSX}</ul>
			);
		} else {
			JSX.push(
				<p>None</p>
			);
		}
	}
	if (data) {
		const nextLevel = level + 1;
		// console.log("nextLevel", nextLevel);
		data.forEach((d, index) => {
			JSX.push(renderHierarchy(d, nextLevel, index));
		});
	}
	return JSX;

};

export const strPadDate = (date) => {
	return `${padZeros(date.getFullYear(), 4)}-${padZeros((date.getMonth() + 1), 2)}-${padZeros(date.getDate(), 2)}`;
};

export const strPadDateTime = (dateTime) => {
	return `${padZeros(dateTime.getFullYear(), 4)}-${padZeros((dateTime.getMonth() + 1), 2)}-${padZeros(dateTime.getDate(), 2)}T${padZeros(dateTime.getHours(), 2)}:${padZeros(dateTime.getMinutes(), 2)}`;// :00+00:00`;
};

export const safeChemistryFileName = (unsafeName) => {
	let safeName = unsafeName.replaceAll(' ', '_');
	safeName = safeName.replaceAll('/', '_');
	safeName = safeName.replaceAll(':', '_');
	safeName = safeName.replaceAll('%', 'percent');
	safeName = encodeURI(safeName);
	safeName = safeName.slice(0, 250);
	return safeName;
};