import './FormTest.scss';
import {
	CheckBox,
	DateField,
	FieldSet,
	Progress,
	Radio,
	Select,
	TextArea,
	TextField,
} from '../../../Components';
import React from 'react';

export class FormTest extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = ({
			count: 100,
			done: false,
			editOptions: [{ key: `option-testSelectEditable-none`, name: '<none>', value: '' }],
			one: '',
			step: 0,
			testCheckBox: false,
			testCheckBoxError: false,
			testTextFieldError: '',
			two: undefined,
		});
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount = () => {
		let {
			step,
		} = this.state;

		setInterval(() => {
			step += 0.1;
			if (step >= 100) step = 0;
			this.setState({
				step: step,
			});
		}, 10);
	};

	handleSubmit = (e) => {
		e.preventDefault();

		const {
			editOptions,
			testSelectEditable,
		} = this.state;

		const id = 'testSelectEditable';

		const newEditOptions = [];
		for (let i = 1; i < editOptions.length; i++) {
			newEditOptions.push(editOptions[i].value);
		}

		newEditOptions.push(testSelectEditable);
		const editOptionsUnique = [...new Set(newEditOptions.map(x => x))].sort();
		const editOptionsUniqueObjects = editOptionsUnique.map(x => {
			return {
				key: `option-${id}-none`,
				name: x,
				value: x
			};
		});
		editOptionsUniqueObjects.unshift({
			key: `option-${id}-none`,
			name: '<none>',
			value: ''
		});

		this.setState({
			editOptions: editOptionsUniqueObjects,
		});

	};

	handleChange = (id, value) => {
		this.setState({
			[id]: value,
		});
	};

	render = () => {
		const {
			alert,
		} = this.props;

		const {
			count,
			dateField,
			done = false,
			five,
			fiveSr,
			four,
			fourSr,
			step,
			testCheckBox,
			testCheckBoxError,
			testSelect,
			testSelectEditable,
			testSelectError,
			testTextFieldError,
			three,
			threeSr,
			two,
			twoSr,
		} = this.state;

		return (
			<div id='formTest'>
				<form onSubmit={this.handleSubmit}>
					<h1>Testing Form Components</h1>
					{!done ?
						<Progress
							count={count}
							step={step}
						// done={done}
						/>
						:
						null
					}
					<FieldSet>
						<TextField
							error='Error test'
							handleChange={this.handleChange}
							id='testTextFieldError'
							label='Test text field with error'
							value={testTextFieldError}
						/>
						<TextField
							handleChange={this.handleChange}
							id='testTextField'
							label='Test text field without error'
						/>
						<TextField
							handleChange={this.handleChange}
							id='testTextField'
							info={{
								message: ([
									<p>We are here to educate.</p>,
									<p>We are here to educate. We are here to educate. We are here to educate. We are here to educate. We are here to educate. We are here to educate. We are here to educate. We are here to educate. We are here to educate. </p>,
									<p>We are here to educate. We are here to educate. We are here to educate. We are here to educate. We are here to educate. We are here to educate. We are here to educate. We are here to educate. We are here to educate. </p>,
								]),
								title: 'TextField information dear user',
							}}
							label='Test text field with info'
						/>
						<TextArea
							error='Error test'
							handleChange={this.handleChange}
							id='testTextAreaError'
							label='Test text area with error'
						/>
						<TextArea
							handleChange={this.handleChange}
							id='testTextArea'
							info={{
								message: ([
									<p>We are here to educate.</p>,
									<p>We are here to educate. We are here to educate. We are here to educate. We are here to educate. We are here to educate. We are here to educate. We are here to educate. We are here to educate. We are here to educate. </p>,
									<p>We are here to educate. We are here to educate. We are here to educate. We are here to educate. We are here to educate. We are here to educate. We are here to educate. We are here to educate. We are here to educate. </p>,
								]),
								title: 'TextArea information dear user',
							}}
							label='Test text area without error'
						/>
						<p>{testCheckBox}</p>
						<CheckBox
							error='Error test'
							handleChange={this.handleChange}
							id='testCheckBoxError'
							info={{
								message: ([
									<p>We are here to educate.</p>,
									<p>We are here to educate. We are here to educate. We are here to educate. We are here to educate. We are here to educate. We are here to educate. We are here to educate. We are here to educate. We are here to educate. </p>,
									<p>We are here to educate. We are here to educate. We are here to educate. We are here to educate. We are here to educate. We are here to educate. We are here to educate. We are here to educate. We are here to educate. </p>,
								]),
								title: 'Checkbox information dear user',
							}}
							label='Test checkbox with error'
							value={testCheckBoxError}
						/>
						<CheckBox
							handleChange={this.handleChange}
							id='testCheckBox'
							label='Test checkbox without error'
							value={testCheckBox}
						/>
						<Select
							handleChange={this.handleChange}
							id='testSelect'
							info={{
								message: ([
									<p>We are here to educate.</p>,
									<p>We are here to educate. We are here to educate. We are here to educate. We are here to educate. We are here to educate. We are here to educate. We are here to educate. We are here to educate. We are here to educate. </p>,
									<p>We are here to educate. We are here to educate. We are here to educate. We are here to educate. We are here to educate. We are here to educate. We are here to educate. We are here to educate. We are here to educate. </p>,
								]),
								title: 'Select information dear user',
							}}
							label={`Test select without error`}
							options={[
								{
									'name': '<none>',
									'value': '',
								},
								{
									'name': 'one',
									'value': 'one',
								},
								{
									'name': 'two',
									'value': 'two',
								}
							]}
							value={testSelect}
						/>
						<Select
							editable={true}
							handleChange={this.handleChange}
							id='testSelectEditable'
							label={`Test select editable without error`}
							options={[
								{
									'name': '<none>',
									'value': '',
								},
								{
									'name': 'one',
									'value': 'one',
								},
								{
									'name': 'two',
									'value': 'two',
								}
							]}
							value={testSelectEditable}
						/>
						<Select
							editable={false}
							handleChange={this.handleChange}
							id='testSelectEditable'
							label={`Test select multiple without error`}
							multiple={true}
							options={[
								{
									'name': '<none>',
									'value': '',
								},
								{
									'name': 'one',
									'value': 'one',
								},
								{
									'name': 'two',
									'value': 'two',
								}
							]}
							value={testSelectEditable}
						/>
						<button type='submit'>Submit</button>
						<Select
							error={`Test error`}
							handleChange={this.handleChange}
							id='testSelectError'
							label={`Test select with error`}
							options={[
								{
									'name': '<none>',
									'value': '',
								},
								{
									'name': 'one',
									'value': 'one',
								},
								{
									'name': 'two',
									'value': 'two',
								}
							]}
							value={testSelectError}

						/>

					</FieldSet>
				</form>
				<form>
					<h1>Testing Form Components (Radios)</h1>
					<FieldSet>
						<Radio
							error={'Error test'}
							handleChange={this.handleChange}
							id='two'
							label='Two'
							sameRow={false}
							radios={[
								{ "name": 'one', "value": 'one' },
								{ "name": 'two', "value": 'two' },
							]}
							value={two}
						/>
						<Radio
							error={'Error test'}
							handleChange={this.handleChange}
							id='three'
							label='Three'
							radios={[
								{ "name": 'one', "value": 'one' },
								{ "name": 'three', "value": 'three' },
								{ "name": 'two', "value": 'two' },
							]}
							sameRow={false}
							value={three}
						/>
						<Radio
							error={'Error test'}
							handleChange={this.handleChange}
							id='four'
							label='Four'
							radios={[
								{ "name": 'one', "value": 'one' },
								{ "name": 'two', "value": 'two' },
								{ "name": 'three', "value": 'three' },
								{ "name": 'four', "value": 'four' },
							]}
							sameRow={false}
							value={four}
						/>
					</FieldSet>
				</form>
				<form>
					<h1>Testing Form Components (Radios)</h1>
					<FieldSet>

						<Radio
							error={'Error test'}
							handleChange={this.handleChange}
							id='five'
							label='Five'
							radios={[
								{ "name": 'one', "value": 'one' },
								{ "name": 'two', "value": 'two' },
								{ "name": 'three', "value": 'three' },
								{ "name": 'four', "value": 'four' },
								{ "name": 'five', "value": 'five' },
							]}
							sameRow={false}
							value={five}
						/>
					</FieldSet>
				</form>
				<form>
					<h1>Testing Form Components (Radios same row)</h1>
					<FieldSet>
						{/* <Radio
							error={'Error test'}
							handleChange={this.handleChange}
							id='one'
							label='One'
							radios={[{ "name": 'one', "value": 'one' }]}
							sameRow={true}
						/> */}
						<Radio
							error={'Error test'}
							handleChange={this.handleChange}
							id='twoSr'
							label='Two'
							radios={[
								{ "name": 'one', "value": 'one' },
								{ "name": 'two', "value": 'two' },
							]}
							sameRow={true}
							value={twoSr}
						/>
						<Radio
							error={'Error test'}
							handleChange={this.handleChange}
							id='threeSr'
							label='Three'
							radios={[
								{ "name": 'one', "value": 'one' },
								{ "name": 'two', "value": 'two' },
								{ "name": 'three', "value": 'three' },
							]}
							sameRow={true}
							value={threeSr}
						/>
						<Radio
							error={'Error test'}
							handleChange={this.handleChange}
							id='fourSr'
							label='Four'
							radios={[
								{ "name": 'one', "value": 'one' },
								{ "name": 'two', "value": 'two' },
								{ "name": 'three', "value": 'three' },
								{ "name": 'four', "value": 'four' },
							]}
							sameRow={true}
							value={fourSr}
						/>
						<Radio
							error={'Error test'}
							handleChange={this.handleChange}
							id='fiveSr'
							label='Five'
							radios={[
								{ "name": 'one', "value": 'one' },
								{ "name": 'two', "value": 'two' },
								{ "name": 'three', "value": 'three' },
								{ "name": 'four', "value": 'four' },
								{ "name": 'five', "value": 'five' },
							]}
							sameRow={true}
							value={fiveSr}
						/>
					</FieldSet>
					<FieldSet>
						<DateField
							handleChange={this.handleChange}
							id='dateField'
							label='Date'
							value={dateField}
						/>
						<button onClick={(e) => {
							e.preventDefault();
							alert("Dialog!");
						}}>Let's have some dialog!</button>
					</FieldSet>
				</form>
			</div>
		);
	};
}