import './IdeaTrackerSettings.scss';
import React from 'react';
import {
	TextField,
} from '..';

export class IdeaTrackerIDSettings extends React.Component {
	static displayName = "IdeaTrackerIDSettings";

	constructor(props) {
		super(props);

		const { ideaTrackerIDData } = this.props;
		if (ideaTrackerIDData && ideaTrackerIDData.configuration) {
			const { configuration } = ideaTrackerIDData;
			const { uniqueIdPrefix: prefix } = configuration;
			this.state = ({
				prefix: prefix
			});
		} else {
			this.state = ({});
		}

		this.handleChange = this.handleChange.bind(this);

	};

	handleChange = (field, value) => {
		this.setState({ [field]: value });
	};

	render = () => {
		const {
			dialog,
			handleIdeaTrackerConfiguration,
		} = this.props;

		const {
			prefix = '',
		} = this.state;

		return (
			<div>
				<fieldset className={`id-form`}>
					<div id={`IDRow`} className='ID-row'>
						<TextField
							className={`prefix`}
							handleChange={this.handleChange}
							id={`prefix`}
							info={{
								message: ([
									<p key={`info1`}>You don't have to select an ID prefix, but if you choose to, all IDs will begin with the prefix you choose. You can preview the format of your IDs in the 'Sample' to the right.</p>,
									<p key={`info2`}>The 123456 part is where the digits of your ID will tick over.</p>
								]),
								title: `ID Prefix`,
							}}
							label={`ID Prefix`}
							value={prefix}
						/>
						<div className={`display`}>
							<label>Sample:&nbsp;</label>
							{prefix}
							1234567
						</div>
						<button
							className='configuration-button'
							onClick={() => {
								handleIdeaTrackerConfiguration(prefix)
									.then((res) => {
										dialog('ID Prefix updated', 'clear');
									});
							}}
						>Update</button>
					</div>
				</fieldset>
			</div>
		);
	};
}
