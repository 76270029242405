import './IdeaTrackerSettings.scss';
import {
	CheckBox,
	Radio,
	Select,
	TextField,
} from '..';
import {
	copyObject,
} from '../../utility';
import React from 'react';

export class IdeaTrackerFieldSettings extends React.Component {
	static displayName = "IdeaTrackerFieldSettings";

	constructor(props) {

		super(props);

		const { ideaTrackerFieldData } = this.props;

		if (ideaTrackerFieldData && ideaTrackerFieldData.metaData) {

			const fields = [];
			ideaTrackerFieldData.metaData.forEach((md) => {
				const {
					allowedValues,
					defaultValue,
					fieldId,
					fieldName,
				} = md;
				let type = 'text';
				const options = [];
				if (allowedValues && allowedValues.length > 0) {
					type = 'category';
					allowedValues.forEach((av) => {
						options.push({
							name: av,
							selected: false,
							value: av,
						});
					});
				}
				fields.push({
					defaultValue: defaultValue,
					fieldId: fieldId,
					fieldName: fieldName,
					options: options,
					type: type,
				});
			});
			if (fields.length > 0) {
				this.state = ({
					fields: fields,
					// type: 'text',
				});
			} else {
				this.state = ({
					// type: 'text',
				});
			}
		} else {
			this.state = ({
				// type: 'text',
			});
		}

		this.handleAddOption = this.handleAddOption.bind(this);
		this.handleAddUpdateField = this.handleAddUpdateField.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleDelete = this.handleDelete.bind(this);

		this.renderFields = this.renderFields.bind(this);
		this.renderOptions = this.renderOptions.bind(this);
	};

	handleAddOption = (e) => {
		// console.log(`handleAddOption`);
		e.preventDefault();
		const {
			options = [],
			optionName,
		} = this.state;
		options.push({
			name: `${optionName}`,
			selected: false,
			value: `${optionName}`,
		});
		this.setState({
			optionName: '',
			options,
		});
	};

	handleAddUpdateField = (add) => {
		// console.log(`handleAddUpdateField(${add ? 'add' : 'update'})`);
		const {
			currentField,
			defaultValue,
			fields,
			fieldName,
			options = [],
			type,
		} = this.state;

		const _this = this;

		const {
			dialog,
			handleIdeaTrackerAddMetaData,
			handleIdeaTrackerUpdateMetaData,
		} = this.props;

		if (add) {
			let newFields = copyObject(fields);
			if (!newFields) newFields = [];
			const newField = {
				defaultValue: defaultValue,
				fieldName: fieldName,
				options: copyObject(options),
			};

			handleIdeaTrackerAddMetaData(newField)
				.then((addedField) => {
					if (addedField !== undefined) {
						addedField.type = 'text';
						if (addedField.allowedValues && addedField.allowedValues.length > 0) {
							addedField.type = 'category';
							addedField.options = [];
							addedField.allowedValues.forEach((value) => {
								addedField.options.push({ name: value, value: value });
							});
						}
						addedField.selected = true;
						delete addedField.allowedValues;

						newFields.push({
							...addedField,
						});

						_this.setState({
							...addedField,
							currentField: newFields.length - 1,
							fields: newFields,
							nSelected: 1,
						}, _this.validate);
					}
				});

		} else {
			// Update
			const updatedFields = copyObject(fields);
			const updatedField =
			{
				...fields[currentField],
				defaultValue: defaultValue,
				fieldName: fieldName,
				options: copyObject(options),
				type: type,
			};

			handleIdeaTrackerUpdateMetaData(updatedField)
				.then((updatedField) => {
					if (updatedField !== undefined) {
						updatedField.type = 'text';
						updatedField.selected = true;
						if (updatedField.allowedValues && updatedField.allowedValues.length > 0) {
							updatedField.type = 'category';
							updatedField.options = [];
							updatedField.allowedValues.forEach((allowedValue) => {
								updatedField.options.push({name: allowedValue, value: allowedValue});
							});
						}
						updatedField.selected = true;
						delete updatedField.allowedValues;
						const foundIndex = updatedFields.findIndex((field) => field.fieldId === updatedField.fieldId);
						if (foundIndex >= 0) updatedFields.splice(foundIndex, 1, updatedField);
						_this.setState({
							fields: updatedFields
						}, _this.validate);
						dialog('Field updated', 'clear');
					}
				});
		}
	};

	handleChange = (field, value) => {
		// console.log(`handleChange(${field},${value})`);
		const {
			fields,
			options,
		} = this.state;

		switch (field) {
			case 'defaultValue':
				if (value === '') {
					this.setState({
						defaultValue: null
					});
				} else {
					this.setState({
						defaultValue: value
					});
				}
				break;
			case 'option':
				const newOptions = copyObject(options);
				newOptions[value].selected = !newOptions[value].selected;
				this.setState({
					options: newOptions,
				}, this.validate);
				break;
			case 'select':
				fields[value].selected = !fields[value].selected;
				if (fields.filter(field => field.selected).length === 1) {
					const currentField = fields.findIndex(field => field.selected);
					const field = fields[currentField];
					const {
						defaultValue,
						fieldName,
						fieldId,
						options,
					} = field;
					let {
						type,
					} = field;

					if (options && options.length > 0)type = 'category';

					this.setState({
						currentField: currentField,
						defaultValue: defaultValue,
						fieldId,
						fieldName: fieldName,
						fields: fields,
						options: copyObject(options),
						type: type,
					}, this.validate);
				} else {
					this.setState({
						currentField: null,
						defaultValue: null,
						fieldId: null,
						fieldName: undefined,
						fields: fields,
						options: undefined,
						type: 'text',
					}, this.validate);
				}
				break;
			case 'type':
				this.setState({
					[field]: value,
					options: [],
				}, this.validate);
				break;
			default:
				this.setState({ [field]: value }, this.validate);
				break;
		}
	};

	handleDelete = (what) => {
		// console.log("handleDelete", what);
		const { dialog } = this.props;
		const { handleIdeaTrackerDeleteMetaData } = this.props;
		const _this = this;

		switch (what) {
			case 'fields':
				dialog("Are you sure you want to delete?", 'home', 'yesno', () => {
					const {
						fields,
					} = _this.state;
					if (fields) {
						const newFields = [];
						fields.forEach((field, index) => {
							if (!field.selected) {
								newFields.push(field);
							} else {
								handleIdeaTrackerDeleteMetaData(field);
							}
						});
						_this.setState({
							currentField: undefined,
							defaultValue: null,
							fieldId: null,
							fieldName: undefined,
							fields: newFields,
							options: undefined,
							type: 'text',
						});
					}
				});
				break;
			case 'options':
				const {
					options,
				} = _this.state;
				let {
					defaultValue,
				} = _this.state;
				if (options) {
					const newOptions = [];
					options.forEach((option, index) => {
						if (!option.selected) {
							newOptions.push(option);
						} else {
							if (option.name === defaultValue) defaultValue = null;
						}
					});
					_this.setState({
						defaultValue: defaultValue,
						options: newOptions
					});
				}
				break;
			default:
				break;
		}
	};

	renderFields = () => {
		const {
			currentField,
			fields,
		} = this.state;

		const JSX = [];
		if (fields) {
			fields.forEach((field, index) => {
				const {
					fieldName,
					options,
					selected = false,
					type,
				} = field;

				const previewOptions = [];
				if (options && options.length > 0) {
					options.forEach((option, index) => {
						let sanitisedValue = '';
						if (option.value !== null && option.value !== undefined)sanitisedValue = option.value;
						previewOptions.push(
							<option
								key={`option-${option.name}`}
								value={sanitisedValue}
							>{option.name}</option>
						);
					});
				}
				let strOptions = '';
				if (options && options.length > 0)strOptions = options.map((o) => o.name).join(', ');
				// if (type === 'category')strPreview = options.map((option) => option.name).join(', ');
				JSX.push(
					<tr
						className={`${currentField === index || field.selected ? 'selected' : null}`}
						key={`field-${index}`}
						onClick={(e) => {
							e.preventDefault();
							this.handleChange('select', index);
						}}
					>
						<td>
							<CheckBox
								handleChange={() => {
									this.handleChange('select', index);
								}}
								id={`select`}
								key={`select-${index}`}
								value={(selected && selected === true) || currentField === index}
							/>
						</td>
						<td>{fieldName}</td>
						<td className={`type`}>{type}</td>
						<td className={`options`}>
							{type === 'category' ?
								<div className={`options`}>{strOptions}</div>
								:
								<span>Plain text</span>
							}
						</td>
						{/* <td className={`options`}><label>{fieldName}:&nbsp;
							{type === 'category' ?
								<>
									<select
										onChange={() => { }}
										onClick={(e) => {
											e.stopPropagation();
											e.preventDefault();
										}}
										value={defaultValue === null ? '' : defaultValue}
									>{previewOptions}</select>
								</>
								:
								<input
									type='text'
									name={`${index}-dummy-input`}
									onClick={(e) => {
										e.stopPropagation();
										e.preventDefault();
									}}/>
							}</label></td> */}
					</tr>
				);
			});
		}
		return JSX;
	};

	renderOptions = () => {
		const {
			options = [],
		} = this.state;
		const JSXoptionsRows = [];

		options.forEach((option, index) => {
			const {
				name,
				selected = false,
			} = option;
			JSXoptionsRows.push(
				<tr
					key={`option-row-${index}`}
					onClick={(e) => {
						e.preventDefault();
						this.handleChange('option', index);
					}}
				>
					<td>
						<CheckBox
							handleChange={() => {
								this.handleChange('option', index);
							}}
							id={`option`}
							key={`option-${index}`}
							value={(selected && selected === true)}
						/>
					</td>
					<td>{name}</td>
				</tr>
			);
		});

		return JSXoptionsRows;

	};

	render = () => {
		const {
			id,
		} = this.props;

		const {
			defaultValue = '',
			fieldName = '',
			// nameError,
			// option,
			options = [],
			optionName = '',
			type = 'text',
			// typeError,
		} = this.state;

		const {
			currentField,
			error = true,
			fields,
		} = this.state;

		const fieldTypeOptions = [
			{
				name: 'text',
				value: 'text',
			},
			{
				name: 'category',
				value: 'category',
			},
		];

		const defaultValues = copyObject(options);
		defaultValues.unshift({name: 'None', value: ""});

		let nSelected = 0;
		if (fields) {
			nSelected = fields.filter(field => field.selected).length;
		}

		let nOptionsSelected = 0;
		if (options) nOptionsSelected = options.filter(option => option.selected).length;

		return (
			<div
				className={`idea-tracker-admin`}
				id={id}
				key={id}
			>
				<p>In this area, you can configure Idea Tracker fields and associated StarDrop columns according to your organisation's business rules.</p>
				<div className={`fields-container ${fields && fields.length > 0 ? 'show' : ''}`}>
					<table className={`striped row fields-row idt-field-settings `}>
						<thead>
							<tr>
								<th className={`checkbox`}></th>
								<th>Name</th>
								<th>Type</th>
								<th>Options</th>
							</tr>
						</thead>
						<tbody>
							{this.renderFields()}
						</tbody>
					</table>
					<div
						className={`row delete-row button-row `}
						id={`deleteRow`}
					>
						<button
							className={nSelected === 0 ? 'disabled' : ''}
							disabled={nSelected === 0}
							onClick={() => this.handleDelete('fields')}
						>Delete fields</button>
					</div>
				</div>
				{nSelected <= 1 ?
					<fieldset className={`field-form`}>
						<Radio
							className={`field-type`}
							// error={typeError}
							handleChange={this.handleChange}
							id={`type`}
							// info={{
							// 	message: ([
							// 		<p key={`info1`}><b>text</b> allows simple text content in the field.</p>,
							// 		<p key={`info2`}><b>category</b> results in a set of options the user can choose from.</p>,
							// 		<p key={`info3`}>You can see a preview in the table on the left when you add or update a field.</p>
							// 	]),
							// 	title: 'Type',
							// }}
							label={`Type`}
							radios={fieldTypeOptions}
							sameRow={true}
							value={type}
						/>
						<TextField
							className={`field-form-row`}
							// error={nameError}
							label={`Name`}
							id={`fieldName`}
							// info={{
							// 	message: ([
							// 		<p key={`info4`}>Used to identify the field (so must be unique). Also used to label the field.</p>,
							// 	]),
							// 	title: 'Name',
							// }}
							handleChange={this.handleChange}
							placeholder={'Please enter a field name'}
							value={fieldName}
						/>
						<div className={`row field-form-row options-row ${type === 'category' ? 'show' : ''}`}>
							<label>Options: {type}</label>
							<table className={`striped row options-row-table`}>
								<thead>
									<tr>
										<th className={`checkbox`}></th>
										<th>Option</th>
									</tr>
								</thead>
								<tbody>
									{this.renderOptions()}
									<tr>
										<td></td>
										<td>
											<div className={`choose-default-option`}>
												<Select
													className={`default-option`}
													handleChange={this.handleChange}
													id={`defaultValue`}
													info={{
														message: ([
															<p key={`info5`}>You don't have to select a default option, but if you do, this will be the default choice if the user chooses not to make a selection.</p>,
															<p key={`info6`}>When you have selected a default option, you must press the Add or Update field button to see it in preview.</p>,
															<p key={`info7`}>You can see a preview in the table on the left when you add or update a field.</p>
														]),
														title: `Select default option`,
													}}
													label={`Select default option`}
													options={defaultValues}
													value={defaultValue}
												/>
											</div>
										</td>
									</tr>
									<tr>
										<td></td>
										<td>
											<div className={`add-option`}>
												<TextField
													className={`option-name`}
													handleChange={this.handleChange}
													id={`optionName`}
													label={``}
													value={optionName}
												/>
												<button
													className={`${optionName === '' ? 'disabled' : ''}`}
													disabled={`${optionName === '' ? 'disabled' : ''}`}
													onClick={this.handleAddOption}
													title={`Add option`}
												>Add option</button>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
							<div className={`row button-row ${nOptionsSelected > 0 ? 'show' : ''}`}>
								<button
									className={nOptionsSelected === 0 ? 'disabled' : ''}
									onClick={() => this.handleDelete('options')}
								>Delete options</button>
							</div>

						</div>
						{nSelected === 1 ?
							<div className={`row button-row`}>
								<button
									className={`${error ? 'disabled' : ''}`}
									disabled={`${error ? 'disabled' : ''}`}
									onClick={(e) => {
										e.preventDefault();
										this.handleAddUpdateField(false);
									}}>Update field</button>
								<button
									onClick={(e) => {
										e.preventDefault();
										const { fields } = this.state;
										const newFields = copyObject(fields);
										newFields[currentField].selected = false;
										this.setState({
											currentField: null,
											fieldName: undefined,
											fields: newFields,
											options: [],
											type: 'text',
										});
									}}>Cancel</button>
							</div>
							:
							<div className={`row button-row`}>
								<button
									className={`${error ? 'disabled' : ''}`}
									disabled={`${error ? 'disabled' : ''}`}
									onClick={(e) => {
										e.preventDefault();
										// if (!error)
										this.handleAddUpdateField(true);
									}}>Add field</button>
							</div>
						}
					</fieldset>
					:
					<div id='oneAtATime'><p>You can only edit one field at a time, you have {nSelected} fields selected. But this enables you to select and delete multiple fields.</p></div>
				}
			</div >
		);
	};

	validate = () => {
		const {
			fieldName,
		} = this.state;
		if (!fieldName){
			this.setState({
				error: true,
			});
			return false;
		}
		this.setState({
			error: false,
		});

		return true;
	};
}