import './IdeaTrackerSpinner.scss';
import aspirin from './aspirin.png';
import caffeine from './caffeine.png';
import fluoxetine from './fluoxetine.png';
import ibuprofen from './ibuprofen.png';
import morphine from './morphine.png';
import React from 'react';
import ritonavir from './ritonavir.png';
import sildenafil from './sildenafil.png';

export class IdeaTrackerSpinner extends React.PureComponent {
	render() {

		const {
			showSpinner,
			spinnerMessage = "Loading...",
		} = this.props;

		return (
			<div
				className={`${showSpinner ? 'show' : ''}`}
				id='ideatrackerspinner'
			>
				<div className='background' />
				<h1>{spinnerMessage}</h1>
				<ul>
					<li className="container">
						<div className="content">
							<figure>
								<img
									alt={`caffeine`}
									className={`caffeine`}
									src={caffeine} />
								<figcaption>Caffeine</figcaption>
							</figure>
						</div>
					</li>
					<li className="container">
						<div className="content">
							<figure>
								<img
									alt={`aspirin`}
									className={`aspirin`}
									src={aspirin} />
								<figcaption>Aspirin</figcaption>
							</figure>
						</div>
					</li>
					<li className="container">
						<div className="content">
							<figure>
								<img
									alt={`sildenafil`}
									className={`sildenafil`}
									src={sildenafil} />
								<figcaption>Sildenafil</figcaption>
							</figure>
						</div>
					</li>
					<li className="container">
						<div className="content">
							<figure>
								<img
									alt={`morphine`}
									className={`morphine`}
									src={morphine} />
								<figcaption>Morphine</figcaption>
							</figure>
						</div>
					</li>
					<li className="container">
						<div className="content">
							<figure>
								<img
									alt={`ritonavir`}
									className={`ritonavir`}
									src={ritonavir} />
								<figcaption>Ritonavir</figcaption>
							</figure>
						</div>
					</li>
					{/* <li className="container">
						<div className="content bulb">
							<figure>
								<img src="blank.png"></img>
								<figcaption>Penicillin</figcaption>
							</figure>
						</div>
					</li> */}
					<li className="container">
						<div className="content">
							<figure>
								<img
									alt={`ibuprofen`}
									className={`ibuprofen`}
									src={ibuprofen} />
								<figcaption>Ibuprofen</figcaption>
							</figure>
						</div>
					</li>
					<li className="container">
						<div className="content">
							<figure>
								<img
									alt={`fluoxetine`}
									className={`fluoxetine`}
									src={fluoxetine} />
								<figcaption>Fluoxetine</figcaption>
							</figure>
						</div>
					</li>
					<li className="container">
						<div className="content">
							<figure>
								<img
									alt={`caffeine`}
									className={`caffeine`}
									src={caffeine} />
								<figcaption>Caffeine</figcaption>
							</figure>
						</div>
					</li>
				</ul>


			</div>
		);
	}
}