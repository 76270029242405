import '../Statistics.scss';
import colourVariables from '../../../styles/_colours.module.scss';
import {
	handleResponse,
} from '../../../utility';
import Plot from 'react-plotly.js';
import React from 'react';

export class InternalValidation extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			internalValidationRetrieved: false,
		};

		this.refresh = this.refresh.bind(this);
	}

	componentDidMount = () => {
		// console.log("componentDidMount begin");
		this.refresh();
		// console.log("componentDidMount end");
	};

	componentDidUpdate = (oldProps) => {
		// console.log("componentDidUpdate begin");
		const { refreshStatistics: oldRefreshStatistics } = oldProps;
		const { refreshStatistics } = this.props;
		if (refreshStatistics !== oldRefreshStatistics) this.refresh();
		// console.log("componentDidUpdate end");
	};

	refresh = () => {
		// console.log("refresh");
		const {
			aaaKey,
			checkPermissionsLogError,
			statisticsEndpoint,
		} = this.props;

		if (statisticsEndpoint) {
			// console.log("    internalValidation refresh", refreshStatistics);
			const headers = new Headers();
			headers.append("X-api-key", aaaKey);

			const requestOptions = {
				headers: headers,
				method: 'GET',
				redirect: 'follow',
			};

			fetch(`${statisticsEndpoint}/model/validation/internal/json`, requestOptions)

				.then(handleResponse)
				.then(res => {
					let virtual;
					let impute;
					for (let i = 0; i < res.length; i++) {
						if (res[i].modelName === 'Virtual') {
							virtual = res[i];
						};
						if (res[i].modelName === 'Impute') {
							impute = res[i];
						}
					}
					const decimalPlaces = 3;
					const virtualMedianR2 = virtual.medianR2.value.toFixed(decimalPlaces);
					const { endpoints: virtualEndpoints } = virtual;
					const slimmedVirtual = virtualEndpoints.map((item) => { return { name: item.endpointName, y: item.r2.value, }; }).sort((a, b) =>
						(a.y < b.y) ? 1 : -1
					);
					const imputeMedianR2 = impute.medianR2.value.toFixed(decimalPlaces);
					const { endpoints: imputeEndpoints } = impute;
					const slimmedImpute = imputeEndpoints.map((item) => { return { name: item.endpointName, y: item.r2.value, }; }).sort((a, b) =>
						(a.y < b.y) ? 1 : -1
					);

					const {
						cerellaHomeColour = '#fdbd75',
						cerellaUserColour = '#5a99b7',
					} = colourVariables;

					const newData = [{
						"marker": {
							"color": cerellaHomeColour,
							"maxdisplayed": 80,
							"size": 8
						},
						"mode": "lines+markers",
						"name": `Imputation  (Median&nbsp;R&#178;: ${imputeMedianR2})`,
						"text": slimmedImpute.map((item) => item.name),
						"type": "scatter",
						"y": slimmedImpute.map((item) => item.y),
					}, {
						"marker": {
							"color": cerellaUserColour,
							"maxdisplayed": 80,
							"size": 8
						},
						"mode": "lines+markers",
						"name": `Virtual  (Median&nbsp;R&#178;: ${virtualMedianR2})`,
						"text": slimmedVirtual.map((item) => item.name),
						"type": "scatter",
						"y": slimmedVirtual.map((item) => item.y),
					}];

					this.setState({
						internalValidation: {
							config: {
								displaylogo: false,
								responsive: true,
							},
							data: newData,
							layout: {
								height: 500,
								legend: {
									orientation: "h",
								},
								margin: {
									b: 30,
									l: 50,
									r: 20,
									t: 50,
								},
								title: 'Model Performance',
								width: 800,
								xaxis: {
									showticklabels: false,
									title: 'Endpoints Ordered by Accuracy',
								},
								yaxis: {
									range: [-0.5, 1],
									title: 'R&#178;',
								},
							},

						},
						internalValidationRetrieved: true,
					});
				})
				.catch(error => {
					const action = `Retrieving hyperparameter optimisation`;
					checkPermissionsLogError(error, action);
				});
		}
	};

	render() {
		const {
			internalValidationRetrieved,
			internalValidation,
		} = this.state;

		const {
			className,
		} = this.props;

		return (
			internalValidationRetrieved ?
				<Plot
					className={`optimisation-chart chart ${className}`}
					divId='internalValidationPlot'
					useResizeHandler
					data={internalValidation.data}
					layout={internalValidation.layout}
					config={internalValidation.config} />
				:
				<div
					className={`optimisation-chart placeholder`}
					id='internalValidationPlaceholder' >
					Loading...
				</div>
		);
	}
}
