import '../Statistics.scss';
import {
	handleResponse,
} from '../../../utility';
import React from 'react';

export class IngestProcesses extends React.PureComponent {

	constructor(props) {
		super(props);

		this.state = {
			dataRetrieved: false,
		};

		this.getProcesses = this.getProcesses.bind(this);
		this.renderIngestProcesses = this.renderIngestProcesses.bind(this);
		this.pollingTimer = null;
	}

	getProcesses = () => {
		const {
			checkPermissionsLogError,
			statisticsEndpoint,
			aaaKey,
		} = this.props;
		if (statisticsEndpoint) {
			const headers = new Headers();
			headers.append("Content-Type", "application/json");
			headers.append("X-api-key", aaaKey);

			const requestOptions = {
				headers: headers,
				method: 'GET',
				redirect: 'follow',
			};
			fetch(`${statisticsEndpoint}/status/processes`, requestOptions)

				.then(handleResponse)
				.then(res => {
					this.setState({
						processes: res,
						processesLoaded: true,
					});
				})
				.catch(error => {
					const action = `Retrieving processes`;
					checkPermissionsLogError(error, action);
					clearInterval(this.pollingTimer);
				});
		}
	};

	componentDidMount = () => {
		const { pollingInterval = 60 } = this.props;
		this.getProcesses();

		this.pollingTimer = setInterval(() => {
			this.getProcesses();
		}, pollingInterval * 1000);

	};

	componentWillUnmount = () => {
		clearInterval(this.pollingTimer);
	};

	renderIngestProcesses = () => {
		const { processes = [] } = this.state;
		const listItems = [];

		processes.forEach((process) => {
			let start = new Date(process.startTime).toUTCString();
			if (start === 'Invalid Date')start = 'Not started';
			let end = new Date(process.endTime).toUTCString();
			if (end === 'Invalid Date') {
				if (start === 'Not started') {
					end = '';
				} else {
					end = 'In progress';
				}
			}
			listItems.push(<tr key={`${process.name}`}>
				<td className='name'>{`${process.name}`}</td>
				<td>{start}</td>
				<td>{end}</td>
			</tr >);
		});
		return listItems;
	};

	render() {
		const { className = '' } = this.props;
		const {
			processesLoaded = false,
		} = this.state;
		if (processesLoaded) return (
			<div id='ingestProcesses' className={`${className}`} >
				<table className='striped ingest-processes'>
					<thead>
						<tr>
							<th>Name</th>
							<th>Start Time</th>
							<th>End Time</th>
						</tr >
					</thead>
					<tbody>
						{this.renderIngestProcesses()}
					</tbody>
				</table>
			</div >
		);
		return (<></>);
	}
}
