// import './Tags.scss';
import {
	Info,
} from '..';
import React from 'react';

export class HeaderTag extends React.PureComponent {
	render() {

		const {
			children,
			className,
			id,
			info,
			level = 1,
		} = this.props;

		const CustomTag = `h${level}`;

		return (
			<CustomTag
				className={className ? `${className}` : null}
				id={id ? `${id}` : null}
			>
				{children}{info ?
					<Info
						infoTitle={info.title}
						infoMessage={info.message}
					/>
					:
					null
				}
			</CustomTag>
		);
	}
}