import {
	DateField,
	FieldSet,
	Radio,
} from '../..';
import {
	strPadDate,
	strPadDateTime,
} from '../../../utility';
import React from 'react';

export class DateRange extends React.PureComponent {
	constructor(props) {
		super(props);

		this.handleChange = this.handleChange.bind(this);
	}

	handleChange = (field, fieldValue) => {
		const {
			handleChangeDateRange,
		} = this.props;
		const {
			id,
			time = false,
			value,
		} = this.props;

		let dateRange = '', endDate, startDate;
		if (value) {
			({
				dateRange = '',
				endDate,
				startDate,
			} = value);
		}

		let returnObject;

		switch (field) {
			case 'dateRange': {
				const today = new Date();
				let strToday = strPadDate(today);
				if (time) strToday = strPadDateTime(today);

				switch (fieldValue) {
					case 'hour': {
						dateRange = fieldValue;
						endDate = strToday;
						startDate = new Date();
						startDate.setHours(today.getHours() - 1);
						startDate = time ? strPadDateTime(startDate) : strPadDate(startDate);
						break;
					}
					case 'day': {
						dateRange = fieldValue;
						endDate = strToday;
						startDate = new Date();
						startDate.setDate(today.getDate() - 1);
						startDate = time ? strPadDateTime(startDate) : strPadDate(startDate);
						break;
					}
					case 'week': {
						dateRange = fieldValue;
						endDate = strToday;
						startDate = new Date();
						startDate.setDate(today.getDate() - 7);
						startDate = time ? strPadDateTime(startDate) : strPadDate(startDate);
						break;
					}
					case 'month': {
						dateRange = fieldValue;
						endDate = strToday;
						startDate = new Date(today);
						startDate.setMonth(startDate.getMonth() - 1);
						startDate = time ? strPadDateTime(startDate) : strPadDate(startDate);
						break;
					}
					case 'year': {
						dateRange = fieldValue;
						endDate = strToday;
						startDate = new Date(today);
						startDate.setFullYear(startDate.getFullYear() - 1);
						startDate = time ? strPadDateTime(startDate) : strPadDate(startDate);
						break;
					}
					default: {
						dateRange = fieldValue;
						break;
					}
				}
				returnObject = {
					dateRange: dateRange,
					endDate: endDate,
					id: id,
					startDate: startDate,
				};
				handleChangeDateRange(id, returnObject);
				break;
			}
			case "startDate": {
				returnObject = {
					dateRange: '',
					endDate: endDate,
					startDate: fieldValue,
				};
				handleChangeDateRange(id, returnObject);
				break;
			}
			case "endDate": {
				returnObject = {
					dateRange: '',
					endDate: fieldValue,
					startDate: startDate,
				};
				handleChangeDateRange(id, returnObject);
				break;
			}
			default: {
				break;
			}
		}
	};

	render = () => {
		const {
			id,
			label,
			time,
			value,
		} = this.props;

		let dateRange = '', endDate, startDate;
		if (value) {
			({
				dateRange = '',
				endDate,
				startDate,
			} = value);
		}

		const today = new Date();
		const strToday = strPadDate(today);

		const yesterday = new Date();
		yesterday.setDate(today.getDate() - 1);
		const strYesterday = strPadDate(yesterday);

		let strStartDatePlusOne;
		if (startDate !== '') {
			const startDatePlusOne = new Date(startDate);
			startDatePlusOne.setDate(startDatePlusOne.getDate() + 1);
			strStartDatePlusOne = strPadDate(startDatePlusOne);
		}

		let minOfEndDateMinusOneAndYesterday = strYesterday;
		if (endDate) {
			const endDateMinusOne = new Date(endDate);
			endDateMinusOne.setDate(endDateMinusOne.getDate() - 1);
			minOfEndDateMinusOneAndYesterday = new Date(Math.min(yesterday, endDateMinusOne)).toISOString().split("T")[0]; // eslint-disable-line
		}

		const periodOptions = [
			{ name: 'Previous day', value: 'day' },
			{ name: 'Previous week', value: 'week' },
			{ name: 'Previous month', value: 'month' },
			{ name: 'Previous year', value: 'year' },
			{ name: 'Other', value: '' },
		];

		if (time) periodOptions.unshift({name: 'Previous hour', value: 'hour'});

		return (
			<form id={id}>
				<FieldSet>
					<Radio
						handleChange={this.handleChange}
						id='dateRange'
						label={label}
						radios={periodOptions}
						sameRow={false}
						value={dateRange}
					/>
					<DateField
						className={`${time ? 'invert' : ''}`}
						handleChange={this.handleChange}
						id='startDate'
						label='From'
						max={minOfEndDateMinusOneAndYesterday}
						time={time}
						value={startDate}
					/>
					<DateField
						className={`${time ? 'invert' : ''}`}
						handleChange={this.handleChange}
						id='endDate'
						label='To'
						max={strToday}
						min={strStartDatePlusOne}
						time={time}
						value={endDate}
					/>
				</FieldSet>
			</form>
		);
	};
}