import './ModelComparison.scss';
import '../Statistics/Statistics.scss';
import {
	CheckBox,
	SecureDownload,
} from '..';
import {
	copyObject,
	handleResponse,
	handleResponseCSV,
	renderHierarchy,
} from '../../utility';
import React from 'react';
import update from 'immutability-helper';
import view from '../../images/view.svg';

export class ModelComparison extends React.PureComponent {

	constructor(props) {
		super(props);

		this.state = {
			dataRetrieved: false,
		};

		this.getComparisonReports = this.getComparisonReports.bind(this);
		this.handleCSVload = this.handleCSVload.bind(this);
		this.handleDeleteSelected = this.handleDeleteSelected.bind(this);
		this.handleSelect = this.handleSelect.bind(this);
		this.renderModelComparison = this.renderModelComparison.bind(this);
		this.viewReport = this.viewReport.bind(this);
		this.pollingTimer = null;
	}

	componentDidMount = () => {
		const { pollingInterval = 60 } = this.props;
		this.getComparisonReports();

		this.pollingTimer = setInterval(() => {
			this.getComparisonReports();
		}, pollingInterval * 1000);

	};

	componentWillUnmount = () => {
		clearInterval(this.pollingTimer);
	};

	getComparisonReports = () => {
		const {
			checkPermissionsLogError,
			handleShowSpinner,
			ingestEndpoint,
			statisticsEndpoint,
			aaaKey,
		} = this.props;

		if (ingestEndpoint && statisticsEndpoint) {
			const { reports } = this.state;
			handleShowSpinner(true, `${reports === undefined ? 'Loading' : 'Synchronising'} model comparison reports`);

			const headers = new Headers();
			headers.append("Content-Type", "application/json");
			headers.append("X-api-key", aaaKey);

			const requestOptions = {
				headers: headers,
				method: 'GET',
				redirect: 'follow',
			};
			// First figure out if model comparison is running presently?
			fetch(`${ingestEndpoint}/comparisonReady`, requestOptions)
				.then((res) => {
					if (res) {
						const { status } = res;
						this.setState({
							serverStatus: status,
						});
					}
				})
				.catch((error) => {
					const action = `Retrieving model comparison server status`;
					checkPermissionsLogError(error, action);
					return Promise.resolve();
				});
			fetch(`${statisticsEndpoint}/model/prediction/comparison/comparisonReports`, requestOptions)
				.then(handleResponse)
				.then(res => {
					const newReports = [];
					res.forEach((report) => {
						let selected = false;
						if (reports) {
							const oldReport = reports.find((oldReport) => oldReport.name === report.name);
							if (oldReport && oldReport.selected) selected = true;
						}
						newReports.push({
							...report,
							selected: selected,
						});
					});

					this.setState({
						reports: newReports,
						reportsLoaded: true,
					}, () => {
						handleShowSpinner(false);
						return Promise.resolve();
					});
				})
				.catch(error => {
					const action = `Retrieving model comparison reports`;
					checkPermissionsLogError(error, action);
					clearInterval(this.pollingTimer);
				});
		}
	};

	handleCSVload = (e) => {
		// console.log("handleCSVload");
		this.setState({
			loading: true,
		});

		const {
			aaaKey,
			checkPermissionsLogError,
			dialog,
			handleShowSpinner,
			ingestEndpoint,
		} = this.props;

		handleShowSpinner(true);

		const fileName = e.target.files[0].name;

		const formData = new FormData();

		formData.append("fileName", fileName);
		formData.append("dataFile", e.target.files[0]);

		const headers = new Headers();
		headers.append("X-api-key", aaaKey);

		const requestOptions = {
			body: formData,
			headers: headers,
			method: 'POST',
			redirect: 'follow',
		};

		fetch(`${ingestEndpoint}/modelComparison`, requestOptions)
			.then(res => handleResponseCSV(res, dialog))
			.then(res => {
				// Refresh list
				this.getComparisonReports();
				handleShowSpinner(false);
			})
			.catch(error => {
				const action = `Uploading model comparison file`;
				checkPermissionsLogError(error, action);
				handleShowSpinner(false);
			});
	};

	handleDeleteSelected = () => {
		const {
			aaaKey,
			checkPermissionsLogError,
			handleShowSpinner,
			statisticsEndpoint,
		} = this.props;
		handleShowSpinner(true, "Deleting model comparison reports");
		// Delete rows that have their checkbox clicked
		const { reports } = this.state;
		const reportsSelected = reports.filter((report) => { return report.selected; });
		const reportsUnselected = reports.filter((report) => { return !report.selected; });

		const headers = new Headers();
		headers.append("Content-Type", "application/json");
		headers.append("X-api-key", aaaKey);

		const raw = JSON.stringify({ "comparisonIds": reportsSelected.map(report => report.comparisonId) });

		const requestOptions = {
			body: raw,
			headers: headers,
			method: 'POST',
			redirect: 'follow',
		};
		fetch(`${statisticsEndpoint}/model/prediction/comparison/delete/comparisonReports`, requestOptions)
			.then(handleResponse)
			.then(res => {
				this.setState({
					nSelected: 0,
					reports: reportsUnselected,
				}, () => handleShowSpinner(false));
			})
			.catch(error => {
				const action = `Deleting model comparison reports`;
				checkPermissionsLogError(error, action);
				handleShowSpinner(false);
			});
	};

	handleSelect = (id) => {
		const {
			reports,
		} = this.state;
		let {
			nSelected = 0,
		} = this.state;
		const report = reports[id];
		if (report.selected) {
			nSelected--;
		} else {
			nSelected++;
		}
		report.selected = !report.selected;
		const foundIndex = reports.findIndex(x => x.id === id);
		let newReports = copyObject(reports);
		if (foundIndex > -1) newReports = update(reports, { $splice: [[foundIndex, 1, report]] });
		this.setState({
			nSelected,
			reports: newReports,
		});
	};

	renderModelComparison = () => {
		const {
			aaaKey,
			dialog,
			handleShowSpinner,
			logError,
			statisticsEndpoint,
		} = this.props;
		const {
			reports = [],
		} = this.state;
		const listItems = [];

		reports.forEach((report, index) => {
			listItems.push(<tr key={`${report}${index}-tr`}>
				<td
					className='checkbox report'
					key={`${report}${index}checkboxtd`}
				>
					<CheckBox
						id={`delete${index}`}
						key={`${report}${index}checkbox`}
						value={report.selected}
						handleChange={() => { this.handleSelect(index); }} />
				</td>
				<td
					className='name'
					key={`${report}${index}nametd`}
				>{report.fileName}</td>
				<td
					className='start-time'
					key={`${report}${index}starttimetd`}
				>{new Date(report.timeStamp).toUTCString()}</td>
				<td
					className='status'
					key={`${report}${index}statustd`}
				>{report.status}</td>
				<td className={`report-cell`}>
					{report.status !== "Running" ?
						<>
							<SecureDownload
								aaaKey={aaaKey}
								contentType='application/json'
								dialog={dialog}
								fileName={`${report.fileName}.json`}
								handleShowSpinner={handleShowSpinner}
								href={`${statisticsEndpoint}/model/prediction/comparison/report/${report.comparisonId}`}
								logError={logError}
							/>
							<img
								alt='view'
								className={`view`}
								id='showInfo'
								onClick={() => {
									this.viewReport(report.comparisonId);
								}}
								src={view}
								title={`View`}
							/>
						</>
						:
						'Pending'
					}
				</td>
				<td>
					{report.status !== "Running" ?

						<SecureDownload
							aaaKey={aaaKey}
							contentType='text/csv'
							dialog={dialog}
							fileName={`${report.fileName}.csv`}
							handleShowSpinner={handleShowSpinner}
							href={`${statisticsEndpoint}/model/prediction/comparison/results/${report.comparisonId}`}
							logError={logError}
						/>
						:
						'Pending'
					}
				</td>
			</tr >);
		});
		return listItems;
	};

	render() {
		const { className = '' } = this.props;
		const {
			nSelected = 0,
			reportJSON,
			reports = [],
			reportsLoaded = false,
			serverStatus = 0,
			showInfo = false,
		} = this.state;

		const CSVloaded = false;
		const CSVfileError = '';

		return (
			<>
				<div id='modelComparison' className={`${className}`} >
					{reportsLoaded & reports.length > 0 ?
						<>
							<div
								className={`${showInfo ? 'show' : ''}`}
								id='info'
							>
								<header>
									< button
										title="Close"
										onClick={(e) => { this.setState({ showInfo: false }); }}
										className='window-close' >
									</button >
								</header>
								<div className='background' />
								<div className={`for-scrolling`}>
									<>
										{reportJSON ? renderHierarchy(reportJSON, 1) : null}
									</>
								</div>
							</div>
							<div className={`table-container`}>
								<table
									className='striped model-comparison-reports'
									key={`model-comparison-table`}
								>
									<thead
										key={`model-comparison-table-thead`}
									>
										<tr
											key={`model-comparison-table-thead-tr`}
										>
											<th
												key={`model-comparison-table-thead-tr-th1`}
											></th>
											<th
												key={`model-comparison-table-thead-tr-th2`}
											>File Name</th>
											<th
												key={`model-comparison-table-thead-tr-th3`}
											>Start Time</th>
											<th
												key={`model-comparison-table-thead-tr-th4`}
											>Status</th>
											<th
												key={`model-comparison-table-thead-tr-th5`}
											>Report</th>
											<th
												key={`model-comparison-table-thead-tr-th6`}
											>Results</th>
										</tr>
									</thead>
									<tbody
										key={`model-comparison-table-tbody`}
									>
										{serverStatus === 423 ?
											<tr><td></td><td>File name pending</td><td>Running</td><td></td></tr>
											:
											null}
										{this.renderModelComparison()}
									</tbody>
								</table>
							</div>
						</>
						:
						<p>No model comparison reports available presently.</p>
					}
					<div className={`command-buttons ${!reportsLoaded || reports.length === 0 ? 'upload-only' : ''}`}>
						{reportsLoaded & reports.length > 0 ?
							<button
								onClick={(e) => {
									this.handleDeleteSelected();
								}}
								className={`home ${(nSelected === 0) ? 'disabled' : ''}`}
								disabled={nSelected === 0}
								id={`ButtonDelete`}
							>Delete</button>
							:
							null
						}

						<div className={`csv-loader ${CSVloaded ? 'loaded' : 'not-loaded'} `}>
							<input type='file'
								className={`csv-input`}
								error={CSVfileError}
								id='CSVreader'
								accept="application/CSV"
								onChange={this.handleCSVload}
								value=''
							/>
						</div>
					</div>
				</div >
			</>
		);
	}

	viewReport = (id) => {
		this.setState({
			reportJSON: undefined,
			showInfo: false,
		});
		const {
			aaaKey,
			logError,
			statisticsEndpoint,
		} = this.props;

		const headers = new Headers();
		headers.append("Content-Type", 'application/json');
		headers.append("X-api-key", aaaKey);
		headers.append("Accept", 'text/csv');

		const requestOptions = {
			headers: headers,
			method: 'GET',
			redirect: 'follow',
		};

		// Anti cache
		const ms = Date.now();

		fetch(`${statisticsEndpoint}/model/prediction/comparison/report/${id}?anticache=${ms}`, requestOptions)
			.then(handleResponse)
			.then(res => {
				this.setState({
					reportJSON: res,
					showInfo: true,
				});
			})
			.catch(error => {
				const action = `Retrieving secure download`;
				logError(action, error);
			});
	};
}