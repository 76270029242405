import './HelpButton.scss';
import '../../styles/LoginAndPasswordReset.scss';
import React from 'react';

export class HelpButton extends React.PureComponent {

	render() {
		return (
			<a
				id='helpButton'
				href={`https://optibrium.com/stardrop-help/`}
				target={`_blank`}
				title={`Request support`}
				rel={`noreferrer`}>?</a>
		);
	}
}