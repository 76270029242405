import './MassiveMatrix.scss';
import {
	handleResponse,
} from '../../../utility';
import React from 'react';

export class MassiveMatrix extends React.PureComponent {

	constructor(props) {
		super(props);

		this.state = {
		};
	}

	componentDidMount = () => {

		const {
			aaaKey,
			checkPermissionsLogError,
			statisticsEndpoint,
		} = this.props;

		if (statisticsEndpoint) {

			const headers = new Headers();
			// headers.append("Content-Type", "application/json");
			headers.append("X-api-key", aaaKey);

			const requestOptions = {
				headers: headers,
				method: 'GET',
				redirect: 'follow',
			};

			fetch(`${statisticsEndpoint}/model/statistics`, requestOptions)

				.then(handleResponse)
				.then(res => {
					this.setState(res);
					// We should now have the AAA and query interface endpoints and the Company code.
				})
				.catch(error => {
					const action = `Retrieving data matrix statistics`;
					checkPermissionsLogError(error, action);
				});
		}
	};

	render() {
		const {
			endpointCount,
			compoundCount,
		} = this.state;

		return (
			<>
				<h1>Data Matrix</h1>
				<table className='massive-matrix'>
					<tbody>
						<tr><th>Total compounds:</th><td>{compoundCount}</td></tr>
						<tr><th>Total endpoints:</th><td>{endpointCount}</td></tr>
					</tbody>
				</table>
			</>
		);
	}
}
