import './UserForm.scss';
import {
	addPolicy,
	copyObject,
	hasPolicy,
	removePolicy,
} from '../../utility';
import {
	CheckBox,
	DataSourcesList,
	FieldSet,
	GroupsList,
	PoliciesList,
	TextField,
} from '../../Components';
import React from "react";

export class UserForm extends React.Component {
	constructor(props) {
		super(props);

		const {
			user: userOriginal = {},
		} = this.props;

		const user = copyObject(userOriginal);

		const {
			name = '',
			password = '',
		} = user;

		this.state = ({
			name: name,
			nameError: '',
			oldPassword: '',
			password1: password,
			password1Error: '',
			password2: password,
			password2Error: '',
			passwordLink: '',
			showUsersOwnPasswordFields: false,
			user: user,
			valid: true,
		});

		this.handleChange = this.handleChange.bind(this);
		this.handleChangeDataSource = this.handleChangeDataSource.bind(this);
		this.handleChangeGroup = this.handleChangeGroup.bind(this);
		this.handleChangePolicy = this.handleChangePolicy.bind(this);
		this.setActive = this.setActive.bind(this);
		this.validate = this.validate.bind(this);
		this.validateName = this.validateName.bind(this);
		this.validatePasswords = this.validatePasswords.bind(this);
	}

	handleChangeGroup = (groupId) => {
		const {
			user,
		} = this.state;

		const { groups: newGroups = []} = user;

		const foundIndex = newGroups.indexOf(groupId);
		if (foundIndex >= 0) {
			newGroups.splice(foundIndex, 1);
		} else {
			newGroups.push(groupId);
		}

		user.groups = newGroups;

		this.setState({
			owneduser: user,
		});
	};

	handleChangeDataSource = (service, action) => {
		action = action.toUpperCase();
		let {
			user,
		} = this.state;

		if (hasPolicy(user, 'query_interface', service, action)) {
			// Has it, so remove it
			user = removePolicy(user, 'query_interface', service, action);
		} else {
			user = addPolicy(user, 'query_interface', service, action);
		}
		this.setState({
			user:user,
		});
	};

	handleChangePolicy = (service, target, action) => {
		action = action.toUpperCase();
		let {
			user,
		} = this.state;

		if (hasPolicy(user, service, target, action)) {
			// Has it, so remove it
			user = removePolicy(user, service, target, action);
		} else {
			user = addPolicy(user, service, target, action);
		}
		this.setState({
			user:user,
		});
	};

	handleChange(field, value) {
		this.setState({ [field]: value }, this.validate);
	}

	setActive = () => {
		const { user } = this.state;
		const { active } = user;
		user.active = !active;
		this.setState({
			user: user
		});
	};

	validateName = () => {
		const {
			AAA,
			users,
		} = this.props;

		let valid = true;
		let nameError = '';

		const {
			name,
		} = this.state;

		if (!AAA.name.valid(name)) {
			valid = false;
			nameError = 'Name does not conform to name rules';
		}

		// Belt and braces check for unique name. Server should also do this
		users.forEach((user) => {
			if (user.name === name.trim()) {
				nameError = 'Name has to be unique';
				valid = false;
				this.setState({
					nameError: nameError,
				});
			}
		});

		if (name.trim() === '') {
			nameError = 'Please enter a name';
			valid = false;
		}

		if (valid) nameError = '';

		this.setState({
			nameError: nameError,
			valid: valid,
		});

		return valid;
	};

	validatePasswords = () => {
		const {
			AAA
		} = this.props;

		const {
			password1,
			password2,
		} = this.state;

		if (password1.trim() === '') {
			this.setState({
				password1Error: "Please enter a password",
			});
			return false;
		}

		if (AAA && AAA.passwordPolicy !== undefined && !AAA.passwordPolicy.valid(password1)) {
			this.setState({
				password1Error: 'Password does not conform to password rules',
			});
			return false;
		}

		if (password1 === password2) {
			this.setState({
				password1Error: '',
				password2Error: '',
			});
			return true;
		} else {
			this.setState({
				password1Error: "Passwords do not match",
				password2Error: "Passwords do not match"
			});
			return false;
		}
	};

	validate = () => {
		const {
			secondaryNav,
			userName,
		} = this.props;

		const {
			name,
			showUsersOwnPasswordFields,
		} = this.state;

		let valid = true;

		if (secondaryNav === 'add') {
			valid = this.validateName();
		}
		if ((name !== undefined && userName !== name) || (userName === name && showUsersOwnPasswordFields)) {
			valid = this.validatePasswords();
		}
		this.setState({
			valid: valid,
		});
	};

	handleChangePassword = () => {
		const {
			showUsersOwnPasswordFields
		} = this.state;
		this.setState({
			showUsersOwnPasswordFields: !showUsersOwnPasswordFields
		}, this.validate);
	};

	render() {

		const {
			adminGroupName,
			appName,
			dataSources,
			getGroupById,
			grantDataSourcesList,
			grantGroupForUserList,
			grantGroupList,
			grantPolicyList,
			groups,
			handleAddUpdateUser,
			handleCancelClick,
			nameRules,
			passwordRules,
			policies,
			secondaryNav,
			superUI,
			userName,
		} = this.props;

		const {
			name,
			nameError,
			oldPassword,
			password1,
			password2,
			password1Error,
			password2Error,
			showUsersOwnPasswordFields = false,
			user,
			valid,
		} = this.state;

		const { active } = user;

		return (
			<form className={`form user ${superUI ? 'super-ui' : ''}`}>
				<FieldSet className={`details user ${(secondaryNav === 'edit' && userName === name) ? 'viewingSelf' : ''} ${showUsersOwnPasswordFields ? 'showUsersOwnPasswordFields' : ''}`}>
					<h2>User</h2>
					<>
						<TextField
							autocomplete='off'
							className='alternating'
							disabled={secondaryNav === 'edit'}
							error={nameError}
							id='name'
							placeholder='Name'
							label='Name*'
							value={name}
							handleChange={this.handleChange}
							tabIndex={1}
						/>
						{(nameRules !== undefined && secondaryNav !== 'edit') ?
							<div
								id='nameRules'
								className={'hideOnUpdate alternating'}
							>
								<p>{nameRules}</p>
							</div> : null}
					</>
					{(secondaryNav === 'edit' && userName === name) ?
						<>
							<CheckBox
								className='user alternating'
								label={`Update password?`}
								id={`updatePassword`}
								key={`updatePasswordKey`}
								title={`Check this if you want to update your password`}
								value={showUsersOwnPasswordFields}
								handleChange={this.handleChangePassword}
							/>
							<TextField
								autocomplete='off'
								className={'hideOnUpdate alternating'}
								handleChange={this.handleChange}
								id='oldPassword'
								label='Old Password*'
								placeholder='Old Password'
								tabIndex={1}
								type={'password'}
								value={oldPassword}
							/>
						</>
						: null}
					<TextField
						autocomplete='off'
						className={'hideOnUpdate alternating'}
						error={password1Error}
						handleChange={this.handleChange}
						id='password1'
						label='Password*'
						placeholder='Password'
						tabIndex={2}
						type={'password'}
						value={password1}
					/>
					<TextField
						autocomplete='off'
						className={'hideOnUpdate alternating'}
						error={password2Error}
						handleChange={this.handleChange}
						id='password2'
						label='Please retype password*'
						placeholder='Please retype password'
						tabIndex={3}
						type={'password'}
						value={password2}
					/>
					{passwordRules !== undefined ?
						<div
							id='passwordRules'
							className={'hideOnUpdate alternating'}
						>
							<p>{passwordRules}</p>
						</div>
						:
						null
					}
					{user.id === userName ?
						<CheckBox
							className='active'
							id='active'
							label='Active'
							placeholder='Active'
							title={`You can't make yourself inactive`}
							value={3}
						/>
						:
						<CheckBox
							className='active'
							handleChange={() => {
								this.setActive();
							}}
							id='active'
							label='Active'
							placeholder='Active'
							value={active}
						/>
					}
					{name === 'Admin' ? <p style={{ color: 'red' }}>{`You should exercise extreme caution if removing groups or policies from the ${name} user as you may no longer have sufficient privileges to revert your change.`}</p> : null}
				</FieldSet>
				{grantGroupList && grantGroupForUserList ?
					< FieldSet className='group'>
						<h2>Groups</h2>
						<GroupsList
							adminGroupName={adminGroupName}
							groups={groups}
							handleChangeGroup={this.handleChangeGroup}
							user={user}
							userName={userName}
						/>
					</FieldSet> : null}
				{grantPolicyList && appName === 'Cerella' && superUI ? <FieldSet className='policy'>
					<h2>Policies</h2>
					<PoliciesList
						getGroupById={getGroupById}
						handleChangePolicy={this.handleChangePolicy}
						policies={policies}
						policiesOwner={user}
						what={'user'}
					/>
				</FieldSet> : null}
				{grantDataSourcesList ? <FieldSet className='data-source'>
					<h2>Data sources</h2>
					<DataSourcesList
						dataSources={dataSources}
						dataSourcesOwner={user}
						getGroupById={getGroupById}
						handleChangeDataSource={this.handleChangeDataSource}
						superUI={superUI}
						what={'user'}
					/>
				</FieldSet> : null}
				<div className='command-buttons'>
					<button
						className='user'
						id='userButtonCancel'
						onClick={(e) => {
							e.preventDefault();
							handleCancelClick(e);
						}}
					>Cancel</button>
					<button
						className={`user ${!valid ? 'disabled' : ''}`}
						disabled={!valid}
						id={`userButton${secondaryNav === 'add' ? 'Add' : 'Update'}`}
						onClick={(e) => {
							e.preventDefault();
							handleAddUpdateUser(
								{
									active: active,
									groups: user.groups,
									id: name,
									name: name,
									password: password1,
									policies: user.policies,
								}
								, (secondaryNav === 'add'));
						}}
						tabIndex={4}
					>{secondaryNav === 'add' ? 'Add' : 'Update'}</button>
				</div>
			</form >
		);
	}
}