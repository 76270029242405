import { CheckBox } from '..';
import React from "react";

export class GroupsList extends React.Component {
	render() {
		const {
			adminGroupName,
			groups,
			handleChangeGroup,
			user,
			userName,
		} = this.props;

		const { groups: ownedGroups = [] } = user;

		const groupsForm = [];
		groups.forEach((group) => {
			if (group.name === adminGroupName && user.id === userName && ownedGroups.includes(group.id)) {
				groupsForm.push(
					<CheckBox
						className='group'
						id={`group-${group.id}`}
						key={`${group.name}`}
						label={`${group.name}`}
						value={3} // readonly & set
						title={`You can't remove yourself as an administrator`}
					/>
				);

			} else {
				groupsForm.push(
					<CheckBox
						className='group'
						handleChange={() => {
							handleChangeGroup(group.id);
						}}
						id={`group-${group.id}`}
						key={`${group.name}`}
						label={`${group.name}`}
						value={ownedGroups.includes(group.id)}
					/>
				);
			}
		});
		return (groupsForm);
	}
}