import './TextField.scss';
import {
	Info,
} from '../..';
import React from 'react';

export class TextField extends React.PureComponent {

	constructor(props) {
		super(props);
		this.handleKeyPress = this.handleKeyPress.bind(this);
		this.select = this.select.bind(this);
		this.setSelectionRange = this.setSelectionRange.bind(this);
		this.toggleRevealPassword = this.toggleRevealPassword.bind(this);

		const {
			parentRef
		} = this.props;

		this.state = {
			revealPassword: false,
		};

		this.inputRef = parentRef ? parentRef : React.createRef();
	};

	handleKeyPress = (e) => {
		// console.log("handleKeyPress", e);
		if (e.altKey && e.keyCode === 119) this.toggleRevealPassword(e); // alt f8
	};

	render() {
		const {
			autocomplete = '',
			children,
			className = '',
			copyButton = false,
			disabled,
			elide = false,
			error,
			handleBlur = () => {
				// This is intentional
			},
			handleChange,
			id,
			info,
			label,
			max,
			min,
			name,
			placeholder,
			readOnly = false,
			required,
			step,
			style,
			tabindex = 0,
			type,
			value = '',
		} = this.props;

		const {
			revealPassword = false,
		} = this.state;

		const revealPasswordButton = [];
		if (type === 'password') {
			revealPasswordButton.push(
				<span
					className={`reveal-password ${revealPassword ? 'reveal' : ''} ${value.length === 0 ? 'hide' : ''}`}
					key={`reveal-password`}
					onClick={this.toggleRevealPassword}
					onKeyDown={this.handleKeyPress}
					title={`${revealPassword ? 'Hide' : 'Reveal'} password`}
				>{`${revealPassword ? 'Hide' : 'Reveal'} password`}</span>
			);
		}

		return (
			<div className={`
				text-field-row
				row
				${copyButton ? 'with-button' : ''}
				${label === '' || label === undefined ? 'without-label' : ''}
				${className} 
				${value === '' ? 'unset' : ''} 
				${disabled || readOnly ? 'disabled' : ''}
				${error ? 'error' : ''}
			`}>
				{label ? <label htmlFor={id}>{label}{required ? <sup>*</sup> : ""}{info ? <Info
					infoTitle={info.title}
					infoMessage={info.message}
				/> : ""}:</label> : ''}
				{(autocomplete !== '') ?
					<div
						style={{ 'position': 'relative', ...style }}
					>
						<input
							autoComplete={autocomplete}
							className={`text-field ${type === 'password' ? 'password' : ''} ${elide ? 'elide' : ''}`}
							disabled={disabled}
							editable={readOnly ? 'false' : 'true'}
							id={id}
							max={max}
							min={min}
							name={name}
							onBlur={handleBlur}
							onChange={(e) => handleChange(id, e.target.value)}
							onKeyDown={this.handleKeyPress}
							placeholder={placeholder}
							ref={this.inputRef}
							required={required ? "required" : ""}
							step={step}
							tabIndex={tabindex}
							title={`${elide ? value : ''}`}
							type={type || 'text'}
							value={value}
						/>
						{revealPasswordButton}
					</div>
					:
					<div
						style={{ 'position': 'relative', ...style }}
					>
						<input
							className={`text-field ${type === 'password' ? 'password' : ''} ${elide ? 'elide' : ''}`}
							editable={readOnly ? 'false' : 'true'}
							disabled={disabled}
							id={id}
							max={max}
							min={min}
							onBlur={handleBlur}
							onChange={(e) => handleChange(id, e.target.value)}
							onKeyDown={this.handleKeyPress}
							placeholder={placeholder}
							ref={this.inputRef}
							required={required ? "required" : ""}
							step={step}
							tabIndex={tabindex}
							type={type || 'text'}
							title={`${elide ? value : ''}`}
							value={value}
						/>
						{revealPasswordButton}
					</div>
				}
				{children}
				<div className="error">{error}</div>
			</div >
		);
	};

	select = () => {
		const inputRef = this.inputRef.current;
		inputRef.select();
	};

	setSelectionRange = (from, to) => {
		const inputRef = this.inputRef.current;
		// structureCopyInput.value = structure;
		inputRef.setSelectionRange(from, to);
	};

	selectAll = () => {
		this.select();
		this.setSelectionRange(0, 99999);
	};

	toggleRevealPassword = (e) => {
		e.preventDefault();
		e.stopPropagation();
		const inputPassword = this.inputRef.current;
		if (inputPassword.type === "password") {
			inputPassword.type = "text";
			this.setState({revealPassword: true});
		} else {
			inputPassword.type = "password";
			this.setState({revealPassword: false});
		}
	};
}