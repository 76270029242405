import './SearchBox.scss';
import React from 'react';

export class SearchBox extends React.PureComponent {
	static displayName = "SearchBox";
	render() {
		const {
			className = '',
			handleChangeSearch,
			id,
			searchTerm = '',
			tabIndex,
			what,
		} = this.props;

		return (
			<div
				className={`${className} ${what} search-box`}
				{...id ? { id: id } : {}}
			>
				<div className="magnify-icon" />
				<input
					type='text'
					onChange={(e) => handleChangeSearch(what, e.target.value)}
					value={searchTerm}
					placeholder={`Search`}
					tabIndex={tabIndex}
				/>
				<div className={`button clear-search-button`}
					onClick={() => handleChangeSearch(what, '')}
					title='Clear'
				>Clear</div>
			</div>
		);
	}
}
