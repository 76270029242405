import {
	FieldSet,
	SecureDownload,
	Select,
} from '../../../Components';
import {
	handleResponse,
	renderHierarchy,
} from '../../../utility';
import React from 'react';
import view from '../../../images/view.svg';

export class Reports extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = ({
			selectedReport: null,
		});

		this.APIgetReports = this.APIgetReports.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.getReport = this.getReport.bind(this);
	}

	componentDidMount = () => {
		this.APIgetReports();
	};

	APIgetReports = () => {

		// console.log("APIgetReports");
		const {
			aaaKey,
			checkPermissionsLogError,
			statisticsEndpoint,
		} = this.props;

		if (statisticsEndpoint) {
			const headers = new Headers();
			headers.append("X-api-key", aaaKey);

			const requestOptions = {
				headers: headers,
				method: 'GET',
				redirect: 'follow',
			};

			return fetch(`${statisticsEndpoint}/status/reports`, requestOptions)
				.then(handleResponse)
				.then(res => {
					this.setState({
						reports: res,
						reportsRetrieved: true,
					});
				})
				.catch(error => {
					const action = `Retrieving report list`;
					checkPermissionsLogError(error, action);
				});
		}
	};

	handleChange = (field, value) => {
		switch (field){
			case 'showInfo': {
				this.getReport();
				this.setState({
					showInfo: true,
				});
				break;
			}
			case 'selectedReport': {
				this.setState({
					selectedReport: value,
					// showInfo: true,
				});
				break;
			}
			default: {
				this.setState({
					[field]: value,
				});
				break;
			}
		}
	};

	getReport = () => {
		const {
			// reportJSON,
			reports,
			reportsRetrieved = false,
			selectedReport,
		} = this.state;

		const {
			aaaKey,
			logError,
			statisticsEndpoint,
		} = this.props;

		// console.log("getReport");

		if (reports && reportsRetrieved && selectedReport){

			const headers = new Headers();
			headers.append("Content-Type", 'application/json');
			headers.append("X-api-key", aaaKey);
			headers.append("Accept", 'text/csv');

			const requestOptions = {
				headers: headers,
				method: 'GET',
				redirect: 'follow',
			};

			// Anti cache
			const ms = Date.now();

			fetch(`${statisticsEndpoint}/status/reports/${selectedReport}?anticache=${ms}`, requestOptions)
				.then(handleResponse)
				.then(res => {
					// console.log("res", res);
					this.setState({
						reportJSON: res,
					});
				})
				.catch(error => {
					const action = `Retrieving secure download`;
					logError(action, error);
				});
		}
	};

	render() {
		const {
			reportJSON,
			reports,
			reportsRetrieved = false,
			selectedReport = '',
			showInfo = false,
		} = this.state;

		const {
			aaaKey,
			dialog,
			handleShowSpinner,
			logError,
			statisticsEndpoint,
		} = this.props;

		if (reports && reportsRetrieved && statisticsEndpoint) {
			const nowDate = new Date();
			const strDate = `${nowDate.getDate()}-${(nowDate.getMonth() + 1)}-${nowDate.getFullYear()}`;

			const renderedOptions = [];
			if (reports) {
				renderedOptions.push(
					{ name: "Select a report...", value: '' }
				);
				reports.forEach((report) => {
					renderedOptions.push(
						{ name: report.reportName, value: report.reportId }
					);
				});
			}

			let reportFileName = '';
			if (selectedReport !== '') {
				const selectedReportObject = reports.find((item) => item.reportId === selectedReport);
				if (selectedReportObject) reportFileName = selectedReportObject.reportName;
			}

			return (
				<div id={'reports'}>
					<h3>Reports:</h3>

					<form>
						<FieldSet>
							<Select
								handleChange={this.handleChange}
								id='selectedReport'
								label=''
								options={renderedOptions}
								value={selectedReport}
							/>
						</FieldSet>
					</form>
					<p id='reportsLink' className={`${selectedReport ? 'show' : ''}`}>
						View: <img
							alt='view'
							className={`view ${selectedReport ? '' : 'disabled'}`}
							id='showInfo'
							onClick={() => this.handleChange('showInfo', true)}
							src={view}
							title={`View`}
						/>
						JSON: <SecureDownload
							aaaKey={aaaKey}
							contentType='application/json'
							dialog={dialog}
							handleShowSpinner={handleShowSpinner}
							fileName={`${reportFileName}-${strDate}.json`}
							href={`${statisticsEndpoint}/status/reports/${selectedReport}`}
							logError={logError}
						/>
					</p>
					<div
						className={`${showInfo ? 'show' : ''}`}
						id='info'
					>
						<header>
							< button
								title="Close"
								onClick={(e) => { this.setState({ showInfo: false }); }}
								className='window-close' >
							</button >
						</header>
						<div className='background' />
						<div className={`for-scrolling`}>
							<>
								{reportJSON ? renderHierarchy(reportJSON, 1) : null}
							</>
						</div>

					</div>
				</div>
			);
		}
	}
};
