import './SortButton.scss';
import React from 'react';

export class SortButton extends React.PureComponent {
	static displayName = "SortButton";
	render() {
		const {
			ascending,
			handleChangeSort,
			description,
			field,
			sortField,
			what,
		} = this.props;

		let orientation = 'neutral';
		if (field === sortField) {
			orientation = 'descending';
			if (ascending) orientation = 'ascending';
		}

		let intent = 'ascending';
		if (field === sortField) {
			if (ascending) intent = 'descending';
		}

		return (
			<div
				key={`${what}-${field}`}
				className={`sort-button ${what}`}
				title={`Click to sort by ${description} ${intent}`}
				onClick={() => handleChangeSort(what, field)}
			><div className={`arrow ${orientation}`}></div></div>
		);
	}
}
