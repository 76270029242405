import {
	addPolicy,
	copyObject,
	hasPolicy,
	removePolicy,
} from '../../utility';
import {
	DataSourcesList,
	FieldSet,
	PoliciesList,
	TextField
} from '../../Components';
import React from "react";

export class GroupForm extends React.Component {
	constructor(props) {
		super(props);

		const {
			group: groupOriginal = {}
		} = this.props;

		const group = copyObject(groupOriginal);

		const {
			name = '',
		} = group;

		this.state = ({
			group: group,
			name: name,
			nameError: '',
			valid: false,
		});

		this.handleChange = this.handleChange.bind(this);
		this.handleChangeDataSource = this.handleChangeDataSource.bind(this);
		this.handleChangePolicy = this.handleChangePolicy.bind(this);
		this.validate = this.validate.bind(this);
		this.validateName = this.validateName.bind(this);
	}

	componentDidMount = () => {
		const {
			secondaryNav,
		} = this.props;

		if (secondaryNav === 'edit') {
			this.validate();
		}
	};

	handleChangeDataSource = (service, action) => {
		action = action.toUpperCase();
		let {
			group,
		} = this.state;

		if (hasPolicy(group, 'query_interface', service, action)) {
			// Has it, so remove it
			group = removePolicy(group, 'query_interface', service, action);
		} else {
			group = addPolicy(group, 'query_interface', service, action);
		}
		this.setState({
			group:group,
		});
	};

	handleChangePolicy = (service, target, action) => {
		action = action.toUpperCase();
		let {
			group,
		} = this.state;

		if (hasPolicy(group, service, target, action)) {
			// Has it, so remove it
			group = removePolicy(group, service, target, action);
		} else {
			group = addPolicy(group, service, target, action);
		}

		this.setState({
			group:group,
		});
	};

	handleChange(field, value) {
		this.setState({ [field]: value }, this.validate);
	}

	validateName = () => {

		let {
			nameError,
		} = this.state;

		const {
			groups
		} = this.props;

		let valid = true;
		const {
			name,
		} = this.state;

		// Belt and braces check for unique name. Server should also do this
		groups.forEach((group) => {
			if (group.name.trim() === name.trim()) {
				nameError = 'Name has to be unique';
				valid = false;
				this.setState({
					nameError: nameError,
				});
			}
		});

		if (name.trim() === '') {
			nameError = 'Please enter a name';
			valid = false;
		}

		if (valid) nameError = '';

		this.setState({
			nameError: nameError
		});
		return valid;
	};

	validate = () => {
		const {
			secondaryNav,
		} = this.props;

		let valid = true;

		if (secondaryNav === 'add') {
			valid = this.validateName();
		}
		this.setState({
			valid: valid,
		});
	};

	render() {

		const {
			dataSources,
			getGroupById,
			grantPolicyList,
			handleAddUpdateGroup,
			handleCancelClick,
			nameRules,
			policies,
			secondaryNav,
			superUI,
		} = this.props;

		const {
			group,
			name,
			nameError,
			valid,
		} = this.state;

		return (
			<div className='form group'>
				<FieldSet className='details group'>
					<h2>Group</h2>
					<TextField
						autocomplete={'off'}
						disabled={secondaryNav === 'edit'}
						error={nameError}
						handleChange={this.handleChange}
						id='name'
						label='Name'
						placeholder='Name'
						tabIndex={1}
						value={name}
					/>
					{(nameRules !== undefined && secondaryNav !== 'edit') ?
						<div
							id='nameRules'
							className={'hideOnUpdate alternating'}
						>
							<p>{nameRules}</p>
						</div> : null}
					{(name === 'Admins' && secondaryNav === 'edit') ? <p style={{ color: 'red' }}>{`You should exercise extreme caution if removing policies from the ${name} group as you may no longer have sufficient privileges to revert your change.`}</p> : null}
				</FieldSet>
				{
					grantPolicyList && superUI ? <FieldSet className='policy'>
						<h2>Policies</h2>
						<PoliciesList
							handleChangePolicy={this.handleChangePolicy}
							policies={policies}
							policiesOwner={group}
							what={'group'}
						/>

					</FieldSet> : null
				}
				{
					grantPolicyList ? <FieldSet className='data-source'>
						<h2>Data sources</h2>
						<DataSourcesList
							dataSources={dataSources}
							dataSourcesOwner={group}
							getGroupById={getGroupById}
							handleChangeDataSource={this.handleChangeDataSource}
							superUI={superUI}
							what={'group'}
						/>

					</FieldSet> : null
				}
				<div className='command-buttons'>
					<button
						className='group'
						id='groupButtonCancel'
						onClick={handleCancelClick}
					>Cancel</button>
					<button
						className={`group ${!valid ? 'disabled' : ''}`}
						disabled={!valid}
						id={`groupButton${secondaryNav === 'add' ? 'Add' : 'Update'}`}
						onClick={() => {
							handleAddUpdateGroup(
								{
									id: name,
									name: name,
									policies: group.policies,
								}
								, (secondaryNav === 'add'));
						}}
						tabIndex={2}
					>{secondaryNav === 'add' ? 'Add' : 'Update'}</button>
				</div>
			</div >
		);
	}
}