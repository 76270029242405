import '../Statistics.scss';
import download from '../../../images/download.svg';
import {
	handleResponseCSV,
} from '../../../utility';
import React from 'react';


export class SecureDownload extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}

	handleClick = () => {
		const {
			aaaKey,
			contentType = 'text/csv',
			dialog,
			handleShowSpinner,
			fileName = 'download',
			href,
			logError,
		} = this.props;
		handleShowSpinner(true, "Downloading...");
		const headers = new Headers();
		if (contentType) {
			headers.append("Content-Type", contentType);
			headers.append("Accept", contentType);
		}
		if (contentType === 'text/csv') headers.append('responseType', 'text');
		headers.append("X-api-key", aaaKey);

		const requestOptions = {
			headers: headers,
			method: 'GET',
			redirect: 'follow',
		};

		// Anti cache
		const ms = Date.now();

		fetch(`${href}?anticache=${ms}`, requestOptions)
			.then(res => handleResponseCSV(res, dialog))
			.then(res => {

				if (!res) {
					// File not available
					handleShowSpinner(false);
					return;
				}
				const fileData = new Blob([res], { type: contentType });
				const fileUrl = URL.createObjectURL(fileData);
				const a = document.createElement('a');

				a.href = fileUrl;
				a.target = '_blank';
				a.className = 'hidden';
				a.download = fileName;

				document.body.appendChild(a);
				a.click();

				// Tidy up, delete it.
				document.body.removeChild(a);
				handleShowSpinner(false);

			})
			.catch(error => {
				const action = `Retrieving secure download`;
				logError(action, error);
			});
	};

	render() {
		return (
			<img
				alt='download'
				className='download'
				onClick={(e) => {
					this.handleClick();
					e.stopPropagation();
				}}
				src={download}
				title='Download'
			/>
		);
	}
}