import {
	Accordion,
	AccordionArticle,
	CheckBox,
} from '..';
import {
	becauseGroupPolicy,
	hasPolicy,
	sortObjectByKeys,
} from '../../utility';
import React from 'react';

export class PoliciesList extends React.Component {
	constructor(props) {
		super(props);

		this.renderActions = this.renderActions.bind(this);
		this.renderTargets = this.renderTargets.bind(this);
	}

	renderActions = (service, target) => {
		const {
			policiesOwner,
			getGroupById,
			handleChangePolicy,
			policies,
			what,
		} = this.props;

		const policiesCheckBoxes = [];
		const actions = policies[service][target];

		actions.sort().forEach((action) => {

			let value = false;
			let title = '';
			value = hasPolicy(policiesOwner, service, target, action);

			if (what === 'user' && policiesOwner.groups) {
				policiesOwner.groups.forEach((groupId) => {
					const group = getGroupById(groupId);
					if (group.policies) {
						if (hasPolicy(group, service, target, action)) {
							title = becauseGroupPolicy;
							value = 3;
						}
					}
				});
			}

			policiesCheckBoxes.push(
				<CheckBox
					className='policy'
					label={`${action.toLowerCase()}`}
					id={`policy-${service}-${target}-${action}`}
					key={`${service}-${target}-${action}`}
					title={title}
					value={value}
					handleChange={() => {
						handleChangePolicy(service, target, action);
					}}
				/>
			);
		});
		return policiesCheckBoxes;
	};

	renderTargets = (service) => {
		const {
			policies,
		} = this.props;

		const policiesTargets = [];

		for (const target in sortObjectByKeys(policies[service])) {

			policiesTargets.push(
				<AccordionArticle
					id={target}
					key={target}
					title={target}
				>
					{this.renderActions(service, target)}
				</AccordionArticle>

			);
		}
		return policiesTargets;
	};

	render() {
		const {
			policies,
		} = this.props;

		const policiesForm = [];

		for (const service in sortObjectByKeys(policies)) {
			policiesForm.push(
				<AccordionArticle
					id={service}
					key={service}
					title={service}
				>
					{this.renderTargets(service)}
				</AccordionArticle>);
		}
		return (
			<Accordion
				id='policiesList'
				key='policiesList'
			>
				{policiesForm}
			</Accordion>);
	}
}
