import '../Statistics.scss';
import {
	CheckBox,
	FieldSet,
	TextField,
} from '../../Form';
import colourVariables from '../../../styles/_colours.module.scss';
import { copyObject } from '../../../utility';
import Plot from 'react-plotly.js';
import plotVariables from './ImportanceMatrix.scss';
import React from 'react';
import { readRemoteFile } from 'react-papaparse';
import { SecureDownload } from '../SecureDownload/SecureDownload';

export class ImportanceMatrix extends React.PureComponent {

	constructor(props) {
		super(props);
		const {
			plotHeight = 750,
			plotWidth = 1200,
		} = plotVariables;

		this.state = ({
			filterInput: '',
			filterOutput: '',
			height: plotHeight,
			hideBlankColumns: false,
			hideDescriptors: false,
			limit: false,
			nothingToSeeHere: false,
			topList: 5,
			width: plotWidth,
			xLabelsHide: false,
			yLabelsHide: false,
		});

		this.handleChange = this.handleChange.bind(this);
		this.refresh = this.refresh.bind(this);
		this.resetZoom = this.resetZoom.bind(this);
	}

	componentDidMount = () => {
		this.refresh();
	};

	refresh = () => {
		const {
			aaaKey,
			handleShowSpinner,
			logError,
			statisticsEndpoint,
		} = this.props;
		if (statisticsEndpoint) {
			const {
				filterInput,
				filterOutput,
				hideBlankColumns,
				hideDescriptors,
				limit,
				topList,
				xLabelsHide,
				yLabelsHide,
			} = this.state;

			handleShowSpinner(true);
			let firstRow = true;
			let secondRow = false;
			let xValues;
			let yValues = [];
			let zValues = [];
			let descriptions;

			readRemoteFile(`${statisticsEndpoint}/model/importanceMatrix?descriptorDefinitions=True`, {
				complete: () => {

					const colourscaleValue = [
						[0, '#ffffff'],
						[1, '#ff0000']
					];

					// Last row can sometimes be null :-(
					if (yValues[yValues.length - 1] === null) {
						yValues = yValues.slice(0, yValues.length - 1);
						zValues = zValues.slice(0, yValues.length);
					}

					const layout = {
						annotations: [],
						legend: {
							orientation: "h",
						},
						margin: {
							b: (xLabelsHide ? 40 : 260),
							l: 20,
							r: 40,
							t: 20,
						},
						showlegend: true,
						xaxis: {
							showgrid: false,
							showline: true,
							showticklabels: !xLabelsHide,
							ticks: 'outside',
						},
						yaxis: {
							automargin: true,
							autorange: 'reversed',
							showgrid: false,
							showline: true,
							showticklabels: !yLabelsHide,
							ticks: 'outside',
							title: {
								standoff: 10,
								text: 'Outputs',
							}
						}
					};

					const thresholdValues = copyObject(zValues); // Needed for deep copy
					if (limit) {
						for (let row = 0; row < thresholdValues.length; row++) {
							const sortedRow = thresholdValues[row].slice(); // Ok for shallow copy
							sortedRow.sort((a, b) => { return b - a; });
							const threshold = sortedRow[topList - 1];
							for (let col = 0; col < thresholdValues[row].length; col++) {
								if (thresholdValues[row][col] < threshold) thresholdValues[row][col] = 0;
							}
						}
					}

					// identify descriptors
					const columnIsDescriptor = [];
					for (let col = 0; col < xValues.length; col++) {
						columnIsDescriptor[col] = true;
						for (let row = 0; row < yValues.length; row++) {
							if (yValues[row] === xValues[col]) {
								// Any x column for which there is no matching x label and y label
								columnIsDescriptor[col] = false;
							}
						}
					}

					// identify blank columns
					const columnIsBlank = [];
					// Initialise columnIsBlank
					for (let col = 0; col < thresholdValues[0].length; col++) {
						columnIsBlank[col] = true;
					}

					// Discover blank columns (all zero values)
					for (let row = 0; row < thresholdValues.length; row++) {
						for (let col = 0; col < thresholdValues[row].length; col++) {
							if (thresholdValues[row][col] > 0) columnIsBlank[col] = false;
						}
					}

					// identify filtered (in) columns
					const columnIsFilteredIn = [];

					// Discover filtered (in) columns
					for (let col = 0; col < xValues.length; col++) {
						columnIsFilteredIn[col] = `${xValues[col]}`.indexOf(filterInput) > -1;
					}

					// identify filtered (in) rows
					const rowIsFilteredIn = [];

					// Discover filtered (in) rows
					for (let row = 0; row < yValues.length; row++) {
						rowIsFilteredIn[row] = `${yValues[row]}`.indexOf(filterOutput) > -1;
					}

					const filteredDescriptions = [];
					const filteredXValues = [];
					const filteredYValues = [];
					const filteredZValues = [];

					const {
						cerellaHomeColour = '#fdbd75',
					} = colourVariables;

					const hoverTemplate = '<b>%{text}</b><br>' +
						'<br>x: %{x}' +
						'<br>y: %{y}' +
						'<br>z: %{z}' +
						'<extra></extra>';

					const config = {
						displaylogo: false,
						responsive: true,
					};

					if (hideBlankColumns || hideDescriptors || filterInput !== '' || filterOutput !== '') {

						// Filter z
						for (let row = 0; row < thresholdValues.length; row++) {
							const newRow = [];
							for (let col = 0; col < thresholdValues[row].length; col++) {
								let push = true;
								if (hideBlankColumns && columnIsBlank[col]) {
									push = false;
								}
								if (hideDescriptors && columnIsDescriptor[col]) {
									push = false;
								}
								if (filterInput !== '' && !columnIsFilteredIn[col]) {
									push = false;
								}
								if (filterOutput !== '' && !rowIsFilteredIn[row]) {
									push = false;
								}

								if (push) {
									newRow.push(thresholdValues[row][col]);
								}
							}
							if (newRow.length > 0) filteredZValues.push(newRow);
						}

						// Filter x (labels) (and descriptions)
						for (let col = 0; col < xValues.length; col++) {
							let push = true;
							if (hideBlankColumns && columnIsBlank[col]) {
								push = false;
							}
							if (hideDescriptors && columnIsDescriptor[col]) {
								push = false;
							}
							if (filterInput !== '' && !columnIsFilteredIn[col]) {
								push = false;
							}

							if (push) {
								filteredXValues.push(xValues[col]);
								filteredDescriptions.push(descriptions[col]);
							}
						}

						// Filter y (labels)
						for (let row = 0; row < yValues.length; row++) {
							let push = true;
							if (filterOutput !== '' && !rowIsFilteredIn[row]) push = false;

							if (push) filteredYValues.push(yValues[row]);
						}

						// Need to repeat descriptions for every row :-(
						const descriptions2d = [];
						for (let i = 0; i < yValues.length; i++) {
							descriptions2d.push(filteredDescriptions);
						}

						const nothingToSeeHere = (filteredXValues.length === 0 || filteredYValues.length === 0);
						this.setState({
							columnIsBlank: columnIsBlank,
							columnIsDescriptor: columnIsDescriptor,
							importanceMatrix: {
								config: config,
								data: [{
									colorscale: colourscaleValue,
									hoverlabel: { bgcolor: cerellaHomeColour },
									hovertemplate: hoverTemplate,
									showscale: true,
									text: descriptions2d,
									type: 'heatmap',
									x: filteredXValues,
									y: filteredYValues,
									z: filteredZValues,
								}],
								layout: layout,
							},
							nothingToSeeHere: nothingToSeeHere,
							xValues: xValues,
							yValues: yValues,
							zValues: zValues,
						}, () => { handleShowSpinner(false); });

					} else {
						const nothingToSeeHere = (xValues.length === 0 || yValues.length === 0);

						// Need to repeat descriptions for every row :-(
						const descriptions2d = [];
						for (let i = 0; i < yValues.length; i++) {
							descriptions2d.push(descriptions);
						}

						this.setState({
							columnIsBlank: columnIsBlank,
							columnIsDescriptor: columnIsDescriptor,
							importanceMatrix: {
								config: config,
								data: [{
									colorscale: colourscaleValue,
									hoverlabel: { bgcolor: cerellaHomeColour },
									hovertemplate: hoverTemplate,
									showscale: true,
									text: descriptions2d,
									type: 'heatmap',
									x: xValues,
									y: yValues,
									z: thresholdValues,
								}],
								layout: layout,
							},
							nothingToSeeHere: nothingToSeeHere,
							xValues: xValues,
							yValues: yValues,
							zValues: zValues,
						}, () => { handleShowSpinner(false); });
					}

					this.resetZoom();
				},
				downloadRequestHeaders: {
					'X-api-key': aaaKey,
				},
				dynamicTyping: true,
				error: (error) => {
					logError('Parsing importance matrix', error);
				},
				step: (row) => {
					if (firstRow) {
						xValues = row.data.slice(1); // Remove first item
						firstRow = false;
						secondRow = true;
					} else if (secondRow) {
						descriptions = row.data.slice(1).map((t) => t ? t : ''); // Remove first item
						secondRow = false;
					} else {
						if (row.data[0] !== '') {
							yValues.push(row.data[0]);
							zValues.push(row.data.slice(1)); // Remove first item
						}
					}
				},
			});
		}
	};

	resetZoom = () => {
		const zoomOutButton = document.querySelector('#importanceMatrix  a[data-attr="zoom"][data-val="reset"]');
		if (zoomOutButton) zoomOutButton.click();
	};

	antiBounce = undefined;
	bounceDelay = 2000; // Ms

	handleChange = (field, value) => {
		let {
			hideBlankColumns,
			limit,
		} = this.state;

		switch (field) {
			case 'filterInput':
				this.canRefresh = false;
				this.setState({
					filterInput: value,
				}, () => {
					clearTimeout(this.antiBounce);
					this.antiBounce = setTimeout(() => {
						this.refresh();
					}, this.bounceDelay);
				});
				break;
			case 'filterOutput':
				this.setState({
					filterOutput: value,
				}, () => {
					clearTimeout(this.antiBounce);
					this.antiBounce = setTimeout(() => {
						this.refresh();
					}, this.bounceDelay);
				});
				break;
			case 'hideBlankColumns':
				hideBlankColumns = value;
				this.setState({
					hideBlankColumns: value,
				}, this.refresh);
				break;
			case 'hideDescriptors':
				this.setState({
					hideDescriptors: value,
				}, this.refresh);
				break;
			case 'limit':
				limit = value;
				if (limit && hideBlankColumns === false) {
					hideBlankColumns = true;
					this.setState({
						hideBlankColumns: true,
						limit: value,
					}, this.refresh);
				} else {
					this.setState({
						limit: value,
					}, this.refresh);
				}
				break;
			case 'xLabelsHide':
				this.setState({
					xLabelsHide: value,
				}, this.refresh);
				break;
			case 'yLabelsHide':
				this.setState({
					yLabelsHide: value,
				}, this.refresh);
				break;
			case 'topList':
				const newVal = parseInt(value);
				if (isNaN(newVal)) return;
				this.setState({
					topList: Math.max(1, newVal),
				}, this.refresh);
				break;
		};
	};

	componentDidUpdate = (oldProps) => {
		const { refreshStatistics: oldRefreshStatistics } = oldProps;
		const { refreshStatistics } = this.props;
		if (refreshStatistics !== oldRefreshStatistics) this.refresh();
	};

	render() {

		const {
			filterInput,
			filterOutput,
			hideBlankColumns,
			hideDescriptors,
			importanceMatrix,
			limit,
			nothingToSeeHere,
			topList,
			xLabelsHide,
			yLabelsHide,
		} = this.state;

		const {
			aaaKey,
			className,
			dialog,
			handleShowSpinner,
			logError,
			statisticsEndpoint,
			strDate,
		} = this.props;

		if (importanceMatrix) {
			return (
				<>
					< form
						id='importanceMatrixForm'
					>
						<FieldSet>
							<CheckBox
								className='active'
								handleChange={this.handleChange}
								id='hideBlankColumns'
								label='Hide zero value columns'
								value={hideBlankColumns}
							/>
							<CheckBox
								className='active'
								handleChange={this.handleChange}
								id='hideDescriptors'
								label='Hide descriptors'
								value={hideDescriptors}
							/>
							<CheckBox
								className='active'
								handleChange={this.handleChange}
								id='xLabelsHide'
								label='Hide X axis labels'
								value={xLabelsHide}
							/>
							<CheckBox
								className='active'
								handleChange={this.handleChange}
								id='yLabelsHide'
								label='Hide Y axis labels'
								value={yLabelsHide}
							/>
							<TextField
								className={`filter-input filter`}
								handleChange={this.handleChange}
								id='filterInput'
								label='Filter input'
								placeholder='Type to search'
								type='text'
								value={filterInput}
							/>
							<TextField
								className={`filter-output filter`}
								handleChange={this.handleChange}
								id='filterOutput'
								label='Filter output'
								placeholder='Type to search'
								type='text'
								value={filterOutput}
							/>
							<CheckBox
								className='active'
								handleChange={this.handleChange}
								id='limit'
								label='Limit to top scores'
								value={limit}
							/>
							<TextField
								className={`${limit ? 'show' : 'hide'} per-row`}
								id='topList'
								label='Show top'
								step='any'
								type='number'
								min={1}
								handleChange={this.handleChange}
								value={topList}
							/>
						</FieldSet>
						<p>CSV:
							<SecureDownload
								aaaKey={aaaKey}
								contentType='text/csv'
								dialog={dialog}
								handleShowSpinner={handleShowSpinner}
								fileName={`importanceMatrix-${strDate}.csv`}
								href={`${statisticsEndpoint}/model/importanceMatrix`}
								logError={logError}
								strDate={strDate}
							/>
						</p>
					</form >
					<div className='optimisation-chart-container'>
						{nothingToSeeHere ?
							<div className='nothing-to-see'>
								<h1>There is no resulting data to display.</h1>
								<h2>Remove some of the filters in the left panel.</h2>
							</div>
							:
							<>
								<span className={`fakeAxisLabelToWorkRoundPlotlyBug`}>Inputs</span>
								<Plot
									className={`optimisation-chart chart ${className ? className : ''} `}
									divId='importanceMatrixPlot'
									useResizeHandler
									data={importanceMatrix.data}
									layout={importanceMatrix.layout}
									config={importanceMatrix.config} >
								</Plot>
							</>
						}
					</div>
				</>
			);
		} else {
			return null;
		}
	}
};
