import './RecordCount.scss';
import React from 'react';

export class RecordCount extends React.PureComponent {
	static displayName = "RecordCount";
	render() {
		const {
			className = '',
			handleChangeRecordCount,
			recordCount,
			what,
		} = this.props;

		return (
			<div
				className={`${className} ${what} record-count`}
			>
				<div className={`container  ${what}`}>
					<div
						className='input'
					>{recordCount}</div>
					<div
						className={`button`}
						onClick={() => handleChangeRecordCount(what, recordCount + 1)}
						title='Increase by 1'
					/>
					<div
						className={`button ${(recordCount <= 1) ? 'disabled' : ''}`}
						onClick={() => (recordCount > 1) ? handleChangeRecordCount(what, recordCount - 1) : null}
						disabled={(recordCount <= 1)}
						title='Decrease by 1'
					/>
				</div>
				<label>Records per page</label>
			</div>
		);
	}
}
