import './Spinner.scss';
import {
	Logo,
} from '..';
import React from 'react';

export class Spinner extends React.PureComponent {
	render() {

		const {
			showSpinner,
			spinnerMessage = "Loading...",
		} = this.props;

		return (
			<div
				className={`${showSpinner ? 'show' : ''}`}
				id='spinner'
			>
				<div className='background' />
				<h1>{spinnerMessage}</h1>
				<Logo />
			</div>
		);
	}
}