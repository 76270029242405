import './Crumbtrail.scss';
import React from 'react';
export class Crumbtrail extends React.PureComponent {

	render() {

		const { trail = [], primaryNav, secondaryNav } = this.props;
		const crumbs = [];
		const className = primaryNav;

		if (secondaryNav === 'edit') {
			crumbs.push(
				<li key={`crumbEdit`} className={`${className} no-click`} > Edit</li >
			);
		}

		if (secondaryNav === 'add') {
			crumbs.push(
				<li key={`crumbEdit`} className={`${className} no-click`} >Add</li >
			);
		}

		for (let i = trail.length; i--; i >= 0) {
			const { name, className, handleClick } = trail[i];
			crumbs.push(
				<li key={`crumb${i}`} className={className} onClick={handleClick} >{name}</li >
			);
		}

		return (
			<ul id='crumbTrail' >
				{crumbs}
			</ul >
		);
	}
}