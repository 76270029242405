import './PreFilter.scss';
import filterOutline from '../../../images/filter_outline.svg';
import { handleResponse } from '../../../utility';
import React from 'react';
import ReactJson from 'react-json-view';

export class PreFilter extends React.PureComponent {
	constructor(props) {
		super(props);
		// const initialJSON = { [id]: { "properties": {} } };
		this.state = {
			// filterJSON: initialJSON, // { [id]: { "properties": {} } },
			showFilterForm: false,
			// strFilter: JSON.stringify(initialJSON)
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleStringPaste = this.handleStringPaste.bind(this);
	}

	handleChange = (s) => {
		const {
			updated_src: updatedSource,
		} = s;
		this.setState({
			filterJSON: updatedSource,
			strFilter: JSON.stringify(updatedSource),
			validJSON: true,
		});
	};

	handleClear = () => {
		this.setState({
			filterJSON: {},
			strFilter: '{}',
			validJSON: true,
		});
	};

	handleClick = (e) => {
		// console.log("handleClick", e);
		e.preventDefault();
		e.stopPropagation();

		// console.log("FETCH config, set filterJSON");

		const {
			aaaKey,
			id,
			ideaTrackerService,
			logError,
		} = this.props;

		const IDTheaders = new Headers();
		IDTheaders.append("X-api-key", aaaKey);

		const IDTRequestOptions = {
			headers: IDTheaders,
			method: 'GET',
			// mode: 'no-cors',
			redirect: 'follow',
		};

		fetch(`${ideaTrackerService}/configuration/dataSource/${id}`, IDTRequestOptions)
			.then(handleResponse)
			.then(res => {
				// console.log("pre-filter res", res);
				this.setState({
					filterJSON: res, // { [id]: { "properties": {} } },
					showFilterForm: true,
					strFilter: JSON.stringify(res)
				});
				return Promise.resolve();
			})
			.catch(error => {
				const {
					status,
				} = error;
				if (status === 404) { // Need to interpret as not created yet
					this.setState({
						filterJSON: {},
						showFilterForm: true,
						strFilter: '{}',
					});
				} else {
					logError("Getting Idea Tracker pre-filter", error);
					this.setState({
						filterJSON: undefined,
						showFilterForm: false,
						strFilter: undefined,
					});
				}
				return Promise.resolve();
			});
	};

	handleClose = (e) => {
		// console.log("handleClose", e);
		e.preventDefault();
		e.stopPropagation();
		this.setState({
			showFilterForm: false,
		});
	};

	handleDelete = () => {
		const {
			aaaKey,
			dialog,
			id,
			ideaTrackerService,
			logError,
		} = this.props;

		const IDTheaders = new Headers();
		IDTheaders.append("X-api-key", aaaKey);
		// IDTheaders.append("Content-Type", "application/json");

		const IDTRequestOptions = {
			headers: IDTheaders,
			method: 'DELETE',
			redirect: 'follow',
		};

		fetch(`${ideaTrackerService}/configuration/dataSource/${id}`, IDTRequestOptions)
			.then(handleResponse)
			.then(res => {
				this.setState({
					filterJSON: {},
					strFilter: ''
				},				() => { dialog(`Filter deleted.`, 'data-source'); });
				return Promise.resolve();
			})
			.catch(error => {
				if (error.status !== 404)logError("Deleting Idea Tracker pre-filter", error);

				return Promise.resolve();
			});

	};

	handleSet = () => {
		// console.log("handleSet");
		// console.log("FETCH write to API");
		const { filterJSON } = this.state;
		const strFilter = JSON.stringify(filterJSON);

		if (!filterJSON || strFilter === '{}' || strFilter === '') {
			this.handleDelete();
			return;
		}

		const {
			aaaKey,
			dialog,
			id,
			ideaTrackerService,
			logError,
		} = this.props;

		const IDTheaders = new Headers();
		IDTheaders.append("X-api-key", aaaKey);
		IDTheaders.append("Content-Type", "application/json");

		const raw = JSON.stringify(filterJSON);

		const IDTRequestOptions = {
			body: raw,
			headers: IDTheaders,
			method: 'PUT',
			redirect: 'follow',
		};

		fetch(`${ideaTrackerService}/configuration/dataSource/${id}`, IDTRequestOptions)
			.then(handleResponse)
			.then(res => {
				// console.log("pre-filter res", res);
				this.setState({
					filterJSON: res,
					showFilterForm: false,
					strFilter: JSON.stringify(res)
				},				() => { dialog(`Filter set.`, 'data-source'); });
				return Promise.resolve();
			})
			.catch(error => {
				logError("Putting Idea Tracker pre-filter", error);

				return Promise.resolve();
			});

	};

	handleStringPaste = (e) => {
		// console.log("e", e);
		const s = e.target.value;
		try {
			const j = JSON.parse(s);
			this.setState({
				filterJSON: j,
				strFilter: s,
				validJSON: true,
			});
		} catch (err) {
			// console.log(err);
			this.setState({
				strFilter: s,
				validJSON: false,
			});
		}
	};

	render() {

		const {
			filterJSON,
			showFilterForm = false,
			strFilter,
			validJSON = true,
		} = this.state;
		// console.log("filterJSON", filterJSON);

		const example = `{\n
\t"criteria": [\n
\t\t{\n
\t\t\t"criterionType": "NUMBER",\n
\t\t\t"property": "string",\n
\t\t\t"operator": "EXISTS",\n
\t\t\t"values": [\n
\t\t\t"string"\n
\t\t\t]\n
\t\t}\n
\t],\n
\t"properties": {\n
\t\t"idProperty": "mmv_id",\n
\t\t"structureProperty": "structure"\n
\t}\n
}`;
		return (
			<>
				<img
					alt='filter'
					className='pre-filter'
					onClick={this.handleClick}
					src={filterOutline}
					title='Filter'
				/>
				{filterJSON ?
					<>
						<div
							className={`pop-over ${showFilterForm ? 'show' : ''}`}
							id={`preFilterContainer`}
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
							}}
						>
							<header>
								<button
									className={`window-close`}
									onClick={this.handleClose}
									title={`Close`}
								></button>
								<h1>JSON Pre-filter Configuration</h1>
							</header>
							<div className={`scrolly`}>
								<form id={`prefilterForm`}>
									{/* <p>Configuration will not be updated unless you press Add/Update</p> */}
									<p>This configuration is used to pre-filter your data.
									You can reduce the number of records.
									You can reduce the number of columns. Note that the ID already in the forms, is the ID of this dataset (to save you from transcribing it).</p>
									<div className={`columns`}>
										<div className={`container`}>
											<label htmlFor='preFilter'>You can build your valid JSON here (try hovering your mouse): </label>
											<div className={`scroll-window`}>
												<ReactJson
													enableAdd={true}
													enableDelete={true}
													iconStyle={`circle`}
													name={false}
													onAdd={this.handleChange}
													onDelete={this.handleChange}
													onEdit={this.handleChange}
													src={filterJSON}
												/>
											</div>
										</div>
										<div className={`container container-example`}>
											<label
												className={`label-example`}
												htmlFor='preFilter'>Example: </label>
											<pre className={`example pre-example`}>
												{example}
											</pre>
										</div>
										<div className={`container container-textarea`}>
											<label htmlFor='preFilter'>Or type/paste valid JSON here: </label>
											<textarea
												className={`${validJSON ? '' : 'error'}`}
												onChange={this.handleStringPaste}
												placeholder='You can also type or paste valid JSON here'
												value={strFilter}
											/>
											<p className={`error-message ${validJSON ? '' : 'error'}`}>Not valid JSON currently</p>
										</div>
									</div>
								</form>
							</div>
							<footer>
								<div className='command-buttons'>
									{/* <button
								className={`data-source`}
								onClick={this.handleClose}>Close</button> */}
									<button
										className={`data-source ${strFilter === '{}' ? 'disabled' : ''}`}
										disabled={strFilter === '{}'}
										onClick={this.handleClear}>Clear</button>
									<button
										className={`data-source ${!validJSON ? 'disabled' : ''}`}
										onClick={this.handleSet}>Set</button>
								</div>
							</footer>
						</div>
					</>
					:
					null
				}
			</>
		);
	};
};
