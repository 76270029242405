import './IngestManager.scss';
import '../Statistics.scss';
import {
	SecureDownload,
	Select,
} from '../../';
import React from 'react';

export class IngestManager extends React.PureComponent {

	constructor(props) {
		super(props);

		this.state = {
			lastWasEnabled: true, // Although it may never have been enabled yet, we only want to refresh stats when there is a transition from not enabled, to enabled, so we need to detect not enabled first.
			servicesLoaded: false,
		};

		this.getServices = this.getServices.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleIngestResponse = this.handleIngestResponse.bind(this);
		this.renderServices = this.renderServices.bind(this);
		this.startIngestA = this.startIngestA.bind(this);
		this.pollingTimer = null;
	}

	handleChange(field, value) {
		this.setState({ [field]: value });
	}

	handleIngestResponse = (response) => {
		return response.status === 200;
	};

	getServices = () => {
		const {
			checkPermissionsLogError,
			handleStatisticsRefresh,
			loggedIn,
		} = this.props;

		const {
			ingestOptionsInitialised = false,
			lastWasEnabled,
		} = this.state;

		if (loggedIn) {
			const {
				ingest,
			} = this.props;
			if (ingest) {
				ingest.ready()
					.then((res) => {
						// console.log("Ingest ready", res);
						const {
							ingestEnabledStatus,
							ingestStatus,
							steps: servicesStatus,
						} = res;
						// console.log("ingestEnabledStatus", ingestEnabledStatus);
						// console.log("servicesStatus", servicesStatus);
						if (!lastWasEnabled && ingestEnabledStatus) handleStatisticsRefresh();

						let ingestOptions = [];
						if (!ingestOptionsInitialised) {
							ingestOptions = ingest.options.map((io) => { return { 'name': io.name, 'value': io.id }; });
							ingestOptions.unshift({ disabled: true, name: 'Please select steps required', value: 'null' });
							this.setState({
								ingestOptions: ingestOptions,
							});
						}

						// We want to trap the transition from not enabled, to the first enabled result.
						// We don't want to do anything on subsequent enabled results.
						this.setState({
							// ...res,
							ingestEnabledStatus: ingestEnabledStatus,
							ingestOptionsInitialised: true,
							ingestStatus: ingestStatus,
							lastWasEnabled: ingestEnabledStatus,
							servicesLoaded: true,
							servicesStatus: servicesStatus,
						});
					})
					.catch((error) => {
						const action = "Retrieving ingest status";
						checkPermissionsLogError(error, action);
					});
			}
		}
	};

	componentDidMount = () => {
		const { pollingInterval = 60 } = this.props;
		this.getServices();

		this.pollingTimer = setInterval(() => {
			this.getServices();
		}, pollingInterval * 1000);
	};

	componentWillUnmount = () => {
		clearInterval(this.pollingTimer);
	};

	startIngestA = () => {
		const {
			ingestOption,
		} = this.state;

		const {
			ingest,
		} = this.props;

		// Disable the button
		this.setState({
			ingestEnabledStatus: false,
			lastWasEnabled: false,
		});

		// Start the upload
		ingest.upload(ingestOption);
	};

	renderServices = () => {
		const {
			servicesStatus = [],
		} = this.state;

		const {
			aaaKey,
			dialog,
			handleShowSpinner,
			logError,
			statisticsEndpoint,
			strToday,
		} = this.props;

		// console.log("servicesStatus", servicesStatus);
		const listItems = [];
		servicesStatus.forEach((service) => {
			listItems.push(<tr key={service.name}>
				<th>{service.name}</th>
				<td>
					<div className={`${service.status.toLowerCase()} indicator circle`} title={`${service.status}`} />
				</td>
				<td><p>Log: <SecureDownload
					aaaKey={aaaKey}
					contentType='text/plain'
					dialog={dialog}
					handleShowSpinner={handleShowSpinner}
					fileName={`${service.name.replaceAll(' ', '-').toLowerCase()}-${strToday}.txt`}
					href={`${statisticsEndpoint}${service.log}`}
					logError={logError}
				/>
				</p></td>
			</tr>);
		});
		return listItems;
	};

	render() {
		const {
			className = '',
			ingest,
			loggedIn,
		} = this.props;

		const {
			ingestEnabledStatus,
			ingestOption = 'null',
			ingestOptions,
			ingestStatus,
		} = this.state;

		return (
			<div id='ingestManager' className={`${className}`} >
				{ingest && ingestOptions ?
					<form>
						<Select
							className={`ingest-options`}
							id='ingestOption'
							handleChange={this.handleChange}
							value={ingestOption}
							options={ingestOptions}
						/>
						<div className='row start-button-row'>
							{!ingestEnabledStatus && ingestStatus !== 'Ready' ? <span>{ingestStatus}</span> : null}
							<button
								className={`startIngestAButton`}
								disabled={(!ingestEnabledStatus || !loggedIn || ingestOption === 'null')}
								onClick={(e) => {
									e.stopPropagation();
									e.preventDefault();
									this.startIngestA();
								}}
							>Start</button>
						</div>
					</form>
					:
					<p>Model building control unavailable</p>
				}
			</div >);
	}
}