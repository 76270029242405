import './Footer.scss';
import React from 'react';

export class Footer extends React.PureComponent {
	render() {
		const {
			appName,
			handleShowAdminClick,
			showAdminLink = false,
		} = this.props;

		return (
			<footer>
				{showAdminLink ?
					<div
						className={`admin-contact`}
						onClick={handleShowAdminClick}
					>Set administrator email address</div>
					:
					null}
				<div className='copyright'>
					Copyright &copy; <span id='year'>{(new Date()).getFullYear()}</span> Optibrium Ltd.
					All rights reserved.
					{appName === 'Cerella' ?
						<>Augmented Chemistry<sup>&reg;</sup> and Cerella&trade; are trademarks of Optibrium Ltd.</>
						:
						null
					}
				</div>
			</footer >
		);
	}
}
