import {
	Accordion,
	AccordionArticle,
	CheckBox,
} from '..';
import {
	actionList,
	hasPolicy,
} from '../../utility';
import React from 'react';
// import { DataSourceForm } from '.';

export class DataSourcesList extends React.Component {
	constructor(props) {
		super(props);

		this.renderActions = this.renderActions.bind(this);
	}

	renderActions = (dataSource) => {

		const {
			dataSourcesOwner,
			getGroupById,
			handleChangeDataSource,
			what,
		} = this.props;

		const dataSourcesAction = [];

		for (const action of actionList) {
			// Inheritance is a ballache
			let value = false;
			let title = '';

			if (what === 'user') {

				// Check for explicit user access
				if (hasPolicy(dataSourcesOwner, 'query_interface', dataSource.id, action.toUpperCase())) {
					value = true;
					title = `User has datasource ${action} access permission`;
				}

				// Second check against user global inheritance
				if (hasPolicy(dataSourcesOwner, 'query_interface', 'data_sources', action.toUpperCase())) {
					value = 3;
					title = `User inherits datasource ${action} access from user global permissions`;
				}

				// console.log("user groups global inheritance and user groups inheritance");
				if (dataSourcesOwner.groups) {
					dataSourcesOwner.groups.forEach((id) => {
						const group = getGroupById(id);

						// Third check against group explicit inheritance
						if (hasPolicy(group, 'query_interface', dataSource.id, action.toUpperCase())) {
							value = 3;
							title = `User inherits datasource ${action} access from group '${id}' permission`;
						}

						// Fourth check against group global inheritance
						if (hasPolicy(group, 'query_interface', 'data_sources', action.toUpperCase())) {
							value = 3;
							title = `User inherits datasource ${action} access from group '${id}' global permissions`;
						}

					});
				}
			}

			if (what === 'group') {

				// Check for explicit group access
				if (hasPolicy(dataSourcesOwner, 'query_interface', dataSource.id, action.toUpperCase())) {
					value = true;
					title = `Group has datasource ${action} access permission`;
				}

				// Second check against group global inheritance
				if (hasPolicy(dataSourcesOwner, 'query_interface', 'data_sources', action.toUpperCase())) {
					value = 3;
					title = `Group inherits datasource ${action} access from group global permissions`;
				}
			}

			dataSourcesAction.push(
				<CheckBox
					className='data-source'
					label={`${action}`}
					id={`dataSource-${dataSource.id}-${action}`}
					key={`data-source-${dataSource.id}-${action}`}
					value={value}
					title={title}
					handleChange={() => {
						handleChangeDataSource(dataSource.id, action);
					}}
				/>
			);
		}
		return dataSourcesAction;
	};

	render() {
		const {
			dataSourcesOwner,
			getGroupById,
			handleChangeDataSource,
			superUI,
		} = this.props;

		let {
			dataSources,
		} = this.props;

		const dataSourcesForm = [];

		dataSources = dataSources.sort((a, b) => a.name - b.name);
		dataSources.forEach((dataSource, i) => {
			if (superUI) {
				dataSourcesForm.push(
					<AccordionArticle
						id={dataSource.name}
						key={dataSource.name}
						title={dataSource.name}
					>
						{this.renderActions(dataSource)}
					</AccordionArticle>
				);
			} else {
				let value = false;
				let title = '';
				// Check for explicit user access
				if (hasPolicy(dataSourcesOwner, 'query_interface', dataSource.id, 'READ')) {
					value = true;
					title = `User has datasource read access permission`;
				}

				// Second check against user global inheritance
				if (hasPolicy(dataSourcesOwner, 'query_interface', 'data_sources', 'READ')) {
					value = 3;
					title = `User inherits datasource read access from user global permissions`;
				}

				// console.log("user groups global inheritance and user groups inheritance");
				if (dataSourcesOwner.groups) {
					dataSourcesOwner.groups.forEach((id) => {
						const group = getGroupById(id);

						// Third check against group explicit inheritance
						if (hasPolicy(group, 'query_interface', dataSource.id, 'READ')) {
							value = 3;
							title = `User inherits datasource read access from group '${id}' permission`;
						}

						// Fourth check against group global inheritance
						if (hasPolicy(group, 'query_interface', 'data_sources', 'READ')) {
							value = 3;
							title = `User inherits datasource read access from group '${id}' global permissions`;
						}

					});
				}
				dataSourcesForm.push(
					<CheckBox
						className='data-source'
						label={`${dataSource.name}`}
						id={`dataSource-${dataSource.id}`}
						key={`data-source-${dataSource.id}`}
						value={value}
						title={title}
						handleChange={() => {
							handleChangeDataSource(dataSource.id, 'read');
						}}
					/>
				);
			}
		});
		return (
			<Accordion
				id='dataSourcesList'
				key='dataSourcesList'
			>{dataSourcesForm}</Accordion>
		);
	}
}
