import './IngestServerConfiguration.scss';
import {
	copyObject,
	handleResponse,
} from '../../utility';
import {
	FieldSet,
	Select,
	TextField,
} from '../../Components';
import React from "react";

export class IngestServerConfiguration extends React.Component {
	constructor(props) {
		super(props);

		const {
			user: userOriginal = {},
		} = this.props;

		const user = copyObject(userOriginal);

		const {
			name = '',
			password = '',
		} = user;

		this.state = ({
			name: name,
			nameError: '',
			oldPassword: '',
			password1: password,
			password1Error: '',
			password2: password,
			password2Error: '',
			passwordLink: '',
			showUsersOwnPasswordFields: false,
			user: user,
			valid: true,
		});

		this.handleChange = this.handleChange.bind(this);
		this.handleChangeInteger = this.handleChangeInteger.bind(this);
		this.handleUpdate = this.handleUpdate.bind(this);

		// this.validate = this.validate.bind(this);
	}

	componentDidMount = () => {
		this.getFormData();
		this.getConfig();
	};

	getFormData = () => {
		const {
			aaaKey,
			logError,
			ingestServerConfigurationEndpoint,
		} = this.props;

		const headers = new Headers();
		headers.append("X-api-key", aaaKey);
		headers.append("Content-Type", "application/json");

		const requestOptions = {
			headers: headers,
			method: 'GET',
			redirect: 'follow',
		};

		if (ingestServerConfigurationEndpoint) {
			fetch(`${ingestServerConfigurationEndpoint}/configuration/ingest/definition`, requestOptions)
				.then(handleResponse)
				.then(res => {
					let formData = { ...res.properties };
					formData = Object.entries(formData).map(([key, value]) => ({ key, value }));

					this.setState({ formData: formData });
				})
				.catch(error => {
					const action = `Retrieving Configuration Server form field data`;
					clearInterval(this.intervalTimer);
					logError(action, error);
				});
		}
	};

	getConfig = () => {
		const {
			aaaKey,
			logError,
			ingestServerConfigurationEndpoint,
		} = this.props;

		if (ingestServerConfigurationEndpoint) {
			const headers = new Headers();
			headers.append("X-api-key", aaaKey);
			headers.append("Content-Type", "application/json");

			const requestOptions = {
				headers: headers,
				method: 'GET',
				redirect: 'follow',
			};

			fetch(`${ingestServerConfigurationEndpoint}/configuration/ingest`, requestOptions)
				.then(handleResponse)
				.then(res => {
					this.setState({ configuration: res });
				})
				.catch(error => {
					const action = `Retrieving system configuration`;
					clearInterval(this.intervalTimer);
					logError(action, error);
				});
		}
	};

	handleChange = (field, value) => {
		const { configuration } = this.state;
		configuration[field] = value;
		this.setState({
			configuration: configuration
		}, this.validate);
	};

	handleChangeInteger = (field, value) => {
		const { configuration } = this.state;
		if (value.includes('.')) return;
		configuration[field] = value;
		this.setState({
			configuration: configuration
		}, this.validate);
	};

	handleUpdate = () => {
		const {
			aaaKey,
			dialog,
			logError,
			ingestServerConfigurationEndpoint,
		} = this.props;

		if (ingestServerConfigurationEndpoint) {
			const { configuration } = this.state;

			const headers = new Headers();

			headers.append("Accept", '*/*');
			headers.append("Content-Type", 'application/json');
			headers.append('responseType', 'text');
			headers.append("X-api-key", aaaKey);

			const requestOptions = {
				body: JSON.stringify(configuration),
				headers: headers,
				method: 'PUT',
				redirect: 'follow',
			};

			const endpoint = `${ingestServerConfigurationEndpoint}/configuration/ingest`;

			fetch(endpoint, requestOptions)
				.then(handleResponse)
				.then(res => {
					// Update version
					this.setState({
						configuration: { ...configuration, ...res, }
					}, () => {
						dialog(
							"Configuration succesfully updated",
						);
					});
				})
				.catch(err => {
					if (err.status === 409) {
						dialog(
							`Configuration has been updated by another user, please refresh`,
							'home',
							'yesno',
							() => { window.location.reload(); }
						);
					} else {
						logError('Updating system configuration', err);
					}
				});
		}
	};

	render() {

		const {
			configuration = [],
			formData = [],
		} = this.state;

		const form = [];

		const nData = formData.length; // Number of config items, we don't know in advance so set max-height once we know.
		if (!this.maxHeightAdded && nData) {
			const maxHeight = nData * 62 + 154;
			const configurationNode = document.getElementById('configuration');

			if (configurationNode) {
				this.setState({ maxHeightAdded: true });
				const style = `.accordion .accordion-article#configuration.expanded > .content { max-height: ${maxHeight}px; }`;
				const styleSheet = document.createElement("style");
				styleSheet.innerText = style;
				document.head.appendChild(styleSheet);
				this.maxHeightAdded = true;
			}
		}

		formData.forEach((fd) => {
			const {
				key,
				value: field
			} = fd;

			const message = [];
			const {
				description,
				title,
				type,
			} = field;

			message.push(
				<p key={`description-${key}`}>{`${description}`}</p>
			);

			const info = {
				message: message,
				title: title,
			};

			if (key !== 'version')
				switch (type) {
					case 'integer':
						form.push(
							<TextField
								handleChange={this.handleChangeInteger}
								id={`${key}`}
								info={description ? info : null}
								key={`${key}`}
								label={`${field.title}`}
								max={field.maximum ? field.maximum : null}
								min={field.minimum ? field.minimum : null}
								step={1}
								type={`number`}
								value={`${configuration[key] ? configuration[key] : null}`}
							/>
						);
						break;
					case 'number': // float
						form.push(
							<TextField
								handleChange={this.handleChange}
								id={`${key}`}
								info={description ? info : null}
								key={`${key}`}
								label={`${field.title}`}
								max={field.maximum ? field.maximum : null}
								min={field.minimum ? field.minimum : null}
								step={`any`}
								type={`number`}
								value={`${configuration[key] ? configuration[key] : null}`}
							/>
						);
						break;
					case 'string':
					case 'select':
					case 'text':
						if (field.enum) {
							const options = field.enum.map((item => ({ name: item, value: item })));
							form.push(
								<Select
									handleChange={this.handleChange}
									id={`${key}`}
									info={description ? info : null}
									key={`${key}`}
									label={`${field.title}`}
									options={options}
									value={`${configuration[key] ? configuration[key] : null}`}
								/>
							);
						} else
							form.push(
								<TextField
									handleChange={this.handleChange}
									id={`${key}`}
									info={description ? info : null}
									key={`${key}`}
									label={`${field.title}`}
									type={`TEXT`}
									value={`${configuration[key] ? configuration[key] : null}`}
								/>
							);
						break;
					default:
						break;
				}
		});

		return (
			<div>
				<form className={`form ingest-server-configuration`} onSubmit={(e) => { e.preventDefault(); }}>
					<FieldSet className={`details`}>
						<>
							{form}
						</>
					</FieldSet>
					<div className='command-buttons'>
						{/* <button
							// className='user'
							id='ingestConfigurationButtonCancel'
							onClick={handleCancelClick}
						>Cancel</button> */}
						<button
							// className={`${!valid ? 'disabled' : ''}`}
							// disabled={(!ingestEnabledStatus || !loggedIn || ingestOption === 'null')}
							id={`ingestConfigurationButtonUpdate`}
							onClick={(e) => {
								e.preventDefault();
								this.handleUpdate(e);
							}}
						>Update</button>
					</div>
				</form >
			</div>
		);
	}
}