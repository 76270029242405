import './Header.scss';
import {
	Crumbtrail,
	Logout
} from '..';
import cerella from '../../images/logo.svg';
import idtLogo from '../../images/idea_tracker_logo_white.png';
import React from 'react';

export class Header extends React.PureComponent {

	render() {
		const {
			appName = 'Cerella',
			handleHomeClick,
			trail,
		} = this.props;
		return (
			<header className="app-header" >
				{appName === 'Cerella' ?
					<img src={cerella} alt="Cerella" onClick={handleHomeClick} />
					:
					<img src={idtLogo} alt="IdeaTracker" onClick={handleHomeClick}/>
				}
				<Crumbtrail trail={trail} {...this.props} />
				<Logout {...this.props} />
			</header >
		);
	}
}
