import './SystemLog.scss';
import {
	DateRange,
	Select,
	TextField,
}from '../..';
import {
	handleResponse,
	handleResponseCSV,
} from '../../../utility';
import download from '../../../images/download.svg';
import React from 'react';

export class SystemLog extends React.PureComponent {

	constructor(props) {
		super(props);

		this.state = {
			logSelection: undefined,
			maxRowLimit: 1000,
			rowLimit: 1000,
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleChangeDataRange = this.handleChangeDateRange.bind(this);
		this.handleDownload = this.handleDownload.bind(this);
		this.renderLogTable = this.renderLogTable.bind(this);
		this.updateData = this.updateData.bind(this);
	}

	handleChange = (field, value) => {
		const { dialog } = this.props;
		switch (field) {
			case 'rowLimit':
				const { maxRowLimit } = this.state;
				if (value > maxRowLimit)dialog(`Cannot return more than ${maxRowLimit} rows`);
				this.setState({ [field]: Math.min(value, maxRowLimit)});
				break;
			default:
				this.setState({ [field]: value }, this.validate);
				break;
		}
	};

	handleChangeDateRange = (id, returnObject) => {
		this.setState({
			[`${id}Data`]: { ...returnObject },
		}, this.validate);
	};

	handleDownload = () => {
		// console.log("handleDownload");
		const {
			aaaKey,
			logServerEndpoint,
			dialog,
			handleShowSpinner,
			logError,
		} = this.props;

		const {
			logServerDateRangeData,
			logSelection = '',
			rowLimit = 1000,
		} = this.state;

		const {
			endDate,
			startDate,
		} = logServerDateRangeData;

		handleShowSpinner(true, "Downloading...");
		const headers = new Headers();
		headers.append("Accept", 'text/csv');
		headers.append("Content-Type", 'application/json');
		headers.append('responseType', 'text');
		headers.append("X-api-key", aaaKey);

		// Parse local date and time set in browser
		const startDateTime = new Date(startDate);
		const endDateTime = new Date(endDate);

		// Send UTC date and time to server in ISO 8601 format
		const requestOptions = {
			body: JSON.stringify({
				endTime: endDateTime.toISOString(),
				limit: rowLimit,
				log: logSelection,
				startTime: startDateTime.toISOString(),
			}),
			headers: headers,
			method: 'PUT',
			redirect: 'follow',
		};

		// Anti cache
		const ms = Date.now();
		const fileName = (`system_log_${logSelection}-${startDate}_${endDate}.csv`);

		fetch(`${logServerEndpoint}/logs/csv?anticache=${ms}`, requestOptions)
			.then(res => handleResponseCSV(res, dialog))
			.then(res => {

				if (!res) {
					// File not available
					handleShowSpinner(false);
					return;
				}
				const csvData = new Blob([res], { type: 'text/csv' });
				const csvUrl = URL.createObjectURL(csvData);
				const a = document.createElement('a');

				a.href = csvUrl;
				a.target = '_blank';
				a.className = 'hidden';
				a.download = fileName;

				document.body.appendChild(a);
				a.click();

				// Tidy up, delete it.
				document.body.removeChild(a);
				handleShowSpinner(false);

			})
			.catch(error => {
				const action = `Retrieving secure download`;
				logError(action, error);
				handleShowSpinner(false);

			});

	};

	renderLogTable = () => {
		const { logServerLogData } = this.state;
		const { headers, logs } = logServerLogData;

		if (headers.length === 0 && logs.length === 0) return (
			<div
				className={`right`}
				key={`rightone`}
				id='emptyLog'
			>
				<h2>No data for that time period / log combination</h2>
			</div>
		);

		const headerRowJSX = [];
		const headerCellsJSX = [];
		headers.forEach((header, index) => {
			headerCellsJSX.push(
				<th key={`headerKey-${index}`}>{header}</th>
			);
		});
		headerRowJSX.push(<tr key={`logRowKey-${0}`}>{headerCellsJSX}</tr>);

		const logsRowsJSX = [];
		logs.forEach((log, i) => {
			const logsCellsJSX = [];
			log.forEach((logCell, j) => {
				logsCellsJSX.push(
					<td key={`logCellKey-${i}-${j}`}>{logCell}</td>
				);
			});
			logsRowsJSX.push(
				<tr key={`logRowKey-${i}`}>{logsCellsJSX}</tr>
			);
		});
		return (
			<div className={`right table-container`} key={`righttwo`}
			>
				<table className={`right striped`}>
					<thead>
						{headerRowJSX}
					</thead>
					<tbody>
						{logsRowsJSX}
					</tbody>
				</table>
			</div>

		);
	};

	render() {
		const {
			logServerLogs = [],
			dialog,
		} = this.props;

		const {
			logServerLogData,
			logServerDateRangeData,
			error = true,
			logSelection = '',
			rowLimit = 1000,
		} = this.state;

		let endDate, startDate;
		if (logServerDateRangeData) {
			({endDate, startDate}	= logServerDateRangeData);
		}

		const logServerLogOptions = [{
			name: 'Select a log',
			selected: true,
			value: '',
		}];
		logServerLogs.forEach((log) => {
			logServerLogOptions.push({name: log.name, value: log.log});
		});

		return (
			<>
				<div className='date-range form left'>
					<Select
						className={`invert`}
						handleChange={this.handleChange}
						id={`logSelection`}
						label={`Log`}
						options={logServerLogOptions}
						placeHolder={`Select a log`}
						value={logSelection}
					/>
					<TextField
						dialog={dialog}
						handleChange={this.handleChange}
						id={`rowLimit`}
						label={'Limit rows returned'}
						max={1000}
						min={1}
						placeholder={`Limit rows returned`}
						type={`number`}
						value={rowLimit}
					/>
					<h3>Date Range</h3>
					<DateRange
						handleChangeDateRange={this.handleChangeDateRange}
						id={`logServerDateRange`}
						time={true}
						value={logServerDateRangeData}
					/>
					<div className={`command-buttons`}>
						{startDate && endDate && logSelection ?
							<p id='logPointDownloadLink' >CSV:
								<img
									alt='download'
									className='download'
									onClick={(e) => {
										this.handleDownload();
										e.stopPropagation();
									}}
									src={download}
									title='Download'
								/>
							</p>
							:
							<span/>
						}
						<button
							className={`home ${error ? 'disabled' : ''}`}
							onClick={this.updateData}
							disabled={error}
						>Update</button>
					</div>
				</div>
				{logServerLogData ?
					<>{this.renderLogTable()}</>
					:
					<div
						className={`right`}
						id='emptyLog'
					>
						{startDate && endDate && logSelection === '' ?
							<h2>Please select a log</h2>
							:
							null
						}
						{(!startDate || !endDate) && logSelection !== '' ?
							<h2>Please select a date range</h2>
							:
							null
						}
						{(!startDate || !endDate) && logSelection === '' ?
							<h2>Please select a log and a date range</h2>
							:
							null
						}
						{startDate && endDate && logSelection !== '' ?
							<h2>Press 'Update' to start retrieving log file</h2>
							:
							null
						}
					</div>
				}
			</>
		);
	}

	updateData = () => {
		const {
			aaaKey,
			logServerEndpoint,
			handleShowSpinner,
			logError,
		} = this.props;

		handleShowSpinner(true, `Retrieving log file...`);

		const {
			logServerDateRangeData,
			logSelection,
			rowLimit,
		} = this.state;

		const {
			endDate,
			startDate,
		} = logServerDateRangeData;

		if (logServerEndpoint && startDate && endDate && startDate !== '' && endDate !== '' && logSelection !== '') {
		// Get all LogServer logs

			const cwHeaders = new Headers();
			cwHeaders.append("Accept", '*/*');
			cwHeaders.append("Content-Type", 'application/json');
			cwHeaders.append('responseType', 'text');
			cwHeaders.append("X-api-key", aaaKey);

			// Parse local date and time set in browser
			const startDateTime = new Date(startDate);
			const endDateTime = new Date(endDate);

			// Send UTC date and time to server in ISO 8601 format
			const cwRequestOptions = {
				body: JSON.stringify({
					endTime: endDateTime.toISOString(),
					limit: rowLimit,
					log: logSelection,
					startTime: startDateTime.toISOString(),
				}),
				headers: cwHeaders,
				method: 'PUT',
				redirect: 'follow',
			};

			fetch(`${logServerEndpoint}/logs/json`, cwRequestOptions)
				.then(handleResponse)
				.then(res => {
					this.setState({
						logServerLogData: res,
					}, () => {handleShowSpinner(false);});
				})
				.catch(error => {
					logError("Retrieving system log", error);
					handleShowSpinner(false);
				});


		}
	};

	validate = () => {
		const {
			logServerDateRangeData,
			logSelection,
		} = this.state;

		let endDate, startDate;
		if (logServerDateRangeData) {
			({endDate, startDate}	= logServerDateRangeData);
		}

		const error = !startDate || !endDate || !logSelection || logSelection === '';

		this.setState({
			error: error,
		});

		return error;
	};
}
