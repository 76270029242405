import '../../styles/LoginAndPasswordReset.scss';
import {
	FieldSet,
	Footer,
	HelpButton,
	Logo,
	TextField,
} from '..';
import cerella from '../../images/cerella_animate.svg';
import React from 'react';

export class PasswordChange extends React.PureComponent {

	constructor(props) {

		super(props);

		const {
			userName,
		} = this.props;

		this.state = ({
			formDirty: false,
			oldPassword:'',
			password1: '',
			password2: '',
			userName:userName,
			valid: false,
		});

		this.handleChange = this.handleChange.bind(this);
	}

	handleChange = (what, value) => {
		const { handleClearLoginError } = this.props;
		handleClearLoginError();
		switch (what) {
			case 'oldPassword':
				this.setState({
					formDirty: true,
					oldPassword: value,
					oldPasswordError: '',
				}, this.validate);
				break;
			case 'password1':
				this.setState({
					formDirty: true,
					password1: value,
				}, this.validate);
				break;
			case 'password2':
				this.setState({
					formDirty: true,
					password2: value,
				}, this.validate);
				break;
			case 'userName':
				this.setState({
					formDirty: true,
					oldPasswordError: '',
					userName: value,
				}, this.validate);
				break;		}
	};

	validatePasswords = () => {
		const {
			AAA
		} = this.props;

		const {
			oldPassword,
			password1,
			password2,
		} = this.state;

		if (oldPassword.trim() === '') {
			this.setState({
				oldPasswordError: "Please enter your old password",
			});
			return false;
		} else {
			this.setState({
				oldPasswordError: "",
			});
		}

		if (password1.trim() === '') {
			this.setState({
				password1Error: "Please enter a new password",
			});
			return false;
		} else {
			this.setState({
				password1Error: "",
			});
		}

		if (AAA && AAA.passwordPolicy !== undefined && !AAA.passwordPolicy.valid(password1)) {
			this.setState({
				password1Error: 'Password does not conform to password rules',
			});
			return false;
		}

		if (password1 === password2) {
			this.setState({
				password2Error: ''
			});
			return true;
		} else {
			this.setState({
				password2Error: "Passwords do not match",
			});
			return false;
		}
	};

	validate = () => {
		this.setState({
			valid: this.validatePasswords()
		});
	};

	render() {
		const {
			appName,
			handleHomeClick,
			handlePasswordChange,
			loginError,
			passwordRules,
		} = this.props;

		const {
			formDirty,
			oldPassword,
			password1,
			password2,
			password1Error,
			password2Error,
			userName,
			valid,
		} = this.state;

		let {
			oldPasswordError,
		} = this.state;

		if (oldPasswordError === '' && !formDirty)oldPasswordError = loginError;

		return (
			<div id='passwordResetPage'>
				<div id='logoContainer'>
					<Logo appName={appName} />
					{appName === 'Cerella' ? <img src={cerella} alt="Cerella" /> : null}
				</div>
				<form
					className={`form login ${(this.props.loggedIn ? '' : 'show')}`}
					onSubmit={(e) => {
						e.preventDefault();
						handlePasswordChange(e, {
							oldPassword: oldPassword,
							password: password1,
							userName: userName,
						});
						this.setState({
							formDirty: false,
						});
					}}
				>
					<FieldSet controlId="formBasicEmail">
						<TextField
							handleChange={this.handleChange}
							id='userName'
							value={userName}
							placeholder='User name'
							label='User name'
						/>
						<TextField placeholder='Old Password'
							error={oldPasswordError}
							handleChange={this.handleChange}
							id='oldPassword'
							label='Old Password*'
							type={'password'}
							value={oldPassword}
						/>
						<TextField placeholder='New Password'
							error={password1Error}
							handleChange={this.handleChange}
							id='password1'
							label='New password*'
							type={'password'}
							value={password1}
						/>
						<TextField placeholder='Please retype new password'
							error={password2Error}
							handleChange={this.handleChange}
							id='password2'
							label='Please retype new password*'
							type={'password'}
							value={password2}
						/>
						{(passwordRules !== undefined) ?
							<div
								id='passwordRules'
								className={'hideOnUpdate'}
							>
								<p>{passwordRules}</p>
							</div> : null}

					</FieldSet>

					<div className='button-group ok-cancel' >
						<button
							className={`home`}
							onClick={handleHomeClick}
							onMouseDown={handleHomeClick}
						>
							Cancel
						</button>
						<button
							className={`home ${!valid ? 'disabled' : ''}`}
							disabled={!valid}
							type='submit'
						>
							Change password
						</button>
						<HelpButton/>
					</div>
				</form>
				<Footer
					appName={appName}
				/>
			</div >
		);
	};
};